import React from 'react';
import './Likvardiga.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import blueFlag from '../../assets/images/blue-flag.png';
import yellowFlag from '../../assets/images/yellow-flag.png';
import remove from '../../assets/images/x-mark.png';
import arrowDown from '../../assets/images/arrow-down-black.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { fetchAuth } from '../../fetch-auth';
import Chart from "react-apexcharts";
import { autoAnalysis } from './autoAnalysis';
import exitImg from '../../assets/images/exit.png';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import fullscreen from '../../assets/images/fullscreen.png';
import zoomOut from '../../assets/images/zoom-out.png';
import zoomIn from '../../assets/images/icon-zoom.png';
import { jitter } from '../AnalysLika/Jitter';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import CustomPrompt from '../General/CustomPrompt.js';
import percentile from "percentile";
import extraOn from '../../assets/images/extraOff.png';
import extraOff from '../../assets/images/extraOffKryss.png';
import bigText from '../../assets/images/bigText.png';
import eyeOn from '../../assets/images/eyeOn.png';
import eyeOff from '../../assets/images/eyeOff.png';
import lang from '../Language/language.js';

export class LikvardigaNormal extends React.Component {
    constructor(props) {
        super(props);
        let langu = localStorage.getItem('language') ?? 'sv';
        this.state = {
            group1: [],
            group2: [],
            group3: [],
            group4: [],
            group5: [],
            group6: [],
            group7: [],
            group8: [],
            group9: [],
            group10: [],
            group11: [],
            group12: [],
            group13: [],
            group14: [],
            group15: [],
            comments: [],
            commentsLastYear: [],
            analysis: "all",
            deletedComments: [],
            allComments: [],
            selectedYear: 0,
            year: 0,
            showOldComments: true,
            saveButtonStatus: "Sparat",
            moreFields: false,
            clickedGroup: "",
            avgSalary: null,
            womenPercent: null,
            demands: null,
            showInfoIcon: false,
            nrOfEqualentGroups: 6,
            //Below is for AIComment
            showAIComment: false,
            AInotable: "",
            AIreason: "",
            AIAnalys: "",
            //Below is for adding completely new comment
            addComment: "",
            addReason: "",
            addTodo: "",
            addDeadline: "",
            addCost: "",
            addResponsible: "",
            addAnalysis: "likvärdiga",
            addCommentSummary: "",
            //Below is for autoAnalysis
            divOpened: "first",
            analyseGroup1: "Välj grupp",
            group1EqualGroup: 0,
            selectGroup1: false, //when true, we can click to select group 1
            analyseGroup2: "Välj grupp",
            group2EqualGroup: 0,
            selectGroup2: false,
            allEmp: [],
            tableInfo: [{ oneNrOfEmp: "" }],
            analysisText: "Inga grupper valda",
            //For popups:
            popUp: false,
            popUpComments: false,
            popUpInfo: false,
            popUpEditSalarySetting: false,
            //below is for showing salaries as percent
            showPercent: false,
            hideName: false,
            //If chart is edited
            groupOneEdited: [],
            groupTwoEdited: [],
            clickedPerson: "",
            clickedPersonIndex: "",
            serieIndex: "",
            popItUp: false,
            newSalary: 0,
            showChangesPrompt: "Yes", //<-- For changes PopUp
            showDiffPercent: false,
            //Below is for making text big or small
            bigTextIcon: true,
            textBig1: false,
            textBig2: false,
            textBig3: false,
            textBig4: false,
            textBig5: false,
            textBig6: false,
            textBig7: false,
            textBig8: false,
            textBig9: false,
            textBig10: false,
            textBig11: false,
            textBig12: false,
            textBig13: false,
            textBig14: false,
            textBig15: false,
            //Below is for extra vs salary
            hideExtra: "includeExtra", //"includeExtra", "hideExtra", "onlyExtra"
            showHideExtraIcon: "off",
            //below is for EU
            showWhat: "Original",
            analysisOpen: false,
            infoBoxEU: false,
            filteredData: [],
            filteredOut: [],
            seriesBigFilteredOut: [],
            annotationsFilteredOut: [],
            gapPercent: false,
            payGapPerGroup: [],
            //Below is for chart
            xAxisType: "experience",
            xAxisTypeSummary: "experience",
            hideExtraSummary: "includeExtra", //"includeExtra", "hideExtra", "onlyExtra"
            chartWidthSummary: "500",
            chartHeightSummary: "auto",
            apexPopUpSummary: "",
            summaryGreyBtn: false,
            spridningPopUp: false,
            popUpSummaryQuestionMark: false,
            xSetting: false,
            chartWidth: "300",
            chartHeight: "255",
            apexPopUp: "",
            longestExp: 0,
            summaryView: "chart", // Show chart or comments for summary view
            summaryComments: [], // Shows all comments that the algoritm think fits for the summary.
            commentsEqual: [], // equal comments is used for the summary
            selectedSummaryComments: "equivalent", // selects what type of comments to show in the summary
            colorFill: "work", // selects if color should be for work or gender in the chart for summary
            popItUpSummary: false, // Show/hide popUp for hiding people in summary
            popUpEditSalarySettingSummary: false, // Show/hide the edit salary box in the summary for the popup
            clickedPersonSummary: "", // The clicked person in summary popUp
            clickedPersonIndexSummary: "", // The clicked person index in summary popUp
            serieIndexSummary: "", // The serie our clicked person is in in summary. Each work is its own serie
            newSalarySummary: 0, // new edited salary for summary
            popUpSummaryAction: false,
            summaryNrOfActions: 0,
            series: [{
                name: "",
                data: []
            },
            {
                name: "",
                data: []
            }
            ],
            options: {
                colors: ['#ffffff', 'transparent'],
                fill: {
                    colors: ['#ffffff', 'transparent']
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                grid: {
                    show: false
                },
                /* toolbar: {
                     show: false,
                 },*/


                //fill:['white', 'blue'],
                tooltip: {
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        return `<ul>
                            <li style="color: #333333"><b>Namn</b>: ${data.fullName}</li>
                            <li style="color: #333333"><b>Lön</b>: ${data.y} kr</li>
                            <li style="color: #333333"><b>År i org</b>: ${data.expNoJitter}</li>
                            <li style="color: #333333"><b>Ålder</b>: ${data.age}</li>
                              <li style="color: #333333"><b>Ort</b>: ${data.city}</b>: ${data.city}</li>
                               <li style="color: #333333"><b>Kön</b>: ${data.gender === "woman" ? "Kvinna" : "Man"}</li>
                            <li style="color: #4caf9e; font-weight: 700; margin-top: 5px">${data.equalGroup}</li>
                            </ul>`
                    },
                },
                chart: {
                    height: 350,
                    type: 'scatter',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    tickAmount: 5,
                    type: 'numeric',
                    //max: longestExp,
                    //min: 0,
                    ticks: {
                        includeBounds: true,
                    },
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#a3eade'
                        }
                    },
                },
                yaxis: {
                    tickAmount: 0,
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        },
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        /*hover: {*/
                        style: {
                            colors: '#a3eade',
                            /*    }*/
                        },
                    },
                },
            },
            // Below is for EU version
            seriesBig: [{
                name: "",
                data: []
            },
            {
                name: "",
                data: []
            }
            ],
            optionsBig: {
                annotations: {
                    points: []
                },
                /* annotations: {
                     points: [
                         {
                             //If men
                             x: 4, // Index of the data point in the series
                             y: 30000, // Y-value of the data point
                             marker: {
                                 cssClass: 'annotationChartRhombus', //Important, makes it not stand away from hover
                               size: 8,
                               radius: 0,
                              // shape: "square",
                               fillColor: "#fb9240",
                               strokeColor: "#fb9240",
                               strokeWidth: 2,
                               //imageUrl: {zoomIn}, // Replace with the path to your image
                               //imageUrl: '../../assets/images/icon-zoom.png',
                             },
                             label: {
                                 text: "K", //0x2642 is male, 0x2640 is female, use like this: String.fromCharCode(0x2640)
                                 offsetY: 22.2,
                                 borderWidth: 0,
                                 style: {
                                     background: 'transparent',
                                     color: "#fff",
                                     fontSize: '10px',
                                     fontWeight: 600,
                                 }
                             }
                           },
                       {
                         //If women
                         x: 6, // Index of the data point in the series
                         y: 27000, // Y-value of the data point
                         marker: {
                             cssClass: 'annotationChart', //Important, makes it not stand away from hover
                           size: 5.5,
                           radius: 0,
                           shape: "circle",
                           fillColor: "transparent",
                           strokeColor: "#fff",
                           strokeWidth: 1.5,
                         },
                       }
                     ],
                   },*/

                colors: ['#ffffff', 'transparent'],
                fill: {
                    colors: ['#ffffff', 'transparent']
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                grid: {
                    show: true,
                    borderColor: '#e1e0e0',
                },
                /* toolbar: {
                     show: false,
                 },*/
                //fill:['white', 'blue'],
                tooltip: {
                    custom: function ({ seriesBig, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                        /* return `<ul>
                             <li style="color: #333333"><b>Namn</b>: ${data.fullName}</li>
                             <li style="color: #333333"><b>Lön</b>: ${data.y} kr</li>
                             <li style="color: #333333"><b>År i org</b>: ${data.expNoJitter}</li>
                             <li style="color: #333333"><b>Ålder</b>: ${data.age}</li>
                             <li style="color: #333333">${data.equalGroup}</li>
                             </ul>`
                     },*/
                        return `  <ul>
                            <li style="color: #333333"><b>${langu === "sv" ? "Namn" : lang[langu].name}</b>: ${data.fullName}</li>
                            <li style="color: #333333"><b>${langu === "sv" ? "Lön" : lang[langu].salary}</b>: ${data.y} kr</li>
                            <li style="color: #333333"><b>${langu === "sv" ? "År i org" : lang[langu].yInOrg}</b>: ${data.experience}</li>
                            <li style="color: #333333"><b>${langu === "sv" ? "Ålder" : lang[langu].age}</b>: ${data.age}</li>
                              <li style="color: #333333"><b>${langu === "sv" ? "Ort" : lang[langu].city}</b>: ${data.city}</b>: ${data.city}</li>
                               <li style="color: #333333"><b>Kön</b>: ${langu === "sv" && data.gender === "woman" ? "Kvinna" : langu === "sv" && data.gender !== "woman" ? "Man" : data.gender}</li>
                               <li style="color: #4caf9e; font-weight: 700; margin-top: 5px">${data.groupNameArbVardering}</li>
                            </ul>`
                    },
                },
                chart: {
                    height: 350,
                    type: 'scatter',
                    /*zoom: {
                        enabled: false,
                    },*/
                    zoom: {
                        enabled: true,
                        type: 'x',
                        zoomedArea: {
                            fill: {
                                color: '#54c7b9',
                            },
                            stroke: {
                                color: '#54c7b9',
                                opacity: 0.4,
                                width: 1,
                            }
                        }
                    },
                    toolbar: {
                        //show: false
                        show: true,
                        tools: {
                            customIcons: []
                        }
                    },
                    events: {
                        beforeZoom: (e, { xaxis }) => {
                            return {
                                xaxis: {
                                    tickAmount: 1,
                                    max: xaxis.max,
                                    min: xaxis.min
                                }
                            }
                        },
                        dataPointSelection: (event, chartContext, config) => {
                            /*let getGroupOne = this.state.series[0].data
                            let getGroupTwo = this.state.series[1].data
                            let clickedPerson = ""

                            if (config.seriesIndex == 0) { //If mens group
                                getGroupOne = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
                                clickedPerson = getGroupOne[config.dataPointIndex]
                            }
                            if (config.seriesIndex == 1) { //If womens group
                                getGroupTwo = this.state.series[1].data.slice(0)
                                clickedPerson = getGroupTwo[config.dataPointIndex]
                            }


                            //Edit groups so they include everything that is needed before setting state
                            let prepareGroupOne = getGroupOne.map(function (el) {
                                return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, equalentGroup: groupOne[0].equalentGroup }
                            })
                            let prepareGroupTwo = getGroupTwo.map(function (el) {
                                return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, equalentGroup: groupTwo[0].equalentGroup }
                            })*/

                            let theSeriesIndex = config.seriesIndex
                            let theSelectedSeriex = this.state.seriesBig[theSeriesIndex].data.slice(0)
                            let clickedPerson = theSelectedSeriex[config.dataPointIndex]

                            this.setState({
                                /* groupOneEdited: prepareGroupOne,
                                 groupTwoEdited: prepareGroupTwo,
                                 clickedPerson: clickedPerson,
                                 clickedPersonIndex: config.dataPointIndex,
                                 serieIndex: config.seriesIndex,*/
                                clickedPersonSummary: clickedPerson,
                                clickedPersonIndexSummary: config.dataPointIndex,
                                serieIndexSummary: config.seriesIndex,
                                popItUpSummary: true,
                            })
                        }
                    },
                },
                markers: {
                    size: 6,
                    strokeWidth: 0,
                    hover: {
                        size: 6
                    },
                    fillOpacity: 0,
                },
                xaxis: {
                    tickAmount: 5,
                    type: 'numeric',
                    //max: longestExp,
                    //min: 0,
                    ticks: {
                        includeBounds: true,
                    },
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#44484a'
                        }
                    },
                },
                yaxis: {
                    tickAmount: 0,
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        },
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        /*hover: {*/
                        style: {
                            colors: '#44484a',
                            /*    }*/
                        },
                    },
                },
            },
        }
    }


    //Toggle div
    toggleDiv = () => {
        if (this.state.divOpened === "first") this.setState({ divOpened: "yes" })
        else if (this.state.divOpened === "yes") this.setState({ divOpened: "no" })
        else if (this.state.divOpened === "no") this.setState({ divOpened: "yes" })
    }
    hideExtra = () => {
        return new Promise((resolve, reject) => {
          // First, we update the state with setState
          this.setState(
            (prevState) => {
              // Determine the new value for hideExtra based on the current state
              let newHideExtra;
              if (prevState.hideExtra === "includeExtra") {
                newHideExtra = "hideExtra";
              } else if (prevState.hideExtra === "hideExtra") {
                newHideExtra = "onlyExtra";
              } else {
                newHideExtra = "includeExtra";
              }
              return { hideExtra: newHideExtra };
            },
            () => {
              // Perform your logic after the state has been updated
              if (this.state.hideExtra === "includeExtra") {
                document.documentElement.style.setProperty('--widthExtraSalIcon', '20px');
                document.documentElement.style.setProperty('--margRightExtraSalIcon', '0px');
              } else {
                document.documentElement.style.setProperty('--widthExtraSalIcon', '86px');
                document.documentElement.style.setProperty('--margRightExtraSalIcon', '-5px');
              }
              // Resolve the promise when the logic is done
              resolve();
            }
          );
        });
      };
    hideExtraSummary = () => {
        return new Promise((resolve, reject) => {
          // First, we update the state with setState
          this.setState(
            (prevState) => {
              // Determine the new value for hideExtraSummary based on the current state
              let newHideExtraSummary;
              if (prevState.hideExtraSummary === "includeExtra") {
                newHideExtraSummary = "hideExtra";
              } else if (prevState.hideExtraSummary === "hideExtra") {
                newHideExtraSummary = "onlyExtra";
              } else {
                newHideExtraSummary = "includeExtra";
              }
              return { hideExtraSummary: newHideExtraSummary };
            },
            () => {
              // Perform your logic after the state has been updated
              if (this.state.hideExtraSummary === "includeExtra") {
                document.documentElement.style.setProperty('--widthExtraSalIcon', '20px');
                document.documentElement.style.setProperty('--margRightExtraSalIcon', '0px');
              } else {
                document.documentElement.style.setProperty('--widthExtraSalIcon', '86px');
                document.documentElement.style.setProperty('--margRightExtraSalIcon', '-5px');
              }
              // Resolve the promise when the logic is done
              resolve();
            }
          );
        });
      };

    //Make text big/small
    makeTextbig1 = () => { this.state.textBig1 ? this.setState({ textBig1: false }) : this.setState({ textBig1: true }) }
    makeTextbig2 = () => { this.state.textBig2 ? this.setState({ textBig2: false }) : this.setState({ textBig2: true }) }
    makeTextbig3 = () => { this.state.textBig3 ? this.setState({ textBig3: false }) : this.setState({ textBig3: true }) }
    makeTextbig4 = () => { this.state.textBig4 ? this.setState({ textBig4: false }) : this.setState({ textBig4: true }) }
    makeTextbig5 = () => { this.state.textBig5 ? this.setState({ textBig5: false }) : this.setState({ textBig5: true }) }
    makeTextbig6 = () => { this.state.textBig6 ? this.setState({ textBig6: false }) : this.setState({ textBig6: true }) }
    makeTextbig7 = () => { this.state.textBig7 ? this.setState({ textBig7: false }) : this.setState({ textBig7: true }) }
    makeTextbig8 = () => { this.state.textBig8 ? this.setState({ textBig8: false }) : this.setState({ textBig8: true }) }
    makeTextbig9 = () => { this.state.textBig9 ? this.setState({ textBig9: false }) : this.setState({ textBig9: true }) }
    makeTextbig10 = () => { this.state.textBig10 ? this.setState({ textBig10: false }) : this.setState({ textBig10: true }) }
    makeTextbig11 = () => { this.state.textBig11 ? this.setState({ textBig11: false }) : this.setState({ textBig11: true }) }
    makeTextbig12 = () => { this.state.textBig12 ? this.setState({ textBig12: false }) : this.setState({ textBig12: true }) }
    makeTextbig13 = () => { this.state.textBig13 ? this.setState({ textBig13: false }) : this.setState({ textBig13: true }) }
    makeTextbig14 = () => { this.state.textBig14 ? this.setState({ textBig14: false }) : this.setState({ textBig14: true }) }
    makeTextbig15 = () => { this.state.textBig15 ? this.setState({ textBig15: false }) : this.setState({ textBig15: true }) }

    autoCompare = (filterOn) => {
        let language = localStorage.getItem('language') ?? 'sv';
        //Do nothing if no groups are compared
        if (
            (this.state.analyseGroup1 === "Klicka på grupp" || this.state.analyseGroup1 === "Välj grupp") ||
            (this.state.analyseGroup2 === "Klicka på grupp" || this.state.analyseGroup2 === "Välj grupp")
        ) { return; }

        else {
            let hideExtra = this.state.hideExtra //For showing/adding extra/tilagg button
            //If div is not opened, open it
            if (this.state.divOpened === "first") this.setState({ divOpened: "yes" })
            else if (this.state.divOpened === "no") this.setState({ divOpened: "yes" })

            //Make calculations
            const group1 = this.state.analyseGroup1
            const group2 = this.state.analyseGroup2

            let groupOne = []
            let groupTwo = []
            if (filterOn !== "edited") {
                groupOne = this.state.allEmp.filter(person => person.groupNameArbVardering === group1);
                groupTwo = this.state.allEmp.filter(person => person.groupNameArbVardering === group2);
                this.setState({ groupOneEdited: [], groupTwoEdited: [] })
            } else {
                if (this.state.groupOneEdited.length <= 0 || this.state.groupTwoEdited.length <= 0) { return; }
                groupOne = this.state.groupOneEdited
                groupTwo = this.state.groupTwoEdited
            }

            //1. First, get statistics for table
            let originalSalary = filterOn !== "edited" ? "totalSalary" : "salBeforeAddOn" // This saves the uses the original salary to each employee
            //group 1: 
            const oneNrOfEmp = groupOne.length
            const oneWomen = Math.round((groupOne.filter(person => person.gender === "woman").length / oneNrOfEmp) * 100)
            let oneAvgSalary = 0
            if (hideExtra === "hideExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => (acc + (person.salNoExtra === undefined ? (person[originalSalary] - person.addOn) : (person.salNoExtra))), 0) / oneNrOfEmp) }
            if (hideExtra === "onlyExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => (acc + (person.addOn)), 0) / oneNrOfEmp) }
            if (hideExtra === "includeExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => acc + (person.salNoExtra === undefined ? person[originalSalary] : (person.salNoExtra + person.addOn)), 0) / oneNrOfEmp) }
            const oneAvgAge = Math.round(groupOne.reduce((acc, person) => acc + person.age, 0) / oneNrOfEmp)
            const oneAvgExp = Math.round(groupOne.reduce((acc, person) => acc + person.experience, 0) / oneNrOfEmp)
            const oneEqualentGroup = groupOne[0].equalentGroup
            const oneCityArray = groupOne.map(person => person.city)
            const oneAverageCity = oneCityArray.sort((a, b) =>
                oneCityArray.filter(v => v === a).length
                - oneCityArray.filter(v => v === b).length
            ).pop();
            //For spridning, get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let getOnlySalariesG1 = []
            if (hideExtra === "hideExtra") { for (let i = 0; i < groupOne.length; i++) { groupOne[i].salNoExtra === undefined ? getOnlySalariesG1.push(groupOne[i][originalSalary] - groupOne[i].addOn) : getOnlySalariesG1.push(groupOne[i].salNoExtra) } }
            if (hideExtra === "onlyExtra") { for (let i = 0; i < groupOne.length; i++) { getOnlySalariesG1.push(groupOne[i].addOn) } }
            if (hideExtra === "includeExtra") { for (let i = 0; i < groupOne.length; i++) { groupOne[i].salNoExtra === undefined ? getOnlySalariesG1.push(groupOne[i][originalSalary]) : getOnlySalariesG1.push(groupOne[i].salNoExtra + groupOne[i].addOn) } }
            let lowestSalaryG1 = Math.min(...getOnlySalariesG1)
            let highestSalaryG1 = Math.max(...getOnlySalariesG1)
            let spridningskvotG1 = Math.round((highestSalaryG1 / lowestSalaryG1) * 10) / 10

            //group 2:
            const twoNrOfEmp = groupTwo.length
            const twoWomen = Math.round((groupTwo.filter(person => person.gender === "woman").length / twoNrOfEmp) * 100)
            let twoAvgSalary = 0
            if (hideExtra === "hideExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => (acc + (person.salNoExtra === undefined ? (person[originalSalary] - person.addOn) : (person.salNoExtra))), 0) / twoNrOfEmp) }
            if (hideExtra === "onlyExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => (acc + (person.addOn)), 0) / twoNrOfEmp) }
            if (hideExtra === "includeExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => acc + (person.salNoExtra === undefined ? person[originalSalary] : (person.salNoExtra + person.addOn)), 0) / twoNrOfEmp) }
            const twoAvgAge = Math.round(groupTwo.reduce((acc, person) => acc + person.age, 0) / twoNrOfEmp)
            const twoAvgExp = Math.round(groupTwo.reduce((acc, person) => acc + person.experience, 0) / twoNrOfEmp)
            const twoEqualentGroup = groupTwo[0].equalentGroup
            const twoCityArray = groupTwo.map(person => person.city)
            const twoAverageCity = twoCityArray.sort((a, b) =>
                twoCityArray.filter(v => v === a).length
                - twoCityArray.filter(v => v === b).length
            ).pop();
            //For spridning, get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let getOnlySalariesG2 = []
            if (hideExtra === "hideExtra") { for (let i = 0; i < groupTwo.length; i++) { groupTwo[i].salNoExtra === undefined ? getOnlySalariesG2.push(groupTwo[i][originalSalary] - groupTwo[i].addOn) : getOnlySalariesG2.push(groupTwo[i].salNoExtra) } }
            if (hideExtra === "onlyExtra") { for (let i = 0; i < groupTwo.length; i++) { getOnlySalariesG2.push(groupTwo[i].addOn) } }
            if (hideExtra === "includeExtra") { for (let i = 0; i < groupTwo.length; i++) { groupTwo[i].salNoExtra === undefined ? getOnlySalariesG2.push(groupTwo[i][originalSalary]) : getOnlySalariesG2.push(groupTwo[i].salNoExtra + groupTwo[i].addOn) } }
            let lowestSalaryG2 = Math.min(...getOnlySalariesG2)
            let highestSalaryG2 = Math.max(...getOnlySalariesG2)
            let spridningskvotG2 = Math.round((highestSalaryG2 / lowestSalaryG2) * 10) / 10

            //Diffs
            const diffWomen = (oneWomen - twoWomen)

            //Calculate 10 and 90 percentiles if needed (if 20 or more employees)
            let moreOne10 = "-"
            let moreOne90 = "-"
            let moreTwo10 = "-"
            let moreTwo90 = "-"
            if (oneNrOfEmp > 19 || twoNrOfEmp > 19) {
                //If men bigger but not women
                if (oneNrOfEmp > 19 && twoNrOfEmp < 20) {
                    let g1SalariesInOrder = getOnlySalariesG1.sort((a, b) => a - b) //Sort lowest to highest
                    moreOne10 = percentile(10, g1SalariesInOrder)
                    moreOne90 = percentile(90, g1SalariesInOrder)
                    spridningskvotG1 = Math.round((moreOne90 / moreOne10) * 10) / 10
                }
                //If women bigger but not men
                if (twoNrOfEmp > 19 && oneNrOfEmp < 20) {
                    let g2SalariesInOrder = getOnlySalariesG2.sort((a, b) => a - b) //Sort lowest to highest
                    moreTwo10 = percentile(10, g2SalariesInOrder)
                    moreTwo90 = percentile(90, g2SalariesInOrder)
                    spridningskvotG2 = Math.round((moreTwo90 / moreTwo10) * 10) / 10
                }
                //If both bigger
                if (twoNrOfEmp > 19 && oneNrOfEmp > 19) {
                    let g1SalariesInOrder = getOnlySalariesG1.sort((a, b) => a - b) //Sort lowest to highest
                    moreOne10 = percentile(10, g1SalariesInOrder)
                    moreOne90 = percentile(90, g1SalariesInOrder)
                    spridningskvotG1 = Math.round((moreOne90 / moreOne10) * 10) / 10
                    let g2SalariesInOrder = getOnlySalariesG2.sort((a, b) => a - b) //Sort lowest to highest
                    moreTwo10 = percentile(10, g2SalariesInOrder)
                    moreTwo90 = percentile(90, g2SalariesInOrder)
                    spridningskvotG2 = Math.round((moreTwo90 / moreTwo10) * 10) / 10
                }
            }
            let spridningDiff = Math.round((spridningskvotG1 - spridningskvotG2) * 10) / 10

            this.setState({
                tableInfo: [{
                    oneNrOfEmp: oneNrOfEmp,
                    oneWomen: oneWomen,
                    oneAvgSalary: oneAvgSalary,
                    oneAvgAge: oneAvgAge,
                    oneAvgExp: oneAvgExp,
                    oneEqualentGroup: oneEqualentGroup,
                    oneAvgCity: oneAverageCity,
                    twoNrOfEmp: twoNrOfEmp,
                    twoWomen: twoWomen,
                    twoAvgSalary: twoAvgSalary,
                    twoAvgAge: twoAvgAge,
                    twoAvgExp: twoAvgExp,
                    twoEqualentGroup: twoEqualentGroup,
                    twoAvgCity: twoAverageCity,
                    diffWomen: diffWomen,
                    lowestSalaryG1: lowestSalaryG1,
                    highestSalaryG1: highestSalaryG1,
                    spridningskvotG1: spridningskvotG1,
                    lowestSalaryG2: lowestSalaryG2,
                    highestSalaryG2: highestSalaryG2,
                    spridningskvotG2: spridningskvotG2,
                    spridningDiff: spridningDiff,
                    moreOne10: moreOne10,
                    moreOne90: moreOne90,
                    moreTwo10: moreTwo10,
                    moreTwo90: moreTwo90,
                }]
            });

            let xAxisType = this.state.xAxisType
            //Get data to use with settings for chart x-axis
            const bothGroups = groupOne.concat(groupTwo);
            let allExperiences = []
            let longestExp = 0
            if (xAxisType === "experience") {
                allExperiences = bothGroups.map(person => person.experience)
                longestExp = Math.max(...allExperiences)
                if (longestExp < 10) longestExp = 10
                if (longestExp > 10 && longestExp < 16) longestExp = 16
                this.setState({ longestExp: longestExp }) //This is for buggfix with chart.
            }
            if (xAxisType === "age") {
                allExperiences = bothGroups.map(person => person.age)
                longestExp = Math.max(...allExperiences)
                this.setState({ longestExp: longestExp }) //This is for buggfix with chart.
            }

            //2. Then update chart
            //if should show in kr
            if (!this.state.showPercent) {
                let groupOneToChart = []
                let groupTwoToChart = []
                groupOneToChart = groupOne.map(function (el) {
                    // let salary = this.state.clickedNewGroup ? "totalSalary" : !this.state.clickedNewGroup && !this.state.showPercent ? "y" : "salInKr"
                    // let salBeforeAddOn = clickedNewGroup ? el[salary] : el.salBeforeAddOn // This saves the original salary to each employee
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    return {
                        y: hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })
                groupTwoToChart = groupTwo.map(function (el) {
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    return {
                        y: hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? (el.salNoExtra) : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })

                //Create jitter if years and experience are exactly the same
                jitter(groupOneToChart, groupTwoToChart, 130)

                //Buggfix, if cero and only one person the chart will show a very small dot that is not hoverable. This fixes it.
                /*let groupOneMen = groupOneToChart.filter(person => person.gender === "man")
                let groupOneWomen = groupOneToChart.filter(person => person.gender === "woman")
                if (groupOneMen.length === 1 && groupOneWomen.length === 0) {
                    if (groupOneMen[0].x === 0) {
                        let getChartMenAgain = groupOneMen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupOneMen[0].equalGroup, addOn: el.addOn } })
                        groupOneToChart = groupOneMen.concat(getChartMenAgain)
                    }
                } if (groupOneWomen.length === 1 && groupOneMen.length === 0) {
                    if (groupOneWomen[0].x === 0) {
                        let getChartWomenAgain = groupOneWomen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupOneWomen[0].equalGroup, addOn: el.addOn } })
                        groupOneToChart = groupOneWomen.concat(getChartWomenAgain);
                    }
                }
                let groupTwoMen = groupTwoToChart.filter(person => person.gender === "man")
                let groupTwoWomen = groupTwoToChart.filter(person => person.gender === "woman")
                if (groupTwoMen.length === 1 && groupTwoWomen.length === 0) {
                    if (groupTwoMen[0].x === 0) {
                        let getChartMenAgain = groupTwoMen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupTwoMen[0].equalGroup, addOn: el.addOn } })
                        groupTwoToChart = groupTwoMen.concat(getChartMenAgain)
                    }
                } if (groupTwoWomen.length === 1 && groupTwoMen.length === 0) {
                    if (groupTwoWomen[0].x === 0) {
                        let getChartWomenAgain = groupTwoWomen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupTwoWomen[0].equalGroup, addOn: el.addOn } })
                        groupTwoToChart = groupTwoWomen.concat(getChartWomenAgain);
                    }
                }*/

                //Show or hide name
                const nameStatus = this.state.hideName ? "display: none" : "";

                //Set state for chart
                this.setState({
                    options: {
                        colors: ['#ffffff', 'transparent'],
                        fill: {
                            colors: ['#ffffff', 'transparent']
                        },
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            show: false
                        },
                        grid: {
                            show: false
                        },
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                if (data.additional === null) { data.additional = "" }
                                let additionalToLong = data.additional.replace(/(.{40})/g, "$1<br>")
                                let showAdditional = ""
                                data.additional === "" ? showAdditional = "" : showAdditional = `<li style="color: #333333;"><b>${language === "sv" ? "Övrigt" : lang[language].other}</b>: ${additionalToLong}</li>`
                                if (data.addOn === 0 || data.addOn === null || data.addOn === undefined) { data.addOn = "" }
                                let showAddOn = ""
                                data.addOn === "" ? showAddOn = "" : hideExtra === "includeExtra" ? showAddOn = ` | <i style="font-size: 12px">${language === "sv" ? "Varav tillägg" : lang[language].inclBen}: ${data.addOn}</i>` : hideExtra === "hideExtra" ? showAddOn = ` | <i style="font-size: 12px">${language === "sv" ? (`Tillägg: ${data.addOn} (exkluderat)</li>`) : (`${lang[language].benefits}: ${data.addOn} ${lang[language].excluded}`)}</i>` : showAddOn = ``
                                let salaryName = hideExtra === "onlyExtra" ? (language === "sv" ? "Tillägg" : lang[language].benefits) : (language === "sv" ? "Lön" : lang[language].salary)
                                //let salaryExklExtra = data.salNoExtra === undefined ? data.y : data.salNoExtra
                                return `<ul>
                                    <li style="color: #333333 ${nameStatus}"><b>${language === "sv" ? "Namn" : lang[language].name}</b>: ${data.fullName}</li>
                                    <li style="color: #333333"><b>${salaryName}</b>: ${data.y} kr${showAddOn}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "År i org" : lang[language].yInOrg}</b>: ${data.expNoJitter}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ålder" : lang[language].age}</b>: ${data.age}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ort" : lang[language].city}</b>: ${data.city}</li>
                                    ${showAdditional}
                                    <li style="padding-top: 5px;color: #4caf9e; font-weight: bold">${data.equalGroup}</li>
                                    </ul>`
                            },
                        },
                        states: { active: { filter: { type: "none" } } },
                        chart: {
                            height: 350,
                            type: 'scatter',
                            zoom: {
                                enabled: true,
                                type: 'x',
                                zoomedArea: {
                                    fill: {
                                        color: '#ffffff',
                                    },
                                    stroke: {
                                        color: '#ffffff',
                                        opacity: 0.4,
                                        width: 1
                                    }
                                }
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false,
                                    zoom: `<img src=${zoomIn} style="filter: brightness(0) invert(1);opacity: 0.40;width: 18px;margin-top: 2.3px;margin-left: 4px;">`,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    customIcons: [{
                                        icon: `<div>${language === "sv" ? "Ålder" : lang[language].age}</div>`,
                                        index: 4,
                                        title: `${language === "sv" ? "Sätt x-axel till ålder" : lang[language].ageXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-white apexChartLikvAge' : 'custom-icon-div  custom-icon-div-transparent apexChartLikvAge') : 'hide',
                                      click: function (chart, options, e) {
                                            const activeItem = this.state.activeItem; 
                                            this.setState({ xAxisType: "age" }, () => {
                                              if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) {
                                                this.autoCompare("edited");
                                              } else {
                                                this.autoCompare();
                                              }
                                            });
                                          }.bind(this),
                                    }, {
                                        icon: hideExtra === "includeExtra" ? `
                                        <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; opacity: 0.47;filter: brightness(10);">` : hideExtra === "hideExtra" ?
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Exkl tillägg" : lang[language].exclBenVeryShort}</div>
                                          ` :
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Bara tillägg" : lang[language].onlyBenVeryShort}</div>
                                          `
                                        ,
                                        index: 2,
                                        title: hideExtra === "includeExtra" ? (language === "sv" ? 'Visar lön inklusive tillägg' : lang[language].iconInclSal) : hideExtra === "hideExtra" ? (language === "sv" ? 'Visar lön exklusive tillägg' : lang[language].iconExclSal) : (language === "sv" ? "Visar tillägg exklusive lön" : lang[language].iconOnlyBen),
                                        class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                                        click: async function (chart, options, e) {
                                            await this.hideExtra();
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${zoomOut} width="15px" style="margin-left: 7px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 1,
                                        title: language === "sv" ? "Zooma ut" : lang[language].zoomOut,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "300.1",
                                                })
                                            }
                                            if (this.state.chartWidth === "92%") {
                                                this.setState({
                                                    chartWidth: "92.01%",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                            if (this.state.chartWidth === "92.01%") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }

                                        }.bind(this)
                                    },
                                    {
                                        icon: `<div>${language === "sv" ? "År i org." : lang[language].yearInOrgShorten}</div>`,
                                        index: 5,
                                        title: `${language === "sv" ? "Sätt x-axel till år i organisationen" : lang[language].yearInOrgXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-mright custom-icon-div-transparent apexChartLikvExp' : 'custom-icon-div custom-icon-div-mright custom-icon-div-white apexChartLikvExp') : 'hide',
                                          click: function (chart, options, e) {
                                            const activeItem = this.state.activeItem; 
                                            this.setState({ xAxisType: "experience" }, () => {
                                                if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                               else { this.autoCompare(); }
                                            });
                                          }.bind(this),
                                    },
                                    {
                                        icon: `<img src=${fullscreen} width="15px" style="margin-left: 10px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 3,
                                        title: language === "sv" ? "Fullskärm" : lang[language].fullscreen,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    chartHeight: "auto",
                                                    apexPopUp: "apexPopUp",
                                                    options: {
                                                        grid: {
                                                            show: true,
                                                            borderColor: '#a3eade',
                                                        },
                                                    }

                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    chartHeight: "240",
                                                    apexPopUp: "",
                                                    options: {
                                                        grid: {
                                                            show: false,
                                                        },
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                        }.bind(this)
                                    },
                                    ]
                                },
                            },
                            events: {
                                beforeZoom: (e, { xaxis }) => {
                                    return {
                                        xaxis: {
                                            tickAmount: 1,
                                            max: xaxis.max,
                                            min: xaxis.min
                                        }
                                    }
                                },
                                dataPointSelection: (event, chartContext, config) => {
                                    let getGroupOne = this.state.series[0].data
                                    let getGroupTwo = this.state.series[1].data
                                    let clickedPerson = ""

                                    if (config.seriesIndex == 0) { //If mens group
                                        getGroupOne = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
                                        clickedPerson = getGroupOne[config.dataPointIndex]
                                    }
                                    if (config.seriesIndex == 1) { //If womens group
                                        getGroupTwo = this.state.series[1].data.slice(0)
                                        clickedPerson = getGroupTwo[config.dataPointIndex]
                                    }
                                    //Edit groups so they include everything that is needed before setting state
                                    let prepareGroupOne = getGroupOne.map(function (el) {
                                        return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, equalentGroup: groupOne[0].equalentGroup }
                                    })
                                    let prepareGroupTwo = getGroupTwo.map(function (el) {
                                        return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, equalentGroup: groupTwo[0].equalentGroup }
                                    })

                                    this.setState({
                                        groupOneEdited: prepareGroupOne,
                                        groupTwoEdited: prepareGroupTwo,
                                        clickedPerson: clickedPerson,
                                        clickedPersonIndex: config.dataPointIndex,
                                        serieIndex: config.seriesIndex,
                                        popItUp: true,
                                    })
                                }
                            },
                        },
                        xaxis: {
                            tickAmount: 5,
                            type: 'numeric',
                            max: longestExp,
                            min: 0,
                            ticks: {
                                /*stepSize: 1,**/
                                /*maxTicksLimit: 10*/
                                /* count: 10*/
                                /*autoSkip: false*/
                                includeBounds: true,
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                }
                            }
                        },
                        yaxis: {
                            tickAmount: 0,
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                },
                            },
                            //floating: true,
                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            labels: {
                                show: true,
                                /*hover: {*/
                                style: {
                                    colors: '#a3eade',
                                    /*    }*/
                                },
                            },
                        },
                    },
                    series: [{
                        name: group1,
                        data: groupOneToChart
                    },
                    {
                        name: group2,
                        data: groupTwoToChart
                    }],
                })
            }

            //if should show in PERCENT
            if (this.state.showPercent) {
                //1. Put togheter groupOne And Two
                const bothGroups = groupOne.concat(groupTwo);
                //2. calculate total salary for use when calculating percent
                // const salaryTotal = bothGroups.reduce((sum, cur) => sum + (filterOn !== "edited" ? cur.totalSalary : cur.salBeforeAddOn), 0) //<-- MÅSTE JUSTERA!
                let allSalaries = []
                for (let i = 0; i < bothGroups.length; i++) {
                    let el = bothGroups[i]
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    allSalaries.push(hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn)
                }
                const salaryTotal = allSalaries.reduce((sum, cur) => sum + cur, 0)
                //3. Update properties
                let groupOneToChart = []
                let groupTwoToChart = []
                groupOneToChart = groupOne.map(function (el) {
                    //let theSalary = 0
                    //!hideExtra ? theSalary = el.totalSalary : theSalary = el.totalSalary - el.addOn
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    let ySalary = hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn
                    return {
                        y: ((ySalary / salaryTotal) * 100).toFixed(1),
                        totSalinKr: el.totalSalary,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })
                groupTwoToChart = groupTwo.map(function (el) {
                    // let theSalary = 0
                    // !hideExtra ? theSalary = el.totalSalary : theSalary = el.totalSalary - el.addOn
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    let ySalary = hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn
                    return {
                        y: ((ySalary / salaryTotal) * 100).toFixed(1),
                        totSalinKr: el.totalSalary,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })

                //Create jitter if years and experience are exactly the same
                jitter(groupOneToChart, groupTwoToChart, 130)

                //Buggfix, if cero and only one person the chart will show a very small dot that is not hoverable. This fixes it.
                /* let groupOneMen = groupOneToChart.filter(person => person.gender === "man")
                 let groupOneWomen = groupOneToChart.filter(person => person.gender === "woman")
                 if (groupOneMen.length === 1 && groupOneWomen.length === 0) {
                     if (groupOneMen[0].x === 0) {
                         let getChartMenAgain = groupOneMen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupOneMen[0].equalGroup } })
                         groupOneToChart = groupOneMen.concat(getChartMenAgain)
                     }
                 } if (groupOneWomen.length === 1 && groupOneMen.length === 0) {
                     if (groupOneWomen[0].x === 0) {
                         let getChartWomenAgain = groupOneWomen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupOneWomen[0].equalGroup } })
                         groupOneToChart = groupOneWomen.concat(getChartWomenAgain);
                     }
                 }
                 let groupTwoMen = groupTwoToChart.filter(person => person.gender === "man")
                 let groupTwoWomen = groupTwoToChart.filter(person => person.gender === "woman")
                 if (groupTwoMen.length === 1 && groupTwoWomen.length === 0) {
                     if (groupTwoMen[0].x === 0) {
                         let getChartMenAgain = groupTwoMen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupTwoMen[0].equalGroup } })
                         groupTwoToChart = groupTwoMen.concat(getChartMenAgain)
                     }
                 } if (groupTwoWomen.length === 1 && groupTwoMen.length === 0) {
                     if (groupTwoWomen[0].x === 0) {
                         let getChartWomenAgain = groupTwoWomen.map(function (el) { return { y: el.y, x: 0.001, expNoJitter: 0, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, equalGroup: groupTwoWomen[0].equalGroup } })
                         groupTwoToChart = groupTwoWomen.concat(getChartWomenAgain);
                     }
                 }*/

                //Show or hide name
                const nameStatus = this.state.hideName ? "display: none" : "";

                //Set state for chart
                this.setState({
                    options: {
                        colors: ['#ffffff', 'transparent'],
                        fill: {
                            colors: ['#ffffff', 'transparent']
                        },
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            show: false
                        },
                        grid: {
                            show: false
                        },
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                if (data.additional === null) { data.additional = "" }
                                let additionalToLong = data.additional.replace(/(.{40})/g, "$1<br>")
                                let showAdditional = ""
                                data.additional === "" ? showAdditional = "" : showAdditional = `<li style="color: #333333"><b>${language === "sv" ? "Övrigt" : lang[language].other}</b>: ${additionalToLong}</li>`

                                return `<ul>
                                    <li style="color: #333333 ${nameStatus}"><b>${language === "sv" ? "Namn" : lang[language].name}</b>: ${data.fullName}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Lön" : lang[language].salary}</b>: ${data.y} %</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "År i org" : lang[language].yInOrg}</b>: ${data.expNoJitter}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ålder" : lang[language].age}</b>: ${data.age}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ort" : lang[language].city}</b>: ${data.city}</li>
                                    ${showAdditional}
                                    <li style="padding-top: 5px;color: #4caf9e; font-weight: bold">${data.equalGroup}</li>
                                    </ul>`
                            },
                        },
                        states: { active: { filter: { type: "none" } } },
                        chart: {
                            height: 350,
                            type: 'scatter',
                            zoom: {
                                enabled: true,
                                type: 'x',
                                zoomedArea: {
                                    fill: {
                                        color: '#ffffff',
                                    },
                                    stroke: {
                                        color: '#ffffff',
                                        opacity: 0.4,
                                        width: 1
                                    }
                                }
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false,
                                    zoom: `<img src=${zoomIn} style="filter: brightness(0) invert(1);opacity: 0.40;width: 18px;margin-top: 2.3px;margin-left: 4px;">`,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    customIcons: [{
                                        icon: `<div>${language === "sv" ? "Ålder" : lang[language].age}</div>`,
                                        index: 4,
                                        title: `${language === "sv" ? "Sätt x-axel till ålder" : lang[language].ageXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-white apexChartLikvAge' : 'custom-icon-div  custom-icon-div-transparent apexChartLikvAge') : 'hide',
                                        click: function (chart, options, e) {
                                            const activeItem = this.state.activeItem; 
                                            this.setState({ xAxisType: "age" }, () => {
                                              if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) {
                                                this.autoCompare("edited");
                                              } else {
                                                this.autoCompare();
                                              }
                                            });
                                          }.bind(this),
                                    }, {
                                        icon: hideExtra === "includeExtra" ? `
                                        <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; opacity: 0.47;filter: brightness(10);">` : hideExtra === "hideExtra" ?
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Exkl tillägg" : lang[language].exclBenVeryShort}</div>
                                          ` :
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Bara tillägg" : lang[language].onlyBenVeryShort}</div>
                                          `,
                                        index: 2,
                                        title: hideExtra === "includeExtra" ? (language === "sv" ? 'Visar lön inklusive tillägg' : lang[language].iconInclSal) : hideExtra === "hideExtra" ? (language === "sv" ? 'Visar lön exklusive tillägg' : lang[language].iconExclSal) : (language === "sv" ? "Visar tillägg exklusive lön" : lang[language].iconOnlyBen),
                                        class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                                        click: async function (chart, options, e) {
                                            await this.hideExtra();
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${zoomOut} width="15px" style="margin-left: 7px;filter: brightness(0) invert(1);
                                    opacity: 0.40;">`,
                                        index: 1,
                                        title: language === "sv" ? "Zooma ut" : lang[language].zoomOut,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({ chartWidth: "300.1" })
                                            }
                                            if (this.state.chartWidth === "92%") {
                                                this.setState({ chartWidth: "92.01%" })
                                            }
                                            if (this.state.chartWidth === "92.01%") {
                                                this.setState({ chartWidth: "92%" })
                                            }
                                            else {
                                                this.setState({ chartWidth: "300" })
                                            }

                                        }.bind(this)
                                    },
                                    {
                                        icon: `<div>${language === "sv" ? "År i org." : lang[language].yearInOrgShorten}</div>`,
                                        index: 5,
                                        title: `${language === "sv" ? "Sätt x-axel till år i organisationen" : lang[language].yearInOrgXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-mright custom-icon-div-transparent apexChartLikvExp' : 'custom-icon-div custom-icon-div-mright custom-icon-div-white apexChartLikvExp') : 'hide',
                                        click: function (chart, options, e) {
                                            const activeItem = this.state.activeItem; 
                                            this.setState({ xAxisType: "experience" }, () => {
                                                if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                               else { this.autoCompare(); }
                                            });
                                          }.bind(this),
                                    },
                                    {
                                        icon: `<img src=${fullscreen} width="15px" style="margin-left: 10px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 3,
                                        title: language === "sv" ? "Fullskärm" : lang[language].fullscreen,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    chartHeight: "auto",
                                                    apexPopUp: "apexPopUp",
                                                    options: {
                                                        grid: {
                                                            show: true,
                                                            borderColor: '#a3eade',
                                                        },
                                                    }

                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    chartHeight: "240",
                                                    apexPopUp: "",
                                                    options: {
                                                        grid: {
                                                            show: false,
                                                        },
                                                    }
                                                })
                                            }
                                        }.bind(this)
                                    },
                                    ]
                                },
                            },
                            events: {
                                beforeZoom: (e, { xaxis }) => {
                                    return {
                                        xaxis: {
                                            tickAmount: 1,
                                            max: xaxis.max,
                                            min: xaxis.min
                                        }
                                    }
                                },
                                dataPointSelection: (event, chartContext, config) => {
                                    let getGroupOne = this.state.series[0].data
                                    let getGroupTwo = this.state.series[1].data
                                    let clickedPerson = ""

                                    if (config.seriesIndex == 0) { //If mens group
                                        getGroupOne = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
                                        clickedPerson = getGroupOne[config.dataPointIndex]
                                    }
                                    if (config.seriesIndex == 1) { //If womens group
                                        getGroupTwo = this.state.series[1].data.slice(0)
                                        clickedPerson = getGroupTwo[config.dataPointIndex]
                                    }
                                    //Edit groups so they include everything that is needed before setting state
                                    let prepareGroupOne = getGroupOne.map(function (el) { return { totalSalary: el.totSalinKr, addOn: el.addOn, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, equalentGroup: groupOne[0].equalentGroup } })
                                    let prepareGroupTwo = getGroupTwo.map(function (el) { return { totalSalary: el.totSalinKr, addOn: el.addOn, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, equalentGroup: groupTwo[0].equalentGroup } })

                                    this.setState({
                                        groupOneEdited: prepareGroupOne,
                                        groupTwoEdited: prepareGroupTwo,
                                        clickedPerson: clickedPerson,
                                        clickedPersonIndex: config.dataPointIndex,
                                        serieIndex: config.seriesIndex,
                                        popItUp: true,
                                    })
                                }
                            },
                        },
                        xaxis: {
                            tickAmount: 5,
                            type: 'numeric',
                            max: longestExp,
                            min: 0,
                            ticks: {
                                includeBounds: true,
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                }
                            }
                        },
                        yaxis: {
                            tickAmount: 0,
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                },
                            },
                            //floating: true,
                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            labels: {
                                show: true,
                                /*hover: {*/
                                style: {
                                    colors: '#a3eade',
                                    /*    }*/
                                },
                            },
                        },
                    },
                    series: [{
                        name: group1,
                        data: groupOneToChart
                    },
                    {
                        name: group2,
                        data: groupTwoToChart
                    }],
                })
            }

            //b. We change this based on some if statements below. So we dont have to copy our If statements. 
            //Initial state is the same as "if (oneWomen > 59 && twoWomen < 60)"
            let lessWomen = oneWomen //Percentage of women in group, ex 60 = 60% women
            let moreWomen = twoWomen //Percentage of women in the other group, below we switch it so that lessWomen always is the group with the smallest percantage of women.
            let firsAvgSalary = oneAvgSalary //Total average salary for group 1
            let secondAvgSalary = twoAvgSalary //Total average salary for group 2
            let nrOfEmp = oneNrOfEmp //Nr of employee for the women dominated group. If under 4 we do some things
            let nrOfEmpTwo = twoNrOfEmp //Nr of employee for the male dominated group
            let firstGroup = group1 //Employees in group 1
            let secondGroup = group2 //Employees in group 2
            let firstExp = oneAvgExp //Average Experience in group 1
            let secondExp = twoAvgExp //Average Experience in group 2
            let firstAge = oneAvgAge //Average Experience in group 1
            let secondAge = twoAvgAge //Average Experience in group 2
            let firstEqualent = this.state.group1EqualGroup //the equalent group the group belong to, (1-6). The first equalent group will always belong to the women dominated group
            let secondEqualent = this.state.group2EqualGroup
            let firstCity = oneAverageCity
            let secondCity = twoAverageCity

            //If it is the second group that is women dominated, that groups should be first. Therefore we swap all data
            if (twoWomen > 59 && oneWomen < 60) {
                lessWomen = twoWomen
                moreWomen = oneWomen
                firsAvgSalary = twoAvgSalary
                secondAvgSalary = oneAvgSalary
                nrOfEmp = twoNrOfEmp
                nrOfEmpTwo = oneNrOfEmp
                firstGroup = group2
                secondGroup = group1
                firstExp = twoAvgExp
                secondExp = oneAvgExp
                firstAge = twoAvgAge
                secondAge = oneAvgAge
                firstEqualent = this.state.group2EqualGroup
                secondEqualent = this.state.group1EqualGroup
                firstCity = twoAverageCity
                secondCity = oneAverageCity
            }

            //Run auto analysis (from separate file)
            //const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, this.state.group1, this.state.group2, this.state.group3, this.state.group4, this.state.group5, this.state.group6);
            const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "No");

            //Check if analysisText contains "<age>"
            if (autoAnalys[0].includes("<age>")) {
                this.setState({ showInfoIcon: true })
            } else {
                this.setState({ showInfoIcon: false })
            }
            //Remove
            let autoString = autoAnalys[0].replace("<age>", "")
            //Set state to result of auto analysis
            if (autoAnalys[1] === "ignore" || autoAnalys[1] == undefined) { autoAnalys[1] = "" }
            if (autoAnalys[2] === "ignore" || autoAnalys[1] == undefined || typeof autoAnalys[2] === 'object') { autoAnalys[2] = "" }
            if (hideExtra === "onlyExtra") {
                this.setState({ analysisText: language === "sv" ? "Autoanalys ej tillämpbar för enbart tillägg." : lang[language].AInotForBenOnly, AInotable: "", AIreason: "", AIAnalys: "" });
            }
            else {
                this.setState({ analysisText: autoString, AInotable: autoAnalys[1], AIreason: autoAnalys[2], AIAnalys: autoAnalys[3] });
            }
        }
    }

    selectGroup1 = () => {
        this.setState({
            analyseGroup1: "Klicka på grupp",
            selectGroup1: true,
        })
    }
    selectGroup2 = () => {
        this.setState({
            analyseGroup2: "Klicka på grupp",
            selectGroup2: true,
        })
    }

    updateApexChartWithThis = (men, women) => {
        return new Promise(resolve => {
            this.setState({
                series: [{
                    name: "Män",
                    data: men //filteredMale
                },
                {
                    name: "Kvinnor",
                    data: women //filteredWoman
                }],
            })
            resolve()
        });
    }

    showDiffPercent = () => { this.state.showDiffPercent ? this.setState({ showDiffPercent: false }) : this.setState({ showDiffPercent: true }) }
    /* calcContribition = (avgMaleSalary, avgFemaleSalary, employees, femalePercentage, malePercentage, totalWageGapPercentage, totalMaleSalaryAll, totalFemaleSalaryAll, totalEmployees) => { //  totalWageGapPercentage,  totalMaleSalaryAll,  totalFemaleSalaryAll,  totalEmployees
         if (isNaN(avgMaleSalary)) { avgMaleSalary = 0 }
         if (isNaN(avgFemaleSalary)) { avgFemaleSalary = 0 }
         if (isNaN(employees)) { employees = 0 }
         if (isNaN(totalMaleSalaryAll)) { totalMaleSalaryAll = 0 }
         if (isNaN(totalFemaleSalaryAll)) { totalFemaleSalaryAll = 0 }
 
         // Ändrar minus procent till plus:
         totalWageGapPercentage = Number(totalWageGapPercentage) // Turn total percentage to number
         if (totalWageGapPercentage < 0) { // Make negative number positive <-- Får se om behåller det så eller ändrar sen.
             totalWageGapPercentage = Math.abs(totalWageGapPercentage);
         }
 
      //   console.log(avgMaleSalary, avgFemaleSalary, employees, femalePercentage, malePercentage, totalWageGapPercentage, totalMaleSalaryAll, totalFemaleSalaryAll, totalEmployees)
 
         // Beräkna total lön för män och kvinnor i befattningen
         const totalMaleSalary = (malePercentage / 100) * employees * avgMaleSalary;
         const totalFemaleSalary = (femalePercentage / 100) * employees * avgFemaleSalary;
 
         // Total löneskillnad i befattningen
         const wageDifference = totalMaleSalary - totalFemaleSalary;
 
         // Totala lönegapet i organisationen
         const totalWageDifference = totalMaleSalaryAll - totalFemaleSalaryAll;
 
         // Hantera fall där totalWageDifference är 0
         if (totalWageDifference === 0) {
             return {
                 percentageOfTotalWageGap: 0,
                 genderImpactPercentage: 0,
                 employeeImpactPercentage: 0,
                 salaryImpactPercentage: 0
             };
         }
 
         // Beräkna procentuell påverkan till det totala lönegapet
         const percentageOfTotalWageGap = (wageDifference / totalWageDifference) * totalWageGapPercentage;
 
         // Beräkna bidrag från könssammansättning, antal anställda och medellön
         const avgSalary = (avgMaleSalary * (malePercentage / 100) + avgFemaleSalary * (femalePercentage / 100));
         const genderImpact = Math.abs(malePercentage - femalePercentage) / 100 * employees * avgSalary;
         const employeeImpact = employees * avgSalary;
         const salaryImpact = (avgMaleSalary - avgFemaleSalary) * ((malePercentage / 100) * (femalePercentage / 100) * employees);
 
         // Total påverkan utan viktning
         const totalImpact = genderImpact + employeeImpact + Math.abs(salaryImpact);
 
         // Om totalImpact är 0, returnera 0 för alla påverkan
         if (totalImpact === 0) {
             return {
                 percentageOfTotalWageGap: percentageOfTotalWageGap.toFixed(2),
                 genderImpactPercentage: 0,
                 employeeImpactPercentage: 0,
                 salaryImpactPercentage: 0
             };
         }
 
         // Normalisera så att summan blir lika med total påverkan
         const genderImpactPercentage = (genderImpact / totalImpact) * percentageOfTotalWageGap;
         const employeeImpactPercentage = (employeeImpact / totalImpact) * percentageOfTotalWageGap;
         const salaryImpactPercentage = (Math.abs(salaryImpact) / totalImpact) * percentageOfTotalWageGap;
 
         return {
             percentageOfTotalWageGap: percentageOfTotalWageGap.toFixed(2),
             genderImpactPercentage: genderImpactPercentage.toFixed(2),
             employeeImpactPercentage: employeeImpactPercentage.toFixed(2),
             salaryImpactPercentage: salaryImpactPercentage.toFixed(2)
         };
     }*/
    calcContribition = (avgMaleSalary, avgFemaleSalary, employees, femalePercentage, malePercentage, totalWageGapPercentage, totalMaleSalaryAll, totalFemaleSalaryAll, totalEmployees) => { //  totalWageGapPercentage,  totalMaleSalaryAll,  totalFemaleSalaryAll,  totalEmployees
        // Removes the above function for now to not make unnecessary calculations
        return {
            percentageOfTotalWageGap: 0,
            genderImpactPercentage: 0,
            employeeImpactPercentage: 0,
            salaryImpactPercentage: 0,
        };
    }



    componentDidMount() {

        const getDataAndComments = async () => {
            const response = await fetchAuth(`/api/loadEqualent`, 'POST');
            let data = await response.json();

            //set settings
            let percentStatus = localStorage.getItem("settingPercent");
            percentStatus === "true" ? percentStatus = true : percentStatus = false
            let hideName = localStorage.getItem("settingName");
            hideName === "true" ? hideName = true : hideName = false
            const ageChart = data[4]
            const extraSetting = data[5]
            let warningPrompt = localStorage.getItem('warningPrompt');
            let settingEU = localStorage.getItem('settingEU');

            //set state before doing the rest
            this.setState({
                nrOfEqualentGroups: data[3],
                showPercent: percentStatus,
                hideName: hideName,
                xSetting: ageChart && ageChart.length > 0 ? (ageChart[0].showAgeChart === "on" || ageChart[0].showAgeChart === "adminOn") ? true : false : false,
                showHideExtraIcon: extraSetting && extraSetting.length > 0 ? (extraSetting[0].showExtraSetting === "on" || extraSetting[0].showExtraSetting === "adminOn") ? "on" : "off" : "off",
                showChangesPrompt: warningPrompt === "off" ? "No" : "Yes",
                showWhat: settingEU === "on" ? "EU" : "Original"

            }, () => {

                let employees = data[0]
                const comments = data[1]
                const commentsLastYear = data[2]
                const allEmployees = employees

                //*** data for GROUP 1: ***
                const group1 = allEmployees.filter(person => person.equalentGroup === 1); //Get all works with demands 1
                //get unique groups
                const onlyGroups = group1.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups = onlyGroups.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group1Data = [];

                let payG1 = (((group1.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group1.filter(obj => obj.gender === "woman").length) / (group1.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group1.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                payG1 = isNaN(payG1) ? 0 : payG1 // The paygap, integer like -5.7. For all works in equivalent group
                let totalMaleSalaryG1 = 0; // total of all men salaries. For all works in equivalent group
                let totalFemaleSalaryG1 = 0; // total of all women salaries. For all works in equivalent group
                let totalEmployeesG1 = group1.length // All employees. For all works in equivalent group
                group1.forEach(elem => {
                    if (elem.gender === 'man') { totalMaleSalaryG1 += elem.totalSalary; }
                    if (elem.gender === 'woman') { totalFemaleSalaryG1 += elem.totalSalary; }
                });

                uniqueGroups.forEach(elem => {
                    let filteredMale = group1.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group1.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    //get averageExperience and avg age
                    let filteredGroup = group1.filter(person => (person.groupNameArbVardering === elem))
                    let avgExp = Math.round(filteredGroup.reduce((total, person) => total + person.experience, 0) / (filteredGroup.length));
                    let avgAge = Math.round(filteredGroup.reduce((total, person) => total + person.age, 0) / (filteredGroup.length));
                    // Get salary difference
                    let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                    let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                    let salDiff = avgWomenSalary - avgMenSalary
                    let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                    if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                    //   let avgMenSalary =
                    //   let salDiff = 
                    //   let salDiffPercent = 

                    // Calculate the contribution for each work in the equivalent group
                    let contribition = this.calcContribition(avgMenSalary, avgWomenSalary, nrAll, womenPercent, 100 - womenPercent, payG1, totalMaleSalaryG1, totalFemaleSalaryG1, totalEmployeesG1) // avgMaleSalary, avgFemaleSalary, employees, femalePercentage, malePercentage

                    //let avgExp = 
                    //maybe also median salary...
                    let group1data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "avgExp": avgExp,
                        "avgAge": avgAge,
                        "demands": 1,
                        "womenSalary": avgWomenSalary,
                        "menSalary": avgMenSalary,
                        "salDiffKr": salDiff,
                        "salDiffPercent": salDiffPercent,
                        "employeeList": group1.filter(person => (person.groupNameArbVardering === elem)),
                        "impactTotalWageGap": contribition.percentageOfTotalWageGap,
                        "genderImpactPercentage": contribition.genderImpactPercentage,
                        "employeeImpactPercentage": contribition.employeeImpactPercentage,
                        "salaryImpactPercentage": contribition.salaryImpactPercentage
                    }
                    group1Data.push(group1data)
                });

                //*** data for GROUP 2: ***
                const group2 = allEmployees.filter(person => person.equalentGroup === 2); //Get all works with demands 2
                //get unique groups
                const onlyGroups2 = group2.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups2 = onlyGroups2.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group2Data = [];

                let payG2 = (((group2.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group2.filter(obj => obj.gender === "woman").length) / (group2.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group2.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                payG2 = isNaN(payG2) ? 0 : payG2 // The paygap, integer like -5.7. For all works in equivalent group
                let totalMaleSalaryG2 = 0; // total of all men salaries. For all works in equivalent group
                let totalFemaleSalaryG2 = 0; // total of all women salaries. For all works in equivalent group
                let totalEmployeesG2 = group1.length // All employees. For all works in equivalent group
                group1.forEach(elem => {
                    if (elem.gender === 'man') { totalMaleSalaryG2 += elem.totalSalary; }
                    if (elem.gender === 'woman') { totalFemaleSalaryG2 += elem.totalSalary; }
                });

                uniqueGroups2.forEach(elem => {
                    let filteredMale = group2.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group2.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    // Get salary difference
                    let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                    let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                    let salDiff = avgWomenSalary - avgMenSalary
                    let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                    if (isNaN(salDiffPercent)) { salDiffPercent = 0 }

                    let contribition = this.calcContribition(avgMenSalary, avgWomenSalary, nrAll, womenPercent, 100 - womenPercent, payG2, totalMaleSalaryG2, totalFemaleSalaryG2, totalEmployeesG2)

                    //maybe also median salary...
                    let group2data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 2,
                        "womenSalary": avgWomenSalary,
                        "menSalary": avgMenSalary,
                        "salDiffKr": salDiff,
                        "salDiffPercent": salDiffPercent,
                        "employeeList": group2.filter(person => (person.groupNameArbVardering === elem)),
                        "impactTotalWageGap": contribition.percentageOfTotalWageGap,
                        "genderImpactPercentage": contribition.genderImpactPercentage,
                        "employeeImpactPercentage": contribition.employeeImpactPercentage,
                        "salaryImpactPercentage": contribition.salaryImpactPercentage
                    }
                    group2Data.push(group2data)
                });

                //*** data for GROUP 3: ***
                const group3 = allEmployees.filter(person => person.equalentGroup === 3); //Get all works with demands 3
                //get unique groups
                const onlyGroups3 = group3.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups3 = onlyGroups3.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group3Data = [];

                uniqueGroups3.forEach(elem => {
                    let filteredMale = group3.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group3.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    // Get salary difference
                    let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                    let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                    let salDiff = avgWomenSalary - avgMenSalary
                    let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                    if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                    //maybe also median salary...
                    let group3data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 3,
                        "womenSalary": avgWomenSalary,
                        "menSalary": avgMenSalary,
                        "salDiffKr": salDiff,
                        "salDiffPercent": salDiffPercent,
                        "employeeList": group3.filter(person => (person.groupNameArbVardering === elem))
                    }
                    group3Data.push(group3data)
                });

                //*** data for GROUP 4: ***
                const group4 = allEmployees.filter(person => person.equalentGroup === 4); //Get all works with demands 1
                //get unique groups
                const onlyGroups4 = group4.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups4 = onlyGroups4.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group4Data = [];

                uniqueGroups4.forEach(elem => {
                    let filteredMale = group4.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group4.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    // Get salary difference
                    let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                    let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                    let salDiff = avgWomenSalary - avgMenSalary
                    let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                    if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                    //maybe also median salary...
                    let group4data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 4,
                        "womenSalary": avgWomenSalary,
                        "menSalary": avgMenSalary,
                        "salDiffKr": salDiff,
                        "salDiffPercent": salDiffPercent,
                        "employeeList": group4.filter(person => (person.groupNameArbVardering === elem))
                    }
                    group4Data.push(group4data)
                });

                //*** data for GROUP 5: ***
                const group5 = allEmployees.filter(person => person.equalentGroup === 5); //Get all works with demands 1
                //get unique groups
                const onlyGroups5 = group5.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups5 = onlyGroups5.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group5Data = [];

                uniqueGroups5.forEach(elem => {
                    let filteredMale = group5.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group5.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    // Get salary difference
                    let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                    let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                    let salDiff = avgWomenSalary - avgMenSalary
                    let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                    if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                    //maybe also median salary...
                    let group5data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 5,
                        "womenSalary": avgWomenSalary,
                        "menSalary": avgMenSalary,
                        "salDiffKr": salDiff,
                        "salDiffPercent": salDiffPercent,
                        "employeeList": group5.filter(person => (person.groupNameArbVardering === elem))
                    }
                    group5Data.push(group5data)
                });

                //*** data for GROUP 6: ***
                const group6 = allEmployees.filter(person => person.equalentGroup === 6); //Get all works with demands 1
                //get unique groups
                const onlyGroups6 = group6.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                const uniqueGroups6 = onlyGroups6.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                //For each unique group, check:
                //Nr of male and Women, Nr of employees, salary
                const group6Data = [];

                uniqueGroups6.forEach(elem => {
                    let filteredMale = group6.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                    let filteredWomen = group6.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                    let nrOfMen = filteredMale.length
                    let nrOfWomen = filteredWomen.length
                    let nrAll = nrOfMen + nrOfWomen
                    let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                    let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                    let avgSalary = (totSalary / nrAll).toFixed(0)
                    // Get salary difference
                    let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                    let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                    let salDiff = avgWomenSalary - avgMenSalary
                    let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                    if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                    //maybe also median salary...
                    let group6data = {
                        "group": elem,
                        "womenPercent": womenPercent,
                        "Men": nrOfMen,
                        "Women": nrOfWomen,
                        "totEmployees": nrAll,
                        "avgSalary": avgSalary,
                        "demands": 6,
                        "womenSalary": avgWomenSalary,
                        "menSalary": avgMenSalary,
                        "salDiffKr": salDiff,
                        "salDiffPercent": salDiffPercent,
                        "employeeList": group6.filter(person => (person.groupNameArbVardering === elem))
                    }
                    group6Data.push(group6data)
                });

                //*** data for GROUP 7: ***
                const group7Data = [];
                let group7 = []
                if (this.state.nrOfEqualentGroups > 6) {
                    group7 = allEmployees.filter(person => person.equalentGroup === 7); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups7 = group7.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups7 = onlyGroups7.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups7.forEach(elem => {
                        let filteredMale = group7.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group7.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group7data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 7,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group7.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group7Data.push(group7data)
                    });
                }

                //*** data for GROUP 8: ***
                const group8Data = [];
                let group8 = []
                if (this.state.nrOfEqualentGroups > 6) {
                    group8 = allEmployees.filter(person => person.equalentGroup === 8); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups8 = group8.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups8 = onlyGroups8.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups8.forEach(elem => {
                        let filteredMale = group8.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group8.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group8data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 8,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group8.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group8Data.push(group8data)
                    });
                }

                //*** data for GROUP 9: ***
                const group9Data = [];
                let group9 = []
                if (this.state.nrOfEqualentGroups > 6) {
                    group9 = allEmployees.filter(person => person.equalentGroup === 9); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups9 = group9.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups9 = onlyGroups9.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups9.forEach(elem => {
                        let filteredMale = group9.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group9.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group9data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 9,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group9.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group9Data.push(group9data)
                    });
                }

                //*** data for GROUP 10: ***
                const group10Data = [];
                let group10 = []
                if (this.state.nrOfEqualentGroups > 6) {
                    group10 = allEmployees.filter(person => person.equalentGroup === 10); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups10 = group10.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups10 = onlyGroups10.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups10.forEach(elem => {
                        let filteredMale = group10.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group10.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group10data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 10,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group10.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group10Data.push(group10data)
                    });
                }

                //*** data for GROUP 11: ***
                const group11Data = [];
                let group11 = []
                if (this.state.nrOfEqualentGroups > 6) {
                    group11 = allEmployees.filter(person => person.equalentGroup === 11); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups11 = group11.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups11 = onlyGroups11.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups11.forEach(elem => {
                        let filteredMale = group11.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group11.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group11data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 11,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group11.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group11Data.push(group11data)
                    });
                }

                //*** data for GROUP 12: ***
                const group12Data = [];
                let group12 = []
                if (this.state.nrOfEqualentGroups > 6) {
                    group12 = allEmployees.filter(person => person.equalentGroup === 12); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups12 = group12.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups12 = onlyGroups12.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups12.forEach(elem => {
                        let filteredMale = group12.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group12.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group12data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 12,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group12.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group12Data.push(group12data)
                    });
                }

                //*** data for GROUP 13: ***
                const group13Data = [];
                let group13 = []
                if (this.state.nrOfEqualentGroups > 12) {
                    group13 = allEmployees.filter(person => person.equalentGroup === 13); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups13 = group13.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups13 = onlyGroups13.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups13.forEach(elem => {
                        let filteredMale = group13.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group13.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group13data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 13,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group13.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group13Data.push(group13data)
                    });
                }
                //*** data for GROUP 14: ***
                const group14Data = [];
                let group14 = []
                if (this.state.nrOfEqualentGroups > 12) {
                    group14 = allEmployees.filter(person => person.equalentGroup === 14); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups14 = group14.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups14 = onlyGroups14.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups14.forEach(elem => {
                        let filteredMale = group14.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group14.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group14data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 14,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group14.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group14Data.push(group14data)
                    });
                }
                //*** data for GROUP 15: ***
                const group15Data = [];
                let group15 = []
                if (this.state.nrOfEqualentGroups > 12) {
                    group15 = allEmployees.filter(person => person.equalentGroup === 15); //Get all works with demands 1
                    //get unique groups
                    const onlyGroups15 = group15.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
                    const uniqueGroups15 = onlyGroups15.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
                    //For each unique group, check:
                    //Nr of male and Women, Nr of employees, salary

                    uniqueGroups15.forEach(elem => {
                        let filteredMale = group15.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
                        let filteredWomen = group15.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
                        let nrOfMen = filteredMale.length
                        let nrOfWomen = filteredWomen.length
                        let nrAll = nrOfMen + nrOfWomen
                        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
                        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
                        let avgSalary = (totSalary / nrAll).toFixed(0)
                        // Get salary difference
                        let avgWomenSalary = filteredWomen.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredWomen.length;
                        let avgMenSalary = filteredMale.reduce((sum, employee) => sum + employee.totalSalary, 0) / filteredMale.length;
                        let salDiff = avgWomenSalary - avgMenSalary
                        let salDiffPercent = (salDiff / avgMenSalary * 100).toFixed(1)
                        if (isNaN(salDiffPercent)) { salDiffPercent = 0 }
                        //maybe also median salary...
                        let group15data = {
                            "group": elem,
                            "womenPercent": womenPercent,
                            "Men": nrOfMen,
                            "Women": nrOfWomen,
                            "totEmployees": nrAll,
                            "avgSalary": avgSalary,
                            "demands": 15,
                            "womenSalary": avgWomenSalary,
                            "menSalary": avgMenSalary,
                            "salDiffKr": salDiff,
                            "salDiffPercent": salDiffPercent,
                            "employeeList": group15.filter(person => (person.groupNameArbVardering === elem))
                        }
                        group15Data.push(group15data)
                    });
                }


                //Sort comments by date 
                const commentsByDate = comments.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
                const commentsByDateLastYear = commentsLastYear.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

                //Calculate payGap for each equivalent group <-- Note, using totalSalary
                let payGapPerGroup = ""

                /*let salaryWomenG1 = group1.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group1.filter(obj => obj.gender === "woman").length
                let salaryMenG1 =   group1.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group1.filter(obj => obj.gender === "man").length
                let payG1 = ((salaryWomenG1 - salaryMenG1) / salaryMenG1).toFixed(1) // This is in integer style, like 0.01 for 10%. */

                // let payG1 = (((group1.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group1.filter(obj => obj.gender === "woman").length) / (group1.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group1.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                // let payG2 = (((group2.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group2.filter(obj => obj.gender === "woman").length) / (group2.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group2.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG3 = (((group3.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group3.filter(obj => obj.gender === "woman").length) / (group3.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group3.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG4 = (((group4.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group4.filter(obj => obj.gender === "woman").length) / (group4.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group4.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG5 = (((group5.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group5.filter(obj => obj.gender === "woman").length) / (group5.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group5.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG6 = (((group6.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group6.filter(obj => obj.gender === "woman").length) / (group6.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group6.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG7 = (((group7.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group7.filter(obj => obj.gender === "woman").length) / (group7.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group7.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG8 = (((group8.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group8.filter(obj => obj.gender === "woman").length) / (group8.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group8.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG9 = (((group9.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group9.filter(obj => obj.gender === "woman").length) / (group9.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group9.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG10 = (((group10.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group10.filter(obj => obj.gender === "woman").length) / (group10.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group10.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG11 = (((group11.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group11.filter(obj => obj.gender === "woman").length) / (group11.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group11.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG12 = (((group12.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group12.filter(obj => obj.gender === "woman").length) / (group12.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group12.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG13 = (((group13.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group13.filter(obj => obj.gender === "woman").length) / (group13.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group13.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG14 = (((group14.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group14.filter(obj => obj.gender === "woman").length) / (group14.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group14.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)
                let payG15 = (((group15.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0) / group15.filter(obj => obj.gender === "woman").length) / (group15.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0) / group15.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1)

                //   payG1 = isNaN(payG1) ? 0 : payG1
                //  payG2 = isNaN(payG2) ? 0 : payG2
                payG3 = isNaN(payG3) ? 0 : payG3
                payG4 = isNaN(payG4) ? 0 : payG4
                payG5 = isNaN(payG5) ? 0 : payG5
                payG6 = isNaN(payG6) ? 0 : payG6
                payG7 = isNaN(payG7) ? 0 : payG7
                payG8 = isNaN(payG8) ? 0 : payG8
                payG9 = isNaN(payG9) ? 0 : payG9
                payG10 = isNaN(payG10) ? 0 : payG10
                payG11 = isNaN(payG11) ? 0 : payG11
                payG12 = isNaN(payG12) ? 0 : payG12
                payG13 = isNaN(payG13) ? 0 : payG13
                payG14 = isNaN(payG14) ? 0 : payG14
                payG15 = isNaN(payG15) ? 0 : payG15


                // Filter out equal comments
                // const commentsByDate = comments.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
                // const commentsByDateLastYear = commentsLastYear.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
                const commentsOriginal = commentsByDate.filter(comment => comment.analysis !== "lika" && comment.analysis !== "general");
                const commentsLastYearOriginal = commentsByDateLastYear.filter(comment => comment.analysis !== "lika" && comment.analysis !== "general");
                const commentsEqual = commentsByDate.filter(comment => comment.analysis === "lika");

                //Set state for groups
                this.setState({
                    group1: group1Data,
                    group2: group2Data,
                    group3: group3Data,
                    group4: group4Data,
                    group5: group5Data,
                    group6: group6Data,
                    group7: group7Data,
                    group8: group8Data,
                    group9: group9Data,
                    group10: group10Data,
                    group11: group11Data,
                    group12: group12Data,
                    group13: group13Data,
                    group14: group14Data,
                    group15: group15Data,
                    comments: commentsOriginal,
                    commentsLastYear: commentsLastYearOriginal,
                    commentsEqual: commentsEqual,
                    payGapPerGroup: {
                        payG1: payG1,
                        payG2: payG2,
                        payG3: payG3,
                        payG4: payG4,
                        payG5: payG5,
                        payG6: payG6,
                        payG7: payG7,
                        payG8: payG8,
                        payG9: payG9,
                        payG10: payG10,
                        payG11: payG11,
                        payG12: payG12,
                        payG13: payG13,
                        payG14: payG14,
                        payG15: payG15,
                    }
                })

                //Set alla employees to state
                this.setState({
                    allEmp: allEmployees
                })
                //Set state for all comments (remove years for this year or after the selected year)
                let selectedYear = localStorage.getItem('year')
                if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
                if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
                const allComments = data[6]
                const allCommentsExceptNewer = allComments.filter(obj => { const objYear = new Date(obj.dateAdded).getFullYear(); return objYear < selectedYear; });
                // const allYears = [...new Set(allCommentsExceptNewer.map(obj => new Date(obj.dateAdded).getFullYear()))].sort((a, b) => a - b); 
                this.setState({
                    allComments: allCommentsExceptNewer,
                    selectedYear: selectedYear,
                    year: selectedYear - 1,
                })



                // Calculate how much each group affects the payGap: 
                /*  console.log("group1")
                  console.log(group1) // <-- This is all employees in the equivalentGroup, group1,2,3 and so on
                  console.log("group1Data")
                  console.log(group1Data) // <-- This is a list with the data for each equivalent work in group 1 (2,3 and so on), such as average salary etc. It is the same as this.state.group1
      
                  // First, get total data
                  let totalWomen = group1.filter(obj => obj.gender === "woman").length
                  let totalMen = group1.filter(obj => obj.gender === "man").length
                  let totalSalaryWomen = group1.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.totalSalary, 0)
                  let totalSalaryMen = group1.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.totalSalary, 0)
      
                  const avgSalaryWomen = totalSalaryWomen / totalWomen;
                  const avgSalaryMen = totalSalaryMen / totalMen;
                  const totalPayGap = (avgSalaryMen - avgSalaryWomen) / avgSalaryMen; // OBS! Räknar åt "fel håll", dvs om kvinnor tjänar mer blir det plus. Om vill vända på det kan jag bara ta avgSalaryWomen - avgSalaryMen istället
                  console.log('Genomsnittslön kvinnor:', avgSalaryWomen);
                  console.log('Genomsnittslön män:', avgSalaryMen);
                  console.log('Totalt lönegap:', totalPayGap * 100 + '%');
      
                  // Calculate how much each work contributes to the paygap
                  let totalContribution = 0;
                  let contributions = [];
                  group1Data.forEach(pos => {
                      const totalEmployees = pos.Women + pos.Men;
                      const weight = totalEmployees / (totalWomen + totalMen);
                      let relativePayGap;
      
                      let menSalary = pos.menSalary; if (isNaN(menSalary)) { menSalary = 0 }
                      let womenSalary = pos.womenSalary; if (isNaN(womenSalary)) { womenSalary = 0 }
      
                      if (pos.Women > 0 && pos.Men > 0) {
                          // Befattning med både kvinnor och män
                          relativePayGap = (menSalary - womenSalary) / avgSalaryMen; // OBS! Räknar åt "fel håll",
                      } else if (pos.Men > 0) {
                          // Befattning med endast män
                          relativePayGap = (menSalary - avgSalaryWomen) / avgSalaryMen; // OBS! Räknar åt "fel håll",
                      } else {
                          // Befattning med endast kvinnor
                          relativePayGap = (avgSalaryMen - womenSalary) / avgSalaryMen; // OBS! Räknar åt "fel håll",
                      }
      
                      const contribution = weight * relativePayGap;
                      contributions.push({ name: pos.group, contribution });
                      totalContribution += contribution;
                      console.log(`Bidrag från befattning ${pos.group}: ${contribution * 100}%`);
                  });
                  // Kontrollera att summan av bidragen är lika med det totala lönegapet
                  console.log('Total contribution:', totalContribution * 100 + '%');
                  // Justera för avrundningsfel
                  const roundingAdjustment = totalPayGap - totalContribution;
      
                  contributions.forEach(contrib => {
                      const adjustedContribution = contrib.contribution + (roundingAdjustment * (contrib.contribution / totalContribution));
                      console.log(`Adjusted contribution from position ${contrib.name}: ${adjustedContribution * 100}%`);
                  });*/
            })
        }
        getDataAndComments();
    }

    //handle click function for hierarchical jobs. 
    //1. IF CLICKING ON WOMEN GROUP, MARK EVERY GROUP THAT IS NOT WOMEN AND HAS HIGHER PAY BUT LOWER DEMANDS
    //2. IF CLICKING ON MEN OR EQUAL GROUP, MARK EVERY GROUP THAT IS WOMEN AND HAS LOWER PAY AND HIGHER DEMANDS (LIKE IT IS NOW, BUT ONLY FOR MEN)
    handleClick = (event) => {

        const data = JSON.parse(event.currentTarget.id)

        //First, check if selectGroup1 and 2 is active, if so, the user is trying to select a group to compare
        if (this.state.selectGroup1) {
            this.setState({
                analyseGroup1: data.group,
                group1EqualGroup: data.demands,
                selectGroup1: false,
            })
            return;
        }
        if (this.state.selectGroup2) {
            this.setState({
                analyseGroup2: data.group,
                group2EqualGroup: data.demands,
                selectGroup2: false,
            })
            return;
        }

        //If user is not trying to select a group to compare, then show groups in green.
        if (this.state.clickedGroup === data.group) {

            this.setState({
                clickedGroup: "",
                avgSalary: null,
                womenPercent: null,
                demands: null
            })
        } else {
            this.setState({
                clickedGroup: data.group,
                avgSalary: data.avgSalary,
                womenPercent: data.womenPercent,
                demands: data.demands
            })
        }
    }

    eyeClick = (event) => {
        event.stopPropagation(); // Makes sure handleClick is not also running

        //FilteredData contains all individuals that should be shown, 
        //FilteredOut contains all individuals that should be hidden,
        const group = event.target.dataset.egroup;

        // DO IT FOR INDIVIDUALS ---
        let filtered = this.state.filteredData.slice()
        let filteredOut = this.state.filteredOut.slice()

        //Check if group is included in filtered
        const groupIncluded = filtered.some(item => item.groupNameArbVardering === group);

        if (groupIncluded) {
            //Move it to filteredOut
            filteredOut.push(...filtered.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            filtered = filtered.filter(item => item.groupNameArbVardering !== group);
        } else {
            //Move it to filtered
            filtered.push(...filteredOut.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            filteredOut = filteredOut.filter(item => item.groupNameArbVardering !== group);
        }
        this.setState({ filteredOut: filteredOut, filteredData: filtered })
        // --- END FOR THE INDIVIDUALS

        // DO IT FOR seriesBig ---
        let seriesBig = this.state.seriesBig.slice()
        let seriesBigFilteredOut = this.state.seriesBigFilteredOut.slice()

        const groupFound = seriesBig.some(element => element.name.includes(group));
        if (groupFound) {
            // Iterate through the main array
            for (let i = 0; i < seriesBig.length; i++) {
                const element = seriesBig[i];
                if (element.name === group) {
                    // Copy the array to seriesBigFilteredOut
                    seriesBigFilteredOut.push({ ...element });
                    // Remove the array from seriesBig
                    seriesBig.splice(i, 1);
                    break; // Break the loop after the first occurrence
                }
            }
        } else {
            for (let i = 0; i < seriesBigFilteredOut.length; i++) {
                const element = seriesBigFilteredOut[i];
                if (element.name === group) {
                    // Copy the "data" array to the new array
                    seriesBig.push({ ...element });
                    seriesBigFilteredOut.splice(i, 1);
                    break;
                }
            }
            // Also add back any hidden individuals and remove them from seriesBigFilteredOut
            for (let i = 0; i < seriesBigFilteredOut.length; i++) {
                for (let j = 0; j < seriesBig.length; j++) {
                    if (seriesBig[j].name === seriesBigFilteredOut[i].groupNameArbVardering) {
                        seriesBig[j].data.push(seriesBigFilteredOut[i]);    // Push the object into the data array
                        seriesBigFilteredOut.splice(i, 1); // Remove the object from seriesBigFilteredOut
                        i--;  // Adjust the index to account for the removed item
                        break;    // Exit the inner loop once a match is found
                    }
                }
            }
        }
        this.setState({ seriesBig: seriesBig, seriesBigFilteredOut: seriesBigFilteredOut })
        /*  console.log("seriesBig")
          console.log(seriesBig)
          console.log("seriesBigFilteredOut")
          console.log(seriesBigFilteredOut)*/

        // -- End for seriesBig

        // For annotations
        let annotations = this.state.optionsBig.annotations.points.slice()
        let annotationsFilteredOut = this.state.annotationsFilteredOut.slice()

        //OVAN FUNKAR MEN VI MÅSTE RENSA ANNOTATIONS MED!
        //Check if group is included in filtered
        const groupIncludedA = annotations.some(item => item.groupNameArbVardering === group);
        if (groupIncludedA) {
            //Move it to filteredOut
            annotationsFilteredOut.push(...annotations.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            annotations = annotations.filter(item => item.groupNameArbVardering !== group);
        } else {
            //Move it to filtered
            annotations.push(...annotationsFilteredOut.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            annotationsFilteredOut = annotationsFilteredOut.filter(item => item.groupNameArbVardering !== group);
        }
        this.setState({
            annotationsFilteredOut: annotationsFilteredOut,
            optionsBig: {
                ...this.state.optionsBig,
                annotations: {
                    points: annotations
                },
            }
        })
        // Lastly, rerun openanalysis to make sure colors are correct if changing from gender to work or vice versa
        // this.openAnalysis(this.state.analysisOpen, "noClose");
        this.selectColorFill(undefined, "useSelected")

    }
    gapPercent = () => { this.state.gapPercent ? this.setState({ gapPercent: false }) : this.setState({ gapPercent: true }) }

    editComment = (event) => {
        //let comment = ""
        const column = event.target.name //what column should be saved
        const commentId_PK = event.target.id //For what comment ID
        const text = event.target.value //what text should be saved
        const editedComments = this.state.comments

        // if (text === "") { return; } //If no text changed, don't do anything

        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { [column]: text, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });

        this.setState({ comments: updateComments })
        //Update save button
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    deleteComment = (event) => {
        const commentId_PK = event.target.id
        const removedComment = this.state.comments.filter(el => el.commentId_PK == commentId_PK);  //Get the deleted comment
        const listWithoutComment = this.state.comments.filter(el => el.commentId_PK != commentId_PK); //create new list without the deleted comment
        let del = this.state.deletedComments
        if (removedComment[0].reuseComment !== "Yes") { del = this.state.deletedComments.concat(removedComment) } //add removedComment to deletedComments array:

        this.setState({
            comments: listWithoutComment, //set state for new array without the deleted comment.
            deletedComments: del //set state for the comment/s that should be deleted, so it wont be deleted until we hit the save button
        })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    //Save changes in comments
    saveButton = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        //send only updatedComments to server for saving (and also the deleted comments)
        const updatedComments = this.state.comments.filter(el => el.updated)
        const deletedComments = this.state.deletedComments

        const saveData = async () => {
            const commentData = {
                changes: updatedComments,
                deleted: deletedComments
            }

            let response = await fetchAuth(`/api/saveEqualCommentChanges`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();
            if (data == 'Saved') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)

                this.setState({ saveButtonStatus: "Sparat" })

            } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        };
        saveData();

        //Remove property updated if saved
        const removeUpdatedProperty = this.state.comments.map(el => {
            if (el.updated) {
                delete el.updated;
                return el;
            }
            return el;
        });
        this.setState({ comments: removeUpdatedProperty })
    }

    //Filter comments
    filterEqualent = () => {
        this.setState({ analysis: 'likvärdiga' })
    }
    filterHierarchy = () => {
        this.setState({ analysis: 'hierarkisk' })
    }
    filterSummary = () => {
        this.setState({ analysis: 'summary' })
    }
    filterAll = () => {
        this.setState({ analysis: 'all' })
    }

    //toggle more save fields
    toggleFields = () => {
        if (this.state.moreFields) {
            this.setState({
                moreFields: false
            })
        } else {
            this.setState({
                moreFields: true
            })
        }
    }

    //Save new comment
    //First add state for fields:
    addAnalysisEqualent = () => {
        this.setState({
            addAnalysis: "likvärdiga",
            analysisOpen: false,
            filteredOut: [],
            seriesBigFilteredOut: [],
            annotationsFilteredOut: []
        })
    }
    addAnalysisHierarchy = () => {
        this.setState({
            addAnalysis: "hierarkisk",
            analysisOpen: false,
            filteredOut: [],
            seriesBigFilteredOut: [],
            annotationsFilteredOut: []
        })
    }
    addAnalysisSummary = () => {
        this.setState({ addAnalysis: "summary" })
        /* this.setState({ addAnalysis: "summary", analysisOpen: 1 }, () => {
             this.openAnalysis(this.state.analysisOpen)
         });
         this.filterEqualent()
         */
        //changeWhatAnalysisOpen
        let analysisOpen = "1"
        if (this.state.analysisOpen) { analysisOpen = this.state.analysisOpen }
        let commentGroup = this.state.comments.filter(comment => comment.groupE == analysisOpen && comment.analysis === "summary");
        if (commentGroup.length > 0) {
            // Update the state and the textarea value using the ref
            this.setState({ addCommentSummary: commentGroup[0].notable, summaryGreyBtn: false });
            this.curSelCommentSummary.value = commentGroup[0].notable;
        } else {
            this.setState({ addCommentSummary: "", summaryNrOfActions: "" });
            this.curSelCommentSummary.value = ""
        }
        // Also calculate nr of actions for the group
        const count = this.state.comments.reduce((acc, obj) => {
            return acc + (obj.groupE === "1" && obj.todo !== "" && obj.todo !== null ? 1 : 0);
        }, 0);
        this.setState({ summaryNrOfActions: count })
    }
    handleComment = (event) => {
        this.setState({ addComment: this.curSelComment.value })
    }
    handleReason = (event) => {
        this.setState({ addReason: this.curSelReason.value })
    }
    handleTodo = (event) => {
        this.setState({ addTodo: this.curSelTodo.value })
    }
    handleDeadline = (event) => {
        this.setState({ addDeadline: event.target.value })
    }
    handleCost = (event) => {
        this.setState({ addCost: this.curSelCost.value })
    }
    handleResponsible = (event) => {
        this.setState({ addResponsible: this.curSelResp.value })
    }
    handleCommentSummary = () => {
        this.setState({ addCommentSummary: this.curSelCommentSummary.value })
    }
    handleCommentSalaryClick = () => {
        this.setState({ summaryGreyBtn: true })
    }

    //Then, save comment if clicking save button
    saveNewComment = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if ((this.state.addComment !== "" && this.state.addAnalysis !== "summary") || (this.state.addCommentSummary !== "" && this.state.addAnalysis === "summary")) {

            const saveData = async () => {
                let notable = this.state.addAnalysis === "summary" ? this.state.addCommentSummary : this.state.addComment
                let groupE = this.state.addAnalysis === "summary" && this.state.analysisOpen !== false ? this.state.analysisOpen : this.state.addAnalysis === "summary" ? "1" : undefined // "1" is default group
                const commentData = {
                    notable: notable,
                    reason: this.state.addReason,
                    todo: this.state.addTodo,
                    deadline: this.state.addDeadline,
                    cost: this.state.addCost,
                    responsible: this.state.addResponsible,
                    analysis: this.state.addAnalysis,
                    groupE: groupE
                }

                let response = await fetchAuth(`/api/saveEqualentComment`, 'POST', JSON.stringify({ data: commentData }));
                let data = await response.json();

                //add todays date to temporary comment,or a previous date if we are doing it for a previous year
                let selectedYear = localStorage.getItem('year')
                if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
                if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
                let d = new Date()
                let month = d.getMonth();
                let day = d.getDate();
                const todaysDate = new Date(selectedYear, month, day);
                const dateNow = todaysDate.toISOString();

                //When saved, add the new comment to the comments array
                const toState = {
                    notable: notable,
                    reason: this.state.addReason,
                    todo: this.state.addTodo,
                    deadline: this.state.addDeadline,
                    cost: this.state.addCost,
                    responsible: this.state.addResponsible,
                    equalGroup: this.state.groupTitle,
                    commentId_PK: data,
                    analysis: this.state.addAnalysis,
                    dateAdded: dateNow,
                    groupE: groupE
                }
                const commentList = this.state.comments.concat(toState);

                //Arrange by descending date so the new comments goes to top:
                //commentList = [...comments].sort((a, b) => b.dateAdded > a.dateAdded ? 1: -1)
                const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
                //Reset fields
                if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                    this.setState({
                        addComment: "",
                        addReason: "",
                        addTodo: "",
                        addDeadline: "",
                        addCost: "",
                        addResponsible: "",
                        addCommentSummary: "",
                        comments: sortedComments //add the new comment to the comments array
                    })
                    this.curSelCommentSummary.value = ""
                    this.curSelComment.value = ""
                    this.curSelReason.value = ""
                    this.curSelTodo.value = ""
                    this.curSelCost.value = ""
                    this.curSelResp.value = ""

                    // This makes sure new comments for summary is still showing when clicked save
                    if (this.state.showWhat === "EU" || this.state.addAnalysis === "summary") {
                        this.curSelCommentSummary.value = notable
                        this.setState({ summaryGreyBtn: false })
                    }
                } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
            };

            // For summary, first check if this is a new comment or old one that should be updated. There can only be one summary per group
            if (this.state.showWhat !== "EU" || this.state.addAnalysis !== "summary") {
                saveData();
            } else {
                // Check if groupE is already in comments, if so update, if not, call saveData as usual
                let groupE = this.state.analysisOpen !== false ? this.state.analysisOpen : "1" // "1" is default group
                const hasGroupE = this.state.comments.find(obj => obj.groupE === groupE && obj.analysis === "summary");// Undefined if not found, which is a false value. If so save as new, if found update 
               // console.log(hasGroupE)
                if (!hasGroupE) { saveData(); }
                else {

                    // Update text instead of saving new object
                    // create a new object with the updated 'notable' property
                    const updatedObject = {
                        ...hasGroupE,
                        notable: this.state.addCommentSummary,
                    };
                    // Create a new array with the updated object
                    const updatedComments = this.state.comments.map(obj =>
                        obj.groupE === groupE && obj.analysis === "summary" ? updatedObject : obj
                    );

                    // Save it using saveEqualCommentChanges (same function as main save button, therefore need to add the commentData object)
                    this.setState({ comments: [] }, () => { // Empty array to force update state, otherwise the updated comment wont be seen in the list until user refreshes the page. Creates a flicker but it is ok.
                        const updateData = async () => {
                            const commentData = {
                                changes: [updatedObject],
                                deleted: []
                            }
                            let response = await fetchAuth(`/api/saveEqualCommentChanges`, 'POST', JSON.stringify({ data: commentData }));
                            let data = await response.json();
                            if (data == 'Saved') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                                this.setState({ summaryGreyBtn: false, comments: updatedComments })
                            } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
                        };
                        updateData();
                    });
                }
            }
        } else {
            alert(language === "sv" ? 'Du kan inte spara en tom notering. Vänligen fyll i fältet "Noterbart".' : lang[language].noEmptyComment)
        }
    }

    togglePop = () => {
        this.state.popUp ? this.setState({ popUp: false }) : this.setState({ popUp: true })
    }
    popUpInfo = () => {
        this.state.popUpInfo ? this.setState({ popUpInfo: false }) : this.setState({ popUpInfo: true })
    }
    popUpSummaryQuestionMark = () => {
        this.state.popUpSummaryQuestionMark ? this.setState({ popUpSummaryQuestionMark: false }) : this.setState({ popUpSummaryQuestionMark: true })
    }

    //----------------------------- For old comments // ----------------------------- 
    reuseComment = (event) => {
        const commentId_PK = event.target.id

        //get data for comment from state
        const commentData = this.state.commentsLastYear.filter((comment) => comment.commentId_PK == commentId_PK)
        //Get selected year from user
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

        //Add the new comment to the comments array
        const toState = {
            notable: commentData[0].notable,
            reason: commentData[0].reason,
            todo: commentData[0].todo,
            deadline: commentData[0].deadline,
            cost: commentData[0].cost,
            responsible: commentData[0].responsible,
            equalGroup: commentData[0].equalGroup,
            commentId_PK: commentId_PK,
            analysis: commentData[0].analysis,
            dateAdded: dateNow,
            updated: 'Yes',
            reuseComment: 'Yes',
        }
        const commentList = this.state.comments.concat(toState);

        //Sort comments by descending date
        const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

        //get all comments except this comment (to use for setting new state)
        const pastYearsComments = this.state.commentsLastYear.filter((comment) => comment.commentId_PK != commentId_PK)

        //also remove from allComments
        const allComments = this.state.allComments.filter((comment) => comment.commentId_PK != commentId_PK)

        //Add comment to new state and remove from the past
        this.setState({
            comments: commentsByDate, //add the new comment to the comments array
            commentsLastYear: pastYearsComments,
            allComments: allComments
        })

        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    reuseAll = () => {
        this.setState({ popUpComments: false }) //Hide popUp

        const comments = this.state.commentsLastYear.filter((comment) => (comment.todo == null || comment.todo == "")) //We filter out the ones with a todo, because these should be evaluated and can not be used for this year
        const commentsWithTodo = this.state.commentsLastYear.filter((comment) => (comment.todo != null && comment.todo !== "")) //We use this to temporary update the commentsLastYearState
        //Also filter out the all comments array and not only
        const commentsShouldBeRemoved = this.state.commentsLastYear.filter((comment) => (comment.todo === null || comment.todo == "")) //1. get what comments to be reused
        const removeFromAllComments = this.state.allComments.filter(obj2 => !commentsShouldBeRemoved.some(obj1 => obj1.commentId_PK === obj2.commentId_PK)); //2. remove those comments from all comments

        //Get selected year from user
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

        let addedYes = comments.map(el => {
            return Object.assign({}, el, { updated: 'Yes', dateAdded: dateNow, reuseComment: 'Yes' })
        }); //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving

        const commentList = this.state.comments.concat(addedYes);
        const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

        this.setState({
            comments: commentsByDate, //add the new comment to the comments array
            commentsLastYear: commentsWithTodo, //Empty last years comments. 
            allComments: removeFromAllComments,
        })

        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    toggleOldComments = () => {
        this.state.showOldComments ? this.setState({ showOldComments: false }) : this.setState({ showOldComments: true })
    }

    toggleCommentPop = () => {
        this.state.popUpComments ? this.setState({ popUpComments: false }) : this.setState({ popUpComments: true })
    }

    AIComment = () => {
        //First, show popUp
        this.state.showAIComment ? this.setState({ showAIComment: false }) : this.setState({ showAIComment: true })

        //*** LOAD FROM COMMENTS HERE <------ DO LATER ***
        //1. KONTROLLERA DETTA ÅRS KOMMENTARER OM DEN FINNS DÄR, ISF HÄMTA OCH SKRIV DET (SKRIV UNDER "DU har redan förklarat med ovanstående")
        //2. KONTROLLERA FÖREGÅENDE ÅRS KOMMENTARER OM DEN FINNS DÄR, ISF SKRIV UNDER OCH BERÄTTA ATT FÖREGÅENDE FINNS OCH FRÅGA OM VILL HÄMTA
        //3. HÄMTA ANNARS FRÅN AUTOANALYS

        //ALT HÄMTA BARA FRÅN AUTOANALYS, IAF TILL EN BÖRJAN <--- BÖRJA MED DETTA! GÖRS GENOM ATT JAG SÄTTER AInotable och AIreason state när autonaalys har körts.

    }

    saveAIComment = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        // SAVE TO DB HERE <-------- GLÖM EJ!!!!!! ***/
        let comparedArray = [this.state.analyseGroup1.replace(/\s/g, '').toLowerCase(), this.state.analyseGroup2.replace(/\s/g, '').toLowerCase()].sort()
        let compared = comparedArray[0] + comparedArray[1]

        let notable = this.state.AInotable
        let reason = this.state.AIreason
        let analyseType = this.state.AIAnalys

        //Check if empty, if so return "must add notable"
        if (notable === "") {
            alert(language === "sv" ? 'Du kan inte spara en tom kommentar. Fyll i noterbart rutan.' : lang[language].noEmptyComment)
            return;
        }

        //Save to DB:
        const saveData = async () => {
            const commentData = {
                notable: notable,
                reason: reason,
                todo: "",
                deadline: "",
                cost: "",
                responsible: "",
                analysis: analyseType,
                comparedGroups: compared
            }

            let response = await fetchAuth(`/api/saveEqualentComment`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();

            //add todays date to temporary comment,or a previous date if we are doing it for a previous year
            let selectedYear = localStorage.getItem('year')
            if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
            if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
            let d = new Date()
            let month = d.getMonth();
            let day = d.getDate();
            const todaysDate = new Date(selectedYear, month, day);
            const dateNow = todaysDate.toISOString();

            //When saved, add the new comment to the comments array
            const toState = {
                notable: notable,
                reason: reason,
                todo: "",
                deadline: "",
                cost: "",
                responsible: "",
                commentId_PK: data,
                analysis: analyseType,
                dateAdded: dateNow,
                comparedGroups: compared
            }
            const commentList = this.state.comments.concat(toState);

            //Arrange by descending date so the new comments goes to top:
            //commentList = [...comments].sort((a, b) => b.dateAdded > a.dateAdded ? 1: -1)
            const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

            if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                this.setState({
                    comments: sortedComments //add the new comment to the comments array
                })
            } else {
                alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave)
            }
        };
        saveData();

        //Then hide PopUp
        this.state.showAIComment ? this.setState({ showAIComment: false }) : this.setState({ showAIComment: true })
    }

    changeTextAINotable = (event) => {
        this.setState({ AInotable: event.target.value })
    }
    changeTextAIReason = (event) => {
        this.setState({ AIreason: event.target.value })
    }

    //For edit salary and hide person functions
    hideEditBox = () => { this.setState({ popItUp: false, popUpEditSalarySetting: false }) }
    hideEditBoxSummary = () => { this.setState({ popItUpSummary: false, popUpEditSalarySettingSummary: false }) }
    addEditSalary = (event) => {
        let salary = Number(event.target.value)
        this.setState({ newSalary: salary })
    }
    addEditSalarySummary = (event) => {
        let salary = Number(event.target.value)
        this.setState({ newSalarySummary: salary })
    }
    forceUpdateApexChart = (serieZero, serieOne) => {
        return new Promise(resolve => {
            this.setState({
                groupOneEdited: serieZero,
                groupTwoEdited: serieOne,
                popItUp: false,
            })
            resolve()
        });
    }
    hideEmployeeSummary = async () => {
        let serieIndex = this.state.serieIndexSummary
        let seriesBig = JSON.parse(JSON.stringify(this.state.seriesBig)) // create deep copy of series big
        let employee = seriesBig[serieIndex].data.splice(this.state.clickedPersonIndexSummary, 1) // remove the person from the array. Employee holds the employee and seriesBig will be left without the employee

        let filteredData = this.state.filteredData.slice()
        let filteredOut = this.state.filteredOut.slice()
        filteredOut.push(employee[0])

        let seriesBigFilteredOut = this.state.seriesBigFilteredOut.slice()
        seriesBigFilteredOut.push(employee[0]) //<--? use[0]? 

        // Remove from filteredData also
        let fullName = employee[0].fullName
        let experience = employee[0].experience
        let age = employee[0].age
        let positionTitle = employee[0].positionTitle
        let filteredDataUpdated = filteredData.filter(person =>
            person.fullName !== fullName ||
            person.experience != experience ||
            person.age != age ||
            person.positionTitle !== positionTitle
        );

        // Also remove from annotations
        let indexInAnnotations = -1
        for (let i = 0; i < this.state.optionsBig.annotations.points.length; i++) {
            if (this.state.optionsBig.annotations.points[i].fullName === fullName &&
                this.state.optionsBig.annotations.points[i].experience === experience &&
                this.state.optionsBig.annotations.points[i].age === age &&
                this.state.optionsBig.annotations.points[i].positionTitle === positionTitle) {
                indexInAnnotations = i;
                break;  // Break the loop as soon as a match is found
            }
        }

        let annotationsCopy = JSON.parse(JSON.stringify(this.state.optionsBig.annotations.points)) // create deep copy of series big
        let employeeFromAnn = annotationsCopy.splice(indexInAnnotations, 1) // remove the person from the annotations array. employeeFromAnn holds the employee and annotationsCopy will be left without the employee

        let annotationsFilteredOut = this.state.annotationsFilteredOut.slice()
        annotationsFilteredOut.push(employeeFromAnn[0])

        this.setState({
            seriesBig: seriesBig,
            filteredOut: filteredOut,
            seriesBigFilteredOut: seriesBigFilteredOut,
            filteredData: filteredDataUpdated,
            popItUpSummary: false,
            annotationsFilteredOut: annotationsFilteredOut,
            optionsBig: {
                ...this.state.optionsBig,
                annotations: {
                    points: annotationsCopy
                },
            }
        })

    }
    hideEmployee = async () => {
        let serieZero = this.state.groupOneEdited
        let serieOne = this.state.groupTwoEdited
        if (this.state.serieIndex == 0) { //If serie 0.
            serieZero = this.state.groupOneEdited.slice(0) // Copy the array with all employees without mutating
            serieZero.splice(this.state.clickedPersonIndex, 1) //Remove the person from the array
        }
        if (this.state.serieIndex == 1) { //If serie 1
            serieOne = this.state.groupTwoEdited.slice(0)
            serieOne.splice(this.state.clickedPersonIndex, 1)
        }
        await this.forceUpdateApexChart(serieZero, serieOne)
        this.autoCompare("edited")
    }
    popUpEditSalarySetting = () => { this.setState({ popUpEditSalarySetting: true }) }
    popUpEditSalarySettingSummary = () => { this.setState({ popUpEditSalarySettingSummary: true }) }

    editSalarySummary = async (event) => {
        // Works like this: Set editedSalaryFull or editedSalaryBase for the indivdual. Do this for seriesBig and filteredData. 
        // Then call openAnalysis and inside the openAnalysis function it should check if editedSalaryFull or editedSalaryBase is set, if so use that, if not, use as normal

        let includeSalary = event == undefined ? true : event.target.id === "excludeSalary" ? false : true // If addon should be included or not
        let newSalary = this.state.newSalarySummary

        // Set the new salary for the employee in this.state.seriesBig
        let serieIndex = this.state.serieIndexSummary
        let seriesBig = JSON.parse(JSON.stringify(this.state.seriesBig)) // create deep copy of series big
        let theEmployee = seriesBig[serieIndex].data[this.state.clickedPersonIndexSummary]
        if (includeSalary) { theEmployee.editedSalaryFull = newSalary; theEmployee.editedSalaryBase = undefined }
        if (!includeSalary) { theEmployee.editedSalaryBase = newSalary; theEmployee.editedSalaryFull = undefined }


        // Set the new salary for the employee in this.state.filteredData
        let filteredData = JSON.parse(JSON.stringify(this.state.filteredData)) // create deep copy of series big
        for (let i = 0; i < filteredData.length; i++) {
            if (theEmployee.fullName === filteredData[i].fullName && theEmployee.age === filteredData[i].age && theEmployee.positionTitle === filteredData[i].positionTitle && theEmployee.totalSalary === filteredData[i].totalSalary) {
                if (includeSalary) { filteredData[i].editedSalaryFull = newSalary; filteredData[i].editedSalaryBase = undefined }
                if (!includeSalary) { filteredData[i].editedSalaryBase = newSalary; filteredData[i].editedSalaryFull = undefined }
            }
        }

        // Update state and call openAnalysis, openAnalysis will select the correct salary
        this.setState({ seriesBig: seriesBig, filteredData: filteredData, popUpEditSalarySettingSummary: false, popItUpSummary: false }, () => {
            this.openAnalysis(this.state.analysisOpen, "noClose")
        });
    }

    editSalary = async (excluded) => {
        let serieZero = this.state.groupOneEdited
        let serieOne = this.state.groupTwoEdited
        if (this.state.serieIndex == 0) { //If serie 0
            serieZero = this.state.groupOneEdited.slice(0) // Copy the array with all men without mutating
            //Below is bugfixes to rerun chart, basically it removes the person and then ads it again
            let thePerson = serieZero[this.state.clickedPersonIndex]
            if (excluded === "exclude") { //if changed salary excluding extra 
                thePerson.salNoExtra = this.state.newSalary
            } else { //if changed salary including extra 
                thePerson.salBeforeAddOn = this.state.newSalary //Create new person with updated salary
                thePerson.salNoExtra = undefined
            }
            serieZero.splice(this.state.clickedPersonIndex, 1) //Remove the person
            if (serieZero.length === 0) { //If there is only one person in the list, push a copy
                // await this.forceUpdateApexChart(serieZero, serieOne);
            }
            if (serieZero.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
                serieZero.push(thePerson)
            } else { //Else push at the start instead
                serieZero.unshift(thePerson)
            }
        }
        if (this.state.serieIndex == 1) { //If womens group
            serieOne = this.state.groupTwoEdited.slice(0) // Copy the array with all men without mutating
            //Below is bugfixes to rerun chart, basically it removes the person and then ads it again
            let thePerson = serieOne[this.state.clickedPersonIndex]
            if (excluded === "exclude") { //if changed salary excluding extra 
                thePerson.salNoExtra = this.state.newSalary //Create new person with updated salary that is not including extra
            }
            else { //if changed salary including extra 
                thePerson.salBeforeAddOn = this.state.newSalary //Create new person with updated salary
                thePerson.salNoExtra = undefined
            }
            serieOne.splice(this.state.clickedPersonIndex, 1) //Remove the person
            if (serieOne.length === 0) { //If there is only one person in the list, push a copy
                //  await this.forceUpdateApexChart(serieZero, serieOne);
            }
            if (serieOne.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
                serieOne.push(thePerson)
            } else { //Else push at the start instead
                serieOne.unshift(thePerson)
            }
        }
        this.setState({ popUpEditSalarySetting: false })
        await this.forceUpdateApexChart(serieZero, serieOne)
        this.autoCompare("edited")
    }
    editSalaryExclude = () => { this.editSalary("exclude") }
    editSalaryExcludeSummary = () => { this.editSalarySummary("exclude") }

    selectSummaryComments = (event) => {
        let what = event.target.id
        if (what === "idequiv") { this.setState({ selectedSummaryComments: "equivalent" }) }
        if (what === "idequal") { this.setState({ selectedSummaryComments: "equal" }) }

    }

    selectColorFill = (event, forFilter) => {
        let theGroup = ""
        if (event !== undefined && event.target !== undefined) {
            theGroup = event.target.id // This is either "work" or "gender"
        } else {
            theGroup = this.state.colorFill
        }
        this.setState(prevState => ({
            colorFill: theGroup
        }), () => {
            this.openAnalysis(this.state.analysisOpen, "noClose");
        });
    }

    changeWhatAnalysisOpen = (event) => {
        let whatGroup = event.target.value
        // Check if the group already has a comment, if so, add it so it is filled in.
        let commentGroup = this.state.comments.filter(comment => comment.groupE == whatGroup && comment.analysis === "summary");
        if (commentGroup.length > 0) {
            // Update the state and the textarea value using the ref
            this.setState({ addCommentSummary: commentGroup[0].notable });
            this.curSelCommentSummary.value = commentGroup[0].notable;
        } else {
            this.setState({ addCommentSummary: "" });
            this.curSelCommentSummary.value = ""
        }
        // Also calculate nr of actions for the group
        const count = this.state.comments.reduce((acc, obj) => {
            return acc + (obj.groupE === whatGroup && obj.todo !== "" && obj.todo !== null ? 1 : 0);
        }, 0);
        // Update rest of state
        this.setState({ analysisOpen: false, filteredOut: [], seriesBigFilteredOut: [], annotationsFilteredOut: [], summaryNrOfActions: count }, () => {
            this.openAnalysis(whatGroup, "fromComment");
        });
    }

    openAnalysis = (event, close) => {

        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.analysisOpen && close !== "noClose") {
            this.setState({
                analysisOpen: false,
                filteredOut: [],
                seriesBigFilteredOut: [],
                annotationsFilteredOut: []
            })
        }
        else {
            let theEventGroup = 0
            if (event !== undefined && event.target !== undefined) {
                theEventGroup = event.target.dataset.group
                window.scroll(0, 0);
            } else {
                theEventGroup = event
                if (typeof event === 'string' && close === "fromComment") {
                    window.scroll(0, 0);
                }
            }
            // First set the text for the summary textBox when changing analysis
            let commentGroup = this.state.comments.filter(comment => comment.groupE == theEventGroup && comment.analysis === "summary");
            this.curSelCommentSummary.value = commentGroup.length > 0 ? commentGroup[0].notable : ""

            // Get comments that fits here
            let allComments = this.state.comments
            let pastAllComments = this.state.commentsLastYear
            // Filter comments that matches our criterias <-- Glöm inte göra allt till små bokstäver med.
            let worksIncluded = []
            let selectedGroup = theEventGroup
            let groupShort = "grupp " + selectedGroup
            worksIncluded.push(groupShort)
            let groupLong = "gruppen " + selectedGroup
            worksIncluded.push(groupLong)
            let whatGroup = "group" + selectedGroup
            for (let i = 0; i < this.state[whatGroup].length; i++) {
                worksIncluded.push(this.state[whatGroup][i].group)
            }
            const worksExcluded = this.state.allEmp // <-- Används inte nu
                .filter(employee => !worksIncluded.includes(employee.groupNameArbVardering))
                .map(employee => employee.groupNameArbVardering)
                .filter((value, index, self) => self.indexOf(value) === index);

            let filteredComments = []
            for (let i = 0; i < allComments.length; i++) {
                if (allComments[i].analysis === "likvärdiga") {
                    const commentText = allComments[i].notable.toLowerCase(); //<-- Kolla även reason
                    if (worksIncluded.some(work => commentText.includes(work.toLowerCase()))) {
                        filteredComments.push(allComments[i]);
                    }
                }
            }

            let commentsEqual = this.state.commentsEqual
            const filteredCommentsEqual = commentsEqual.filter(work => worksIncluded.includes(work.equalGroup)); // filter the relevant equal works
            this.setState({ summaryComments: filteredComments, summaryCommentsEqual: filteredCommentsEqual })


            // End of get comments

            this.setState({ analysisOpen: theEventGroup, addAnalysis: "summary" })
            let filteredData = []
            let updateEmployeeData = (groupNr) => {
                const group = `group${groupNr}`;
                filteredData = this.state[group].flatMap(group =>
                    group.employeeList.map(employee => ({
                        ...employee,
                        x: employee[this.state.xAxisTypeSummary], // employee.experience or employee.age
                        y: employee.totalSalary
                        /*  y: 
                          hideExtraSalary === "includeExtra" ? employee.totalSalary : //"includeExtra", "hideExtra", "onlyExtra"
                          hideExtraSalary === "hideExtra" ? employee.totalSalary - employee.addOn :
                          hideExtraSalary === "onlyExtra" ? employee.addOn*/
                    })))
            }
            updateEmployeeData(theEventGroup)
            if (close !== "noClose") { // Filter should not be saved in state if we change colorfill, but we still need all employees to make sure everyone gets colored correctly. 
                this.setState({ filteredData: filteredData })
            }

            // Group employees by groupNameArbVardering
            const groupedData = filteredData.reduce((groups, employee) => {
                const groupName = employee.groupNameArbVardering;
                if (!groups[groupName]) {
                    groups[groupName] = [];
                }
                groups[groupName].push(employee);
                return groups;
            }, {});
            // Generate colors for each group (will start from index 0 and work upwards)
            const colors = ['#50e7a6', '#40a0fc', '#d450e7', '#7650e7', '#e7508c', '#e79e50', '#60FF33', '#3360FF', '#FFB833', '#33FFB8', '#B833FF', '#FF3366', '#66FF33', '#3366FF', '#FF33B5', '#B5FF33', '#33B5FF', '#FF3387', '#87FF33', '#3387FF', '#FF33C4', '#C4FF33', '#33C4FF', '#FF33E0', '#E0FF33', '#33E0FF', '#FFBB33', '#33FFBB', '#BB33FF', '#FF8C33', '#33FF8C', '#8C33FF', '#FF5B33', '#33FF5B', '#5B33FF', '#FF33F0', '#F0FF33', '#33F0FF', '#FFA833', '#33FFA8', '#A833FF', '#FF33D3', '#D3FF33', '#33D3FF', '#FF333F', '#3FFF33', '#333FFF'];
            //Calculate biggest xAxis
            let allExperiences = filteredData.map(person => person[this.state.xAxisTypeSummary]) // employee.experience or employee.age
            let longestExp = Math.max(...allExperiences)
            if (longestExp < 10) longestExp = 10
            if (longestExp > 10 && longestExp < 16) longestExp = 16
            if (this.state.xAxisTypeSummary === "age" && longestExp > 16 && longestExp < 41) longestExp = 40
            if (this.state.xAxisTypeSummary === "age" && longestExp > 41 && longestExp < 51) longestExp = 50

            // Update seriesBig and optionsBig in the state
            let seriesBig = Object.entries(groupedData).map(([groupName, employees], index) => ({
                name: groupName,
                data: employees,
                color: colors[index % colors.length],
            }));
            // New array to store the modified objects
            let annotationsList = [];
            // Iterate through each array in seriesBig
            for (const seriesItem of seriesBig) {
                // Iterate through each object in the "data" array
                for (const originalObject of seriesItem.data) {
                    // Create a new object with properties copied from the original object
                    const newObject = { ...originalObject };

                    // Add the "marker" property based on the "gender" property
                    newObject.marker = {
                        cssClass: originalObject.gender === 'man' ? 'annotationChartRhombus' : 'annotationChart',
                        size: originalObject.gender === 'man' ? 6 : 5.5,
                        radius: 0,
                        //  fillColor: seriesItem.color, // Use the color from the same array*/
                        fillColor: this.state.colorFill === "gender" ? (originalObject.gender === 'man' ? "#008ffb" : "#00e396") : seriesItem.color, // Use the color from the same array
                        strokeWidth: originalObject.gender === 'man' ? 0 : 0.5,
                        strokeColor: "#fff",
                    };
                    /*newObject.label = {
                        text: "M", //0x2642 is male, 0x2640 is female, use like this: String.fromCharCode(0x2640)
                        offsetY: 16.5,
                        borderWidth: 0,
                        style: {
                            background: 'transparent',
                            color: "#fff",
                            fontSize: '8px',
                            fontWeight: 600,
                        }
                    };*/
                    // Push the new object to the annotationsList array
                    annotationsList.push(newObject);
                }
            }
            // Now, annotationsList contains the modified objects with the "marker" property

            /*for (let i = 0; i < seriesBig.length; i++) {
                for (let a = 0; a < seriesBig[i].data.length; a++) {
                    //console.log(seriesBig[i].data[a])
                    seriesBig[i].data[a].color = "#333333"
                }
            }*/

            // Above have changed the colors correctly, now filter seriesBig and annotations before updating if close === "noClose"
            // By doing this after we assure that even filtered out items are colored correctly


            let seriesBigFiltered = []
            if (close === "noClose") { // <------- look
                // seriesBig

                // First, check if there is any filter for seriesBig (inside this.state.seriesBigFilteredOut), if so, remove that group before saving state
                const filteredOutWorks = new Set(this.state.seriesBigFilteredOut.map(item => item.name));
                seriesBig = seriesBig.filter(item => !filteredOutWorks.has(item.name));

                // Second, check if any individuals are filtered out (inside this.state.filteredOut), if so, remove them from the group 
                seriesBigFiltered = seriesBig.map(series => ({
                    ...series,
                    data: series.data.filter(individual =>
                        !this.state.filteredOut.some(filteredOutIndividual =>
                            individual.fullname === filteredOutIndividual.fullname &&
                            individual.positionTitle === filteredOutIndividual.positionTitle &&
                            individual.age === filteredOutIndividual.age &&
                            individual.experience === filteredOutIndividual.experience
                        )
                    )
                }));

                /* 
            color: "#50e7a6"
            data: [{…}, {…}] // individer här, fullname, gender etc. <-- kontrollera mot filterOut här och ta bort om det finns där.
            name: "Drifttekniker"
            */

                // Third, filter out annotationsList
                annotationsList = annotationsList.filter(item =>
                    !this.state.annotationsFilteredOut.some(filteredOutItem =>
                        item.fullname === filteredOutItem.fullname &&
                        item.positionTitle === filteredOutItem.positionTitle &&
                        item.age === filteredOutItem.age &&
                        item.experience === filteredOutItem.experience
                    )
                );
            }

            // Update seriesBig individuals Y value if changed (is inside this.state.filteredData)
            // console.log(this.state.filteredData)
            // Edit seriesBig so that it matches filteredData. Also change y to be what is selected in this.state.hideExtraSalary
            // if an employee is found inside filteredData and inside any group, add "y" to be the same as filteredDatas y
            // Will probably need to change annotationsList as well
            if (close === "noClose") {
                // First, update y for all items in this.state.filteredData
                let filteredDataCopy = JSON.parse(JSON.stringify(this.state.filteredData)) // create deep copy of filteredData, we also need to save this to state if close === "noClose"
                let hideExtraSummary = this.state.hideExtraSummary //"includeExtra", "hideExtra", "onlyExtra"
                for (let i = 0; i < filteredDataCopy.length; i++) {
                    if (hideExtraSummary === "includeExtra") {
                        if (filteredDataCopy[i].editedSalaryBase !== undefined) { filteredDataCopy[i].y = filteredDataCopy[i].editedSalaryBase + filteredDataCopy[i].addOn }
                        if (filteredDataCopy[i].editedSalaryFull !== undefined) { filteredDataCopy[i].y = filteredDataCopy[i].editedSalaryFull }
                        if (filteredDataCopy[i].editedSalaryBase === undefined && filteredDataCopy[i].editedSalaryFull === undefined) { filteredDataCopy[i].y = filteredDataCopy[i].totalSalary }
                    } else if (hideExtraSummary === "hideExtra") {
                        if (filteredDataCopy[i].editedSalaryBase !== undefined) { filteredDataCopy[i].y = filteredDataCopy[i].editedSalaryBase }
                        if (filteredDataCopy[i].editedSalaryFull !== undefined) { filteredDataCopy[i].y = filteredDataCopy[i].editedSalaryFull - filteredDataCopy[i].addOn }
                        if (filteredDataCopy[i].editedSalaryBase === undefined && filteredDataCopy[i].editedSalaryFull === undefined) { filteredDataCopy[i].y = filteredDataCopy[i].totalSalary - filteredDataCopy[i].addOn }
                    } else if (hideExtraSummary === "onlyExtra") {
                        filteredDataCopy[i].y = filteredDataCopy[i].addOn
                    }
                }
                // Second, update seriesBigFiltered with the information from filteredDataCopy (editedSalaryBase, editedSalaryFull and new Y value)
                seriesBigFiltered.forEach(series => {
                    // Iterate over each data object in the series
                    series.data.forEach(dataObj => {
                        let matchingEmployee = filteredDataCopy.find(employee => // Find the matching employee in filteredDataCopy
                            employee.fullName === dataObj.fullName && employee.age === dataObj.age && employee.experience === dataObj.experience && employee.positionTitle === dataObj.positionTitle
                        );
                        if (matchingEmployee) {  // If a matching employee is found
                            dataObj.y = matchingEmployee.y;
                            if (matchingEmployee.editedSalaryBase !== undefined) { dataObj.editedSalaryBase = matchingEmployee.editedSalaryBase }
                            if (matchingEmployee.editedSalaryFull !== undefined) { dataObj.editedSalaryFull = matchingEmployee.editedSalaryFull }
                        }
                    });
                });
                // Third, update annotations, check against filteredDataCopy and use the same y value
                annotationsList.forEach(annotation => {
                    // Find the matching employee in filteredDataCopy
                    let matchingEmployee = filteredDataCopy.find(employee =>
                        employee.fullName === annotation.fullName && employee.age === annotation.age && employee.experience === annotation.experience && employee.positionTitle === annotation.positionTitle
                    );
                    if (matchingEmployee) {  // If a matching employee is found, update the Y value
                        annotation.y = matchingEmployee.y
                    }
                });
                // Forth, update filteredData so that Y is updated
                this.setState({ filteredData: filteredDataCopy })
            }

            this.setState({
                seriesBig: close !== "noClose" ? seriesBig : seriesBigFiltered,
                optionsBig: {
                    ...this.state.optionsBig,
                    annotations: {
                        points: annotationsList
                    },
                    xaxis: {
                        ...this.state.optionsBig.xaxis,
                        max: longestExp,
                        min: 0,
                        labels: {
                            formatter: function (val) { return parseFloat(val).toFixed(0) }
                        },
                    },
                    /*fill: { // <-- Add to make the series themself the group color
                        colors: seriesBig.map(group => group.color),
                    },*/
                },
            });
        }

        // Set toolbar for chart
        this.setState((prevState) => ({
            optionsBig: {
                ...prevState.optionsBig,
                chart: {
                    ...prevState.optionsBig.chart,
                    toolbar: {
                        ...prevState.optionsBig.chart.toolbar,
                        tools: {
                            download: false,
                            zoom: `<img src=${zoomIn} style="width: 18px;margin-top: 2.3px;margin-left: 4px;">`,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: [

                                {
                                    icon: `<img src=${zoomOut} width="15px" style="margin-left: 7px">`,
                                    index: 1,
                                    title: language === "sv" ? "Zooma ut" : lang[language].zoomOut,
                                    class: 'custom-icon',
                                    click: function (chart, options, e) {
                                        if (this.state.chartWidthSummary === "500") {
                                            this.setState({ chartWidthSummary: "500.1" })
                                        }
                                        if (this.state.chartWidthSummary === "92%") {
                                            this.setState({ chartWidthSummary: "92.01%" })
                                        }
                                        if (this.state.chartWidthSummary === "92.01%") {
                                            this.setState({ chartWidthSummary: "92%" })
                                        }
                                        else {
                                            this.setState({ chartWidthSummary: "500" })
                                        }
                                    }.bind(this)
                                },
                                {
                                    icon: `<img src=${fullscreen} width="15px" style="margin-left: 10px">`,
                                    index: 0,
                                    title: language === "sv" ? "Fullskärm" : lang[language].fullscreen,
                                    class: 'custom-icon',
                                    click: function (chart, options, e) {
                                        if (this.state.chartWidthSummary === "500") {
                                            this.setState({
                                                chartWidthSummary: "92%",
                                                chartHeightSummary: "auto",
                                                apexPopUpSummary: "apexPopUp",
                                            })
                                        }
                                        else {
                                            this.setState({
                                                chartWidthSummary: "500",
                                                chartHeightSummary: "270",
                                                apexPopUpSummary: "",
                                            })
                                        }
                                    }.bind(this)
                                },
                                {
                                    icon: `<div>${language === "sv" ? "År i org." : lang[language].yearInOrgShorten}</div>`,
                                    index: 4,
                                    title: `${language === "sv" ? "Sätt x-axel till år i organisationen" : lang[language].yearInOrgXinfo}`,
                                    class: this.state.xSetting ? (this.state.xAxisTypeSummary === "age" ? 'custom-icon-div custom-icon-exp custom-icon-div-mright' : 'custom-icon-div custom-icon-exp custom-icon-div-mright custom-icon-div-green') : 'hide',
                                    click: function (chart, options, e) {
                                        this.setState({ xAxisTypeSummary: "experience" }, () => {
                                            this.openAnalysis(this.state.analysisOpen, "noClose")
                                        });
                                    }.bind(this)
                                }, {
                                    icon: `<div>${language === "sv" ? "Ålder" : lang[language].age}</div>`,
                                    index: 3,
                                    title: `${language === "sv" ? "Sätt x-axel till ålder" : lang[language].ageXinfo}`,
                                    class: this.state.xSetting ? (this.state.xAxisTypeSummary === "age" ? 'custom-icon-div custom-icon-age custom-icon-div-green ciLeft' : 'custom-icon-div custom-icon-age ciLeft') : 'hide',
                                    click: function (chart, options, e) {
                                        let activeItem = this.state.activeItem
                                        this.setState({ xAxisTypeSummary: "age" }, () => {
                                            this.openAnalysis(this.state.analysisOpen, "noClose")
                                        });
                                    }.bind(this)
                                },
                                {
                                    icon: this.state.hideExtraSummary === "includeExtra" ? `
                                <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px;">` : this.state.hideExtraSummary === "hideExtra" ?
                                        `
                                  <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px">
                                  <div style="
                                  font-size: 11px;
                line-height: 8px;
                width: 60px;
                text-align: center;
                padding: 2px;
                border-radius: 4px;
                margin-top: 4px;
                display: inline-block;
                padding-right: 0px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                vertical-align: top;
                border-bottom: 1.5px solid #9b9999;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 2px;
                padding-left: 2px;
                font-weight: 500;
                color: #9b9999;
                font-style: italic;
                ">${language === "sv" ? "Exkl tillägg" : lang[language].exclBenVeryShort}</div>
                                  ` :
                                        `
                                  <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;">
                                  <div style="
                                  font-size: 11px;
                line-height: 8px;
                width: 60px;
                text-align: center;
                padding: 2px;
                border-radius: 4px;
                margin-top: 4px;
                display: inline-block;
                padding-right: 0px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                vertical-align: top;
                border-bottom: 1.5px solid #9b9999;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 2px;
                padding-left: 2px;
                font-weight: 500;
                color: #9b9999;
                font-style: italic;
                ">${language === "sv" ? "Bara tillägg" : lang[language].onlyBenVeryShort}</div>
                                  `,
                                    index: 2,
                                    title: this.state.hideExtraSummary === "includeExtra" ? (language === "sv" ? 'Visar lön inklusive tillägg' : lang[language].iconInclSal) : this.state.hideExtraSummary === "hideExtra" ? (language === "sv" ? 'Visar lön exklusive tillägg' : lang[language].iconExclSal) : (language === "sv" ? "Visar tillägg exklusive lön" : lang[language].iconOnlyBen),
                                    class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                                    click: function (chart, options, e) {
                                        // this.hideExtraSummary();
                                        this.hideExtraSummary().then(this.openAnalysis(this.state.analysisOpen, "noClose"));
                                    }.bind(this)
                                },
                            ]
                        }
                    }
                }
            }
        }));

    }
    infoBoxEU = () => { this.state.infoBoxEU ? this.setState({ infoBoxEU: false }) : this.setState({ infoBoxEU: true }) }
    eGroupBox = (group) => {
        let language = localStorage.getItem('language') ?? 'sv';
        let minSalMen = (this.state.filteredData.filter(item => item.gender === "man").reduce((min, current) => current.y < min.y ? current : min, { y: Infinity }).y !== Infinity)
            ? this.state.filteredData.filter(item => item.gender === "man").reduce((min, current) => current.y < min.y ? current : min, { y: Infinity }).y
            : '-';
        const maxSalMen = (this.state.filteredData.filter(item => item.gender === "man").reduce((max, current) => current.y > max.y ? current : max, { y: -Infinity }).y !== -Infinity)
            ? this.state.filteredData.filter(item => item.gender === "man").reduce((max, current) => current.y > max.y ? current : max, { y: -Infinity }).y
            : '-';
        const validMinSalMen = minSalMen !== Infinity ? minSalMen : '-';
        const validMaxSalMen = maxSalMen !== -Infinity ? maxSalMen : '-';
        const spridningskvotMen = (validMinSalMen !== '-' && validMaxSalMen !== '-')
            ? (Math.round((validMaxSalMen / validMinSalMen) * 10) / 10)
            : '-';

        let minSalWomen = (this.state.filteredData.filter(item => item.gender === "woman").reduce((min, current) => current.y < min.y ? current : min, { y: Infinity }).y !== Infinity)
            ? this.state.filteredData.filter(item => item.gender === "woman").reduce((min, current) => current.y < min.y ? current : min, { y: Infinity }).y
            : '-';
        const maxSalWomen = (this.state.filteredData.filter(item => item.gender === "woman").reduce((max, current) => current.y > max.y ? current : max, { y: -Infinity }).y !== -Infinity)
            ? this.state.filteredData.filter(item => item.gender === "woman").reduce((max, current) => current.y > max.y ? current : max, { y: -Infinity }).y
            : '-';
        const validMinSalWomen = minSalWomen !== Infinity ? minSalWomen : '-';
        const validMaxSalWomen = maxSalWomen !== -Infinity ? maxSalWomen : '-';
        const spridningskvotWomen = (validMinSalWomen !== '-' && validMaxSalWomen !== '-')
            ? (Math.round((validMaxSalWomen / validMinSalWomen) * 10) / 10)
            : '-';
        let spridningDiff = Math.round((spridningskvotMen - spridningskvotWomen) * 10) / 10

        let onlyMen = this.state.filteredData.filter(item => item.gender === "man")
        let nrOfMen = onlyMen.length
        let onlyWomen = this.state.filteredData.filter(item => item.gender === "woman")
        let nrOfWomen = onlyWomen.length

        let moreThan20 = ""
        let moreMen10 = "-"
        let moreMen90 = "-"
        let moreMenKvot = "-"
        let moreWomen10 = "-"
        let moreWomen90 = "-"
        let moreWomenKvot = "-"
        let getOnlySalariesMen = []
        for (let i = 0; i < nrOfMen; i++) { getOnlySalariesMen.push(onlyMen[i].y) }
        let getOnlySalariesWomen = []
        for (let i = 0; i < nrOfWomen; i++) { getOnlySalariesWomen.push(onlyWomen[i].y) }
        if (nrOfMen > 19 || nrOfWomen > 19) {
            if (nrOfMen > 19 && nrOfWomen < 20) {
                let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
                moreMen10 = percentile(10, menSalariesInOrder)
                moreMen90 = percentile(90, menSalariesInOrder)
                moreMenKvot = Math.round((moreMen90 / moreMen10) * 10) / 10
                moreThan20 = "men"
            }
            if (nrOfWomen > 19 && nrOfMen < 20) {
                let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
                moreWomen10 = percentile(10, womenSalariesInOrder)
                moreWomen90 = percentile(90, womenSalariesInOrder)
                moreWomenKvot = Math.round((moreWomen90 / moreWomen10) * 10) / 10
                moreThan20 = "women"
            }
            if (nrOfWomen > 19 && nrOfMen > 19) {
                let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
                moreMen10 = percentile(10, menSalariesInOrder)
                moreMen90 = percentile(90, menSalariesInOrder)
                moreMenKvot = Math.round((moreMen90 / moreMen10) * 10) / 10
                let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
                moreWomen10 = percentile(10, womenSalariesInOrder)
                moreWomen90 = percentile(90, womenSalariesInOrder)
                moreWomenKvot = Math.round((moreWomen90 / moreWomen10) * 10) / 10
                moreThan20 = "both"
            }
        }


        return (
            <>
                <div className={this.state.analysisOpen == group ? "bigDetailsBox analysisOpenWait" : "hide"}>



                    <div style={{ textAlign: "center", marginTop: 20 }}>
                        <div className={this.state.summaryView === "chart" ? "comment-select-left comment-select-this" : "comment-select-left"} style={{ width: 100 }} onClick={this.selectViewDiagram}>Diagram</div>
                        <div className={this.state.summaryView === "comments" ? "comment-select-right comment-select-this" : "comment-select-right"} style={{ width: 100 }} onClick={this.selectViewComments}>{language === "sv" ? "Kommentarer" : lang[language].comments}</div>
                    </div>
                    {/* Comments view */}
                    <div className={this.state.summaryView !== "comments" ? "hide" : ""}>
                        <div style={{ fontSize: 13.3, textAlign: "center", marginTop: 15 }}>
                            <div id="idequiv" className={this.state.selectedSummaryComments === "equivalent" ? "summaryCommentsDetailActive" : "summaryCommentsDetail"} onClick={this.selectSummaryComments}>{language === "sv" ? "Likvärdiga" : lang[language].equivalent}</div>
                            <div id="idequal" className={this.state.selectedSummaryComments === "equal" ? "summaryCommentsDetailActive" : "summaryCommentsDetail"} onClick={this.selectSummaryComments}>{language === "sv" ? "Lika" : lang[language].equal}</div>
                        </div>
                        <div className="summaryCommentContainer">
                            <div className="summaryCommentsTitle">{language === "sv" ? "Noterbart" : lang[language].notable}</div>
                            <div className="summaryCommentsTitle">{language === "sv" ? "Orsak" : lang[language].reason}</div>
                            <div className="summaryCommentsTitle">{language === "sv" ? "Åtgärd" : lang[language].action}</div>

                            {this.state.selectedSummaryComments === "equivalent" ? this.state.summaryComments.map(elem =>
                                <div style={{ marginTop: 7.5, paddingBottom: 7.5, borderBottom: "1px solid #eeeeee" }}>
                                    <div className="summaryCommentsText">{elem.notable}</div>
                                    <div className="summaryCommentsText">{elem.reason}</div>
                                    <div className="summaryCommentsText">{elem.action}</div>
                                </div>
                            ) : this.state.summaryCommentsEqual.map(elem =>
                                <div style={{ marginTop: 7.5, paddingBottom: 7.5, borderBottom: "1px solid #eeeeee" }}>
                                    <div className="summaryCommentsText"><div><span style={{ fontWeight: "bold" }}>{elem.equalGroup}</span></div>{elem.notable}</div>
                                    <div className="summaryCommentsText" style={{ marginTop: 16 }}>{elem.reason}</div>
                                    <div className="summaryCommentsText" style={{ marginTop: 16 }}>{elem.action}</div>
                                </div>
                            )
                            }
                        </div>
                    </div>

                    {/* Chart view */}
                    <div className={this.state.summaryView !== "chart" ? "hide" : ""}>
                        <div className="regressionContainerResult">

                            <div style={{ textAlign: "center" }}>
                                <div className="statisticsContainerBox">
                                    <div className="statisticSide" style={{ width: "33%" }}>
                                        <div className="statisticSideTitle" style={{ marginBottom: 6 }}>{language === "sv" ? "Antal anställda" : lang[language].equalNrOfWorkers}</div>
                                        <table style={{ display: "inline", margin: "auto" }}>
                                            <tr style={{ fontSize: 12 }}>
                                                <th>{language === "sv" ? "Män" : lang[language].men}</th>
                                                <th>{language === "sv" ? "Kvinnor" : lang[language].women}</th>
                                                <th>{language === "sv" ? "Totalt" : lang[language].total}</th>
                                            </tr>
                                            <tr style={{ fontSize: 14 }}>
                                                <td style={{ paddingTop: 4 }}>{this.state.filteredData.filter(obj => obj.gender === "man").length}</td>
                                                <td style={{ paddingTop: 4 }}>{this.state.filteredData.filter(obj => obj.gender === "woman").length}</td>
                                                <td style={{ paddingTop: 4 }}>{this.state.filteredData.length}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div className="statisticSide" style={{ width: "54%" }}>
                                        <div className="statisticSideTitle" style={{ marginLeft: 0 }}>{language === "sv" ? "Medellön" : lang[language].averageSal}</div>
                                        <table style={{ display: "inline", margin: "auto" }}>
                                            <tr style={{ fontSize: 12 }}>
                                                <th>{language === "sv" ? "Män" : lang[language].men}</th>
                                                <th>{language === "sv" ? "Kvinnor" : lang[language].women}</th>
                                                <th>{language === "sv" ? "Totalt" : lang[language].total}</th>
                                                <th>{language === "sv" ? "Lönegap" : lang[language].payGap}</th>
                                            </tr>
                                            <tr style={{ fontSize: 14 }}>
                                                <td>{Math.round(this.state.filteredData.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "man").length).toLocaleString()}</td>
                                                <td>{Math.round(this.state.filteredData.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "woman").length).toLocaleString()}</td>
                                                <td>{Math.round(this.state.filteredData.reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.length).toLocaleString()}</td>
                                                <td style={{ cursor: "pointer" }} onClick={this.gapPercent}>{!this.state.gapPercent ? (((this.state.filteredData.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "woman").length) / (this.state.filteredData.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1) + "%"
                                                    : Math.round((this.state.filteredData.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "woman").length) - (this.state.filteredData.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "man").length)).toLocaleString()}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="statisticSide" style={{ width: "13%", borderRight: 0, marginRight: 0, background: this.state.spridningPopUp ? "#dbf1ee" : "transparent", marginTop: 15, height: 59 }}>
                                        <span className="statisticSideTitleBtnT">{language === "sv" ? "Spridning" : lang[language].spread}</span>
                                        <img onClick={this.spridningPopUp} src={arrowDown} style={{ marginTop: this.state.spridningPopUp ? 23 : 15 }} className={this.state.spridningPopUp ? "spridningArrowUp statisticSideTitleBtn" : "statisticSideTitleBtn"} />
                                    </div>
                                    {/* Spridning */}
                                    <div className={this.state.spridningPopUp ? "popUpSpreadSummary" : "hide"}>
                                        <table style={{ display: "inline", margin: "auto", fontSize: 12 }}>
                                            <tr>
                                                <th></th>
                                                <th>{this.state.hideExtra === "onlyExtra" ? language === "sv" ? "Lägsta tillägg" : lang[language].lowestBen : language === "sv" ? "Lägsta lön" : lang[language].lowestSaL}</th>
                                                <th>{this.state.hideExtra === "onlyExtra" ? language === "sv" ? "Högsta tillägg" : lang[language].highestBen : language === "sv" ? "Högsta lön" : lang[language].highestSal}</th>
                                                <th>{language === "sv" ? "Spridningskvot" : lang[language].spreadRatio}</th>
                                            </tr>
                                            <tr style={{ borderBottom: "1px solid", fontSize: 12 }}>
                                                <td>{language === "sv" ? "Män" : lang[language].men}</td>
                                                <td>{minSalMen.toLocaleString()}</td>
                                                <td>{maxSalMen.toLocaleString()}</td>
                                                <td>{spridningskvotMen} </td>
                                            </tr>
                                            <tr style={{ fontSize: 12 }}>
                                                <td>{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                                <td>{minSalWomen.toLocaleString()}</td>
                                                <td>{maxSalWomen.toLocaleString()}</td>
                                                <td>{spridningskvotWomen}</td>
                                            </tr>
                                        </table>

                                        <table style={{ display: "inline", paddingBottom: 10, verticalAlign: "bottom", fontSize: 12 }} className={moreThan20 !== "" ? "" : "hideImportant"}>
                                            {/* Show percentiles if more than 20 employees in one group */}
                                            <tr>
                                                <th></th>
                                                <th style={{ width: 76 }}>P10</th>
                                                <th style={{ width: 76 }}>P90</th>
                                                <th>{language === "sv" ? "Spridningskvot" : lang[language].spreadRatio}</th>
                                            </tr>
                                            <tr style={{ borderBottom: "1px solid", fontSize: 12 }} className={moreThan20 === "men" || moreThan20 === "both" ? "" : "hide"}>
                                                <td>{language === "sv" ? "Män" : lang[language].men}</td>
                                                <td>{moreMen10.toLocaleString()}</td>
                                                <td>{moreMen90.toLocaleString()}</td>
                                                <td>{moreMenKvot}</td>
                                            </tr>
                                            <tr className={moreThan20 === "women" || moreThan20 === "both" ? "" : "hide"}>
                                                <td>{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                                                <td>{moreWomen10.toLocaleString()}</td>
                                                <td>{moreWomen90.toLocaleString()}</td>
                                                <td>{moreWomenKvot}</td>
                                            </tr>
                                            {/* End of Show percentiles if more than 20 employees in one group */}
                                        </table>
                                        <div className={moreThan20 === "" ? "" : "hideImportant"} style={{ display: "inline-block", verticalAlign: "bottom", marginLeft: 8 }}><div style={{ fontSize: 12, fontWeight: 600, marginBottom: 12 }}>{language === "sv" ? "Kvot diff." : lang[language].ratioDiff}</div><div style={{
                                            marginBottom: 11, fontSize: 12, textAlign: "center", padding: 3,
                                            border: "1px solid",
                                            borderRadius: 50
                                        }}>{isNaN(spridningDiff) ? "-" : spridningDiff}</div>
                                        </div>
                                        <span class={moreThan20 === "" ? "questionMarkSummary" : "questionMarkMore"} onClick={this.popUpSummaryQuestionMark}>?</span>
                                        <div className={this.state.popUpSummaryQuestionMark ? "spridningsInfoPopUpSummary" : "hide"}>
                                            <img alt="" style={{ width: 22 }} src={exitImg} class="hide-box" onClick={this.popUpSummaryQuestionMark} />
                                            <h4 style={{ fontSize: 15.6 }}>{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</h4>
                                            {language === "sv" ? ` Punktdiagram är ett mycket bra sätt att visualisera lönespridning samtidigt som individuella skillnader belyses. För att snabbt visualisera lönespridning kan man även se över skillnaden mellan högsta och lägsta lön.
                    Om spridningen är stor för ett kön men litet för ett annat kan detta indikera på att ett kön har större möjlighet till löneutveckling och då bör det ses över om så är fallet eller vad det beror på.` : lang[language].equalInfo}
                                            <p>{language === "sv" ? `Spridningskvoten visar hur stor lönespridningen är mellan den högsta och lägsta lönen. Ju högre  kvot desto större spridning. Ett mått på 2.0 innebär att den högsta lönen är dubbelt så stor som den lägsta.` : lang[language].equalInfoTwo}
                                            </p>
                                            <p style={{ marginBottom: -10, textDecoration: "underline" }}>{language === "sv" ? "Vid stora grupper" : lang[language].equalBigGroups}</p>
                                            <p>{language === "sv" ? `Notera att om ett kön består av 20 personer eller fler så kommer ni även se percentil 10 (P10) och percentil 90 (P90). Detta för att ge en mer rättvis bild av spridningen och undvika extremvärden. Det innebär att istället för högsta och lägsta lön så
                      visar P10 den lönenivå där 10% tjänar mindre och P90 visar den lönenivå där 10% tjänar mer.` : lang[language].equalInfoThree}
                                            </p>
                                            <p></p>
                                        </div>
                                    </div>
                                    {/* End of spridning */}
                                </div>
                            </div>
                        </div>
                        {/* Filterable chart here */}
                        <div className={this.state.apexPopUpSummary !== "" ? "apexPopUp apexPopUpPaddingBottom" : "equivalentChart"}>

                            {/* PopUp for hide person in summary */}
                            <div style={{ zIndex: 13, position: "absolute", maxWidth: 145, boxShadow: "0 2px 10px 0 rgb(128 128 128 / 40%)", height: "auto", paddingBottom: 0, top: 260, color: "#333333" }} className={this.state.popItUpSummary ? "popUp active-popUp" : "hide"}>
                                <span className="exitVideo" onClick={this.hideEditBoxSummary} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer", fontSize: 14.6, fontWeight: 600, height: 22, width: 22 }}>x</span>
                                <span style={{ fontWeight: 800, display: "block", paddingBottom: 16, marginTop: -20, float: "left", fontSize: 13.6 }}>{language === "sv" ? "Justera" : lang[language].adjust}</span>
                                <div style={{ textAlign: "center" }} className={this.state.popUpEditSalarySettingSummary ? "hide" : ""}>
                                    <span style={{ textAlign: "center", display: "block", marginTop: 35, marginBottom: 6, fontSize: 13.6, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", letterSpacing: -0.2 }}>{!this.state.hideName ? this.state.clickedPersonSummary.fullName : ""}</span>
                                    <div className={this.state.showPercent ? "hide" : ""}><input type="number" placeholder="Lön" style={{ width: 75, border: "1px solid #d5d4d4", display: "inlinge-block", padding: 0, fontWeight: "normal", height: 30, margin: 0, verticalAlign: "top", borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "none", fontSize: 13.6 }} value={this.state.newSalarySummary} onChange={this.addEditSalarySummary} /><div style={{ display: "inline-block", border: "1px solid #d5d4d4", height: 30, verticalAlign: "top", lineHeight: "30px", paddingLeft: 7, paddingRight: 7, borderTopRightRadius: 5, borderBottomRightRadius: 5, cursor: "pointer", fontSize: 13.6, background: "#d7d7d7" }} onClick={this.state.clickedPersonSummary.addOn > 0 ? this.popUpEditSalarySettingSummary : this.editSalarySummary}>{language === "sv" ? "Ny lön" : lang[language].new}</div></div>
                                    <div onClick={this.hideEmployeeSummary} style={{ height: 30, lineHeight: "30px", width: 133, margin: "auto", marginTop: 7, borderRadius: 5, fontSize: 13.6, cursor: "pointer", background: "#d7d7d7", textAlign: "center" }}>{language === "sv" ? "Dölj anställd" : lang[language].hideEmp}</div>
                                    <p style={{ fontSize: 11.6, borderTop: "1px solid #cacaca", paddingTop: 2, marginBottom: 6, marginTop: 6 }}>{language === "sv" ? "Dölj & visa gruppen för att återställa justeringen." : lang[language].resetChange}</p>
                                </div>
                                <div className={this.state.popUpEditSalarySettingSummary ? "" : "hide"} style={{ textAlign: "center" }}>
                                    <span style={{ display: "block", fontSize: 12.6, marginTop: 35 }}>{language === "sv" ? "Personen har ett tillägg på " : lang[language].personHasBenefit} {this.state.clickedPersonSummary.addOn}{language === "sv" ? "kr. " : ". "} <p>{language === "sv" ? "Ska tillägget inkluderas eller adderas till summan?" : lang[language].personBenefitQuestion}</p></span>
                                    <div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginRight: 4, cursor: "pointer" }} id="includeSalary" onClick={this.editSalarySummary}>{language === "sv" ? "Inkludera" : lang[language].include}</div>
                                    <div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginLeft: 4, cursor: "pointer", marginBottom: 15 }} id="excludeSalary" onClick={this.editSalarySummary}>{language === "sv" ? "Addera" : lang[language].personBenefitAdd}</div>
                                </div>
                            </div>
                            <Chart
                                options={this.state.optionsBig}
                                series={this.state.seriesBig}
                                type="scatter"
                                //width={500}
                                width={this.state.chartWidthSummary}
                                //  height={270}
                                height={this.state.chartHeightSummary}
                            />
                            <div style={{ textAlign: "center", fontWeight: 400, fontSize: 12, fontFamily: "Helvetica, Arial, sans-serif" }}>
                                <div>
                                    <span style={{ color: "gray" }}>Färglägg: </span>
                                    <span className="summarySelectContainer">
                                        <span className={this.state.colorFill === "work" ? "summarySelectColorFillSelected" : "summarySelectColorFill"} id="work" onClick={this.selectColorFill} style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 5 }}>Arbeten</span>
                                        <span className={this.state.colorFill === "gender" ? "summarySelectColorFillSelected" : "summarySelectColorFill"} id="gender" onClick={this.selectColorFill} style={{ paddingLeft: 10, paddingRight: 10 }}>Kön</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    changeYearComments = (event) => {
        let year = Number(event.target.value)
        let allComments = this.state.allComments
        let commentsForYear = allComments.filter(obj => new Date(obj.dateAdded).getFullYear() === year);
        //Below is probably wrong, borde tas bort från allcomments listan också.
        this.setState({ commentsLastYear: commentsForYear, year: year })
    }

    //Create groups
    createGroups = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        let nrOfEqualentGroups = Number(this.state.nrOfEqualentGroups) // 6, 9, 12 or 15
        let allGroups = [];
        for (let i = 0; i < nrOfEqualentGroups; i++) {
            let whatGroup = `group${i + 1}`
            let payGap = `payG${i + 1}`
            let payG = this.state.showWhat === "EU" ? this.state.payGapPerGroup[payGap] : ""
            let group = this.state[whatGroup]
            /*console.log("Group: ")
            console.log(group)*/
            let whatTextBig = `textBig${i + 1}`
            let textBig = this.state[whatTextBig]
            let makeTextbig = `makeTextbig${i + 1}`
            allGroups.push(
                /* <div className={
                     this.state.analysisOpen == i + 1
                     ? Number(this.state.analysisOpen) === 1 ? "equivalentGroupOpen popUpOne" :
                       (Number(this.state.analysisOpen) - 1) % 3 === 0
                         ? "equivalentGroupOpen popUpFour"
                         : (Number(this.state.analysisOpen) - 1) % 3 === 1
                         ? "equivalentGroupOpen popUpTwo"
                         : "equivalentGroupOpen popUpThree"
                       : "equivalentGroup"
                   }>*
                 <div className={this.state.analysisOpen == i + 1 ? "equivalentGroupOpen popUpCenter" : "equivalentGroup"}>
                <div className={this.state.analysisOpen == i + 1 ? "equivalentGroupOpen" : "equivalentGroup"}> */
                <div style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 265 : undefined, minWidth: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 265 : undefined }} className={this.state.analysisOpen != false && this.state.analysisOpen != i + 1 ? "hide" : this.state.analysisOpen == i + 1 ? "equivalentGroupOpen" : (this.state.showAIComment ? "equivalentGroup equivalentGroupOnAI" : "equivalentGroup")}>
                    <div className="infoBoxEq" style={{ padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "0 0 0 10px" : undefined }}>
                        {this.state.showWhat === "EU" &&
                            <div className={this.state.analysisOpen == i + 1 ? "moreDetailsBtn moreDetailsBtnClose" : "moreDetailsBtn"}>
                                <div className="moreDetailsBtnLeft">
                                    <span className="payGapText">{language === "sv" ? "Lönegap" : lang[language].payGap}: </span><span> {payG} %</span>
                                </div>
                                <div data-group={i + 1} className="moreDetailsBtnRight" onClick={this.openAnalysis}>Analysera</div>
                            </div>
                        }
                        <div className="fillertitleEq" style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 99 : undefined }}><h4 style={this.state.showWhat === "EU" ? { cursor: "pointer" } : { cursor: "auto" }} data-group={i + 1} onClick={this.state.showWhat === "EU" ? this.openAnalysis : ""} className={this.state.bigTextIcon ? "groupNamesH4" : "groupNamesH4A"}>{language === "sv" ? "Grupp " : lang[language].group}{i + 1}</h4><img src={bigText} className={this.state.bigTextIcon ? "bigTextIcon" : "hide"} onClick={this[makeTextbig]} /></div>
                        <div className="titleEq flag"></div> {/* for flag */}
                        <div className="titleEq" style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 55 : undefined, padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "3px 0" : undefined }}>{language === "sv" ? "Kvinnor" : lang[language].women}</div>
                        <div className="titleEq" style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 35 : undefined, padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "3px 0" : undefined }}>{language === "sv" ? "Ans." : lang[language].empMinimal}</div>
                        <div className="titleEq" style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 48 : undefined, padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "3px 0" : undefined }}>{language === "sv" ? "M. lön" : lang[language].avgSalMinimal}</div>
                        <div style={{ width: 55 }} className={this.state.analysisOpen == i + 1 ? "titleEq" : "hide"}>Påverkan</div>

                        {/* Here is overall statistics for the group */}
                        <div className={this.state.analysisOpen == i + 1 ? "equivalentGroupOverview analysisOpenWait" : "hide analysisOpenHide"}>
                            <div><img src={arrowDown} className="bigequalentbackBtn" onClick={this.openAnalysis} /></div>
                            <div className="equivalentGroupOverviewItem"><div className="insideTitleEquivalent">{language === "sv" ? "Lönegap" : lang[language].payGap}</div><div style={{ fontWeight: 600 }}>{payG} %</div></div>
                            {/* <div className="equivalentGroupOverviewItem"><div className="insideTitleEquivalent">Åtgärdat</div><div>1 %</div></div>
                            <div className="equivalentGroupOverviewItem"><div className="insideTitleEquivalent">Förklarat</div><div>8.5 % <span onMouseEnter={this.infoBoxEU} onMouseLeave={this.infoBoxEU} className="infoIcon" style={{ cursor: "help", color: "black", borderColor: "black", background: "white", marginLeft: 5 }}>i</span></div><div className={this.state.infoBoxEU ? "infoBoxEU" : "hide"}>Vid klarmarkering kommer återstående procent som inte hamnat under åtgärdat att hamna under förklarat. </div></div>
                    <div className="equivalentGroupOverviewItem"><div className="notFinished">Klarmarkera</div></div>*/}
                        </div>
                    </div>
                    <ul style={{ fontSize: 15.6, letterSpacing: -0.3 }} className={this.state.analysisOpen == i + 1 ? "ulListBigStyle" : ""}>
                        {group.map(elem =>
                            //Mark working groups in list
                            <li style={{ padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "7.5px 0px 7.5px 10px" : undefined, opacity: !this.state.analysisOpen ? 1 : !this.state.filteredData.some(item => item.groupNameArbVardering === elem.group) ? 0.5 : 1 }}
                                className={elem.group === this.state.clickedGroup ? "infoBoxEq infoBoxList listSelected" :
                                    // If clicked group is women dominated, this marks male and equal groups with lower demands but higher salary
                                    (this.state.womenPercent !== null && this.state.womenPercent > 59 && elem.womenPercent < 60 && elem.avgSalary > this.state.avgSalary && elem.demands < this.state.demands) ? "infoBoxEq infoBoxList markList"
                                        // If clicked group is not women dominated, this marks women groups with higher demands but lower salary
                                        : (this.state.womenPercent !== null && this.state.womenPercent < 60 && elem.womenPercent > 59 && elem.avgSalary < this.state.avgSalary && elem.demands > this.state.demands) ? "infoBoxEq infoBoxList markList"
                                            //If clicked group is women dominated, this marks male and equal groups with same demands but higher salary
                                            : (this.state.womenPercent !== null && this.state.womenPercent > 59 && elem.womenPercent < 60 && elem.avgSalary > this.state.avgSalary && elem.demands === this.state.demands) ? "infoBoxEq infoBoxList markList"
                                                //If clicked group is male dominated, this marks women groups with same demands but lower salary
                                                : (this.state.womenPercent !== null && this.state.womenPercent < 60 && elem.womenPercent > 59 && elem.avgSalary < this.state.avgSalary && elem.demands === this.state.demands) ? "infoBoxEq infoBoxList markList"
                                                    //Else, dont mark
                                                    : "infoBoxEq infoBoxList default"
                                }
                                key={elem.group}
                                id={
                                    `{"group": "${elem.group}", 
                                         "demands": ${elem.demands},
                                         "womenPercent": ${elem.womenPercent},
                                         "avgSalary": ${elem.avgSalary} }`
                                }
                                onClick={this.handleClick}>
                                <div style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 113 : undefined, fontSize: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 14.6 : undefined }} className={!textBig ? "fillertitleEq" : "fillertitleEqBig"}>{elem.group}</div>
                                <div className="titleEq flag">{elem.womenPercent > 59 ? <img alt="" style={{ width: 14, paddingBottom: 3 }} src={yellowFlag} /> : elem.womenPercent < 41 ? <img alt="" style={{ width: 14, paddingBottom: 3 }} src={blueFlag} /> : ""}</div>
                                <div className="titleEq noBold" style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 45 : undefined, padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "3px 0" : undefined }}>{elem.womenPercent}%</div>
                                <div className="titleEq noBold" style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 35 : undefined, padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "3px 0" : undefined }}>{elem.totEmployees}</div>
                                <div className="titleEq noBold" style={{ width: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? 48 : undefined, padding: this.state.nrOfEqualentGroups === 15 && !this.state.analysisOpen ? "3px 0" : undefined }}>{elem.avgSalary}</div>
                                <div className={this.state.analysisOpen == i + 1 ? "titleEq noBold" : "hide"} style={{ width: 55 }}>{elem.salDiffPercent} %</div> {/* salDiffPercent or impactTotalWageGap*/}
                                <img data-egroup={elem.group} src={!this.state.filteredData.some(item => item.groupNameArbVardering === elem.group) ? eyeOff : eyeOn} onClick={this.eyeClick} className="eye" style={{ visibility: this.state.analysisOpen == i + 1 ? "visible" : "hidden" }} />
                                <div className="colorListItem" style={{ background: this.state.seriesBig.find(item => item.name === elem.group)?.color || "#fff", /*boxShadow: `-1px 0px 4px ${this.state.seriesBig.find(item => item.name === elem.group)?.color || "#fff"}`*/ }} />
                            </li>
                        )}
                    </ul>
                    {(this.state.showWhat === "EU" && this.state.analysisOpen == i + 1) && this.eGroupBox(`${i + 1}`)}
                </div>
            )
        }
        return allGroups
    }
    selectViewDiagram = () => { this.setState({ summaryView: "chart" }) }
    selectViewComments = () => { this.setState({ summaryView: "comments" }) }
    popUpSummaryAction = () => { this.state.popUpSummaryAction ? this.setState({ popUpSummaryAction: false }) : this.setState({ popUpSummaryAction: true }) }
    closePopUpSummaryAction = () => {
        this.setState({
            popUpSummaryAction: false,
            newcomNotable: "",
            newcomReason: "",
            newcomTodo: "",
            newcomDeadline: "",
            newcomCost: "",
            newcomResponsible: ""
        })
    }
    spridningPopUp = () => { this.state.spridningPopUp ? this.setState({ spridningPopUp: false }) : this.setState({ spridningPopUp: true }) }
    newActionSummary = (event) => { this.setState({ [event.target.name]: event.target.value }) }
    newActionSummarySave = async () => {
        let language = localStorage.getItem('language') ?? 'sv';
        let notable = this.state.newcomNotable == undefined ? "" : this.state.newcomNotable
        let reason = this.state.newcomReason == undefined ? "" : this.state.newcomReason
        let todo = this.state.newcomTodo == undefined ? "" : this.state.newcomTodo
        let deadline = this.state.newcomDeadline == undefined ? "" : this.state.newcomDeadline
        let cost = this.state.newcomCost == undefined ? "" : this.state.newcomCost
        let responsible = this.state.newcomResponsible == undefined ? "" : this.state.newcomResponsible

        if ((notable == undefined || notable == "") || (todo == undefined || todo == "") || (deadline == undefined || deadline == "")) {
            alert(language === "sv" ? "Ej skapat. Noterbart, åtgärd och deadline måste anges. Lägg till och försök igen." : lang[language].notCreated)
            return;
        }

        const commentData = {
            notable: notable,
            reason: reason,
            todo: todo,
            deadline: deadline,
            cost: cost,
            responsible: responsible,
            analysis: "summaryAction",
            groupE: this.state.analysisOpen !== false ? this.state.analysisOpen : "1" // "1" is default group
        }

        let response = await fetchAuth(`/api/saveEqualentComment`, 'POST', JSON.stringify({ data: commentData }));
        let data = await response.json();

        //add todays date to temporary comment,or a previous date if we are doing it for a previous year
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString();

        //When saved, add the new comment to the comments array
        const toState = {
            notable: notable,
            reason: reason,
            todo: todo,
            deadline: deadline,
            cost: cost,
            responsible: responsible,
            equalGroup: this.state.groupTitle,
            commentId_PK: data,
            analysis: "summaryAction",
            dateAdded: dateNow,
            groupE: this.state.analysisOpen !== false ? this.state.analysisOpen : "1"
        }
        const commentList = this.state.comments.concat(toState);

        //Arrange by descending date so the new comments goes to top:
        //commentList = [...comments].sort((a, b) => b.dateAdded > a.dateAdded ? 1: -1)
        const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
        //Reset fields
        if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
            this.setState({
                newcomNotable: "",
                newcomReason: "",
                newcomTodo: "",
                newcomDeadline: "",
                newcomCost: "",
                newcomResponsible: "",
                comments: sortedComments, //add the new comment to the comments array
                popUpSummaryAction: false
            })
        } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
    };

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        const group1 = this.state.group1
        const group2 = this.state.group2
        const group3 = this.state.group3
        const group4 = this.state.group4
        const group5 = this.state.group5
        const group6 = this.state.group6
        const group7 = this.state.group7
        const group8 = this.state.group8
        const group9 = this.state.group9
        const group10 = this.state.group10
        const group11 = this.state.group11
        const group12 = this.state.group12
        const group13 = this.state.group13
        const group14 = this.state.group14
        const group15 = this.state.group15

        let comments = language === "sv" ? "Inga kommentarer för senaste året." : lang[language].noCommentsFromThisYear

        if (this.state.comments.length > 0) {
            comments = this.state.comments.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        {/* group: group */}

                        <td className="thpadding">
                            {(comment.groupE !== undefined && comment.groupE !== null && comment.analysis === "summary") && <div className="equalGroupUtvardera">{"Grupp " + comment.groupE + " - Summering"}</div>}
                            {(comment.groupE !== undefined && comment.groupE !== null && comment.analysis === "summaryAction") && <div className="equalGroupUtvardera">{"G" + comment.groupE}</div>}
                            <TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} defaultValue={comment.notable} />
                        </td>
                        {/*(comment.groupE === undefined || comment.groupE === null) && */<>
                            <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} defaultValue={comment.reason} /></td>
                            <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} defaultValue={comment.todo} /></td>
                            <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                            <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" defaultValue={comment.cost} onBlur={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                            <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} defaultValue={comment.responsible} /></td>
                            <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /><span className="analysisIcon">{comment.analysis.charAt(0).toUpperCase()}</span></td>
                        </>
                        }
                    </tr>
                } else if ((this.state.analysis === "summary" && comment.analysis === "summaryAction") || comment.analysis === this.state.analysis) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding">
                            {(comment.groupE !== undefined && comment.groupE !== null && comment.analysis === "summary") && <div className="equalGroupUtvardera">{"Grupp " + comment.groupE + " - Summering"}</div>}
                            {(comment.groupE !== undefined && comment.groupE !== null && comment.analysis === "summaryAction") && <div className="equalGroupUtvardera">{"G" + comment.groupE}</div>}
                            <TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} defaultValue={comment.notable} />
                        </td>
                        {/*(comment.groupE === undefined || comment.groupE === null) && */ <>
                            <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} defaultValue={comment.reason} /></td>
                            <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} defaultValue={comment.todo} /></td>
                            <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                            <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" defaultValue={comment.cost} onBlur={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                            <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} defaultValue={comment.responsible} /></td>
                            <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /></td>
                        </>}
                    </tr>
                }
            });
        }

        // let pastComments = language === "sv" ? "Inga kommentarer från tidigare år." : lang[language].noCommentsPastYear
        let pastComments = ""
        if (this.state.commentsLastYear.length > 0) { //<--- FILTER PAST YEARS COMMENTS!!
            pastComments = this.state.commentsLastYear.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly /></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>
                        <td className="thpadding remove"></td>
                    </tr>
                } else if ((this.state.analysis === "summary" && comment.analysis === "summaryAction") || comment.analysis === this.state.analysis) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly /></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>
                        <td className="thpadding remove"></td>
                    </tr>
                }
            });
        }

        return (
            <>
                <CustomPrompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
                />
                <TopNav />
                <Menu />
                <div className="container">
                    <Information step="analysLikvardiga" />

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>

                    {/* PopUp Questionmark */}
                    <div className={this.state.popUp ? "popUp active-popUp" : "popUp"} style={{ height: "auto", maxWidth: 700, marginTop: -300, fontSize: 14.6 }}>
                        <img alt="" src={exitImg} class="hide-box" onClick={this.togglePop} />
                        <h4 style={{ fontSize: 15.6 }}>{language === "sv" ? "Detaljerad och automatisk analys" : lang[language].detailedAnalysis}</h4>
                        {language === "sv" ? "Här kan du utföra en detaljerad analys mellan två grupper. Se mer detaljer, visualisera individuella skillnader samt få en automatisk analys." : lang[language].detailedAnalysisInfo}
                        <div style={{ padding: 20 }}>
                            <p style={{ textAlign: "left" }}><p style={{ marginBottom: 5, marginTop: 20 }}><b>{language === "sv" ? "Välj grupper" : lang[language].chooseGroups}</b></p>{language === "sv" ? `Du väljer grupp att analysera genom att först klicka på en av rutorna där det står "Välj grupp". Texten kommer nu ändras till "Klicka på grupp". Klicka på den första gruppen du vill se. Gör nu likadant för den andra rutan där det står "Välj grupp".` : lang[language].chooseGroupInfo}</p>
                            <p style={{ textAlign: "left" }}><p style={{ marginBottom: 5, marginTop: 20 }}><b>{language === "sv" ? "Byt ut grupper" : lang[language].changeGroups}</b></p>{language === "sv" ? `För att byta ut en grupp klickar du helt enkelt i den ruta där det tidigare stod "Välj grupp" (nu står gruppnamnet). Texten kommer nu ändras till "Klicka på grupp". Klicka på den grupp du vill se istället.` : lang[language].changeGroupInfo}</p>
                        </div>
                    </div>
                    <div className={this.state.popUp ? "popUpFader" : "hide"}></div>
                    {/* PopUp infoblock */}
                    <div style={{ height: "auto" }} className={this.state.popUpInfo ? "popUp active-popUp" : "popUp"}>
                        <img alt="" src={exitImg} class="hide-box" onClick={this.popUpInfo} />
                        <div style={{ padding: 20, textAlign: "left", fontSize: 15.6 }}>
                            <span><b>{language === "sv" ? "Analysen tar hänsyn till flera faktorer såsom: " : lang[language].aiAnalysisInfo}</b></span>
                            {language === "sv" ? <ul style={{ listStyleType: "circle", paddingLeft: 20, padding: "inherit" }}>
                                <li>Lönenivåer</li>
                                <li>Könsfördelning</li>
                                <li>Ålder</li>
                                <li>Erfarenhet</li>
                                <li>Gruppstorlek</li>
                                <li>Placeringsort</li>
                                <li>Arbetets krav</li>
                                <li style={{ display: "block" }}>... och mer</li>
                            </ul> : lang[language].aiAnalysisUl}

                            <span>{language === "sv" ? "Verkligheten kan dock vara mer komplex och andra faktorer kan spela in, såsom marknaden, bibehållna löner, prestationer etc, varav du alltid bör göra en egen bedömning. Eventuella felaktigheter reserveras." : lang[language].aiAnalyisInfoTwo}</span>
                        </div>
                    </div>
                    <div className={this.state.popUpInfo ? "popUpFader" : "hide"}></div>

                    {/* Pop Up summary action */}
                    <div className={this.state.popUpSummaryAction ? "createGeneralPopUp" : "hide"}>
                        <img alt="" src={exitImg} class="hide-box" style={{ position: "absolute", right: 0, marginRight: 11 }} onClick={this.closePopUpSummaryAction} />
                        <span style={{ display: "block", marginTop: 10, fontSize: 19.6, fontWeight: 900 }}>{language === "sv" ? "Skapa åtgärd" : lang[language].createAction}</span>
                        <span style={{ fontSize: 14.6, margin: "auto", marginTop: 16, display: "block", fontWeight: "normal", lineHeight: 1.4, maxWidth: 650 }}>{language === "sv" ? "Om orsaken till lönegapet inte kan förklaras sakligt kan ni skapa en eller flera åtgärder för att minska lönegapet inom gruppen. Alla åtgärder visas i noteringslistan nedan." : lang[language].summaryActionsInfo}</span>
                        <table className="commentsTable" style={{ marginTop: 5 }}>
                            <tr className="trStyleLine" style={{ background: "white", borderBottom: "1px solid #dbdbdb" }}>
                                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="thpadding">Deadline</th>
                                <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                            </tr>
                            <tr id="newGeneralComment" className={"trStyleLine greyTR"}>
                                <td style={{ borderLeft: "1px solid #e2e2e2" }} className="thpadding">
                                    <TextareaAutosize onChange={this.newActionSummary} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="newcomNotable" id="newGeneralComment" value={this.state.newcomNotable} /></td>
                                <td className="thpadding"><TextareaAutosize onChange={this.newActionSummary} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="newcomReason" id="newGeneralComment" value={this.state.newcomReason} /></td>
                                <td className="thpadding"><TextareaAutosize onChange={this.newActionSummary} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="newcomTodo" id="newGeneralComment" value={this.state.newcomTodo} /></td>
                                <td className="thpadding"><input type="date" style={{ background: "transparent" }} value={this.state.newcomDeadline} onChange={this.newActionSummary} name="newcomDeadline" id="newGeneralComment" /></td>
                                <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={this.state.newcomCost} onChange={this.newActionSummary} name="newcomCost" id="newGeneralComment" /></td>
                                <td className="thpadding" style={{ borderRight: "1px solid #e2e2e2" }}><TextareaAutosize onChange={this.newActionSummary} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="newcomResponsible" id="newGeneralComment" value={this.state.newcomResponsible} /></td>
                            </tr>
                        </table>
                        <div style={{ marginTop: 24, marginBottom: 16 }}>
                            <span class="lastYearButton" style={{ background: "#c95759", marginRight: 10, padding: 10, display: "inline-block", width: 100 }} onClick={this.closePopUpSummaryAction}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                            <span class="lastYearButton" style={{ marginLeft: 10, padding: 10, display: "inline-block", width: 100 }} onClick={this.newActionSummarySave}>{language === "sv" ? "Skapa" : lang[language].create}</span>
                        </div>
                    </div>
                    <div className={this.state.popUpSummaryAction ? "popUpFader" : "hide"}></div>

                    {/* Auto analysis */}
                    <div className="equivalentContainer">
                        <div className="autoAnalysisContainer">
                            <div className={this.state.divOpened === "first" ? "topAnalysis hideBoxShadowInitial" : this.state.divOpened === "yes" ? "topAnalysis showBoxShadow" : "topAnalysis hideBoxShadow"}>
                                <span onClick={this.selectGroup1} className={this.state.analyseGroup1 === "Välj grupp" || this.state.analyseGroup1 === "Klicka på grupp" ? "groupAnalysis selectGroup" : "groupAnalysis"} >{language === "sv" ? this.state.analyseGroup1 : (this.state.analyseGroup1 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup1 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup1)}</span>
                                <span style={{ fontSize: 16, display: "inline-block", fontWeight: 600 }}> &amp;</span>
                                <span onClick={this.selectGroup2} className={this.state.analyseGroup2 === "Välj grupp" || this.state.analyseGroup2 === "Klicka på grupp" ? "groupAnalysis selectGroup" : "groupAnalysis"} >{language === "sv" ? this.state.analyseGroup2 : (this.state.analyseGroup2 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup2 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup2)}</span>
                                <div onClick={this.autoCompare} className="compareButton">{language === "sv" ? "Jämför" : lang[language].compare}</div>
                                <span onClick={this.toggleDiv} className="minusAnalysis">-</span>
                                <span className="questionMark" onClick={this.togglePop}>?</span>
                            </div>

                            <div className={this.state.divOpened === "first" ? "hideAnalysisInitial" : this.state.divOpened === "yes" ? "showAnalysis" : "hideAnalysis"}>
                                <div style={{ display: "inline-block", verticalAlign: "top", width: 380, fontSize: 13.6, marginTop: -5 }}>
                                    <div class="cssGrid">
                                        <div class="gridLine"> </div>
                                        <div class="gridLine autoTitleGroup" style={{ paddingRight: 2 }}>{language === "sv" ? this.state.analyseGroup1 : (this.state.analyseGroup1 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup1 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup1)}</div>
                                        <div class="gridLine autoTitleGroup" style={{ paddingLeft: 2 }}>{language === "sv" ? this.state.analyseGroup2 : (this.state.analyseGroup2 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup2 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup2)}</div>
                                        <div class="gridLine autoTitleGroup">{language === "sv" ? "Differens" : lang[language].difference}</div>
                                        <div class="autoTitel">{language === "sv" ? "Krav" : lang[language].requirements}</div>
                                        <div class="gridLine">{this.state.tableInfo[0].oneEqualentGroup}</div>
                                        <div class="gridLine">{this.state.tableInfo[0].twoEqualentGroup}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneEqualentGroup - this.state.tableInfo[0].twoEqualentGroup) ? "" : this.state.tableInfo[0].oneEqualentGroup - this.state.tableInfo[0].twoEqualentGroup}</div>
                                        <div class="autoTitel">{language === "sv" ? "Kvinnor" : lang[language].women}</div>
                                        <div class="gridLine">{isNaN(this.state.tableInfo[0].oneWomen) ? "" : this.state.tableInfo[0].oneWomen + "%"} </div>
                                        <div class="gridLine">{isNaN(this.state.tableInfo[0].twoWomen) ? "" : this.state.tableInfo[0].twoWomen + "%"} </div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].diffWomen) ? "" : this.state.tableInfo[0].diffWomen + "%"} </div>
                                        <div class="autoTitel"> {language === "sv" ? "Anställda" : lang[language].employees}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneNrOfEmp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoNrOfEmp}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneNrOfEmp - this.state.tableInfo[0].twoNrOfEmp) ? "" : this.state.tableInfo[0].oneNrOfEmp - this.state.tableInfo[0].twoNrOfEmp}</div>
                                        <div class="autoTitel"> {this.state.hideExtra === "onlyExtra" ? language === "sv" ? "M. tillägg" : lang[language].avgBen : language === "sv" ? "M. lön" : lang[language].avgSal}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgSalary === undefined ? "" : this.state.tableInfo[0].oneAvgSalary.toLocaleString()}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgSalary === undefined ? "" : this.state.tableInfo[0].twoAvgSalary.toLocaleString()}</div>
                                        <div class="gridLine" style={{ cursor: "pointer" }} onClick={this.showDiffPercent}> {isNaN(this.state.tableInfo[0].oneAvgSalary - this.state.tableInfo[0].twoAvgSalary) ? "" : !this.state.showDiffPercent ? (this.state.tableInfo[0].oneAvgSalary - this.state.tableInfo[0].twoAvgSalary).toLocaleString() : (Math.round((100 - ((this.state.tableInfo[0].twoAvgSalary / this.state.tableInfo[0].oneAvgSalary) * 100)) * 10) / 10) + "%"}</div>
                                        <div class="autoTitel"> {language === "sv" ? "M. ålder" : lang[language].avgAge}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgAge}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgAge}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneAvgAge - this.state.tableInfo[0].twoAvgAge) ? "" : this.state.tableInfo[0].oneAvgAge - this.state.tableInfo[0].twoAvgAge}</div>
                                        <div class="autoTitel"> {language === "sv" ? "M. erfarenhet" : lang[language].avgExp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgExp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgExp}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneAvgExp - this.state.tableInfo[0].twoAvgExp) ? "" : this.state.tableInfo[0].oneAvgExp - this.state.tableInfo[0].twoAvgExp}</div>
                                        <div class="autoTitel">{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningskvotG1}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningskvotG2}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningDiff}</div>
                                        <div class="autoTitel"> {language === "sv" ? "M. ort" : lang[language].avgLocation}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].oneAvgCity}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].twoAvgCity}</div>
                                        <div class="gridLine"> </div>
                                    </div>
                                </div>

                                <div className="analysisInText">
                                    <div style={{ marginTop: -5 }}>
                                        <h4 style={{ margin: 0, fontWeight: 800, fontSize: 15.6 }}>{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}<span className="infoIcon" onClick={this.popUpInfo}>i</span></h4>
                                        <span className="analyseText">
                                            {this.state.analysisText}
                                            <span
                                                data-tip={language === "sv" ? "Ålder i sig är inte en saklig orsak, det behöver vara relevant tidigare erfarenhet." : lang[language].aboutAge}
                                                className={this.state.showInfoIcon ? "infoIconInText" : "hide"}
                                                data-background-color="white"
                                                data-text-color="#333333"
                                            >i</span>
                                            <ReactTooltip />
                                        </span>
                                    </div>
                                    {/*<div className="addAsReasonButton">Ange som orsak</div> */}
                                    <div onClick={this.AIComment} className={this.state.divOpened === "yes" ? "addAsReasonButton" : "hideAddAsReasonButton"}>{language === "sv" ? "Använd som kommentar" : lang[language].useAsComment}</div>
                                    <div className={this.state.showAIComment ? "AIComment" : "hide"}>
                                        <img style={{ position: "absolute", right: 8, top: 8 }} alt="" src={exitImg} class="hide-box" onClick={this.AIComment} />
                                        <div className="AICommentTextField AICommentTitles">{language === "sv" ? "Noterbart" : lang[language].notable}</div>
                                        <div className="AICommentTextField AICommentTitles">{language === "sv" ? "Orsak" : lang[language].reason}</div>
                                        <TextareaAutosize id="AISelComment" onChange={this.changeTextAINotable} style={{ marginRight: 7 }} className="autoHeightText AICommentTextField" placeholder="" value={this.state.AInotable} name="AINotable" />
                                        <TextareaAutosize id="AISelReason" onChange={this.changeTextAIReason} style={{ marginLeft: 7 }} className="autoHeightText AICommentTextField" placeholder="" value={this.state.AIreason} name="AIReason" />
                                        <div className="addAsReasonButton AIAcceptButton" onClick={this.saveAIComment}>{language === "sv" ? "Ange som kommentar" : lang[language].useAsComment}</div>
                                    </div>
                                    <div className={this.state.showAIComment ? "popUpFader popUpFaderFixFader" : "hide"}></div>
                                </div>

                                <div class={this.state.apexPopUp !== "" ? "apexPopUpGreen" : "chartContainerLikvardiga"}>
                                    <div style={{ zIndex: 13, position: "absolute", maxWidth: 145, boxShadow: "0 2px 10px 0 rgb(128 128 128 / 40%)", height: "auto", paddingBottom: 0, top: 260, color: "#333333" }} className={this.state.popItUp ? "popUp active-popUp" : "hide"}>
                                        <span className="exitVideo" onClick={this.hideEditBox} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer", fontSize: 14.6, fontWeight: 600, height: 22, width: 22 }}>x</span>
                                        <span style={{ fontWeight: 800, display: "block", paddingBottom: 16, marginTop: -20, float: "left", fontSize: 13.6 }}>{language === "sv" ? "Justera" : lang[language].adjust}</span>
                                        <div style={{ textAlign: "center" }} className={this.state.popUpEditSalarySetting ? "hide" : ""}>
                                            <span style={{ textAlign: "center", display: "block", marginTop: 35, marginBottom: 6, fontSize: 13.6, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", letterSpacing: -0.2 }}>{!this.state.hideName ? this.state.clickedPerson.fullName : ""}</span>
                                            <div className={this.state.showPercent ? "hide" : ""}><input type="number" placeholder="Lön" style={{ width: 75, border: "1px solid #d5d4d4", display: "inlinge-block", padding: 0, fontWeight: "normal", height: 30, margin: 0, verticalAlign: "top", borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "none", fontSize: 13.6 }} value={this.state.addEditSalary} onChange={this.addEditSalary} /><div style={{ display: "inline-block", border: "1px solid #d5d4d4", height: 30, verticalAlign: "top", lineHeight: "30px", paddingLeft: 7, paddingRight: 7, borderTopRightRadius: 5, borderBottomRightRadius: 5, cursor: "pointer", fontSize: 13.6, background: "#d7d7d7" }} onClick={this.state.clickedPerson.addOn > 0 ? this.popUpEditSalarySetting : this.editSalary}>{language === "sv" ? "Ny lön" : lang[language].new}</div></div>
                                            <div onClick={this.hideEmployee} style={{ height: 30, lineHeight: "30px", width: 133, margin: "auto", marginTop: 7, borderRadius: 5, fontSize: 13.6, cursor: "pointer", background: "#d7d7d7", textAlign: "center" }}>{language === "sv" ? "Dölj anställd" : lang[language].hideEmp}</div>
                                            <p style={{ fontSize: 11.6, borderTop: "1px solid #cacaca", paddingTop: 2, marginBottom: 6, marginTop: 6 }}>{language === "sv" ? "Klicka på jämför för att återställa justeringen." : lang[language].resetChange}</p>
                                        </div>
                                        <div className={this.state.popUpEditSalarySetting ? "" : "hide"}>
                                            <span style={{ display: "block", fontSize: 12.6, marginTop: 35 }}>{language === "sv" ? "Personen har ett tillägg på " : lang[language].personHasBenefit} {this.state.clickedPerson.addOn}{language === "sv" ? "kr. " : ". "} <p>{language === "sv" ? "Ska tillägget inkluderas eller adderas till summan?" : lang[language].personBenefitQuestion}</p></span>
                                            <div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginRight: 4, cursor: "pointer" }} onClick={this.editSalary}>{language === "sv" ? "Inkludera" : lang[language].include}</div><div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginLeft: 4, cursor: "pointer", marginBottom: 15 }} onClick={this.editSalaryExclude}>{language === "sv" ? "Addera" : lang[language].personBenefitAdd}</div>
                                        </div>
                                    </div>
                                    <Chart
                                        options={this.state.options}
                                        series={this.state.series}
                                        type="scatter"
                                        width={this.state.chartWidth}
                                        height={this.state.chartHeight}
                                    />
                                </div>

                                <div className={this.state.apexPopUp ? "newShaderFullScreen" : "hide"}></div>
                            </div>
                        </div>
                    </div>

                    {/* Groups */}
                    <div style={{ maxWidth: this.state.nrOfEqualentGroups == 15 ? "none" : undefined }} className={this.state.apexPopUp ? "hide" : this.state.nrOfEqualentGroups == 12 ? "equivalentContainer eqCont12" : "equivalentContainer"} >
                        {this.createGroups()}
                    </div>

                    {/* Comments */}
                    <div className={this.state.analysisOpen && this.state.addAnalysis === "summary" ? "commentContainerFixedWhite likvCommentCont" : "commentContainer likvCommentCont"} >
                        <h3 style={{ marginTop: 0, fontWeight: 800 }}>{language === "sv" ? "Anteckna" : lang[language].note}</h3>

                        <div style={{ marginBottom: 15 }} className={this.state.showWhat === "EU" ? "" : "hide"}>
                            <div className={this.state.addAnalysis === "likvärdiga" ? "comment-select-left comment-select-this" : "comment-select-left"} onClick={this.addAnalysisEqualent}>{language === "sv" ? "Likvärdig" : lang[language].equivalent}</div>
                            <div style={{ borderLeft: this.state.addAnalysis === "summary" ? "1px solid #dedede" : "1px solid #ffffff", borderRight: this.state.addAnalysis === "likvärdiga" ? "1px solid #dedede" : "1px solid #ffffff" }} className={this.state.addAnalysis === "hierarkisk" ? "comment-select-middle-eqv comment-select-this" : "comment-select-middle-eqv"} onClick={this.addAnalysisHierarchy}>{language === "sv" ? "Hierarkisk" : lang[language].hierarchical}</div>
                            <div className={this.state.addAnalysis === "summary" ? "comment-select-right comment-select-this" : "comment-select-right"} onClick={this.addAnalysisSummary}>{language === "sv" ? "Summering" : lang[language].summary}</div>
                        </div>

                        <div style={{ marginBottom: 15 }} className={this.state.showWhat !== "EU" ? "" : "hide"}>
                            <div className={this.state.addAnalysis === "likvärdiga" ? "comment-select-left comment-select-this" : "comment-select-left"} onClick={this.addAnalysisEqualent}>{language === "sv" ? "Likvärdig" : lang[language].equivalent}</div>
                            <div className={this.state.addAnalysis === "hierarkisk" ? "comment-select-right comment-select-this" : "comment-select-right"} onClick={this.addAnalysisHierarchy}>{language === "sv" ? "Hierarkisk" : lang[language].hierarchical}</div>
                        </div>

                        <div className={this.state.addAnalysis === "summary" ? "summaryTitle" : "hide"}>{language === "sv" ? "Summering av orsaker till lönegapet för:" : lang[language].payGapReasons}
                            <select className="summaryGroupTitle" value={this.state.analysisOpen} onChange={this.changeWhatAnalysisOpen}>
                                {Array.from({ length: this.state.nrOfEqualentGroups }, (_, index) => (
                                    <option key={index} value={index + 1}>{language === "sv" ? "Grupp " : lang[language].group}{index + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className={this.state.addAnalysis === "summary" ? "" : "hide"}>
                            <textarea id="curSelCommentSummary" className="comment" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} style={{ minHeight: 48 }} onBlur={this.handleCommentSummary} defaultValue={this.state.addCommentSummary} ref={el => this.curSelCommentSummary = el} onClick={this.handleCommentSalaryClick} />
                            <div className="fieldsB" style={{ width: "auto" }} onClick={this.popUpSummaryAction}><span style={{ paddingRight: 10 }}>{language === "sv" ? "Åtgärder" : lang[language].actions}: <span>{this.state.summaryNrOfActions}</span></span><span style={{ color: "#cfcece" }}> | </span><span style={{ paddingLeft: 10, textDecoration: "underline" }}>{language === "sv" ? "Skapa åtgärd" : lang[language].createAction}</span></div>
                            {/*}  <div className={this.state.moreFields ? "" : "hideFields"} style={{ width: 630, margin: "auto" }}>
                                <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onBlur={this.handleReason} defaultValue={this.state.addReason} ref={el => this.curSelReasonSummary = el} />
                                <textarea id="curSelTodo" className="comment todo" placeholder={language === "sv" ? "Åtgärd" : lang[language].action} style={{ minHeight: 48 }} onBlur={this.handleTodo} defaultValue={this.state.addTodo} ref={el => this.curSelTodoSummary = el} />
                                <input style={{ background: "white", textAlign: "center", }} type="date" id="deadline" name="deadline" className="dateBox" onChange={this.handleDeadline} value={this.state.addDeadline} />
                                <input id="curSelCost" type="text" className="comment cost" placeholder={language === "sv" ? "Kostnad" : lang[language].cost} onBlur={this.handleCost} defaultValue={this.state.addCost} ref={el => this.curSelCost = el} />
                                <input id="curSelResp" type="text" className="comment responsible" placeholder={language === "sv" ? "Ansvarig" : lang[language].responsible} onBlur={this.handleResponsible} defaultValue={this.state.addResponsible} ref={el => this.curSelResp = el} />
                                <span className="dateBoxTitle">Deadline</span>
                            </div>*/}
                        </div>
                        <div className={this.state.addAnalysis === "summary" ? "hide" : ""}>
                            <textarea id="curSelComment" className="comment" placeholder={language === "sv" ? "Noterbart" : lang[language].notable} style={{ minHeight: 48 }} onBlur={this.handleComment} defaultValue={this.state.addComment} ref={el => this.curSelComment = el} />
                            <div className="fieldsB" onClick={this.toggleFields}>{language === "sv" ? "Fler fält " : lang[language].moreFields}<img src={arrowDown} style={{ width: 9, marginLeft: 5 }} className={this.state.moreFields ? "arrowUp" : ""} /> </div>
                            <div className={this.state.moreFields ? "" : "hideFields"} style={{ width: 630, margin: "auto" }}>
                                <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onBlur={this.handleReason} defaultValue={this.state.addReason} ref={el => this.curSelReason = el} />
                                <textarea id="curSelTodo" className="comment todo" placeholder={language === "sv" ? "Åtgärd" : lang[language].action} style={{ minHeight: 48 }} onBlur={this.handleTodo} defaultValue={this.state.addTodo} ref={el => this.curSelTodo = el} />
                                <input style={{ background: "white", textAlign: "center", }} type="date" id="deadline" name="deadline" className="dateBox" onChange={this.handleDeadline} value={this.state.addDeadline} />
                                <input id="curSelCost" type="text" className="comment cost" placeholder={language === "sv" ? "Kostnad" : lang[language].cost} onBlur={this.handleCost} defaultValue={this.state.addCost} ref={el => this.curSelCost = el} />
                                <input id="curSelResp" type="text" className="comment responsible" placeholder={language === "sv" ? "Ansvarig" : lang[language].responsible} onBlur={this.handleResponsible} defaultValue={this.state.addResponsible} ref={el => this.curSelResp = el} />
                                <span className="dateBoxTitle">Deadline</span>
                            </div>
                        </div>
                        <div
                            className={this.state.addAnalysis === "summary" && !this.state.summaryGreyBtn ? "commentButton commentButtonSummaryGrey" : "commentButton"}
                            style={{ width: 120, display: "block", margin: "auto", marginTop: 20, fontSize: 15.6 }}
                            onClick={this.saveNewComment}>{this.state.addAnalysis === "summary" && !this.state.summaryGreyBtn && language === "sv" ? "Sparat" : this.state.addAnalysis === "summary" && !this.state.summaryGreyBtn && language !== "sv" ? lang[language].saved : language === "sv" ? "Spara" : lang[language].save}</div>
                    </div>

                    <div className="allCommentsContainer">
                        <div>
                            <h3 style={{ paddingTop: 30, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>{language === "sv" ? "Noteringar" : lang[language].notes}</h3>
                            <div className={this.state.analysis === "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterAll}>{language === "sv" ? "Alla" : lang[language].all}</div>
                            <div className={this.state.analysis === "likvärdiga" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterEqualent}>{language === "sv" ? "Likvärdiga" : lang[language].equivalent}</div>
                            <div className={this.state.analysis === "hierarkisk" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterHierarchy}>{language === "sv" ? "Hierarkiska" : lang[language].hierarchical}</div>
                            {this.state.showWhat === "EU" && <div className={this.state.analysis === "summary" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterSummary}>{language === "sv" ? "Summeringar" : lang[language].summaries}</div>}

                        </div>

                        <table className="commentsTable">
                            <tr className="trStyleLine">
                                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="thpadding">Deadline</th>
                                <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                                <th className="thpadding remove" style={{ width: 30 }}></th>
                            </tr>
                            {comments}
                        </table>

                        <div className={this.state.allComments < 1 ? "hide" : ""}>
                            <div style={{ marginTop: 80, fontSize: 16, fontWeight: 900, marginBottom: 20, cursor: "pointer" }}><span onClick={this.toggleOldComments}>{language === "sv" ? "Föregående års kommentarer" : lang[language].commentPast}</span>
                                <img alt="" src={this.state.showOldComments ? down : up} style={{ width: 12, marginLeft: 5, cursor: "pointer" }} onClick={this.toggleOldComments} />
                            </div>
                            <div className={this.state.showOldComments ? "" : "hide"}>
                                <select name="selectionCommentYear" id="selectionCommentYear" onChange={this.changeYearComments} value={this.state.year}>
                                    <option value={this.state.selectedYear - 1}>{this.state.selectedYear - 1}</option>
                                    <option value={this.state.selectedYear - 2}>{this.state.selectedYear - 2}</option>
                                    <option value={this.state.selectedYear - 3}>{this.state.selectedYear - 3}</option>
                                    <option value={this.state.selectedYear - 4}>{this.state.selectedYear - 4}</option>
                                </select>
                                <span class="lastYearButton" style={{ padding: 7, paddingLeft: 10, paddingRight: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Återanvänd alla" : lang[language].reuseAll}</span>
                                <div style={{ top: 500, width: 450 }} className={this.state.popUpComments ? "popUp active-popUp" : "hide"}>
                                    <img alt="" src={exitImg} class="hide-box" onClick={this.toggleCommentPop} />
                                    <span style={{ marginTop: 60, display: "block", fontWeight: 600, display: "block", paddingLeft: 40, paddingRight: 40, fontWeight: "normal", lineHeight: 1.4 }}>{language === "sv" ? `Vill du återanvända alla kommentarer från ${this.state.year} för alla grupper?` : lang[language].reuseAllPop}</span>
                                    <div style={{ marginTop: 40 }}>
                                        <span class="lastYearButton" style={{ background: "#c99555", marginRight: 10, padding: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                                        <span class="lastYearButton" style={{ marginLeft: 10, padding: 10 }} onClick={this.reuseAll}>{language === "sv" ? "Ja, fortsätt" : lang[language].continue}</span>
                                    </div>
                                </div>

                                <div className={this.state.popUpComments ? "popUpFader" : "hide"}></div>
                                <table className="commentsTable lastyearList">

                                    {pastComments}

                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}