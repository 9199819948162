import React from "react";
import blueFlag from '../../../../assets/images/blue-flag.png';
import yellowFlag from '../../../../assets/images/yellow-flag.png';
import { autoAnalysis } from '../../../AnalysLikvardiga/autoAnalysis';
import percentile from "percentile";
import lang from '../../../Language/language.js';

export class ResultatLikvardigaExt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //For equalent comparisons (result if not showing kr)
            allComparisons: [],
            nrOfComparisions: [],
            groupedData: [],
            // For chosing ext or edited
            whatEvaluation: "editedEqualentGroup"
        }
    }

    compareEqualentGroups = () => {
        return new Promise((resolve, reject) => {
            //List to push results
            let comparisons = 0
            let allComparisons = []

            //make array of all groups and their data, not structured by equalent group
            let groupedData = this.state.groupedData.slice()
            //make array of all groups and their data, not structured by equalent group
            let allGroups = groupedData.flat();
            for (let i = 0; i < allGroups.length; i++) {
                for (let j = i + 1; j < allGroups.length; j++) {

                    //console.log(allGroups[i], allGroups[j], )
                    //Here compare them to eachother
                    const group1 = allGroups[i].groupNameArbVardering
                    const group2 = allGroups[j].groupNameArbVardering
                    //Get all employees in those groups
                    const groupOne = this.props.persData.filter(person => person.groupNameArbVardering === group1);
                    const groupTwo = this.props.persData.filter(person => person.groupNameArbVardering === group2);

                    //calculate average values
                    //group 1: 
                    const oneNrOfEmp = groupOne.length
                    const oneWomen = Math.round((groupOne.filter(person => person.gender === "woman").length / oneNrOfEmp) * 100)
                    const oneAvgSalary = Math.round(groupOne.reduce((acc, person) => acc + person.totalSalary, 0) / oneNrOfEmp)
                    const oneAvgAge = Math.round(groupOne.reduce((acc, person) => acc + person.age, 0) / oneNrOfEmp)
                    const oneAvgExp = Math.round(groupOne.reduce((acc, person) => acc + person.experience, 0) / oneNrOfEmp)
                    const oneEqualentGroup = groupOne[0][this.state.whatEvaluation] //CHANGE THIS IF CHANGING EQUALENT GROUP
                    const oneCityArray = groupOne.map(person => person.city)
                    const oneAverageCity = oneCityArray.sort((a, b) =>
                        oneCityArray.filter(v => v === a).length
                        - oneCityArray.filter(v => v === b).length
                    ).pop();
                    //Spridning
                    let oneGetOnlySalaries = []
                    for (let i = 0; i < groupOne.length; i++) { oneGetOnlySalaries.push(groupOne[i].totalSalary) }
                    let oneLowestSalary = Math.min(...oneGetOnlySalaries)
                    let oneHighestSalary = Math.max(...oneGetOnlySalaries)
                    let oneSpridningskvot = Math.round((oneHighestSalary / oneLowestSalary) * 10) / 10
                    //If 20 or more persons in the group
                    if (groupOne.length > 19) {
                        let salariesInOrder = oneGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                        let more10 = percentile(10, salariesInOrder)
                        let more90 = percentile(90, salariesInOrder)
                        oneSpridningskvot = Math.round((more90 / more10) * 10) / 10
                    }

                    //group 2:
                    const twoNrOfEmp = groupTwo.length
                    const twoWomen = Math.round((groupTwo.filter(person => person.gender === "woman").length / twoNrOfEmp) * 100)
                    const twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => acc + person.totalSalary, 0) / twoNrOfEmp)
                    const twoAvgAge = Math.round(groupTwo.reduce((acc, person) => acc + person.age, 0) / twoNrOfEmp)
                    const twoAvgExp = Math.round(groupTwo.reduce((acc, person) => acc + person.experience, 0) / twoNrOfEmp)
                    const twoEqualentGroup = groupTwo[0][this.state.whatEvaluation] //CHANGE THIS IF CHANGING EQUALENT GROUP
                    const twoCityArray = groupTwo.map(person => person.city)
                    const twoAverageCity = twoCityArray.sort((a, b) =>
                        twoCityArray.filter(v => v === a).length
                        - twoCityArray.filter(v => v === b).length
                    ).pop();
                    //Spridning
                    let twoGetOnlySalaries = []
                    for (let i = 0; i < groupTwo.length; i++) { twoGetOnlySalaries.push(groupTwo[i].totalSalary) }
                    let twoLowestSalary = Math.min(...twoGetOnlySalaries)
                    let twoHighestSalary = Math.max(...twoGetOnlySalaries)
                    let twoSpridningskvot = Math.round((twoHighestSalary / twoLowestSalary) * 10) / 10
                    //If 20 or more persons in the group
                    if (groupTwo.length > 19) {
                        let salariesInOrder = twoGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                        let more10 = percentile(10, salariesInOrder)
                        let more90 = percentile(90, salariesInOrder)
                        twoSpridningskvot = Math.round((more90 / more10) * 10) / 10
                    }

                    //Diffs
                    const diffWomen = (oneWomen - twoWomen)

                    //prepare data for robot
                    let lessWomen = oneWomen //Percentage of women in group, ex 60 = 60% women
                    let moreWomen = twoWomen //Percentage of women in the other group, below we switch it so that lessWomen always is the group with the smallest percantage of women.
                    let firsAvgSalary = oneAvgSalary //Total average salary for group 1
                    let secondAvgSalary = twoAvgSalary //Total average salary for group 2
                    let nrOfEmp = oneNrOfEmp //Nr of employee for the women dominated group. If under 4 we do some things
                    let nrOfEmpTwo = twoNrOfEmp //Nr of employee for the male dominated group
                    let firstGroup = group1 //Employees in group 1
                    let secondGroup = group2 //Employees in group 2
                    let firstExp = oneAvgExp //Average Experience in group 1
                    let secondExp = twoAvgExp //Average Experience in group 2
                    let firstAge = oneAvgAge //Average Experience in group 1
                    let secondAge = twoAvgAge //Average Experience in group 2
                    let firstEqualent = oneEqualentGroup //the equalent group the group belong to, (1-6). The first equalent group will always belong to the women dominated group
                    let secondEqualent = twoEqualentGroup
                    let firstCity = oneAverageCity
                    let secondCity = twoAverageCity
                    let firstSpridning = oneSpridningskvot
                    let secondSpridning = twoSpridningskvot

                    //Only compare if one group is women dominated and the second is not
                    if ((oneWomen > 59 && twoWomen < 60) || (twoWomen > 59 && oneWomen < 60)) {
                        //Count nr of comparisions
                        comparisons = comparisons + 1
                        this.setState({ nrOfComparisions: comparisons })

                        //If it is the second group that is women dominated, that groups should be first. Therefore we swap all data
                        if (twoWomen > 59 && oneWomen < 60) {
                            lessWomen = twoWomen
                            moreWomen = oneWomen
                            firsAvgSalary = twoAvgSalary
                            secondAvgSalary = oneAvgSalary
                            nrOfEmp = twoNrOfEmp
                            nrOfEmpTwo = oneNrOfEmp
                            firstGroup = group2
                            secondGroup = group1
                            firstExp = twoAvgExp
                            secondExp = oneAvgExp
                            firstAge = twoAvgAge
                            secondAge = oneAvgAge
                            firstEqualent = twoEqualentGroup
                            secondEqualent = oneEqualentGroup
                            firstCity = twoAverageCity
                            secondCity = oneAverageCity
                            firstSpridning = twoSpridningskvot
                            secondSpridning = oneSpridningskvot
                        }

                        //Only compare if there is a need for it. If no need to compare the groups, because male group is in higher group or has lower salary, then dont compare group
                        if (firstEqualent < secondEqualent || firsAvgSalary > secondAvgSalary) {
                            //Do nothing
                        } else {

                            //Run auto analysis (from separate file)
                            //const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "Yes");
                            const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "No");
                            //create object for analyticList
                            let type = (firstEqualent - secondEqualent) === 0 ? "equalent" : "hierarchical"
                            let group = {
                                "Group1": firstGroup,
                                "avgWomen1": lessWomen,
                                "emp1": nrOfEmp,
                                "avgSal1": firsAvgSalary,
                                "demands1": firstEqualent,
                                "Group2": secondGroup,
                                "avgWomen2": moreWomen,
                                "emp2": nrOfEmpTwo,
                                "avgSal2": secondAvgSalary,
                                "demands2": secondEqualent,
                                "Analys": autoAnalys[0], //<-- Måste skapa så skickar för robot här
                                //"Analys": autoAnalys, // <-- For testing
                                "AllResult": autoAnalys[4], //Used to get all result, example citydiff, agediff etc
                                "TotResult": autoAnalys[4] !== undefined ? autoAnalys[4].total : undefined, //The overall result for all
                                "reason": "",
                                "type": type,
                                "oneSpridningskvot": firstSpridning,
                                "twoSpridningskvot": secondSpridning
                            }
                            if (group.TotResult !== undefined && group.Analys !== "ignore") {
                                allComparisons.push(group)
                            }
                        } //End of if statement if need to run function or not
                    } //End of if first group is women dominated if statement
                } //End of first for loop
            } //End of second for loop
            //Divide all women dominated groups togheter with the male dominated groups that needs to be looked at
            let nestedGroups = allComparisons.reduce(function (r, a) {
                r[a.Group1] = r[a.Group1] || [];
                r[a.Group1].push(a);
                return r;
            }, Object.create(null));
            let nestedGroupsArray = Object.keys(nestedGroups).map((key) => nestedGroups[key]); //Makes nestedgroups an array
            this.setState({ allComparisons: nestedGroupsArray })
            resolve()
        });
    }

    componentDidMount() {
        const run = async () => {
            await this.setWhatEvaluation()
            await this.testThis()
            this.props.hideSalaries && await this.compareEqualentGroups();
        }
        run();
    }

    setWhatEvaluation = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                whatEvaluation: (this.props.whatEvaluation === "extLevel" || this.props.whatEvaluation === "extPoint") ? "extEqualentGroup" : "editedEqualentGroup"
            }, () => {
                resolve()
            })
        })
    }

    testThis() {
        return new Promise((resolve, reject) => {
            //*** GET DATA FOR EACH GROUP ***
            //Group all data that has the same equalent group
            const allEmployees = this.props.persData.slice()
            const groupedData = Object.values(allEmployees.reduce((result, item) => {
                const g = item[this.state.whatEvaluation]; // CHANGE THIS IF WE ARE USING ANOTHER EQUALENT GROUP
                const positionTitle = item.positionTitle;
                const salary = item.totalSalary;
                const gender = item.gender;
                const groupNameArbVardering = item.groupNameArbVardering

                if (!result[g]) {
                    result[g] = [];
                }

                const group = result[g].find(group => group.groupNameArbVardering === groupNameArbVardering);
                if (group) {
                    group.groups.push(item);
                    group.averageSalary += salary;
                    group.numberOfPersons++;
                    group.percentageOfWomen += (gender === 'woman' ? 1 : 0);
                    group.lowestSalary = Math.min(group.lowestSalary, salary);
                    group.highestSalary = Math.max(group.highestSalary, salary);
                } else {
                    result[g].push({
                        g,
                        positionTitle,
                        groupNameArbVardering, //added this
                        groups: [item],
                        averageSalary: salary,
                        numberOfPersons: 1,
                        percentageOfWomen: (gender === 'woman' ? 1 : 0),
                        lowestSalary: salary,
                        highestSalary: salary
                    });
                }
                return result;
            }, {}));

            groupedData.forEach(gGroup => {
                gGroup.forEach(positionGroup => {
                    positionGroup.averageSalary /= positionGroup.numberOfPersons;
                    positionGroup.percentageOfWomen = (positionGroup.percentageOfWomen / positionGroup.numberOfPersons) * 100;
                    positionGroup.spreadRatio = positionGroup.highestSalary / positionGroup.lowestSalary;
                });
            });
            this.setState({
                groupedData: groupedData
            }, () => {
                resolve()
            })
        })
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let nestedGroups = ""
        if (this.props.hideSalaries) {
            if (this.state.allComparisons.length > 0) {
                nestedGroups = this.state.allComparisons.map((item1) => (
                    <div style={{ background: "white", marginBottom: 20, boxShadow: "none", padding: 12, borderRadius: 5 }}>
                        <div className="robotBef" style={{ paddingTop: 0, paddingBottom: 0, borderBottom: "none" }}>
                            <span className="robotListInfo roboSmallTitles" style={{ marginLeft: 0, width: "45%", textAlign: "left" }}>{language === "sv" ? "Grupp" : lang[language].group}</span>
                            <span className="robotListInfo roboSmallTitles" style={{ width: "10%" }}>{language === "sv" ? "Kvinnor" : lang[language].women}</span>
                            <span className="robotListInfo roboSmallTitles">{language === "sv" ? "Ans." : lang[language].employees}</span>
                            <span className="robotListInfo roboSmallTitles">{language === "sv" ? "Spridning" : lang[language].spread}</span>
                            <span className="robotListInfo roboSmallTitles"> {language === "sv" ? "Krav" : lang[language].requirements}</span>
                            {this.props.hideSalaries ? "" : <span className="robotListInfo roboSmallTitles" style={{ width: "15%" }}>{language === "sv" ? "M. lön" : lang[language].avgSal}</span>}
                            <span className="robotListInfo roboSmallTitles" style={{ width: "10%" }}>{this.props.hideSalaries ? (language === "sv" ? "Löneskillnad" : lang[language].salDiffShort) : ""}</span>
                        </div>
                        {/*<div><b>{item1[0].Group1}</b></div>*/}
                        <div className="robotBef" style={{ borderBottom: "1px solid #e2e2e2", fontWeight: "bold" }}>
                            <span className="robotListInfo" style={{ marginLeft: 0, width: "45%", textAlign: "left" }}> {item1[0].Group1}</span>
                            <span className="robotListInfo" style={{ width: "10%" }}> {item1[0].avgWomen1}%</span>
                            <span className="robotListInfo"> {item1[0].emp1}</span>
                            <span className="robotListInfo"> {item1[0].oneSpridningskvot}</span>
                            <span className="robotListInfo"> {item1[0].demands1}</span>
                            {this.props.hideSalaries ? "" : <span className="robotListInfo" style={{ width: "15%" }}> {item1[0].avgSal1}</span>}
                            <span className="robotListInfo" style={{ width: "10%" }}> </span>
                        </div>
                        {item1.map((item2) => (
                            <div className="robotBef robotBefHover" style={{ borderBottom: "none", fontSize: 15 }}>
                                <span className="robotListInfo" style={{ marginLeft: 0, width: "45%", textAlign: "left" }}> {item2.Group2}</span>
                                <span className="robotListInfo" style={{ width: "10%" }}> {item2.avgWomen2}%</span>
                                <span className="robotListInfo"> {item2.emp2}</span>
                                <span className="robotListInfo"> {item2.twoSpridningskvot}</span>
                                <span className="robotListInfo"> {item2.demands2}</span>
                                {this.props.hideSalaries ? "" : <span className="robotListInfo" style={{ width: "15%" }}> {item2.avgSal2}</span>}
                                {/*}  <span className="robotListInfo" style={{ width: "10%", fontWeight: "bold", color: "#c26e17" }}> +{((item2.avgSal2/item2.avgSal1)*100)-100}%</span>*/}
                                {/* <span className="robotListInfo" style={{ width: "10%", fontWeight: "bold", color: "#ce8f1c", fontSize: 15 }}> +{Math.round((((item2.avgSal2 / item2.avgSal1) * 100) - 100) * 10) / 10}%</span>*/}
                                <span className="robotListInfo" style={{ width: "10%", fontWeight: "bold", color: "#ce8f1c", fontSize: 15 }}> {(((item2.avgSal2 - item2.avgSal1) / item2.avgSal2) * 100).toFixed(1)}%</span>
                            </div>
                        ))}
                    </div>
                ))
            }
        }


        // Comments function
        let equalent = this.props.comments.filter(com => com.analysis === "likvärdiga")
        let hierarchy = this.props.comments.filter(com => com.analysis === "hierarkisk")
        let commentsEqualent = equalent.length > 0 ? equalent.map(elem =>
            <tr class="trStyleLine">
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT">{elem.notable}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT">{elem.reason}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT">{elem.todo}</td>
            </tr>
        ) : <tr class="trStyleLine">
            <td style={{ paddingTop: 18, fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT"></td>
        </tr>
        let commentsHierarchy = hierarchy.length > 0 ? hierarchy.map(elem =>
            <tr class="trStyleLine">
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT">{elem.notable}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT">{elem.reason}</td>
                <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT">{elem.todo}</td>
            </tr>
        ) : <tr class="trStyleLine">
            <td style={{ paddingTop: 18, fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoR bNoL bNoT"></td>
            <td style={{ fontSize: 13, textAlign: "left", paddingLeft: 15, wordWrap: "break-word", paddingRight: 10, verticalAlign: "top" }} className="showB bNoL bNoT"></td>
        </tr>

        return (
            <div className="aPage">
                <h3 className="h2PaddingTop">{language === "sv" ? "5.2 Likvärdiga arbeten & hierarkiska skillnader" : lang[language].tocEquivalent}</h3>
                {this.props.hideSalaries ?
                    <>
                        <p className="P-document">{language === "sv" ? "Nedan visas arbeten med likvärdiga krav. Varje grupp innehåller arbeten med likvärdiga krav baserat på utförd arbetsvärdering. Högre grupp innebär högre krav. Dessa grupper har även varit underlag för analys av hierarkiska skillnader. Gul flagg innebär att arbetet är kvinnodominerat och blå flagg innebär att arbetet är mansdominerat. Om ingen flagga anses det vara jämställt. Gränsen för överrepresentation är 60%. Spridningen baseras på percentil 10 och 90 i de fall det är 20 eller fler anställda i gruppen, om mindre grupper baseras det på högsta och lägsta lön." : lang[language].eqvOne}
                        </p><p className="P-document">{language === "sv" ? "Nedanför grupperna redovisas vilka kvinnodominerande arbeten som har lägre lön men lika eller högre krav än icke kvinnodominerande arbeten. Procenten visar hur mycket lägre lönen är för det kvinnodominerade arbetet jämfört med det icke kvinnodominerade arbetet. Därefter redovisas analys. I de fall åtgärd har behövts står dessa noteringar även med i handlingsplanen (avsnitt 6)." : lang[language].eqvTwo}</p>

                    </> :
                    <><p className="P-document">{language === "sv" ? "Nedan visas arbeten med likvärdiga krav. Varje grupp innehåller arbeten med likvärdiga krav baserat på utförd arbetsvärdering. Högre grupp innebär högre krav. Dessa grupper har även varit underlag för analys av hierarkiska skillnader. Gul flagg innebär att arbetet är kvinnodominerat och blå flagg innebär att arbetet är mansdominerat. Om ingen flagga anses det vara jämställt. Gränsen för överrepresentation är 60%. Spridningen baseras på percentil 10 och 90 i de fall det är 20 eller fler anställda i gruppen, om mindre grupper baseras det på högsta och lägsta lön." : lang[language].eqvOne}
                    </p><p className="P-document">{language === "sv" ? "Analys redovisas nedanför grupperna. I de fall åtgärd har behövts står dessa noteringar även med i handlingsplanen (avsnitt 6)." : lang[language].eqvThree}</p>

                    </>}

                {/*Loop all groups */}
                <div className={!this.props.hideSalaries ? "wrapperLikvardigaDoko" : "wrapperLikvardigaDokoSmaller"}>
                    {this.state.groupedData.map((group, index) => (
                        <>
                            <div className="dontCut">
                                <div class="infoBoxEq" style={{ background: "#f3f3f3", border: "1px solid #333333", marginTop: 30, paddingBottom: 7 }}><div class="fillertitleEq" style={{ width: 321 }}><h4 style={{ display: 'inline-block', margin: 0, paddingTop: 20, fontSize: 16 }}>{language === "sv" ? "Grupp " : lang[language].group}{group[0].g}</h4></div><div class="titleEq flag"></div> <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"} style={{ marginLeft: -4 }}><div class="titleEq w80">{language === "sv" ? "Kvinnor" : lang[language].women}</div><div class="titleEq w80">{language === "sv" ? "Anställda" : lang[language].employees}</div><div class="titleEq w80">{language === "sv" ? "Spridning" : lang[language].spread}</div>{!this.props.hideSalaries ? <div class="titleEq w80">{language === "sv" ? "Medellön" : lang[language].avgSal}</div> : <></>} </div></div>
                                {group.map((item, index) => (
                                    <div className="infoBoxEq" style={{ background: "white", border: "1px solid #333333", borderTop: "none" }} key={item.groupNameArbVardering}>
                                        <div className="fillertitleEq" style={{ width: 321 }}>{item.groupNameArbVardering}</div>
                                        <div className="titleEq flag">{item.percentageOfWomen > 59 ? <img alt="" style={{ width: 14, paddingBottom: 1 }} src={yellowFlag} /> : item.percentageOfWomen < 41 ? <img alt="" style={{ width: 14, paddingBottom: 1 }} src={blueFlag} /> : ""}</div>
                                        <div className={!this.props.hideSalaries ? "wrapperLikv" : "wrapperLikvSmaller"}>
                                            <div className="titleEq noBold w80">{Math.round(item.percentageOfWomen)}%</div>
                                            <div className="titleEq noBold w80">{item.numberOfPersons}</div>
                                            <div className="titleEq noBold w80">{Math.round(item.spreadRatio * 10) / 10}</div>
                                            {!this.props.hideSalaries ? <div className="titleEq noBold w80">{Math.round(item.averageSalary)}</div> : <></>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ))
                    }
                </div>

                {/* Loop comments and groups for percentage */}
                {/* Show if salaries is hidden */}
                {this.props.hideSalaries ? <><p style={{ pageBreakBefore: "always" }}><span className='noprint'></span> </p>
                    <p style={{ paddingLeft: 18, paddingRight: 20, paddingTop: 100, paddingBottom: 20 }}>{language === "sv" ? "Nedan redovisas de kvinnodominerande arbeten som har lägre lön men lika eller högre krav än icke kvinnodominerande arbeten. Procenten visar hur mycket lägre lönen är för det kvinnodominerade arbetet jämfört med det icke kvinnodominerade arbetet." : lang[language].eqvFour}</p>
                    {nestedGroups === "" ? <div style={{ padding: 10, border: "1px solid", width: "70%", margin: "auto", textAlign: "center" }}>{language === "sv" ? "Inga kvinnodominerande arbeten har haft lägre lön än icke kvinnodominerade arbeten med lika eller högre krav." : lang[language].eqvFive}</div> : nestedGroups}
                </> : <></>}
                {/* List comments */}
                <div className="dontCut">
                    <h3 style={{ fontSize: "1.1em", paddingTop: 40 }}>{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}</h3>
                    <table className="commentsTableDoco" style={{ fontSize: 13, marginLeft: 0 }}>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: 15 }} colspan="3" className="showB color bNoB">{language === "sv" ? "Likvärdiga arbeten" : lang[language].eqvWorks}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPad bNoT smallTitlePDF">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                        </tr>
                        {commentsEqualent}
                    </table>
                </div>
                <div className="dontCut">
                    <table className="commentsTableDoco" style={{ fontSize: 13, marginTop: 30, marginLeft: 0 }}>
                        <tr>
                            <td style={{ textAlign: "left", paddingLeft: 15 }} colspan="3" className="showB color bNoB">{language === "sv" ? "Hierarkiska skillnader" : lang[language].eqvHie}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bigPad bNoT smallTitlePDF">{language === "sv" ? "Noterbart" : lang[language].notable}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoR bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Orsak" : lang[language].reason}</td>
                            <td style={{ textAlign: "left" }} className="showB color bNoL bigPad bNoT smallTitlePDF12 paddingFix2">{language === "sv" ? "Åtgärd" : lang[language].action}</td>
                        </tr>
                        {commentsHierarchy}
                    </table>
                </div>
            </div>
        )
    }
}