import React from 'react';
import '../AnalysLikvardiga/Likvardiga.css';
import './Arkitektur.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { fetchAuth } from '../../fetch-auth';
import Chart from "react-apexcharts";
import exitImg from '../../assets/images/exit.png';
import CustomPrompt from '../General/CustomPrompt.js';
import { ChartSalaryStructure } from './ChartSalaryStructure';
import chartIcon from '../../assets/images/chartIcon.png';
import Switch from "react-switch";
import settings from '../../assets/images/Settings.png';
import families from './families';
import { SalaryList } from './SalaryList';
import fullscreenWide from '../../assets/images/fullscreenWider.png';
import smallerScreen from '../../assets/images/smallerScreen.png';
import addIcon from '../../assets/images/addIcon.png';
import jobInfo from '../../assets/images/jobInfo.png';
import commentIcon from '../../assets/images/commentIcon.png';
import DraftEditor from './DraftEditor';
import ReadOnlyDraftEditor from './ReadOnlyDraftEditor';
import { convertToRaw } from 'draft-js';
import lang from '../Language/language.js';
import editImg from '../../assets/images/edit.png';
import exportImg from '../../assets/images/export.png';
import ReactToPrint from "react-to-print";
import searchIcon from '../../assets/images/searchIcon.png';
import trashB from '../../assets/images/TrashB.png';
import hierarchyImg from '../../assets/images/hierarchy.png';
import questionIcon from '../../assets/images/questionIcon.png';
import trashIcon from '../../assets/images/trashIcon.png';
import salaryRangeCalc from './salaryRangeCalc';
import update from '../../assets/images/update.png';

export class Arkitektur extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Basic settings
            showChangesPrompt: "Yes", // For popUp when trying to change page and data is not saved. Same for all pages
            saveButtonStatus: "Sparat",
            nrOfEqualentGroups: 6, // Used to create the right amount of levels/grades in the architecture
            chartOpen: false, // Show or hide the chart
            fullScreen: false, // Show fullscreen btn
            toggleTitles: false, // Show or hide position titles
            showSalRange: false, // Show or hide salary rangs in architecture
            showComments: false, // Show or hide salary range comments in architecture and print
            showCommentSalary: false, // Show or hide the editable salary range comment input
            allSalaryComments: [], // Holds all salary range comments
            thisSalaryComment: "", // Shows the selected salary range comment
            salaryCommentSaved: true, // Enables save btn for salary range comment
            showFixedTitle: false, // This is for titles (grades, family etc) to show when scrolling, because cant use css sticky and need to do it manually
            printHeight: 0, // For printing
            colorPicker: '#ffffff', // Holds color for color picker
            searchToggle: false, // Show search field
            searchString: "", // The search string
            triggerChartUpdate: "trigger", // Forces the chart to update
            whatAIToCreate: "", // What should be created automatically, selected by user (just family, just ranges or both)
            noGrade: [], // Holds what groups are missing a work evaluation
            noSSYK: 0, // If more than 0 then there is works that are missing a statistical code. Used for warning about it
            showInputNewFam: "hide", // This is to show or hide "the create new input". It can be family, mainFunction etc. So when we click on that in the architecture it knows what input to show
            editSharedArc: false,
            duplicateLevels: [], // If this array is not empty, it means that there is multiple works with the same name that has different levels. This is not allowed and will create a popUp to change name or edit valuation.
            changeName: false, // Holds what work name should be changed if user tries to change duplicated equal works
            changeNameCompany: false, // Holds for what company the work name that should be changed if user tries to change duplicated equal works
            inputNewName: "", // Holds the new name for the work. Used for the input field
            rangeSettingInfoTitle: "",
            rangeSettingInfoContent: "",
            // PopUps
            popUpInfo: false,
            copyOpen: true, // Open or close the auto create button 
            salaryRangesOpen: false, // This is for the salary ranges setting popUp. 
            salaryRangesManualOpen: false, // This is for when setting a salary range manually. Toggles the popUp
            popUpJobDesc: false, // Popup for job description
            settingPopUp: false, // Show or hide the settings button
            hideNoGrade: false, // Show or hide the popUp that informs that works are missing a work evaluation
            shareArcPop: false,
            toggleArcPop: false,
            changeNamePop: false,
            toggleRangeSettingInfoPop: false,
            updateSalaryRangesPop: false,
            // Job descriptions
            showJobDesc: true, // Shows or hides the job description icon
            jobDescWhatGroup: "",
            jobDescriptions: [],
            jobDescSelected: "",
            editJobDesc: false,
            positionsAndEqualWorks: [],
            // For the scatter chart, which is the <chartSalaryStructure /> module.
            p10: [], // These are the percentiles, used to show the market data in the chart
            p25: [],
            median: [],
            p75: [],
            p90: [],
            employeesInChartLevels: [], // This is the temporary selected employees that should be shown in the chart
            // Settings for auto calculation of salary ranges
            advanceSettings: true, // Hides or shows advanced settings. Can only be hardcoded
            toggleAdvancedSettings: false, // Toggles the advance settings box, this.state.advanceSettings still need to be true for this to have any effect
            minimumSalaryMinHigher: 5, // The minimum salary percentage between each level. If 5 it means that level 2 has to be at least 5% higher than level 1 and so on.
            maxSpread: 100, // The max allowed spread for each salary range, 100 = 100%
            minSpread: 20, // The min allowed spread for each salary range, 20 = 20%
            autoCalculation: "no", // If auto calculation should be run or not, otherwise the function is used to just group and format the ranges and employees for the chart.
            marketPosition: "median", // The market position setting. median, lag or lead. lag = p10 - p75, lead = p25 - p90, median = p10 - p90, medianP25P75 - temporary for test = p25 - p75
            marketSector: "all", // The market sector setting, is: "all", "privat" or "offentlig"
            createProgression: "yes", // Create progression setting, forces salary to never be lower on higher levels
            standardDeviation: "two", // If standardDeviation should be used in the calculation. "one" or "two" standardDeviations, or "no". Can't be set by user. 
            marketWeight: 50, // Market weight setting.
            snappedValue: 50, // This is used to make slider smooth for the marketWeight slider
            // For adding salary ranges manually
            manualLevel: "-", // The selected level that should be added (integer) 
            nrOfSelectableEqualentGroups: [],  // This is when selecting manual levels, it will only show the levels that is not already added.
            //For architecture 
            addFamilytoList: "",
            addMainFunction: "",
            addSubFunction: "",
            addSubFunction2: "",
            addSubFunction3: "",
            familySelectList: ["- Ingen -"],
            mainFunctionsSelectList: ["- Ingen -"],
            subFunctionSelectList: ["- Ingen -"],
            subFunctionSelectList2: ["- Ingen -"],
            subFunctionSelectList3: ["- Ingen -"],
            selectedMainFunction: "",
            selectedSubFunction: "",
            selectedSubFunction2: "",
            selectedSubFunction3: "",
            editGroupPop: false,
            showMore: false,
            nrOfSubFamilies: 2,
            marketSalaries: [],
            hierarchy: [],
            jobFamilies: [],
            arcIds: [], // looks like this: [{"arcId": 1, "dotterBolag": [ "Fiktivista", "Fiktivo"] } ], so the arcId:s grouped
            arcIdsBackup: [], // This is a backup for the arcIds. If we need to know what is changed we can compare to this. Also if we made changes but then click cancel, the data here gets copied to arcIds to reset it
            arcIdOneComp: "yes", // If yes, there is only one company, we then do not show the shared architecture button
            activeCompanyAndId: "", // The selected company and its id (its one object), so we know what architecture that should be active
            sameNameDifferentLevel: [], // If there is more than one company using the architecture, and there is the same equal name but different level, those are saved here
            // Holds the saved salary ranges that is sent on save button
            individual: [], // for equal works 
            rangesLevels: [], // for overall levels 
            family: [], // for family
            mainFunction: [], // for mainFunction
            subFunction: [], // for subFunction
            subFunction2: [], // for subFunction2
            subFunction3: [], // for subFunction3
            subFunction4: [], // for subFunction4
            // This is so that the system know what to save when sent to server. If off it is not saved. Will be on if changed manually or automatically with AI
            activateSalRangeSave: "off", // on | off - If off ranges won't save to DB
            activateSalRangeSaveFamily: "off",
            activateSalRangeSaveMainFunction: "off",
            activateSalRangeSaveSubfunction: "off",
            activateSalRangeSaveSubfunction2: "off",
            activateSalRangeSaveSubfunction3: "off",
            activateSalRangeSaveSubfunction4: "off",
            activateSalRangeSaveIndividual: "off",
            // Settings for salary ranges 
            listOfFamOrFunc: [], // Used for when selecting family or function in scrollable list. So this is the content for the scrollable list
            selectedFamOrFunc: "overall", // overall is for levels, if not levels it is the name of the family or function
            selectedFamOrFuncForSub: "", // This is the selected mainfunction or subfunction. because we here also need to know the family, so it looks like this montör-tillverkning (position-family)
            activeFamilyOrFunc: [], // this array holds the active family or function. Added to correct family or array before saving
            selectedWorkForAutoCalc: false, // <------------ Kontrollera denna, hur völjs den ut? Tror det är det valda namnet bara
            popUpWhatParents: false, // This holds the full parent list. So for example whatFam, whatMainFunction, whatSubfunction and whatSubfunction2. Is changed on popup, not when clicking "skapa lönespann"
            // Main data
            empData: [],
            // Manual delete salary ranges
            removeThis: [], // The ranges that should be deleted are stored here
            // What is selected (active) right now and what salary have we selected to edit/view? These are separe because active shows what is active in popUp, activeSalary shows what is active in chart
            activeWhat: "overall", // Keeps track on what type we have activated right now for the popUp, overall | family | mainFunction | subFunction | subFunction2 | subFunction3 | subFunction4
            activeInfo: { // Shows the active popUp
                position: "",
                whatFam: "",
                whatMainFunction: "",
                whatSubfunction: "",
                whatSubfunction2: "",
                whatSubfunction3: "",
                whatSubfunction4: "",
            },
            activeSalaryWhat: "overall", // Keeps track on what type we have activated right now for the salary ranges, overall | family | mainFunction | subFunction | subFunction2 | subFunction3 | subFunction4
            activeSalaryInfo: {
                position: "",
                whatFam: "",
                whatMainFunction: "",
                whatSubfunction: "",
                whatSubfunction2: "",
                whatSubfunction3: "",
                whatSubfunction4: "",
            },
            uniqueListForScrolling: [], // This is used for the scrollable list. It consists of all unique combinations of families and the active function/work. Ex: {family: "HR", name: "Lön"}. If activeSalaryWhat is "family" it will instead just contain the unique families, like this: ["familj 1", "familj 2" etc]
            //Below is for salary band chart
            legendSalRange: true,
            options3: {
                annotations: { //This is to create lines inside the boxes
                    points: [
                        { // This is the range TOP
                            y: 60000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '60 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        {  // This is the midpoint
                            y: 45000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "#333333",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: "45 000", //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        { // This is the range BOTTOM
                            y: 40000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: "markRangeLine",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '40 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                },
                                offsetX: 31,
                                offsetY: 22
                            }
                        },
                    ]
                },
                fill: {
                    opacity: 1,
                },
                stroke: {
                    // show: false,
                    //width: 0.5,
                    // width: 1.5,
                    width: [0, 2, 2, 2, 2, 2, 2],
                    colors: ["#f7f7f7"]
                },
                //colors: ["#FF0000", "#FF0000","#FF0000", "#FF0000","#FF0000", "#FF0000"],
                grid: {
                    borderColor: "#e1e1e1",
                    position: 'front',
                    show: false,
                    /*  xaxis: {
                          lines: {
                              show: false
                          }
                      }*/
                },
                chart: {
                    height: 350,
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: true
                    },
                    events: { // Change this to change the legend (series buttons) function
                        legendClick: function (chartContext, seriesIndex, config) {
                            // This toggles this.state.legendSalRange, it determes if annotations for lönespann should be shown or hidden.
                            const clickedLegendName = chartContext.w.config.series[seriesIndex].name;
                            if (clickedLegendName === "Lönespann") {
                                this.state.legendSalRange ? this.setState({ legendSalRange: false }) : this.setState({ legendSalRange: true })
                                this.updateChart()
                            }
                        }.bind(this)
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        //floating: true, // Enable floating bars
                    },
                },
                markers: {
                    size: [2, 6]
                },

                tooltip: {
                    shared: false,
                    intersect: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        if (data.range === undefined) { //If undefined we are not looking at the range box, instead we look at an individual or p-value
                            if (data.p === undefined) { //If p is undefined, we are looking at an individual
                                return `<div class="tooltip">
                                <div>Persondata här, tex:</div>
                                <div>Lön: ${data.y}</div>
                                <div>Lön inkl tillägg: ${data.totalSalary}</div>
                                <div>Befattning: ${data.positionTitle}</div>
                                <div>Lika arbete: ${data.groupNameArbVardering}</div>
                                <div>Nivå: ${data.x}</div>
                                <div>Ålder: ${data.age}</div>
                                <div>År i org: ${data.experience}</div>
                                <div>Stad: ${data.city}</div>
                                <div>Kön: ${data.gender}</div>
                                <div>Bolag: ${data.dotterBolagReal}</div>
                                </div>`;
                            } else { //If p is not undefined, we are looking at a "P" value
                                return `<div class="tooltip">${data.y}</div>`;
                            }
                        } else {
                            return `<div class="tooltip">
                            <div style="text-align: center; border-bottom: 1px solid lightgrey"><b>Nivå ${data.x}</b></div>
                            <div style="padding-top: 4px; padding-bottom: 2px"><b>Max:</b> ${data.range[2]}</div>
                            <div style="padding-top: 2px; padding-bottom: 2px"><b>Mid:</b>  ${data.range[1]}</div>
                            <div style="padding-top: 2px"><b>Min:</b>  ${data.range[0]}</div>
                            </div>`;
                        }
                    }
                },
                xaxis: {
                    tickAmount: 5,
                    max: 5,
                    min: 1,
                    type: 'numeric',
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    }
                },
            },
            series3: [
                {
                    name: "Lönespann",
                    type: 'bar',
                    //color: "#cdcccc",
                    data: [ // X tells is the level
                        { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
                        { y: 40000, x: 2, fillColor: "#f7f7f7", range: [40000, 45000, 60000], }, // Bottom
                        { y: 70000, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
                        { y: 60000, x: 5, range: [60000, 45000, 80000] },
                    ],
                },
                /* Marknadslöner kan visas såhär:  {
                      name: "Marknadslöner",
                      type: 'bar',
                      //color: "#cdcccc",
                      data: [ // X tells is the level
                          { y: 50000, x: 2, fillColor: "green" }, // Floating box between 20000-40000 for x-axis 1
                          { y: 40000, x: 2, fillColor: "#f7f7f7",  }, // Customize as per your requirement
                          { y: 60000, x: 2, fillColor: "transparent" }, // Customize as per your requirement
                      ],
                  },*/
                {
                    name: "Medarbetare",
                    data: [{ y: 30000, x: 2 }, { y: 35000, x: 2 }, { y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }, { y: 50000, x: 5 }],
                    type: 'scatter',
                },

                {
                    name: "P10",
                    type: 'line',
                    color: "#dda6a6",
                    data: [{ y: 25000, x: 1, p: "p" }, { y: 20000, x: 2, p: "p" }, { y: 35000, x: 3, p: "p" }, { y: 22500, x: 5, p: "p" },] //"P" is used to determe what type of item it is for tooltip
                },
                {
                    name: "P25",
                    type: 'line',
                    color: "#f6bdc7",
                    data: [{ y: 30000, x: 1, p: "p" }, { y: 40000, x: 2, p: "p" }, { y: 55000, x: 3, p: "p" }, { y: 92500, x: 5, p: "p" },]
                },

                {
                    name: "Median",
                    type: 'line',
                    color: "#8fa7e1",
                    data: [{ y: 35000, x: 1, p: "p" }, { y: 50000, x: 2, p: "p" }, { y: 65000, x: 3, p: "p" }, { y: 40500, x: 5, p: "p" },]
                },
                {
                    name: "P75",
                    type: 'line',
                    color: "#dbc191",
                    data: [{ y: 40000, x: 1, p: "p" }, { y: 58000, x: 2, p: "p" }, { y: 75000, x: 3, p: "p" }, { y: 97500, x: 5, p: "p" },]
                },
                {
                    name: "P90",
                    type: 'line',
                    color: "#9dc59d",
                    data: [{ y: 45000, x: 1, p: "p" }, { y: 60000, x: 2, p: "p" }, { y: 80000, x: 3, p: "p" }, { y: 102500, x: 5, p: "p" },]
                },
                /* {
                     name: "Medellön",
                     data: [{ y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }],
                     color: "#000000",
                     type: 'line',
                 },*/
            ],
        }
        this.targetDivRef = React.createRef();
    }

    setActiveInfo = (activeWhat, activeInfo) => {
        return new Promise((resolve) => {
            this.setState(
                {
                    activeWhat: activeWhat,
                    activeInfo: activeInfo
                }, resolve);
        });
    }

    setActiveSalaryInfo = (activeSalaryWhat, activeSalaryInfo) => {
        return new Promise((resolve) => {
            this.setState(
                {
                    activeSalaryWhat: activeSalaryWhat,
                    activeSalaryInfo: activeSalaryInfo
                }, resolve);
        });
    }

    updateChart = () => {
        //Hides annotations
        this.setState({
            options3: {
                annotations: { //This is to create lines inside the boxes
                    points: [
                        { // This is the range TOP
                            y: 60000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '60 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        {  // This is the midpoint
                            y: 45000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                fillColor: "#333333",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: "45 000", //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 10
                            }
                        },
                        { // This is the range BOTTOM
                            y: 40000,
                            x: 1.66,
                            marker: {
                                size: 1,
                                shape: "square",
                                cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                fillColor: "transparent",
                                strokeWidth: 0,
                                radius: 0,
                            },
                            borderColor: 'transparent',
                            fillColor: 'transparent',
                            label: {
                                borderWidth: 0,
                                text: '40 000', //set to undefined to hide the label
                                textAnchor: 'middle',
                                position: "left",
                                style: {
                                    background: 'transparent',
                                    cssClass: this.state.legendSalRange ? "markRangeLine" : "hide",
                                },
                                offsetX: 31,
                                offsetY: 22
                            }
                        },

                    ]
                },
            }
        })
    }

    handleScroll = () => {
        if (this.targetDivRef.current) {
            const targetPosition = this.targetDivRef.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            // Check if the target div has scrolled into view
            if (targetPosition <= windowHeight && targetPosition >= 0) {
                this.setState({ showFixedTitle: false });
            } else {
                this.setState({ showFixedTitle: true });
            }
        }
    };

    componentDidUpdate() {
        if (this.lineHeightCalculateRef.current) {
            const height = this.lineHeightCalculateRef.current.offsetHeight;
            if (height !== this.state.printHeight) {
                this.setState({ printHeight: height });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll); // handles scrolldown for titles
    }

    getDataAndComments = async () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const responseH = await fetchAuth(`/api/loadArchitecture`, 'POST');
        let dataH = await responseH.json();

        // First, save comments, but hide the ones that no longer is relevant (if the position is removed, changed families etc)
        // create a list of all unique works, job families, functions etc
        let allEmps = this.state.empData.length > 0 ? this.state.empData : dataH[0] // Use data from server or the one we have changed
        const uniqueValues = Array.from(
            new Set(
                allEmps.flatMap((item) => [
                    item.family,
                    item.mainFunction,
                    item.subFunction,
                    item.subFunction2,
                    item.subFunction3,
                    item.groupNameArbVardering,
                ])
            )
        ).filter((value) => value !== null); // Remove null values
        // make sure the name in the allSalaryComments is included in the uniqueValues list, otherwise remove the comment before saving to state
        let allSalaryComments = dataH[4][0]
        const updatedAllSalaryComments = allSalaryComments.filter((item) => uniqueValues.includes(item.theName) || item.theName === "overall");
        this.setState({ allSalaryComments: updatedAllSalaryComments }) // save comments
        //  Finished with handling and saving comments, belows continues with the rest

        let arcIds = dataH[5][0]

        // Step 2: Group data by arcId, retaining full information
        const groupedIds = Object.values(
            arcIds.reduce((acc, item) => {
                if (!acc[item.arcId]) {
                    acc[item.arcId] = { arcId: item.arcId, items: [] };
                }
                acc[item.arcId].items.push(item); // Retain the full object
                return acc;
            }, {})
        );

        // Also check if there is only one company, then the button for "sharedArchitecture" should not be seen
        let oneComp = arcIds.length > 1 ? "no" : "yes"
        // Get what company is used and what arcId should be active
        let activeCompanyAndId = {
            name: dataH[6],
        }
        for (let i = 0; i < arcIds.length; i++) { if (arcIds[i].dotterBolag === dataH[6]) { activeCompanyAndId.arcId = arcIds[i].arcId } }

        // save the grouped arcIds and set if any had a null value (if so we will save the arcId when saving the architecture). Also save what company is now active
        this.setState({ arcIds: groupedIds, arcIdOneComp: oneComp, activeCompanyAndId: activeCompanyAndId })
        let savedSalaryRanges = dataH[2][0]

        // Save all unique families and subfamilies into state, used for list selections
        // Get all unique from empData
        let uniqueFamilies = new Set();
        let uniqueMainFunctions = new Set();
        let uniqueSubFunctions = new Set();
        let uniqueSubFunctions2 = new Set();
        let uniqueSubFunctions3 = new Set();
        for (let obj of allEmps) {
            if (obj.family !== undefined) uniqueFamilies.add(obj.family);
            if (obj.mainFunction !== undefined) uniqueMainFunctions.add(obj.mainFunction);
            if (obj.subFunction !== undefined) uniqueSubFunctions.add(obj.subFunction);
            if (obj.subFunction2 !== undefined) uniqueSubFunctions2.add(obj.subFunction2);
            if (obj.subFunction3 !== undefined) uniqueSubFunctions3.add(obj.subFunction3);
        }
        let familiesArray = Array.from(uniqueFamilies).filter(value => value !== null && value !== undefined);;
        let mainFunctionsArray = Array.from(uniqueMainFunctions).filter(value => value !== null && value !== undefined);;
        let subFunctionsArray = Array.from(uniqueSubFunctions).filter(value => value !== null && value !== undefined);;
        let subFunctions2Array = Array.from(uniqueSubFunctions2).filter(value => value !== null && value !== undefined);;
        let subFunctions3Array = Array.from(uniqueSubFunctions3).filter(value => value !== null && value !== undefined);;
        // Get all unique from families.js
        let uniqueFamiliesSSYK = new Set();
        let uniqueMainFunctionsSSYK = new Set();
        let uniqueSubFunctionsSSYK = new Set();
        for (let obj of families) {
            if (obj.family !== undefined) uniqueFamiliesSSYK.add(obj.family);
            if (obj.cat2 !== undefined) uniqueMainFunctionsSSYK.add(obj.cat2);
            if (obj.cat3 !== undefined) uniqueSubFunctionsSSYK.add(obj.cat3);
        }
        let familiesArraySSYK = Array.from(uniqueFamiliesSSYK);
        let mainFunctionsArraySSYK = Array.from(uniqueMainFunctionsSSYK);
        let subFunctionsArraySSYK = Array.from(uniqueSubFunctionsSSYK);
        // Merge the list from families.js and from empData and remove duplicates
        let combinedArrayFamily = Array.from(new Set([...familiesArray, ...familiesArraySSYK]));
        let combinedArrayMainFunction = Array.from(new Set([...mainFunctionsArray, ...mainFunctionsArraySSYK]));
        let combinedArraySubFunctions = Array.from(new Set([...subFunctionsArray, ...subFunctionsArraySSYK]));
        // Order by alphabetic order
        combinedArrayFamily.sort((a, b) => a.localeCompare(b));
        combinedArrayMainFunction.sort((a, b) => a.localeCompare(b));
        combinedArraySubFunctions.sort((a, b) => a.localeCompare(b));
        subFunctions2Array.sort((a, b) => a.localeCompare(b));
        subFunctions3Array.sort((a, b) => a.localeCompare(b));
        // Save to state
        if (subFunctions2Array.length > 0) { subFunctions2Array.unshift("") } // Bugfix to be able to remove a subFunction 2, adds "" to the start of the array
        if (subFunctions3Array.length > 0) { subFunctions3Array.unshift("") } // Bugfix to be able to remove a subFunction 3, adds "" to the start of the array
        this.setState({
            familySelectList: combinedArrayFamily,
            mainFunctionsSelectList: combinedArrayMainFunction,
            subFunctionSelectList: combinedArraySubFunctions,
            subFunctionSelectList2: subFunctions2Array, // families.js will max contain two subfunctions, therefore no need to combine
            subFunctionSelectList3: subFunctions3Array,
        })

        // Fix the savedSalaryRanges to be the correct format 
        const salaryRangesFixed = [];
        const uniqueCombinations = [];
        // Gather unique combinations of x and familyOrFunction
        savedSalaryRanges.forEach(obj => {
            let combination = `${obj.level}-${obj.familyOrFunction}-${obj.name}-${obj.familyName}`;
            if (obj.familyOrFunction === "individual") {
                combination = `${obj.name}-${obj.familyOrFunction}`;
            }
            if (!uniqueCombinations.includes(combination)) {
                uniqueCombinations.push(combination);
            }
        });

        uniqueCombinations.forEach(combination => {
            const [x, familyOrFunction, name, familyName] = combination.split('-');
            let filteredObjects = savedSalaryRanges.filter(obj => obj.level == x && obj.familyOrFunction == familyOrFunction && obj.name == name && obj.familyName == familyName);
            let filterObjectsIndividual = savedSalaryRanges.filter(obj => obj.name == x && obj.familyOrFunction == familyOrFunction);

            if (filterObjectsIndividual.length > 0) {
                filteredObjects = filterObjectsIndividual
            }

            filteredObjects.forEach((obj, index) => {
                const range = [obj.min, obj.mid, obj.max];
                const spread = ((obj.max - obj.min) / obj.min) * 100;

                salaryRangesFixed.push({
                    fillColor: "#e0e0e0",
                    range: range,
                    spread: spread,
                    x: obj.level,
                    y: obj.max,
                    familyOrFunction: obj.familyOrFunction,
                    name: obj.name,
                    familyName: obj.familyName
                });
                if (index === 0) {
                    salaryRangesFixed.push({
                        fillColor: "#ffffff",
                        range: "hide",
                        spread: spread,
                        x: obj.level,
                        y: obj.min,
                        familyOrFunction: obj.familyOrFunction,
                        name: obj.name,
                        familyName: obj.familyName
                    });
                }
            });
        });
        let rangesLevels = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "overall");
        let family = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "family");
        let mainFunction = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "mainFunction");
        let subFunction = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction");
        let subFunction2 = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction2");
        let subFunction3 = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction3");
        let subFunction4 = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "subFunction4");
        let individual = salaryRangesFixed.filter((obj) => obj.familyOrFunction === "individual");

        this.setState({
            marketSalaries: dataH[1],
            rangesLevels: rangesLevels,
            family: family,
            mainFunction: mainFunction,
            subFunction: subFunction,
            subFunction2: subFunction2,
            subFunction3: subFunction3,
            subFunction4: subFunction4,
            individual: individual,
        })
        // End of salaryRangesFixed

        // Change EqualentGroup, extEqualentGroup and editedEqualentGroup to a generic property "level" instead. So regardless of what architecture is used, the property will always be "level"
        allEmps.forEach(emp => {
            const matchingEntry = arcIds.find(entry => entry.dotterBolag === emp.dotterBolagReal);
            if (matchingEntry) {
                emp.level = (matchingEntry.whatEvaluation === "original" || matchingEntry.whatEvaluation === null || matchingEntry.whatEvaluation === undefined)
                    ? emp.equalentGroup
                    : matchingEntry.whatEvaluation === "extern"
                        ? emp.extEqualentGroup
                        : emp.editedEqualentGroup;
            }
        });


        // Now load more data, this could be used in same fetch as above, but works for now
        const response = await fetchAuth(`/api/loadArchitectureMoreData`, 'POST');
        let data = await response.json();
        // Check if any ssyk codes are missing and if there is any equal works that has no grade/equivalentgroup
        let noSSYK = 0;
        let noGrade = [] // equalentGroup, editedEqualentGroup or extEqualentGroup
        allEmps.forEach(item => {
            if (item.ssyk === null || item.ssyk === undefined || item.ssyk === "") {
                noSSYK++;
            }
            if (item.level === null || item.level === undefined || item.level === "" || item.level === 0) {
                noGrade.push({
                    level: item.level,
                    groupNameArbVardering: item.groupNameArbVardering,
                    positionTitle: item.positionTitle
                });
                item.level = 0
            }
        });
        // Filter so that it is only unique equal groups in the noGrade list
        // Filter the array to only include unique groupNameArbVardering values
        let seenGroupNames = new Set();
        noGrade = noGrade.filter(item => {
            if (!seenGroupNames.has(item.groupNameArbVardering)) {
                seenGroupNames.add(item.groupNameArbVardering);
                return true; // Keep the item
            }
            return false; // Filter out duplicates
        });
        noGrade.sort((a, b) => a.groupNameArbVardering.localeCompare(b.groupNameArbVardering));

        // Crete a list of equal works that has the same name but not the same level. This can happen if using the architecture for more than one company. We later use it for writing the name of the company in the architecture
        // Step 1: Group objects by `groupNameArbVardering`
        const groupsLook = allEmps.reduce((acc, item) => {
            const groupName = item.groupNameArbVardering;
            if (!acc[groupName]) {
                acc[groupName] = [];
            }
            acc[groupName].push(item);
            return acc;
        }, {});

        // Step 2: Check for differing levels and build the result array with unique entries
        const uniqueCombinationList = new Set();
        const resultList = [];
        for (const groupName in groupsLook) {
            const items = groupsLook[groupName];
            const levels = new Set(items.map(item => item.level));
            if (levels.size > 1) { // More than one unique level
                items.forEach(item => {
                    const uniqueKey = `${groupName}-${item.level}-${item.dotterBolagReal}`;
                    if (!uniqueCombinationList.has(uniqueKey)) {
                        uniqueCombinationList.add(uniqueKey);
                        resultList.push({
                            groupNameArbVardering: groupName,
                            level: item.level,
                            dotterBolagReal: item.dotterBolagReal
                        });
                    }
                });
            }
        }

        //  this.setState({ empData: allEmps, noSSYK: noSSYK, noGrade: noGrade, sameNameDifferentLevel: resultList })
        //    this.groupHierarchy()
        this.setState({ empData: allEmps, noSSYK: noSSYK, noGrade: noGrade, sameNameDifferentLevel: resultList }, () => {
            this.groupHierarchy()
        })

        //set nr of selected equalentgroups
        this.setState({ nrOfEqualentGroups: data[3] })

        // Lastly, run calcSalaryRanges to update chart data, if we do not have any data saved before, use the AI and create ranges without progression and any effects. This will show the actual ranges right now
        // Doing a change here, run to create the charts, but do not autoCalculate, its just weird to have it calculated when we havent asked for it, so autoCalculation is now set to "no", before it was yes
        if (savedSalaryRanges.length < 1) {
            this.setState({
                autoCalculation: "no", // Was "yes" before
                standardDeviation: "no", // "one" or "two" standardDeviations, or "no"
                createProgression: "no",
                marketWeight: 0,
            })
        }
        this.calcSalaryRanges(true);
        this.setState({
            autoCalculation: "no", // Was "yes" before
            standardDeviation: "two",
            createProgression: "yes",
            marketWeight: 50,
        })

        // Get jobDescriptions and fix data for when selcting position for the job data
        const originalArray = allEmps
        const positionsAndEqualWorks = Object.entries(
            originalArray.reduce((acc, { groupNameArbVardering, positionTitle }) => {
                acc[groupNameArbVardering] = acc[groupNameArbVardering] || new Set(); // Use a Set to store unique titles
                acc[groupNameArbVardering].add(positionTitle); // Add positionTitle to the Set (automatically handles uniqueness)
                return acc;
            }, {})
        ).map(([groupNameArbVardering, positionTitlesSet]) => ({
            groupNameArbVardering,
            positionTitles: [...positionTitlesSet] // Convert the Set back to an array
        }));

        const jobDesc = dataH[3][0]
        this.setState({ jobDescriptions: jobDesc, positionsAndEqualWorks: positionsAndEqualWorks })

        // Lastly, if there is more than one company that has the same architecture but they now have different nr of equivalent groups, alert a warning
        let haveDifferentValuations = data[8]
        if (haveDifferentValuations === "yes") { alert(language === "sv" ? "OBS! Arkitekturen innehas av flera bolag men alla har inte samma antal nivåer/likavärdesgrupper. Ni bör säkerställa så att alla bolag har lika många nivåer eller delas upp i olika arkitekturer." : lang[language].differentLevelsPop) }

        // Also, check if there is works from different companies that has the same name but different level. If so create a warning (wichs is done by populating this.state.duplicateLevels)
        const worksAndLevels = Array.from(
            new Set(
                allEmps.map(item => `${item.dotterBolagReal}|${item.groupNameArbVardering}|${item.level}`)
            )
        ).map(key => {
            const [dotterBolagReal, groupNameArbVardering, level] = key.split('|');
            return { dotterBolagReal, groupNameArbVardering, level: parseInt(level, 10) };
        });

        const groupedByDifferentLevels = [];
        // console.log(worksAndLevels)
        // Group by `groupNameArbVardering`
        const grouped = worksAndLevels.reduce((acc, obj) => {
            acc[obj.groupNameArbVardering] = acc[obj.groupNameArbVardering] || [];
            acc[obj.groupNameArbVardering].push(obj);
            return acc;
        }, {});

        // Find groups with different `level` values
        Object.values(grouped).forEach(group => {
            const levels = new Set(group.map(item => item.level));
            if (levels.size > 1) {
                groupedByDifferentLevels.push(...group);
            }
        });
        // console.log(groupedByDifferentLevels)
        if (groupedByDifferentLevels.length > 0) { this.setState({ duplicateLevels: groupedByDifferentLevels }); return; }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll); // handles scrolldown for titles
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }

        this.getDataAndComments()
    }


    groupHierarchy = () => {
        function getRandomColor() {
            // Generate a random color in hexadecimal format
            return "#" + ((Math.random() * 0xFFFFFF) << 0).toString(16).padStart(6, '0');
        }
        //1. Create Hierarchy
        const originalArray = this.state.empData
        const groupedData = [];
        originalArray.forEach(item => {
            const { family, mainFunction, subFunction, subFunction2, subFunction3, groupNameArbVardering, positionTitle, color } = item;
            let equalentGroup = item.level
            let familyObj = groupedData.find(entry => entry.name === family);
            if (!familyObj) {
                familyObj = { name: family, mainFunctions: [], color: color !== null ? color : getRandomColor(), edited: color !== null ? "no" : "yes" };
                groupedData.push(familyObj);
            }

            let mainFunctionObj = familyObj.mainFunctions.find(entry => entry.name === mainFunction);
            if (!mainFunctionObj) {
                mainFunctionObj = { name: mainFunction, subFunctions: [] };
                familyObj.mainFunctions.push(mainFunctionObj);
            }

            let subFunctionObj = mainFunctionObj.subFunctions.find(entry => entry.name === subFunction);
            if (!subFunctionObj) {
                subFunctionObj = { name: subFunction, subFunction2s: [] };
                mainFunctionObj.subFunctions.push(subFunctionObj);
            }

            let subFunction2Obj = subFunctionObj.subFunction2s.find(entry => entry.name === subFunction2);
            if (!subFunction2Obj) {
                subFunction2Obj = { name: subFunction2, subFunction3s: [] };
                subFunctionObj.subFunction2s.push(subFunction2Obj);
            }

            let subFunction3Obj = subFunction2Obj.subFunction3s.find(entry => entry.name === subFunction3);
            if (!subFunction3Obj) {
                subFunction3Obj = { name: subFunction3, equalentGroups: [] };
                subFunction2Obj.subFunction3s.push(subFunction3Obj);
            }

            let equalentGroupObj = subFunction3Obj.equalentGroups.find(entry => entry.name === equalentGroup);
            if (!equalentGroupObj) {
                equalentGroupObj = { name: equalentGroup, groupNameArbVarderings: [] };
                subFunction3Obj.equalentGroups.push(equalentGroupObj);
            }

            let groupNameArbVarderingObj = equalentGroupObj.groupNameArbVarderings.find(entry => entry.name === groupNameArbVardering);
            if (!groupNameArbVarderingObj) {
                groupNameArbVarderingObj = { name: groupNameArbVardering, positionTitles: [] };
                equalentGroupObj.groupNameArbVarderings.push(groupNameArbVarderingObj);
            }

            groupNameArbVarderingObj.positionTitles.push(positionTitle);
        });

        //2. Check if there is any functions or subFunctions
        let nrOfFunctions = 0;
        for (const obj of originalArray) {
            if (obj.subFunction3 !== null && obj.subFunction3 !== "") {
                nrOfFunctions = 4;
                break;
            } else if (obj.subFunction2 !== null && obj.subFunction2 !== "") {
                nrOfFunctions = Math.max(nrOfFunctions, 3);
            } else if (obj.subFunction !== null && obj.subFunction !== "") {
                nrOfFunctions = Math.max(nrOfFunctions, 2);
            } else if (obj.mainFunction !== null && obj.mainFunction !== "") {
                nrOfFunctions = Math.max(nrOfFunctions, 1);
            }
        }

        //3. Save color to empData individuals, this is because we need it for the saving
        originalArray.forEach(originalItem => {
            const matchingGroupedItem = groupedData.find(groupedItem => groupedItem.name === originalItem.family);
            if (matchingGroupedItem) {
                originalItem.color = matchingGroupedItem.color;
            }
        });

        // Sort by alphabetic order
        groupedData.sort((a, b) => {
            const nameA = a.name || ""; // Use empty string for null/undefined
            const nameB = b.name || ""; // Use empty string for null/undefined
            return nameA.localeCompare(nameB);
        });

        //4. Save to State
        this.setState({ nrOfSubFamilies: nrOfFunctions, hierarchy: groupedData })
    }

    saveButtonFunc = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        //We can get list items from empData on component mount, this way we do not need to save list items seperately
        const saveData = async () => {
            const filteredArray = this.state.empData.filter(obj => obj.hasOwnProperty("edited") && obj.edited === "Yes"); //Only get employees with edited: "Yes"
            // const salaryRangesCopy = JSON.parse(JSON.stringify(this.state.rangesLevels))
            let salaryRanges = this.state.rangesLevels.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salaryFamily = this.state.family.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salaryMainFunction = this.state.mainFunction.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction = this.state.subFunction.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction2 = this.state.subFunction2.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction3 = this.state.subFunction3.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salarySubfunction4 = this.state.subFunction4.filter(item => item.range !== "hide"); // Filter so that we only have unique values
            let salaryIndividual = this.state.individual.filter(item => item.range !== "hide")
            let families = []
            const propertiesByGroup = {};
            filteredArray.forEach(employee => {
                if (!propertiesByGroup[employee.groupNameArbVardering]) {
                    propertiesByGroup[employee.groupNameArbVardering] = {
                        family: employee.family,
                        mainFunction: employee.mainFunction,
                        subFunction: employee.subFunction,
                        subFunction2: employee.subFunction2,
                        subFunction3: employee.subFunction3,
                        color: employee.color
                    };
                }
            });
            families = Object.entries(propertiesByGroup).map(([groupNameArbVardering, properties]) => ({
                groupNameArbVardering,
                ...properties
            }));

            if (this.state.activateSalRangeSave === "off") { salaryRanges = [] } // If nothing is changed nothing should be saved. Will be "on" if manually changed a salary range or used auto
            if (this.state.activateSalRangeSaveFamily === "off") { salaryFamily = [] }
            if (this.state.activateSalRangeSaveMainFunction === "off") { salaryMainFunction = [] }
            if (this.state.activateSalRangeSaveSubfunction === "off") { salarySubfunction = [] }
            if (this.state.activateSalRangeSaveSubfunction2 === "off") { salarySubfunction2 = [] }
            if (this.state.activateSalRangeSaveSubfunction3 === "off") { salarySubfunction3 = [] }
            if (this.state.activateSalRangeSaveSubfunction4 === "off") { salarySubfunction4 = [] }
            if (this.state.activateSalRangeSaveIndividual === "off") { salaryIndividual = [] }

            // Group all salaryRanges for families or functions togheter (first add key so we know what family or function they belong to)
            const salaryFamilyWithKey = salaryFamily.map(item => ({ ...item, key: 'family' }));
            const salaryMainFunctionWithKey = salaryMainFunction.map(item => ({ ...item, key: 'mainFunction' }));
            const salarySubfunctionWithKey = salarySubfunction.map(item => ({ ...item, key: 'subFunction' }));
            const salarySubfunction2WithKey = salarySubfunction2.map(item => ({ ...item, key: 'subFunction2' }));
            const salarySubfunction3WithKey = salarySubfunction3.map(item => ({ ...item, key: 'subFunction3' }));
            const salarySubfunction4WithKey = salarySubfunction4.map(item => ({ ...item, key: 'subFunction4' }));
            const salaryIndividualWithKey = salaryIndividual.map(item => ({ ...item, key: 'individual' }));
            const salaryIndividualWithKeyFiltered = salaryIndividualWithKey.filter((obj) => obj.edited === "yes")// Remove equal works if they do not have "edited: "yes"
            const subSalaryRanges = salaryFamilyWithKey.concat(salaryMainFunctionWithKey, salarySubfunctionWithKey, salarySubfunction2WithKey, salarySubfunction3WithKey, salarySubfunction4WithKey, salaryIndividualWithKeyFiltered);

            const removeThis = this.state.removeThis
            const arcIds = this.state.arcIds.flatMap(group => group.items);
            let response = await fetchAuth(`/api/saveArchitecture`, 'POST', JSON.stringify({ families: families, salaryRanges: salaryRanges, subSalaryRanges: subSalaryRanges, remove: removeThis, arcIds: arcIds }));
            let data = await response.json();
            if (data == 'Saved') {
                this.setState({ saveButtonStatus: "Sparat" })
            } else { alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave) }
        };
        saveData();
    }

    popUpInfo = () => {
        this.state.popUpInfo ? this.setState({ popUpInfo: false }) : this.setState({ popUpInfo: true })
    }

    //For arkitektur
    showMore = () => { this.state.showMore ? this.setState({ showMore: false }) : this.setState({ showMore: true }) }

    // This is the popUp in architecture for individual works
    editGroup = async (event) => {
        let equalWork = event.currentTarget.id
        // Set activeInfo
        let position = ""
        let family = ""
        let mainFunction = ""
        let subFunction = ""
        let subFunction2 = ""
        let subFunction3 = ""
        for (let i = 0; i < this.state.empData.length; i++) {
            if (this.state.empData[i].groupNameArbVardering === equalWork) {
                position = equalWork
                family = this.state.empData[i].family
                mainFunction = this.state.empData[i].mainFunction
                subFunction = this.state.empData[i].subFunction
                subFunction2 = this.state.empData[i].subFunction2
                subFunction3 = this.state.empData[i].subFunction3
                break;
            }
        }
        let activeInfo = {
            position: position,
            whatFam: family,
            whatMainFunction: mainFunction,
            whatSubfunction: subFunction,
            whatSubfunction2: subFunction2,
            whatSubfunction3: subFunction3,
        }
        await this.setActiveInfo("individual", activeInfo);
    }
    selectFamily = (event, add) => { // This is when changing family and have selected a position, below selectFamilyForFamily() is for when selecting using family or a category
        let selectedFamily = add
        if (event !== "noEvent") {
            selectedFamily = event.target.value
        }
        let color = null
        //First get color if there is any
        for (let i = 0; i < this.state.empData.length; i++) {
            if (this.state.empData[i].family === selectedFamily) {
                color = this.state.empData[i].color
                break;
            }
        }
        //Then update family
        const updatedEmpData = this.state.empData.map((job) => {
            if (job.groupNameArbVardering === this.state.activeInfo.position) {
                return {
                    ...job,
                    family: selectedFamily,
                    /*   mainFunction: null,
                       subFunction: null,
                       subFunction2: null,
                       subFunction3: null,*/
                    color: color,
                    edited: "Yes"
                };
            }
            return job;
        });

        // Also update the activeInfo 
        let activeInfo = { ...this.state.activeInfo } // Creates copy of state so no mutation
        activeInfo.whatFam = selectedFamily
        this.setState({ empData: updatedEmpData, activeInfo: activeInfo, saveButtonStatus: "Spara" }, () => { this.groupHierarchy() });
    }

    selectFamilyForFamily = (event, add) => { // This is when changing family and have selected family or subcategory, above selectFamily() is for when selecting using a position
        let selectedFamily = add
        if (event !== "noEvent") {
            selectedFamily = event.target.value
        }
        let previousFamily = this.state.activeInfo.whatFam // This is the family
        let whatCategory = this.state.activeWhat // family, mainFunction, subFunction, subFunction2, subFunction3
        let parentFamilies = this.state.activeInfo
        let previousSubWhat = whatCategory === "mainFunction" ? "whatMainFunction" : whatCategory === "subFunction" ? "whatSubfunction" : whatCategory === "subFunction2" ? "whatSubfunction2" : whatCategory === "subFunction3" ? "whatSubfunction3" : "whatSubfunction4"
        let previousSub = this.state.activeInfo[previousSubWhat]

        // First get color if there is any
        let color = null
        for (let i = 0; i < this.state.empData.length; i++) {
            if (this.state.empData[i].family === parentFamilies.whatFam) {
                color = this.state.empData[i].color
                break;
            }
        }

        // Then update family for all works and subCategories that has the previous family 
        const updatedEmpData = this.state.empData.map((job) => {

            if (whatCategory === "family") { //If family, just look for different name
                if (job.family === previousFamily) {
                    return {
                        ...job,
                        family: selectedFamily,
                        color: color,
                        edited: "Yes"
                    };
                }
            }
            if (whatCategory === "mainFunction") { //If mainFunction, look for same family as well as the same mainfunciton
                if (job.family === parentFamilies.whatFam && job.mainFunction === previousSub) {
                    selectedFamily === undefined || selectedFamily === "" ? selectedFamily = null : selectedFamily = selectedFamily
                    return {
                        ...job,
                        mainFunction: selectedFamily,
                        color: color,
                        edited: "Yes"
                    };
                }
            }
            if (whatCategory === "subFunction") { //If mainFunction, look for same family as well as the same mainfunciton
                if (job.family === parentFamilies.whatFam && job.mainFunction === parentFamilies.whatMainFunction && job.subFunction === previousSub) {
                    selectedFamily === undefined || selectedFamily === "" ? selectedFamily = null : selectedFamily = selectedFamily
                    return {
                        ...job,
                        subFunction: selectedFamily,
                        color: color,
                        edited: "Yes"
                    };
                }
            }
            if (whatCategory === "subFunction2") { //If mainFunction, look for same family as well as the same mainfunciton
                if (job.family === parentFamilies.whatFam && job.mainFunction === parentFamilies.whatMainFunction && job.subFunction === parentFamilies.whatSubfunction && job.subFunction2 === previousSub) {
                    selectedFamily === undefined || selectedFamily === "" ? selectedFamily = null : selectedFamily = selectedFamily
                    return {
                        ...job,
                        subFunction2: selectedFamily,
                        color: color,
                        edited: "Yes"
                    };
                }
            }
            if (whatCategory === "subFunction3") { //If mainFunction, look for same family as well as the same mainfunciton
                if (job.family === parentFamilies.whatFam && job.mainFunction === parentFamilies.whatMainFunction && job.subFunction === parentFamilies.whatSubfunction && job.subFunction2 === parentFamilies.whatSubfunction2 && job.subFunction3 === previousSub) {
                    selectedFamily === undefined || selectedFamily === "" ? selectedFamily = null : selectedFamily = selectedFamily
                    return {
                        ...job,
                        subFunction3: selectedFamily,
                        color: color,
                        edited: "Yes"
                    };
                }
            }
            return job;
        });

        // Also update the activeInfo 
        let activeInfo = { ...this.state.activeInfo } // Creates copy of state so no mutation
        let whatCat = whatCategory === "family" ? "whatFam" : whatCategory === "mainFunction" ? "whatMainFunction" : whatCategory === "subFunction" ? "whatSubFunction" : whatCategory === "subFunction2" ? "whatSubFunction2" : whatCategory === "subFunction3" ? "whatsubFunction3" : "whatSubFunction4"
        activeInfo[whatCategory] = selectedFamily

        this.setState({ empData: updatedEmpData, activeInfo: activeInfo, saveButtonStatus: "Spara" }, () => { this.groupHierarchy() });
    }


    selectMainFunction = (event, fam, add, whatS) => {
        let family = event === "noEvent" ? fam : event.target.getAttribute('data-family')
        let whatSub = event === "noEvent" ? whatS : event.target.getAttribute('data-whatSub')
        let selectedFunction = event === "noEvent" ? add : event.target.value
        if (selectedFunction === "- Ingen -" || selectedFunction === "Välj jobbfunktion" || selectedFunction === "Välj subfunktion" || selectedFunction === "Välj subfunktion2" || selectedFunction === "Välj jobbfunktion3") { selectedFunction = null }
        let color = null
        //First get color if there is any
        for (let i = 0; i < this.state.empData.length; i++) {
            if (this.state.empData[i].family === family) {
                color = this.state.empData[i].color
                break;
            }
        }
        //Then update family
        const updatedEmpData = this.state.empData.map((job) => {
            if (job.groupNameArbVardering === this.state.activeInfo.position) {
                if (whatSub === "mainFunction") {
                    selectedFunction === "" ? selectedFunction = null : selectedFunction = selectedFunction
                    return {
                        ...job,
                        mainFunction: selectedFunction,
                        /*subFunction: null,
                        subFunction2: null,
                        subFunction3: null,*/
                        color: color,
                        edited: "Yes"
                    };
                } if (whatSub === "subFunction") {
                    selectedFunction === "" ? selectedFunction = null : selectedFunction = selectedFunction
                    return {
                        ...job,
                        subFunction: selectedFunction,
                        /*  subFunction2: null,
                          subFunction3: null,*/
                        color: color,
                        edited: "Yes"
                    };
                } if (whatSub === "subFunction2") {
                    selectedFunction === "" ? selectedFunction = null : selectedFunction = selectedFunction
                    return {
                        ...job,
                        subFunction2: selectedFunction,
                        /*  subFunction3: null,*/
                        color: color,
                        edited: "Yes"
                    };
                } if (whatSub === "subFunction3") {
                    selectedFunction === "" ? selectedFunction = null : selectedFunction = selectedFunction
                    return {
                        ...job,
                        subFunction3: selectedFunction,
                        color: color,
                        edited: "Yes"
                    };
                }
            }
            return job;
        });
        this.setState({ empData: updatedEmpData, saveButtonStatus: "Spara", showMore: false }, () => { this.groupHierarchy() });
    }

    changeFamilyToList = (event) => { this.setState({ addFamilytoList: event.target.value }) }
    changeMainFunction = (event) => { this.setState({ addMainFunction: event.target.value }) }
    changeSubFunction = (event) => { this.setState({ addSubFunction: event.target.value }) }
    changeSubFunction2 = (event) => { this.setState({ addSubFunction2: event.target.value }) }
    changeSubFunction3 = (event) => { this.setState({ addSubFunction3: event.target.value }) }

    createMainFunction = (event) => { // This works with all subFunctions as well, so we do not have many functions that does the same thing
        let whatSub = event.target.getAttribute('data-whatSub')
        let addNew = ""
        let oldList = ""
        if (whatSub === "family") {
            addNew = this.state.addFamilytoList.slice()
            oldList = this.state.familySelectList.slice()
        } if (whatSub === "mainFunction") {
            addNew = this.state.addMainFunction.slice()
            oldList = this.state.mainFunctionsSelectList.slice()
        } if (whatSub === "subFunction") {
            addNew = this.state.addSubFunction.slice()
            oldList = this.state.subFunctionSelectList.slice()
        } if (whatSub === "subFunction2") {
            addNew = this.state.addSubFunction2.slice()
            oldList = this.state.subFunctionSelectList2.slice()
        } if (whatSub === "subFunction3") {
            addNew = this.state.addSubFunction3.slice()
            oldList = this.state.subFunctionSelectList3.slice()
        }

        if (addNew === "") { return } // Cant be an empty string
        if (oldList.some(item => item.trim().toLowerCase() === addNew.trim().toLowerCase())) { alert("Detta namn finns redan."); return } // Checks for duplicates
        // Find the correct position to insert the new string
        const insertIndex = oldList.findIndex((item) => item > addNew);
        // If insertIndex is -1, it means newString should be added at the end
        if (insertIndex === -1) {
            oldList.push(addNew);
        } else {
            // Insert newString at the correct position
            oldList.splice(insertIndex, 0, addNew);
        }
        oldList.sort((a, b) => a.localeCompare(b));    // sort by alphabetic order

        // Buggfix for subFunction 2 and 3 (add "" to the start):
        if (whatSub === "subFunction2" || whatSub === "subFunction3") { oldList.unshift("") }

        // Save to state
        let family = event.target.getAttribute('data-family')

        // let whatSub shows what to change (family, mainFunction etc)
        // let family shows the previous name that we want to change 
        // let new shows the new name

        //if (whatSub === "family") { this.setState({ familySelectList: oldList, addFamilytoList: "" }, () => { this.selectMainFunction("noEvent", family, addNew, whatSub) }); }
        if (whatSub === "family") { this.setState({ familySelectList: oldList, addFamilytoList: "" }, () => { this.state.activeWhat === "individual" ? this.selectFamily("noEvent", addNew) : this.selectFamilyForFamily("noEvent", addNew) }); }
        if (whatSub === "mainFunction") { this.setState({ mainFunctionsSelectList: oldList, addMainFunction: "" }, () => { this.state.activeWhat === "individual" ? this.selectMainFunction("noEvent", family, addNew, whatSub) : this.selectFamilyForFamily("noEvent", addNew) }); }
        if (whatSub === "subFunction") { this.setState({ subFunctionSelectList: oldList, addSubFunction: "" }, () => { this.state.activeWhat === "individual" ? this.selectMainFunction("noEvent", family, addNew, whatSub) : this.selectFamilyForFamily("noEvent", addNew) }); }
        if (whatSub === "subFunction2") { this.setState({ subFunctionSelectList2: oldList, addSubFunction2: "" }, () => { this.state.activeWhat === "individual" ? this.selectMainFunction("noEvent", family, addNew, whatSub) : this.selectFamilyForFamily("noEvent", addNew) }); }
        if (whatSub === "subFunction3") { this.setState({ subFunctionSelectList3: oldList, addSubFunction3: "" }, () => { this.state.activeWhat === "individual" ? this.selectMainFunction("noEvent", family, addNew, whatSub) : this.selectFamilyForFamily("noEvent", addNew) }); }
        this.setState({ showInputNewFam: "hide" })
    }

    exitEditGroupPop = (event) => {
        event.stopPropagation()
        let activeInfo = {
            position: "",
            whatFam: "",
            whatMainFunction: "",
            whatSubfunction: "",
            whatSubfunction2: "",
            whatSubfunction3: "",
        }
        this.setActiveInfo("", activeInfo);
    }

    showJobDesc = (event) => {
        this.setState({ popUpJobDesc: true, jobDescWhatGroup: event.target.dataset.egroup, jobDescSelected: event.target.dataset.egroup })
    }
    closePopUpJobDesc = () => { this.setState({ popUpJobDesc: false, jobDescWhatGroup: "", jobDescSelected: "", editJobDesc: false }) }

    zoomOut = (event) => {
        let zoom = document.documentElement.style.getPropertyValue('--zoom')
        if (zoom.length === 0) {
            document.documentElement.style.setProperty('--zoom', 'scale(0.9)')
        } else {
            let getZoomValue = zoom.substring(6, 9)
            let calculate = (Number(getZoomValue) - 0.1).toFixed(1)
            let string = calculate.toString()
            let addZoom = `scale(${string})`
            document.documentElement.style.setProperty('--zoom', addZoom)
        }
    }
    zoomIn = (event) => {
        let zoom = document.documentElement.style.getPropertyValue('--zoom')

        if (zoom.length === 0) {
            document.documentElement.style.setProperty('--zoom', 'scale(1.1)')
        } else {
            let getZoomValue = zoom.substring(6, 9)
            let calculate = (Number(getZoomValue) + 0.1).toFixed(1)
            let string = calculate.toString()
            let addZoom = `scale(${string})`
            document.documentElement.style.setProperty('--zoom', addZoom)
        }
    }

    openChart = () => { this.state.chartOpen ? this.setState({ chartOpen: false }) : this.setState({ chartOpen: true }) }

    openWhatChart = async (event) => { // This is used to open and show the salary range, not the actual popup for the clicked work/group

        const whatChart = event.target.getAttribute("whatChart"); // individual, overall, mainFunction etc
        const name = event.target.getAttribute("whatName"); // is Null if not added from clicked family or subfunction (if clicked on individual it is null)
        const isTitle = event.target.getAttribute("isTitle") // is "Yes" if we clicked a title in the architecture, otherwise undefined/null

        // First set the info for the salary ranges (not the same as setActiveInfo, instead we use a separate function and state, setActiveSalaryInfo)
        let activeSalaryInfo = ""
        if (!isTitle) { // If not clicked on a title, meaning if we clicked on a group (it is therefore the same as the activegroup info) <--- OBS!!! RULLISTAN ÄR INTE HÄNSYN TAGEN TILL HÄR
            let activeInfo = this.state.activeInfo
            activeSalaryInfo = {
                position: whatChart !== "individual" ? "" : activeInfo.position,
                whatFam: whatChart === "overall" ? "" : activeInfo.whatFam,
                whatMainFunction: whatChart === "overall" ? "" : activeInfo.whatMainFunction,
                whatSubfunction: whatChart === "overall" ? "" : activeInfo.whatSubfunction,
                whatSubfunction2: whatChart === "overall" ? "" : activeInfo.whatSubfunction2,
                whatSubfunction3: whatChart === "overall" ? "" : activeInfo.whatSubfunction3,
            }
        }
        if (isTitle === "Yes") { // If we clicked on a column title, we select the first item in the selected family/function instead. Except for overall, it is run in above if statement "isTitle" is then null) 
            const firstSubCategory = this.state.empData.find(item =>
                item.family && item[whatChart]
            );
            if (firstSubCategory === undefined) { return } // If we have not added any families, nothing should happen if we click on the family title
            activeSalaryInfo = {
                position: "",
                whatFam: firstSubCategory.family,
                whatMainFunction: whatChart === "family" ? "" : firstSubCategory.mainFunction,
                whatSubfunction: whatChart === "family" || whatChart === "mainFunction" ? "" : firstSubCategory.subFunction,
                whatSubfunction2: whatChart === "family" || whatChart === "mainFunction" || whatChart === "subFunction" ? "" : firstSubCategory.subFunction2,
                whatSubfunction3: whatChart === "family" || whatChart === "mainFunction" || whatChart === "subFunction" || whatChart === "subFunction2" ? "" : firstSubCategory.subFunction3,
            }
        }
        // Also create a list of all unique works, families or subFunctions depending on what is selected. This will be used for the scroll list in the chart view
        let uniqueList = [] // For overall it will be empty as no scroll list is shown
        let finalList = [] // Used for individual works

        let whatType =
            whatChart === "individual" ? "groupNameArbVardering" : whatChart
        const seenCombinations = new Set();
        if (whatChart !== "individual") {
            for (const item of this.state.empData) {
                if (item.family && whatType && item[whatType]) { // Ensure family and whatType values are not null.
                    const combo = `${item.family}-${item[whatType]}`;
                    if (!seenCombinations.has(combo)) {
                        seenCombinations.add(combo);
                        uniqueList.push({ family: item.family, name: item[whatType] });
                    }
                }
            }
        }
        else { // If individual work
            let getWorks = this.state.empData.map(obj => obj.groupNameArbVardering)
            finalList = [...new Set(getWorks)];
        }

        let theUniqueList = whatChart !== "individual" ? uniqueList : finalList
        finalList.sort(); // Sort by alphabetic order

        this.setState(
            {
                activeSalaryWhat: whatChart,
                activeSalaryInfo: activeSalaryInfo,
                uniqueListForScrolling: theUniqueList
            },
            () => {
                this.setState(
                    { chartOpen: true }, // Open the chart
                    () => {
                        // Here set the salary ranges array
                        let famOrFuncArray = whatChart === "overall" ? this.state.rangesLevels.slice() : this.state[whatChart].slice() // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
                        let arrayData = []

                        if (whatChart === "individual") {
                            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.position)
                            // For individual/equal works x will always be saved as "0" (because otherwise it could change when user changes valuation), therefore, look up the real x-value from empData
                            arrayDataFiltered.forEach(item => {
                                const matchingEmpItem = this.state.empData.find(empItem => empItem.groupNameArbVardering === item.name);
                                if (matchingEmpItem) {
                                    item.x = matchingEmpItem.level
                                }
                            });

                            arrayData = arrayDataFiltered
                        }
                        if (whatChart === "family") {
                            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatFam)
                            arrayData = arrayDataFiltered
                        }
                        if (whatChart === "mainFunction") {
                            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatMainFunction && obj.familyName === this.state.activeSalaryInfo.whatFam)
                            arrayData = arrayDataFiltered
                        }
                        if (whatChart === "subFunction") {
                            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatSubfunction && obj.familyName === this.state.activeSalaryInfo.whatFam)
                            arrayData = arrayDataFiltered
                        }
                        if (whatChart === "subFunction2") {
                            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatSubfunction2 && obj.familyName === this.state.activeSalaryInfo.whatFam)
                            arrayData = arrayDataFiltered
                        }
                        if (whatChart === "subFunction3") {
                            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatSubfunction3 && obj.familyName === this.state.activeSalaryInfo.whatFam)
                            arrayData = arrayDataFiltered
                        }
                        if (whatChart === "overall") {
                            arrayData = famOrFuncArray
                        }

                        this.setState({ activeFamilyOrFunc: arrayData }, () => {

                            // Run calcSalaryRanges without auto calculation, this creates the salary ranges and data for the chart.
                            this.noAutoSalaryRanges()

                            // Add the comments to the selected work
                            if (this.state.showCommentSalary === true) {
                                this.setState({ showCommentSalary: false }, () => {
                                    this.commentSalary() //Load comments about salary Range
                                })
                            }
                        })
                    })
            })
        return;
    }
    showSalRange = () => { this.state.showSalRange ? this.setState({ showSalRange: false, showComments: false }) : this.setState({ showSalRange: true }) }
    showComments = () => { this.state.showComments ? this.setState({ showComments: false }) : this.setState({ showComments: true, showSalRange: true }) }
    copyOpen = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (!this.state.copyOpen) {
            this.setState({ copyOpen: true })
            return;
        } else {
            if (this.state.noSSYK > 0) {
                const userConfirmed = window.confirm(language === "sv" ? "En eller flera befattningar har ingen statistikkod kopplad. Ni kan lägga till statistikkoder under steg 2.1. Statistikkoderna spelar en stor roll för skapandet av lönespann och jobbfamiljer, ert resultat kommer inte att bli bra utan dessa. Vill du fortsätta ändå? (Rekommenderas ej)" : lang[language].missingSSYK);
                if (userConfirmed) {
                    this.setState({ copyOpen: false })
                    return;
                }
                else { return }
            } else {
                this.setState({ copyOpen: false })
                return;
            }
        }
    } // This is for green "create" button
    whatAIToCreate = (event) => { this.setState({ whatAIToCreate: event.target.value }) }
    runAIArchitecture = () => {
        let whatAIToCreate = this.state.whatAIToCreate
        if (whatAIToCreate === "families") {
            this.groupFamilies();
        } if (whatAIToCreate === "salaryRange") {
            this.salaryRangesSettingsPop();
        } if (whatAIToCreate === "fAndS") {
            this.groupFamilies();
            this.salaryRangesSettingsPop();
        }
    }
    salaryRangesSettingsPop = () => { this.state.salaryRangesOpen ? this.setState({ salaryRangesOpen: false }) : this.setState({ salaryRangesOpen: true }) }
    salaryRangesManualPop = () => {
        if (!this.state.salaryRangesManualOpen) { // If closed, open it
            // First, get all levels that are not already existing
            let activeSalaryWhat = this.state.activeSalaryWhat // // "individual", "mainFunction", "family" etc.
            let notUsedLevels = []
            let whatFam = activeSalaryWhat === "overall" ? "rangesLevels" : "activeFamilyOrFunc" // use this.state.rangesLevels if using "overall", otherwise use this.state.activeFamilyOrFunc
            let uniqueXValues = [...new Set(this.state[whatFam].map(obj => obj.x))];
            let maxXValues = this.state.nrOfEqualentGroups
            // notUsedLevels = Array.from({ length: maxXValues }, (_, i) => i + 1).filter(num => !uniqueXValues.includes(num)); // Use if just want to get all not used numbers instead of creating objects below
            notUsedLevels = Array.from({ length: maxXValues }, (_, i) => {
                const level = i + 1;
                return {
                    level,
                    display: uniqueXValues.includes(level) ? "no" : "yes"
                };
            });
            if (activeSalaryWhat === "individual") { // If individual, it should not be possible to add more levels than the one that already is there. Therefore, make the first one selectable or not (if it exist or not)
                if (uniqueXValues.length > 0) {
                    notUsedLevels = [
                        {
                            level: uniqueXValues[0],
                            display: "no"
                        }
                    ]
                } else {
                    // Get the only x value and make it selectable
                    let position = this.state.activeSalaryInfo.position
                    let firstMatch = this.state.empData.find(item => item.groupNameArbVardering === this.state.activeSalaryInfo.position);
                    const equalentGroupValue = firstMatch ? firstMatch.level : 0;
                    notUsedLevels = [
                        {
                            level: equalentGroupValue,
                            display: "yes"
                        }
                    ]
                }
            }
            this.setState({ nrOfSelectableEqualentGroups: notUsedLevels, salaryRangesManualOpen: true, manualLevel: "notSelected" })
        } else {
            this.setState({ salaryRangesManualOpen: false })
        }
    }
    closeManualOpen = () => { this.setState({ salaryRangesManualOpen: false }) }
    manualLevel = (event) => { this.setState({ manualLevel: event.target.value }) }
    addManualLevel = () => {
        let activeSalaryWhat = this.state.activeSalaryWhat // // "individual", "mainFunction", "family" etc.
        let level = this.state.manualLevel
        if (level === "notSelected") {
            alert("Ingen nivå vald")
            return;
        }
        let whatFam = this.state.activeSalaryWhat !== "overall" ? "activeFamilyOrFunc" : "rangesLevels"
        let name = this.state.activeSalaryWhat === "overall" ? "overall" : this.state.activeSalaryWhat === "individual" ? this.state.activeSalaryInfo.position : this.state.activeSalaryWhat === "family" ? this.state.activeSalaryInfo.whatFam : this.state.activeSalaryWhat === "mainFunction" ? this.state.activeSalaryInfo.whatMainFunction : this.state.activeSalaryWhat === "subFunction" ? this.state.activeSalaryInfo.whatSubfunction : this.state.activeSalaryWhat === "subFunction2" ? this.state.activeSalaryInfo.whatSubfunction2 : this.state.activeSalaryWhat === "subFunction3" ? this.state.activeSalaryInfo.whatSubfunction3 : this.state.activeSalaryInfo.whatSubfunction4
        let dataNow = JSON.parse(JSON.stringify(this.state.activeSalaryWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels));

        // create object and add it to the state
        let newLevel = []
        let extraLevel = []
        newLevel = {
            familyOrFunction: activeSalaryWhat,
            fillColor: "#e0e0e0",
            name: name,
            familyName: this.state.activeSalaryWhat === "overall" ? "overall" : this.state.activeSalaryInfo.whatFam,
            range: [0, 0, 0],
            spread: 0,
            x: Number(level),
            y: 0,
        }
        extraLevel = {
            familyOrFunction: activeSalaryWhat,
            fillColor: "#ffffff",
            name: name,
            familyName: this.state.activeSalaryWhat === "overall" ? "overall" : this.state.activeSalaryInfo.whatFam,
            range: "hide",
            spread: 0,
            x: Number(level),
            y: 0
        }
        dataNow.push(newLevel)
        dataNow.push(extraLevel)

        this.setState({ [whatFam]: dataNow, salaryRangesManualOpen: false })
    }

    selectStandardDeviation = (event) => { this.setState({ standardDeviation: event.target.value }) }
    selectMarketPosition = (event) => { this.setState({ marketPosition: event.target.value }) }
    selectMarketSector = (event) => { this.setState({ marketSector: event.target.value }) }
    selectCreateProgression = (event) => { this.setState({ createProgression: event.target.value }) }
    sliderValue = (event) => {
        let value = parseInt(event.target.value);
        let snappedValue;
        if (value <= 12.5) {
            snappedValue = 0;
        } else if (value <= 37.5) {
            snappedValue = 25;
        } else if (value <= 62.5) {
            snappedValue = 50;
        } else if (value <= 87.5) {
            snappedValue = 75;
        } else {
            snappedValue = 100;
        }
        this.setState({ snappedValue: event.target.value, marketWeight: snappedValue });
    }
    handleSliderRelease = () => { let marketWeight = this.state.marketWeight; this.setState({ snappedValue: marketWeight }) } // Used to snap the range to marketWeight on mouse release
    toggleFullscreen = () => {
        this.state.fullScreen ? this.setState({ fullScreen: false }) : this.setState({ fullScreen: true })
        this.state.fullScreen ? window.scrollTo(0, 470) : window.scrollTo(0, 0)
    }
    toggleTitles = () => { this.state.toggleTitles ? this.setState({ toggleTitles: false }) : this.setState({ toggleTitles: true }) }

    groupFamilies = () => {
        let color = null
        // Create uniqueWorkList with ssyk and associated family
        let uniqueWorkList = [...new Set(this.state.empData.map(emp => emp.groupNameArbVardering))]
            .map(groupNameArbVardering => {
                const emp = this.state.empData.find(emp => emp.groupNameArbVardering === groupNameArbVardering);
                const ssyk = emp.ssyk;
                let familyData = families.find(f => f.ssyk === ssyk); // Find associated data
                if (familyData === undefined) { familyData = { family: "Okategoriserad", cat2: "", cat3: "" } }
                // Before return, make sure some works get another category than their SSYK code. 
                if (groupNameArbVardering.toLowerCase().includes("hr")) { familyData = { family: "HR", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("produktion")) { familyData = { family: "Tillverkning & Produktion", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("lager") || groupNameArbVardering.toLowerCase().includes("logistik")) { familyData = { family: "Supply Chain", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("montör")) { familyData = { family: "Tillverkning & Produktion", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("demontör") || groupNameArbVardering.toLowerCase().includes("demonter") || groupNameArbVardering.toLowerCase().includes("delmontering") || groupNameArbVardering.toLowerCase().includes("mekaniker") || groupNameArbVardering.toLowerCase().includes("reparatör") || groupNameArbVardering.toLowerCase().includes("demontering")) { familyData = { family: "Mekanik", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("säljare") || groupNameArbVardering.toLowerCase().includes("sälj")) { familyData = { family: "Sälj", cat2: "", cat3: "" } }
                if (groupNameArbVardering.toLowerCase().includes("affärsutvecklare") || groupNameArbVardering.toLowerCase().includes("business developer")) { familyData = { family: "Sälj", cat2: "", cat3: "" } }
                if (groupNameArbVardering.toLowerCase().includes("kma") || groupNameArbVardering.toLowerCase().includes("hållbarhet") || groupNameArbVardering.toLowerCase().includes("miljö")) { familyData = { family: "Miljö", cat2: "", cat3: "" } }
                if (groupNameArbVardering.toLowerCase().includes("ekonomi")) { familyData = { family: "Ekonomi", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("skadehanter")) { familyData = { family: "Juridik", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("affärsområdeschef")) { familyData = { family: "Företagsledning", cat2: "", cat3: "" } } // because HR assistent is under "lon"
                if (groupNameArbVardering.toLowerCase().includes("verksamhetsutveckl") || groupNameArbVardering.toLowerCase().includes("affärsutveckl") || groupNameArbVardering.toLowerCase().includes("strategi") || groupNameArbVardering.toLowerCase().includes("organisationsutveckl")) { familyData = { family: "Företagsledning", cat2: "", cat3: "" } }

                return {
                    groupNameArbVardering,
                    ssyk,
                    family: familyData.family,
                    cat2: familyData.cat2,
                    cat3: familyData.cat3
                };
            });

        // Now loop all works
        let updatedEmployeeData = this.state.empData.slice(); // Copying the array

        // Now loop through all works
        for (let i = 0; i < uniqueWorkList.length; i++) {
            let theJob = uniqueWorkList[i].groupNameArbVardering;
            let theFamily = uniqueWorkList[i].family;
            let cat2 = uniqueWorkList[i].cat2;
            let cat3 = uniqueWorkList[i].cat3;
            // Update the data for each employee with the matching work
            updatedEmployeeData = updatedEmployeeData.map((job) => {
                if (job.groupNameArbVardering === theJob) {
                    return {
                        ...job,
                        family: theFamily,
                        mainFunction: cat2,
                        subFunction: cat3,
                        color: color,
                        edited: "Yes"
                    };
                }
                return job;
            });
        }

        // Update state
        this.setState({ empData: updatedEmployeeData, saveButtonStatus: "Spara" }, () => { this.groupHierarchy() });
    }

    handleTrashClick = (level) => {
        // Get the object that should be removed using the level
        let whatState = this.state.activeSalaryWhat === "overall" ? "rangesLevels" : "activeFamilyOrFunc"
        let theObject = this.state[whatState].find(obj => obj.x === level);

        // If there is no "familyOrFunction" object, add it (it is if we loaded it from database, but not if it was created in the same session)
        if (theObject.familyOrFunction === undefined) {
            theObject.familyOrFunction = this.state.activeSalaryWhat
        }

        // Then add the object to remove array
        let copyState = JSON.parse(JSON.stringify(this.state.removeThis))
        copyState.push(theObject)

        // Remove the object from the current array
        let copyStateTwo = JSON.parse(JSON.stringify(this.state[whatState]))
        let updated = this.state[whatState].filter(obj => obj.x !== level);

        // Remove not only from activeFamilyOr func but also from its own array
        let selectedType = this.state.activeSalaryWhat
        if (selectedType === "overall") { selectedType = "rangesLevels" }
        let copyStateSelectedType = this.state[selectedType].slice()
        let updatedSelectedType = copyStateSelectedType.filter(obj => obj.x !== level || obj.familyName !== theObject.familyName || obj.name !== theObject.name);

        // Update state and save button
        this.setState({ removeThis: copyState, [whatState]: updated, [selectedType]: updatedSelectedType, saveButtonStatus: "Spara" })
    }
    showInputNewFam = (event) => { this.setState({ showInputNewFam: event.target.getAttribute('whatfam') }) }
    showInputNewFamHide = () => { this.setState({ showInputNewFam: "hide" }) }

    manualSalaryRangesUpdate = (data) => {
        // We retrieve the change from the child component, now update state, result looks like this: {value: '25000', level: '1', typeOfSal: 'max', familyOrSub: 'no'}
        let dataNow = JSON.parse(JSON.stringify(this.state.activeSalaryWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels));
        let selectedName = this.state.activeSalaryWhat === "overall" ? "overall" : this.state.activeSalaryWhat === "individual" ? this.state.activeSalaryInfo.position : this.state.activeSalaryWhat === "family" ? this.state.activeSalaryInfo.whatFam : this.state.activeSalaryWhat === "mainFunction" ? this.state.activeSalaryInfo.whatMainFunction : this.state.activeSalaryWhat === "subFunction" ? this.state.activeSalaryInfo.whatSubfunction : this.state.activeSalaryWhat === "subFunction2" ? this.state.activeSalaryInfo.whatSubfunction2 : this.state.activeSalaryWhat === "subFunction3" ? this.state.activeSalaryInfo.whatSubfunction3 : this.state.activeSalaryInfo.whatSubfunction4

        if (this.state.activeSalaryWhat === "overall") { // If for overall levels
            for (let i = 0; i < dataNow.length; i++) {
                if (dataNow[i].x == data.level && dataNow[i].range !== "hide") {
                    let whatSal = data.typeOfSal === "max" ? 2 : data.typeOfSal === "mid" ? 1 : 0
                    dataNow[i].range[whatSal] = Number(data.value)
                    if (data.typeOfSal === "max") { dataNow[i].y = Number(data.value) }
                    // also recalculate and update mid value if min or max is set
                    if (data.typeOfSal === "max" || data.typeOfSal === "min") {
                        let min = dataNow[i].range[0]
                        let max = dataNow[i].range[2]
                        let mid = (min + max) / 2
                        dataNow[i].range[1] = mid
                    }
                }
                if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min") {

                    dataNow[i].y = Number(data.value)
                    break; // We can do this because "hide" object will always be after original
                }
            }

            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            this.setState({ triggerChartUpdate: triggerChartUpdate, rangesLevels: dataNow, saveButtonStatus: "Spara" })
        }

        if (this.state.activeSalaryWhat !== "overall" && this.state.activeSalaryWhat !== "individual") { // If for family or function 
            for (let i = 0; i < dataNow.length; i++) {
                if (dataNow[i].x == data.level && dataNow[i].range !== "hide" && dataNow[i].name === selectedName && dataNow[i].familyName === this.state.activeSalaryInfo.whatFam) {
                    if (dataNow[i].x == data.level && dataNow[i].range !== "hide") {
                        let whatSal = data.typeOfSal === "max" ? 2 : data.typeOfSal === "mid" ? 1 : 0
                        dataNow[i].range[whatSal] = Number(data.value)
                        if (data.typeOfSal === "max") { dataNow[i].y = Number(data.value) }
                        // also recalculate and update mid value if min or max is set
                        if (data.typeOfSal === "max" || data.typeOfSal === "min") {
                            let min = dataNow[i].range[0]
                            let max = dataNow[i].range[2]
                            let mid = (min + max) / 2
                            dataNow[i].range[1] = mid
                        }
                    }
                }
                //if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min") {
                if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min") {
                    dataNow[i].y = Number(data.value)
                    break; // We can do this because "hide" object will always be after original
                }
            }
            // Then update original state
            // if x and name is same, update it. If x is not in the old array, add it
            let whatChart = this.state.activeSalaryWhat
            let savingArray = this.state[whatChart] // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
            let activeArray = dataNow // This is the activeArray that we will now save into its original array (savingArray) before we replace it with the new, activeArray

            // if x and name is same, update it. If x is not in the old array, add it
            const updatedArray = savingArray.map(savingItem => {         // Create a new array combining saving and updated objects
                const activeItem = activeArray.find(activeItem => activeItem.x === savingItem.x && activeItem.name === savingItem.name && activeItem.familyName === savingItem.familyName);
                return activeItem ? { ...savingItem, ...activeItem } : savingItem;
            });
            activeArray.forEach(activeItem => {         // Add new items from activeArray that do not exist in savingArray
                const exists = savingArray.some(savingItem => savingItem.x === activeItem.x && savingItem.name === activeItem.name && savingItem.familyName === activeItem.familyName);
                if (!exists) {
                    updatedArray.push(activeItem);
                }
            });

            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            this.setState({ activeFamilyOrFunc: dataNow, [whatChart]: updatedArray, saveButtonStatus: "Spara", triggerChartUpdate: triggerChartUpdate })
        }

        if (this.state.activeSalaryWhat === "individual") { // If for equal work
            for (let i = 0; i < dataNow.length; i++) {
                if (dataNow[i].x == data.level && dataNow[i].range !== "hide" && dataNow[i].name === this.state.activeSalaryInfo.position) { // If same level and equal work, and range is not "hide"
                    dataNow[i].edited = "yes"
                    if (dataNow[i].x == data.level && dataNow[i].range !== "hide") {
                        let whatSal = data.typeOfSal === "max" ? 2 : data.typeOfSal === "mid" ? 1 : 0
                        dataNow[i].range[whatSal] = Number(data.value)
                        if (data.typeOfSal === "max") { dataNow[i].y = Number(data.value) }
                        // also recalculate and update mid value if min or max is set
                        if (data.typeOfSal === "max" || data.typeOfSal === "min") {
                            let min = dataNow[i].range[0]
                            let max = dataNow[i].range[2]
                            let mid = (min + max) / 2
                            dataNow[i].range[1] = mid
                        }
                    }
                }
                if (dataNow[i].x == data.level && dataNow[i].range === "hide" && data.typeOfSal === "min" && dataNow[i].name === this.state.activeSalaryInfo.position) {
                    dataNow[i].y = Number(data.value)
                    break; // We can do this because "hide" object will always be after original
                }
            }
            // Then update original state
            // if x and name is same, update it. If x is not in the old array, add it
            let whatChart = this.state.activeSalaryWhat
            let savingArray = this.state[whatChart].slice() // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
            let activeArray = dataNow // This is the activeArray that we will now save into its original array (savingArray) before we replace it with the new, activeArray
            // if x and name is same, update it. If x is not in the old array, add it

            const name = activeArray[0].name // "Lön"
            const familyOrFunction = activeArray[0].familyOrFunction // "overall", "individual", "mainFunction", "family", "subFunction", "subFunction2", "subFunction3"
            const familyName = activeArray[0].familyName // "HR"
            const fillColor = activeArray[0].fillColor // "#333333"
            const value = Number(data.value) // 4000
            const level = data.level // 3
            const typeOfSal = data.typeOfSal // "min", "mid", "max"

            let found = false
            for (let i = 0; i < savingArray.length; i++) {
                // If we have an object, update it
                if (savingArray[i].name === name && savingArray[i].x === level && savingArray[i].range !== "hide") { // Change the range value for the real object, OBS!!! WE ALSO NEED TO RECALCULATE THE SPREAD and set the Y value!!
                    savingArray[i].edited = "yes" // So that is is saved in the DB
                    let whatRangeChange = typeOfSal === "min" ? 0 : typeOfSal === "mid" ? 1 : 2
                    savingArray[i].range[whatRangeChange] = value
                    if (typeOfSal === "max") { savingArray[i].y = value } // If we changed the highest value we should also update the y value
                    if (typeOfSal !== "mid") { // Recalculate the spread and mid value, not needed if we just changing the mid
                        let min = typeOfSal === "min" ? value : savingArray[i].range[0]
                        let max = typeOfSal === "max" ? value : savingArray[i].range[2]
                        savingArray[i].range[1] = Math.round((min + max) / 2) // Update mid value
                        let difference = max - min
                        let spread = Math.round((difference / min) * 100)
                        savingArray[i].spread = spread
                    }
                    found = true
                }
                if (savingArray[i].name === name && savingArray[i].x === level && savingArray[i].range === "hide" && typeOfSal === "min") { // Change the Y value for the empty object, only if we changed the "min"
                    savingArray[i].y = value
                    found = true
                }
            }
            // If we do not already have an object, create two new objects (one "empty") into savingArray
            if (found === false) {
                savingArray.push(
                    {
                        edited: "yes",
                        familyOrFunction: familyOrFunction,
                        fillColor: fillColor,
                        name: name,
                        familyName: familyName,
                        range: typeOfSal === "min" ? [value, 0, 0] : typeOfSal === "min" ? [0, value, 0] : [0, 0, value],
                        spread: 0, // <-- CHANGE !!!
                        x: level,
                        y: typeOfSal === "max" ? value : 0 // The highest for the "real" object, the lowest for the "empty" one
                    },
                    {
                        familyOrFunction: familyOrFunction,
                        fillColor: "#ffffff",
                        name: name,
                        familyName: familyName,
                        range: "hide",
                        spread: 0,
                        x: level,
                        y: typeOfSal === "min" ? value : 0 // The highest for the "real" object, the lowest for the "empty" one
                    }
                )
            }

            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            this.setState({ activeFamilyOrFunc: dataNow, [whatChart]: savingArray, saveButtonStatus: "Spara", triggerChartUpdate: triggerChartUpdate })
        }

        // Activate save for the selected type.
        if (this.state.activeSalaryWhat === "overall") { this.setState({ activateSalRangeSave: "on" }) }
        if (this.state.activeSalaryWhat === "family") { this.setState({ activateSalRangeSaveFamily: "on" }) }
        if (this.state.activeSalaryWhat === "mainFunction") { this.setState({ activateSalRangeSaveMainFunction: "on" }) }
        if (this.state.activeSalaryWhat === "subFunction") { this.setState({ activateSalRangeSaveSubfunction: "on" }) }
        if (this.state.activeSalaryWhat === "subFunction2") { this.setState({ activateSalRangeSaveSubfunction2: "on" }) }
        if (this.state.activeSalaryWhat === "subFunction3") { this.setState({ activateSalRangeSaveSubfunction3: "on" }) }
        if (this.state.activeSalaryWhat === "subFunction4") { this.setState({ activateSalRangeSaveSubfunction4: "on" }) }
        if (this.state.activeSalaryWhat === "individual") { this.setState({ activateSalRangeSaveIndividual: "on" }) }
    }

    hideNoGrade = () => { this.setState({ hideNoGrade: true }) }
    closeAutoCalc = () => { this.setState({ salaryRangesOpen: false }) }

    autoCalcSalaryRanges = () => {
        this.setState({ autoCalculation: 'yes' }, () => {
            this.calcSalaryRanges();
        });
    }

    noAutoSalaryRanges = () => {
        this.setState({ autoCalculation: 'no' }, () => {
            this.calcSalaryRanges();
        });
    }

    selectFamilyForRanges = async (event) => {
        let whatChart = this.state.activeSalaryWhat
        if (this.state.activeSalaryWhat === "family") { // looks like this: "El"
            let nameCombo = JSON.parse(event.target.value)
            let activeSalaryInfo = {
                position: "",
                whatFam: nameCombo.name,
                whatMainFunction: "",
                whatSubfunction: "",
                whatSubfunction2: "",
                whatSubfunction3: "",
                whatSubfunction4: "",
            }
            await this.setActiveSalaryInfo(whatChart, activeSalaryInfo);
        }
        else if (this.state.activeSalaryWhat === "individual") {
            let nameCombo = event.target.value // looks like this: {family: 'El', name: 'El'}
            // Find the rest of the data from the first employee that we find that has the family and the name
            let restInfo = this.state.empData.find(item => item.groupNameArbVardering === nameCombo); // Its just the position (equal work) we are looking for for individual, not the family because the position is always unique
            let activeSalaryInfo = {
                position: nameCombo,
                whatFam: restInfo.family,
                whatMainFunction: restInfo.mainFunction,
                whatSubfunction: restInfo.subFunction,
                whatSubfunction2: restInfo.subFunction2,
                whatSubfunction3: restInfo.subFunction3,
                whatSubfunction4: ""
            }
            await this.setActiveSalaryInfo(whatChart, activeSalaryInfo);
        }
        else {
            let nameCombo = JSON.parse(event.target.value) // looks like this: {family: 'El', name: 'El'}
            // Find the rest of the data from the first employee that we find that has the family and the name
            const restInfo = this.state.empData.find(item => item[whatChart] === nameCombo.name && item.family === nameCombo.family);

            let activeSalaryInfo = {
                position: "",
                whatFam: nameCombo.family,
                whatMainFunction: restInfo.mainFunction,
                whatSubfunction: whatChart === "mainFunction" ? "" : restInfo.subFunction,
                whatSubfunction2: (whatChart === "mainFunction" || whatChart === "subFunction") ? "" : restInfo.subFunction2,
                whatSubfunction3: (whatChart === "mainFunction" || whatChart === "subFunction" || whatChart === "subFunction2") ? "" : restInfo.subFunction3,
                whatSubfunction4: ""
            }
            await this.setActiveSalaryInfo(whatChart, activeSalaryInfo);
        }

        // Then update the data for the ranges
        let famOrFuncArray = whatChart === "overall" ? this.state.rangesLevels.slice() : this.state[whatChart].slice() // This is the array for all families or subFamilies, subFamilies2 etc depending on what is selected
        let arrayData = []

        if (whatChart === "individual") {
            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.position)
            // For individual/equal works x will always be saved as "0" (because otherwise it could change when user changes valuation), therefore, look up the real x-value from empData
            arrayDataFiltered.forEach(item => {
                const matchingEmpItem = this.state.empData.find(empItem => empItem.groupNameArbVardering === item.name);
                if (matchingEmpItem) {
                    item.x = matchingEmpItem.level
                }
            });
            arrayData = arrayDataFiltered
        }
        if (whatChart === "family") {
            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatFam)
            arrayData = arrayDataFiltered
        }
        if (whatChart === "mainFunction") {
            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatMainFunction && obj.familyName === this.state.activeSalaryInfo.whatFam)
            arrayData = arrayDataFiltered
        }
        if (whatChart === "subFunction") {
            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatSubfunction && obj.familyName === this.state.activeSalaryInfo.whatFam)
            arrayData = arrayDataFiltered
        }
        if (whatChart === "subFunction2") {
            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatSubfunction2 && obj.familyName === this.state.activeSalaryInfo.whatFam)
            arrayData = arrayDataFiltered
        }
        if (whatChart === "subFunction3") {
            let arrayDataFiltered = famOrFuncArray.filter((obj) => obj.name === this.state.activeSalaryInfo.whatSubfunction3 && obj.familyName === this.state.activeSalaryInfo.whatFam)
            arrayData = arrayDataFiltered
        }

        this.setState({ activeFamilyOrFunc: arrayData }, () => {

            // Run calcSalaryRanges without auto calculation, this creates the salary ranges and data for the chart.
            this.noAutoSalaryRanges()

            // Add the comments to the selected work
            if (this.state.showCommentSalary === true) {
                this.setState({ showCommentSalary: false }, () => {
                    this.commentSalary() //Load comments about salary Range
                })
            }
        })

        return
    }

    maxSpread = (event) => { this.setState({ maxSpread: event.target.value }) }
    minSpread = (event) => { this.setState({ minSpread: event.target.value }) }
    minimumSalaryMinHigher = (event) => { this.setState({ minimumSalaryMinHigher: event.target.value }) }
    toggleAdvancedSettings = () => { this.state.toggleAdvancedSettings ? this.setState({ toggleAdvancedSettings: false }) : this.setState({ toggleAdvancedSettings: true }) }

    calcSalaryRanges = async (calledOnLoad) => { // calledOnLoad is true if it was called in componentDidMount. Used to inactivate save if no changes has been made
        // 1. Get all employees and add Y as salary excluding benefits and X as the equalentGroup or extEqualentGroup.
        const empData = this.state.empData.slice()
        /* let employees = empData.map(obj => ({
             ...obj, // Spread the original object (get all properties)
             y: obj.salary,
             x: obj.level
         }));*/
        let employees = empData
            .filter(obj => obj.level !== 0) // Remove objects where level is null/0
            .map(obj => ({
                ...obj, // Spread the original object (get all properties)
                y: obj.salary,
                x: obj.level
            }));

        let selectedName = this.state.activeSalaryWhat === "overall" ? "overall" : this.state.activeSalaryWhat === "individual" ? this.state.activeSalaryInfo.position : this.state.activeSalaryWhat === "family" ? this.state.activeSalaryInfo.whatFam : this.state.activeSalaryWhat === "mainFunction" ? this.state.activeSalaryInfo.whatMainFunction : this.state.activeSalaryWhat === "subFunction" ? this.state.activeSalaryInfo.whatSubfunction : this.state.activeSalaryWhat === "subFunction2" ? this.state.activeSalaryInfo.whatSubfunction2 : this.state.activeSalaryWhat === "subFunction3" ? this.state.activeSalaryInfo.whatSubfunction3 : this.state.activeSalaryInfo.whatSubfunction4
        // Use family or function if that was selected (for the salary range, not necessary the same as the popup work/group)
        if (this.state.activeSalaryWhat !== "overall" && this.state.activeSalaryWhat !== "individual") {
            const activeSalaryWhat = this.state.activeSalaryWhat //mainFunction, subFunction, subFunction2, subFunction3

            if (activeSalaryWhat === "family") { // Family is always unique
                const filteredEmployees = employees.filter((obj) => obj[activeSalaryWhat] === selectedName)
                employees = filteredEmployees // We now only do calculations for the employees in this function or family
            }
            else { // subcatecories may not be unique, so we need to group by subcategory AND family
                const filteredEmployees = employees.filter((obj) => obj[activeSalaryWhat] === selectedName && obj.family === this.state.activeSalaryInfo.whatFam)
                employees = filteredEmployees // We now only do calculations for the employees in this function or family
            }
        }
        // Use individual if that was selected
        if (this.state.activeSalaryWhat === "individual") {
            let position = this.state.activeSalaryInfo.position
            const filteredEmployees = employees.filter((obj) => obj.groupNameArbVardering === position)
            let addNameAndEdited = filteredEmployees.map(item => ({ ...item, name: position, edited: "yes" }));
            employees = addNameAndEdited // We now only do calculations for the employees with the same equal group
        }

        // 2. Get market salaries (P10, P25, P50, P75, P90)

        // 2.1) First, get all ssyk codes for the jobs. We have this inside the employees variable
        // Group ssyk codes by equalentGroup and remove duplicates
        const ssykByEqualentGroup = employees.reduce((acc, employee) => {
            if (!acc[employee.level]) {  // If equalentGroup doesn't exist in the accumulator, create it with an empty Set
                acc[employee.level] = new Set();
            }
            acc[employee.level].add(employee.ssyk);   // Add ssyk code to the corresponding equalentGroup set
            return acc;
        }, {});
        const ssykArray = Object.entries(ssykByEqualentGroup).map(([level, ssykSet]) => ({ // Convert the grouped object into an array of objects with removed duplicates
            level: parseInt(level),
            ssykCodes: Array.from(ssykSet)
        }));
        // 2.2) Now get the market salaries for each ssyk code inside the array. This can be found in this.state.marketSalaries
        const marketData = this.state.marketSalaries
        ssykArray.forEach(item => {
            let totalMedian = 0;
            let totalP10 = 0;
            let totalP25 = 0;
            let totalP75 = 0;
            let totalP90 = 0;
            let totalP10Privat = 0;
            let totalP25Privat = 0;
            let totalP75Privat = 0;
            let totalP90Privat = 0;
            let totalP10Offentlig = 0;
            let totalP25Offentlig = 0;
            let totalP75Offentlig = 0;
            let totalP90Offentlig = 0;
            let totalMedianPrivat = 0;
            let totalMedianOffentlig = 0;

            let count = 0;
            item.ssykCodes.forEach(ssykCode => {
                const marketItem = marketData.find(data => data.ssyk === ssykCode); // Find the corresponding market data for the ssyk code
                if (marketItem) {  // If market data is found, add its marketSalary to totalMedian
                    totalMedian += marketItem.median;
                    totalP10 += marketItem.percentile10;
                    totalP25 += marketItem.percentile25;
                    totalP75 += marketItem.percentile75;
                    totalP90 += marketItem.percentile90;
                    totalP10Privat += marketItem.percentile10Privat;
                    totalP25Privat += marketItem.percentile25Privat;
                    totalP75Privat += marketItem.percentile75Privat;
                    totalP90Privat += marketItem.percentile90Privat;
                    totalP10Offentlig += marketItem.percentile10Offentlig;
                    totalP25Offentlig += marketItem.percentile25Offentlig;
                    totalP75Offentlig += marketItem.percentile75Offentlig;
                    totalP90Offentlig += marketItem.percentile90Offentlig;
                    totalMedianPrivat += marketItem.medianPrivat;
                    totalMedianOffentlig += marketItem.medianOffentlig;
                    count++;
                }
            });
            // Calculate the average market salary
            const averageMedian = count > 0 ? totalMedian / count : 0;
            const averageP10 = count > 0 ? totalP10 / count : 0;
            const averageP25 = count > 0 ? totalP25 / count : 0;
            const averageP75 = count > 0 ? totalP75 / count : 0;
            const averageP90 = count > 0 ? totalP90 / count : 0;
            const averageP10Privat = count > 0 ? totalP10Privat / count : 0;
            const averageP25Privat = count > 0 ? totalP25Privat / count : 0;
            const averageP75Privat = count > 0 ? totalP75Privat / count : 0;
            const averageP90Privat = count > 0 ? totalP90Privat / count : 0;
            const averageP10Offentlig = count > 0 ? totalP10Offentlig / count : 0;
            const averageP25Offentlig = count > 0 ? totalP25Offentlig / count : 0;
            const averageP75Offentlig = count > 0 ? totalP75Offentlig / count : 0;
            const averageP90Offentlig = count > 0 ? totalP90Offentlig / count : 0;
            const averageMedianPrivat = count > 0 ? totalMedianPrivat / count : 0;
            const averageMedianOffentlig = count > 0 ? totalMedianOffentlig / count : 0;
            // Add the average for the market salaries to the item in ssykArray
            item.median = averageMedian;
            item.averageP10 = averageP10;
            item.averageP25 = averageP25;
            item.averageP75 = averageP75;
            item.averageP90 = averageP90;
            item.averageP10Privat = averageP10Privat;
            item.averageP25Privat = averageP25Privat;
            item.averageP75Privat = averageP75Privat;
            item.averageP90Privat = averageP90Privat;
            item.averageP10Offentlig = averageP10Offentlig;
            item.averageP25Offentlig = averageP25Offentlig;
            item.averageP75Offentlig = averageP75Offentlig;
            item.averageP90Offentlig = averageP90Offentlig;
            item.averageMedianPrivat = averageMedianPrivat;
            item.averageMedianOffentlig = averageMedianOffentlig;
        });

        // 2.3 To make the data fit the chart, now create a seperate array for each percentile and add a "p" property with a "p" value. Like this:
        let p10 = []
        let p25 = []
        let median = []
        let p75 = []
        let p90 = []
        for (let i = 0; i < ssykArray.length; i++) {
            const per10 = {
                y: this.state.marketSector === "all" ? ssykArray[i].averageP10 : this.state.marketSector === "privat" ? ssykArray[i].averageP10Privat : ssykArray[i].averageP10Offentlig,
                x: ssykArray[i].level,
                p: "p"
            };
            const per25 = {
                y: this.state.marketSector === "all" ? ssykArray[i].averageP25 : this.state.marketSector === "privat" ? ssykArray[i].averageP25Privat : ssykArray[i].averageP25Offentlig,
                x: ssykArray[i].level,
                p: "p"
            };
            const per50 = {
                y: this.state.marketSector === "all" ? ssykArray[i].median : this.state.marketSector === "privat" ? ssykArray[i].averageMedianPrivat : ssykArray[i].averageMedianOffentlig,
                x: ssykArray[i].level,
                p: "p"
            };
            const per75 = {
                y: this.state.marketSector === "all" ? ssykArray[i].averageP75 : this.state.marketSector === "privat" ? ssykArray[i].averageP75Privat : ssykArray[i].averageP75Offentlig,
                x: ssykArray[i].level,
                p: "p"
            };
            const per90 = {
                y: this.state.marketSector === "all" ? ssykArray[i].averageP90 : this.state.marketSector === "privat" ? ssykArray[i].averageP90Privat : ssykArray[i].averageP90Offentlig,
                x: ssykArray[i].level,
                p: "p"
            };
            p10.push(per10);
            p25.push(per25);
            median.push(per50);
            p75.push(per75);
            p90.push(per90);
        }
        // 3. Create the salary ranges (floating boxes) based on market (as we got above, and internal (as is calculated below) 
        if (this.state.autoCalculation === "yes") {

            // 1. Prepare market data to the correct format
            let whatMarket = this.state.marketSector // "all", "privat", "offentlig"
            const marketData = ssykArray.map(item => {
                return {
                    [`level${item.level}`]: {
                        p10: whatMarket === "all" ? item.averageP10 : whatMarket === "privat" ? item.averageP10Privat : item.averageP10Offentlig,
                        p25: whatMarket === "all" ? item.averageP25 : whatMarket === "privat" ? item.averageP25Privat : item.averageP25Offentlig,
                        p50: whatMarket === "all" ? item.median : whatMarket === "privat" ? item.averageMedianPrivat : item.averageMedianOffentlig,
                        p75: whatMarket === "all" ? item.averageP75 : whatMarket === "privat" ? item.averageP75Privat : item.averageP75Offentlig,
                        p90: whatMarket === "all" ? item.averageP90 : whatMarket === "privat" ? item.averageP90Privat : item.averageP90Offentlig
                    }
                }
            });

            // 2. Prepare employee data to the correct format and calculate the percentiles internally
            function transformAndCalculatePercentiles(data) {
                // Function to calculate the percentile of a sorted array
                const calculatePercentile = (arr, percentile) => {
                    const sorted = arr.slice().sort((a, b) => a - b);
                    const index = (percentile / 100) * (sorted.length - 1);
                    const lower = Math.floor(index);
                    const upper = Math.ceil(index);

                    if (lower === upper) {
                        return sorted[lower];
                    } else {
                        const weightUpper = index - lower;
                        const weightLower = 1 - weightUpper;
                        return sorted[lower] * weightLower + sorted[upper] * weightUpper;
                    }
                };

                // Group data by level
                const groupedByLevel = data.reduce((acc, item) => {
                    if (!acc[item.level]) {
                        acc[item.level] = [];
                    }
                    acc[item.level].push(item.salary);
                    return acc;
                }, {});

                // Calculate percentiles for each level and return the transformed data
                return Object.keys(groupedByLevel).map(level => {
                    const salaries = groupedByLevel[level];
                    const sortedSalaries = salaries.slice().sort((a, b) => a - b);

                    const p0 = sortedSalaries.length > 0 ? sortedSalaries[0] : 0// lowest salary
                    const p5 = calculatePercentile(salaries, 5);
                    const p10 = calculatePercentile(salaries, 10);
                    const p25 = calculatePercentile(salaries, 25);
                    const p50 = calculatePercentile(salaries, 50);
                    const p75 = calculatePercentile(salaries, 75);
                    const p90 = calculatePercentile(salaries, 90);
                    const p95 = calculatePercentile(salaries, 95);
                    const p100 = sortedSalaries.length > 1
                        ? sortedSalaries[sortedSalaries.length - 1] // Highest salary if more than 1 salary
                        : sortedSalaries.length === 1
                            ? sortedSalaries[0] // Only one salary, so it's both p0 and p100
                            : 0; // No salaries, return 0 for p100

                    // For p0 and p100, we will check if these values are "extreme", if they differ to much from p90 and p10. If so we use p90 and p10 instead. 
                    const p0p10Difference = p10 - p0;
                    const p90p100Difference = p100 - p90;

                    // If the difference between p0 and p10 is too large, make p100 the middle of p90 and p100
                    const adjustedP0 = p0p10Difference > (p10 * 0.2) ? // Adjust threshold as needed (0.2 = 20%)
                        (p0 + ((p10 - p0) / 2)) : p0;

                    // If the difference between p90 and p100 is too large, make p100 the middle of p0 and p10
                    const adjustedP100 = p90p100Difference > (p100 * 0.2) ? // Adjust threshold as needed (0.2 = 20%)
                        (p90 + ((p100 - p90) / 2)) : p100;

                    return {
                        [`level${level}`]: {
                            p0: adjustedP0,
                            p10,
                            p25,
                            p50,
                            p75,
                            p90,
                            p95,
                            p100: adjustedP100
                        }
                    };
                });
            }
            const employeePercentiles = transformAndCalculatePercentiles(employees);

            const createdSalaryRanges = await salaryRangeCalc(
                employeePercentiles, // employeeData. This is the percentiles for the internal data. P10 to P90 for each level.
                marketData, // marketData. P10 to P90 for each level.
                this.state.maxSpread, // maxSpread. The max allowed spread for the salary range, in percentage
                this.state.minSpread, // minSpread. The min allowed spread for the salary range, it cant be lower than this. In percentage, for example 10
                this.state.marketWeight, // marketPercentage. How much weight should be on market vs internal data. In percentage
                this.state.minimumSalaryMinHigher, // minimumSalaryMinHigher. If forceProgression is on, this is how much higher each level must be than the previous. In percentage (1 = 1%, not 100%) 
                (this.state.marketPosition === "medianP25P75" || this.state.marketPosition === "lead") ? 25 : 10,  // The lower percentile that should be used for the calculation, we can set it to percentiel 10 or 25. Median (P10-P90), lead (P25-P90), lag (P10-P75), medianP25P75 (P25-P75),
                (this.state.marketPosition === "medianP25P75" || this.state.marketPosition === "lag") ? 75 : 90,  // The lower percentile that should be used for the calculation, we can set it to percentiel 10 or 25. Median (P10-P90), lead (P25-P90), lag (P10-P75), medianP25P75 (P25-P75),
                (this.state.createProgression === "yes" ? true : false), // forceProgression
                this.state.nrOfEqualentGroups, // nr of levels
                1, // Hardcoded only. Keep it as true. The version, for using different algoritm. 1 is version 1, 2 is version 2 etc.
                true, // Hardcoded only. Keep it as true. True or false. Use internal employees highest and lowest salary instead of p10 and p90. overwrites the percentiles for internal data. If false then employee data is handled the same as market.
            )

            const familyOrFunction = this.state.activeSalaryWhat
            const name = this.state.activeSalaryWhat === "overall" ? "overall" : this.state.activeSalaryWhat === "family" ? this.state.activeSalaryInfo.whatFam : this.state.activeSalaryWhat === "mainFunction" ? this.state.activeSalaryInfo.whatMainFunction : this.state.activeSalaryWhat === "subFunction" ? this.state.activeSalaryInfo.whatSubfunction : this.state.activeSalaryWhat === "whatSubfunction2" ? this.state.activeSalaryInfo.whatSubfunction2 : this.state.activeSalaryInfo.whatSubfunction3

            // Format the salary ranges so they fit the chart
            function transformData(inputData) {
                const output = [];

                inputData.forEach(item => {
                    const level = item.level;
                    const max = item.max;
                    const min = item.min;
                    const mid = item.mid;
                    const spread = parseFloat(item.spread);
                    // First object with max value
                    const obj1 = {
                        y: max,
                        x: level,
                        fillColor: "#e0e0e0",
                        range: [min, mid, max],
                        spread: spread,
                        name: name,
                        familyOrFunction: familyOrFunction
                    };

                    // Second object with min value
                    const obj2 = {
                        x: level,
                        y: min,
                        range: "hide",
                        fillColor: "#fff",
                        name: name,
                        familyOrFunction: familyOrFunction
                    };

                    output.push(obj1);
                    output.push(obj2);
                });

                return output;
            }
            const transformedSalaryRanges = transformData(createdSalaryRanges);

            // Above is the new calculation method for auto ranges, below still calculates some things that we need, so we keep it for now ------------


            // 3. A) - First get all Percentile values for the internal employee data (we already got the market p-values from above)
            // Extract unique x values
            const uniqueXValues = [...new Set(employees.map(obj => obj.x))];
            // Calculate percentiles for each unique x value
            const percentilesByX = {};

            uniqueXValues.forEach(xValue => {
                const filteredData = employees.filter(obj => obj.x === xValue);   // Filter data based on the current x value
                const salaries = filteredData.map(obj => obj.salary); // Extract salaries from filtered data
                salaries.sort((a, b) => a - b); // Sort the salaries in ascending order

                // Function to calculate percentile
                let useStandardDeviation = this.state.standardDeviation
                function percentile(p) {

                    // Before we calculate percentiles for percentile 0 and 100 (highest and lowest salary), lets remove the extrem values with standard deviation
                    if (p === 0 || p === 100) {
                        if (useStandardDeviation === "one" || useStandardDeviation === "two") {
                            let editedSalaries = salaries.slice() // Create copy so we dont affect the salaries array
                            const mean = editedSalaries.reduce((sum, salary) => sum + salary, 0) / editedSalaries.length; // Calculate mean
                            const deviation = Math.sqrt(editedSalaries.reduce((sum, salary) => sum + Math.pow(salary - mean, 2), 0) / editedSalaries.length); // Calculate standard deviation
                            const deviationThreshold = useStandardDeviation === "two" ? 2 : 1; // using 2 standard deviations, change if needed
                            // Calculate minimum and maximum salary based on mean and standard deviation
                            const minSalary = Math.max(mean - deviationThreshold * deviation, Math.min(...editedSalaries));
                            const maxSalary = Math.min(mean + deviationThreshold * deviation, Math.max(...editedSalaries));
                            if (p === 0) return minSalary;
                            if (p === 100) return maxSalary;
                        } else {
                            if (p === 0) return salaries[0];
                            if (p === 100) return salaries[salaries.length - 1];
                        }
                    }

                    const index = (p / 100) * (salaries.length - 1);
                    const lowerIndex = Math.floor(index);
                    const upperIndex = Math.ceil(index);
                    if (lowerIndex === upperIndex) {
                        return salaries[lowerIndex];
                    }
                    const lowerValue = salaries[lowerIndex];
                    const upperValue = salaries[upperIndex];
                    return lowerValue + (upperValue - lowerValue) * (index - lowerIndex);
                }
                // Calculate percentiles
                let P10 = Math.round(percentile(10));
                let P25 = Math.round(percentile(25));
                let P50 = Math.round(percentile(50));
                let P75 = Math.round(percentile(75));
                let P90 = Math.round(percentile(90));
                let P0 = Math.round(percentile(0)); // The lowest salary 
                let P100 = Math.round(percentile(100)); // The highest salary

                // Store percentiles for current x value
                percentilesByX[xValue] = { P10, P25, P50, P75, P90, P0, P100 };
            });
            // Now we have the P-values for all levels.
            // Internal percentiles are inside the variable percentilesByX. To access a specific level we do percentilesByX[2], for a specific percentile: percentilesByX[2].P50
            // Market percentiles is inside the variables p10, p25, median, p75, p90 (small letters)


            // 3. B) - Now create the ranges based on internal and market data, then update chart

            // Settings overall:
            let marketPosition = this.state.marketPosition

            let weightMarket = this.state.marketWeight / 100 // weightInternal will fill the rest up till 100%

            let ranges = []
            let weightInternal = 1 - weightMarket

            let midpointP = median
            for (let i = 0; i < midpointP.length; i++) {
                // Get the level
                let theLevel = midpointP[i].x;
                if (Number.isNaN(theLevel)) {
                    theLevel = "null"
                }

                let minInternal = percentilesByX[theLevel].P0; // This uses standardDeviation if the setting is on in state (which it is by default)
                let maxInternal = percentilesByX[theLevel].P100; // This uses standardDeviation if the setting is on in state (which it is by default)

                //Get the range for market
                let minMarket = p10[i].y;
                let maxMarket = p90[i].y;
                if (marketPosition === "lag") { minMarket = p10[i].y; maxMarket = p75[i].y; }
                if (marketPosition === "lead") { minMarket = p25[i].y; maxMarket = p90[i].y; }
                //Get the average range (the min and max salary, takes weight for market vs internal into account)
                //   let avgMin = ((minInternal * weightInternal) + (minMarket * weightMarket));
                //   let avgMax = ((maxInternal * weightInternal) + (maxMarket * weightMarket));

                let avgMinO = ((minInternal * weightInternal) + (minMarket * weightMarket));
                let avgMaxO = ((maxInternal * weightInternal) + (maxMarket * weightMarket));
                let avgMin = Math.floor(avgMinO / 1000) * 1000; // round down to lowest 1000, for example 32888 will be 32000
                let avgMax = Math.floor(avgMaxO / 1000) * 1000; // round down to lowest 1000, for example 32888 will be 32000
                //Get spread for average range
                let midpoint = (avgMax + avgMin) / 2

                // create range
                let bottom = { y: avgMin, x: midpointP[i].x, fillColor: "#fff", range: "hide" /*[min, midpoint, max]*/ }
                let top = { y: avgMax, x: midpointP[i].x, fillColor: "#e0e0e0", range: [avgMin, midpoint, avgMax] }
                if (this.state.activeSalaryWhat === "individual") { // Add name and edited "yes if it is for individual"
                    bottom = { y: avgMin, x: midpointP[i].x, fillColor: "#fff", range: "hide", name: this.state.activeSalaryInfo.position /*[min, midpoint, max]*/ }
                    top = { y: avgMax, x: midpointP[i].x, fillColor: "#e0e0e0", range: [avgMin, midpoint, avgMax], name: this.state.activeSalaryInfo.position, edited: "yes" }
                }
                ranges.push(top)
                ranges.push(bottom)
            }

            // Create progression 
            if (this.state.createProgression === "yes" && uniqueXValues.length > 1) { // If set to "no" or there is only one x-value, do not calculate progression

                let rangesCopy = JSON.parse(JSON.stringify(ranges)); // Create a deep copy of ranges
                const encounteredX = {};  // Object to keep track of encountered x values
                const uniqueXRanges = rangesCopy.filter(obj => { // Filter the array to keep only the first object with each x value
                    if (!encounteredX[obj.x]) {
                        encounteredX[obj.x] = true; // Mark the x value as encountered
                        return true; // Keep this object
                    }
                    return false; // Discard this object
                });

                // Also get the unique x objects for the old values (we wont change them)
                let rangesCopyTwo = JSON.parse(JSON.stringify(ranges)); // Create a deep copy of ranges
                const encounteredXPrev = {};  // Object to keep track of encountered x values
                const rangesPrev = rangesCopyTwo.filter(obj => { // Filter the array to keep only the first object with each x value
                    if (!encounteredXPrev[obj.x]) {
                        encounteredXPrev[obj.x] = true; // Mark the x value as encountered
                        return true; // Keep this object
                    }
                    return false; // Discard this object
                });

                // Calculate average midpoint from all levels
                let sum = 0;
                for (let i = 0; i < uniqueXRanges.length; i++) {
                    sum += uniqueXRanges[i].range[1];
                }
                let average = sum / uniqueXRanges.length;

                // Now, make it so the progress follows the average

                // Get the lowest and highest midpoints
                let highestMidpointLevel = uniqueXRanges[uniqueXRanges.length - 1].range[1];
                let lowestMidpointLevel = uniqueXRanges[0].range[1];
                // V2. This uses the highest max an lowest min overall, no matter what level
                // Find lowest and highest midpoint levels
                /*   let lowestMidpointLevel = Infinity;
                   let highestMidpointLevel = -Infinity;
                   uniqueXRanges.forEach(object => {
                       lowestMidpointLevel = Math.min(lowestMidpointLevel, object.range[1]);
                       highestMidpointLevel = Math.max(highestMidpointLevel, object.range[1]);
                   });*/

                // Calculate average midpoint level 
                let totalMidpointLevels = 0;
                uniqueXRanges.forEach(object => {
                    totalMidpointLevels += object.range[1]; // Adding up midpoints
                });
                const averageMidpointLevel = totalMidpointLevels / uniqueXRanges.length;

                // Calculate new midpoint levels
                const spread = highestMidpointLevel - lowestMidpointLevel;
                const increment = spread / (uniqueXRanges.length - 1); // Increment value

                let currentMidpointLevel = lowestMidpointLevel;
                uniqueXRanges.forEach((object, index) => {
                    if (index > 0) {
                        currentMidpointLevel += increment;
                    }

                    object.range[1] = currentMidpointLevel;
                    let objMax = Math.round(currentMidpointLevel * (1.25))
                    let objMin = Math.round(currentMidpointLevel * (0.75))

                    // Check how the level fits its real range
                    let prevObj = rangesPrev.find(obj => obj.x === object.x);
                    let prevMin = prevObj.range[0]
                    let prevMax = prevObj.range[2]

                    // Before changing the range (if needed), get the values from past and next level, will be used to make sure we stay inside the boundaries and follow the progression
                    let pastMax = objMax
                    let pastMin = objMin
                    if (index > 0) { // If not the first item
                        pastMax = uniqueXRanges[index - 1].range[2]
                        pastMin = uniqueXRanges[index - 1].range[0]
                    }
                    let nextMidpoint = currentMidpointLevel + increment
                    let nextMax = Math.round(nextMidpoint * (1.25))
                    let nextMin = Math.round(nextMidpoint * (0.75))

                    // Adjust the ranges so there are as close to the original min and max but still insied the boundaries. Otherwise all ranges would be exactly the same spread and difference
                    let adjustRanges = (originalNumber, newNumber, lowBoundary, highBoundary, whatRange, nowBoundary) => {
                        if (newNumber < lowBoundary) {  // Ensure the new number is within the boundaries
                            newNumber = lowBoundary;
                        } else if (newNumber > highBoundary) {
                            newNumber = highBoundary;
                        }
                        let difference = Math.abs(originalNumber - newNumber);   // Calculate the difference between the original and new numbers
                        if (originalNumber < newNumber) { // Move new number closer to original, within boundaries
                            newNumber = Math.max(lowBoundary, newNumber - difference);
                        } else {
                            newNumber = Math.min(highBoundary, newNumber + difference);
                        }
                        // Make sure it is not shrinked more than original
                        if (whatRange === 2) { // For max range number
                            if (newNumber < nowBoundary) {
                                newNumber = objMax
                            }
                        }
                        if (whatRange === 0) { // For min range number
                            if (newNumber > nowBoundary) {
                                newNumber = nowBoundary
                            }
                        }
                        object.range[whatRange] = Math.round(newNumber) // Update the number
                    }

                    adjustRanges(prevMax, objMax, pastMax, nextMax, 2, objMax) // For max
                    adjustRanges(prevMin, objMin, pastMin, nextMin, 0, objMin) // For min
                    // Calculate and set new midpoint
                    object.range[1] = Math.round((object.range[0] + object.range[2]) / 2)
                    object.y = object.range[2]
                });
                // Now update ranges to be correct for chart
                const newRange = uniqueXRanges.reduce((acc, obj) => {
                    const minY = obj.range[0]
                    acc.push(obj);
                    acc.push({ x: obj.x, y: minY, range: "hide", fillColor: "#fff" });
                    return acc;
                }, []);

                // Round everything down to nearest 100, and recalculate midpoint and spread
                for (let obj of newRange) {
                    obj.y = Math.floor(obj.y / 1000) * 1000; // Rounds down to nearest 1000
                    if (Array.isArray(obj.range)) { // If range is not "hide" but an array
                        obj.range = obj.range.map(num => Math.floor(num / 1000) * 1000); // Rounds down to nearest 1000
                        // Recalculate midpoint and spread
                        obj.range[1] = (obj.range[0] + obj.range[2]) / 2
                        obj.spread = ((obj.range[2] - obj.range[0]) / obj.range[0]) * 100;
                    }
                }
                // Save updated ranges
                ranges = newRange

                // Add ranges that is not in the created array and push it to delete array (will be deleted when saved)
                const removeArray = [];
                const newRangeXSet = new Set(newRange.map(item => item.x));
                const uniqueXSet = new Set();
                let whatC = this.state.activeSalaryWhat !== "overall" ? "activeFamilyOrFunc" : "rangesLevels"
                this.state[whatC].forEach(item => {
                    if (!newRangeXSet.has(item.x) && !uniqueXSet.has(item.x)) {
                        removeArray.push(item);
                        uniqueXSet.add(item.x);
                    }
                });
                this.setState({ removeThis: removeArray })
                /* The finished array "Ranges" should look like this: 
                    data: [ // X is the level
                         { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
                        { y: 40000, x: 2, fillColor: "#fff", range: [40000, 45000, 60000], }, // Bottom
                         { y: 70000, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
                         { y: 60000, x: 5, range: [60000, 45000, 80000] },
                        ],  */
            }

            //3 C If family or function, add the selected family/funciton name to the range(for overall we just save "overall"), also add the familyName (not the subfunction, but the family it belongs to)
            if (this.state.activeSalaryWhat !== "overall") {
                for (let i = 0; i < ranges.length; i++) {
                    ranges[i].name = selectedName
                    ranges[i].familyName = this.state.activeSalaryInfo.whatFam
                }
            }

            // 3 D Add data to chart
            // Now all market salaries are ready to be added to the chart series, for market just add the variables p10, p25, median, p75 and p90
            // For employees just add the variable "employees"
            // For ranges just add the variable "ranges"

            // Update state, this will update the ChartSalaryStructure module
            let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often
            let activateSalRangeSave = calledOnLoad === true ? "off" : "on"
            let chartOpen = calledOnLoad === true ? false : true
            let whatLevels = this.state.activeSalaryWhat !== "overall" ? this.state.activeSalaryWhat : "rangesLevels" // If job family or function selected, save different saveState. The saveStates are "rangesLevels" (for overall), mainFunciton, subFunction, subFunction2, subFunction3
            let levelOrFamily = this.state.activeSalaryWhat !== "overall" ? "activeFamilyOrFunc" : "rangesLevels"

            // Except to save into activeFamilyOrFunc, wich is our temporary list, we also need to save it into the original array. 
            if (this.state.activeSalaryWhat !== "overall") {
                // Remove all items with the same name
                let savingArray = this.state[whatLevels]
                let name = selectedName
                let familyName = this.state.activeSalaryInfo.whatFam
                //    let removedItems = savingArray.filter(obj => obj.name !== name)
                // let removedItems = savingArray.filter(obj => obj.name !== name || obj.familyName !== familyName)
                let removedItems = savingArray.filter(obj => obj.name !== name || obj.familyName !== familyName) // FELET VERKAR LIGGA I ATT familyName inte alltid finns?
                // Then add the auto generated to it
                let addedAuto = removedItems.concat(ranges)
                this.setState({ [whatLevels]: addedAuto });
            }

            // Then update the rest of the states
            this.setState({
                p10: p10,
                p25: p25,
                median: median,
                p75: p75,
                p90: p90,
                employeesInChartLevels: employees,
                [levelOrFamily]: transformedSalaryRanges,// ranges, <-- Change to use old version
                salaryRangesOpen: false,
                chartOpen: chartOpen,
                triggerChartUpdate: triggerChartUpdate,
            })
            // activate save for selected
            if (this.state.activeSalaryWhat === "overall") { this.setState({ activateSalRangeSave: activateSalRangeSave }) }
            if (this.state.activeSalaryWhat === "family") { this.setState({ activateSalRangeSaveFamily: "on" }) }
            if (this.state.activeSalaryWhat === "mainFunction") { this.setState({ activateSalRangeSaveMainFunction: "on" }) }
            if (this.state.activeSalaryWhat === "subFunction") { this.setState({ activateSalRangeSaveSubfunction: "on" }) }
            if (this.state.activeSalaryWhat === "subFunction2") { this.setState({ activateSalRangeSaveSubfunction2: "on" }) }
            if (this.state.activeSalaryWhat === "subFunction3") { this.setState({ activateSalRangeSaveSubfunction3: "on" }) }
            if (this.state.activeSalaryWhat === "subFunction4") { this.setState({ activateSalRangeSaveSubfunction4: "on" }) }
            if (this.state.activeSalaryWhat === "individual") { this.setState({ activateSalRangeSaveIndividual: "on" }) }
        } // End of if statement for autoCalculation

        // 3 E Add data to chart
        // Now all market salaries are ready to be added to the chart series, for market just add the variables p10, p25, median, p75 and p90
        // For employees just add the variable "employees"
        // For ranges just add the variable "ranges"

        // Update state, this will update the ChartSalaryStructure module
        let triggerChartUpdate = this.state.triggerChartUpdate === "trigger" ? "trigg" : "trigger" // Buggfix for chart rendered to often

        // Make different colors for different companies (if they share arcId) <-- This works, but not sure if it is to much colors.. Therefore not adding right now
        /* let colorWhat = "gender" // or companies
        if(colorWhat === "companies") {
         const colors = [
             "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF", "#33FFF4", "#FF8633",
             "#FFD433", "#91FF33", "#33FF91", "#3391FF", "#9133FF", "#FF3391", "#FF5733",
             "#33A1FF", "#A1FF33", "#FF33F4", "#A1FF91", "#A191FF", "#33F4FF"
           ];
           const colorMap = {};
           for (let i = 0; i < employees.length; i++) {
             const dotterBolag = employees[i].dotterBolagReal;
             if (!colorMap[dotterBolag]) {    // Assign color from the array or reuse an existing one
               colorMap[dotterBolag] = colors[Object.keys(colorMap).length % colors.length];
             }
             employees[i].fillColor = colorMap[dotterBolag];
           }
         } else if (colorWhat = "gender") {
             for (let i = 0; i < employees.length; i++) {
                 if( employees[i].gender === "man") {
                     employees[i].fillColor = "#45a0fc";
                 } else {  employees[i].fillColor = "#4ae2a2";}
             }
         }*/


        this.setState({
            p10: p10,
            p25: p25,
            median: median,
            p75: p75,
            p90: p90,
            employeesInChartLevels: employees,
            // rangesLevels: ranges, <-- Only set if we use autocalc
            salaryRangesOpen: false,
            chartOpen: calledOnLoad === true ? false : true,
            triggerChartUpdate: triggerChartUpdate
        })
        // The data in the chart need to have this format (click on rows to see it, it is commented out so not changing anything)
        /*
        series3: [
                 {
                     name: "Lönespann",
                     type: 'bar',
                     //color: "#cdcccc",
                     data: [ // X tells is the level
                         { y: 60000, x: 2, fillColor: "#e0e0e0", range: [40000, 45000, 60000], }, // Middle
                         { y: 40000, x: 2, fillColor: "#fff", range: [40000, 45000, 60000], }, // Bottom
                         { y: 70000, x: 2, fillColor: "transparent", range: [40000, 45000, 60000], }, // Top
                         { y: 60000, x: 5, range: [60000, 45000, 80000] },
                     ],
                 },
                   {
                     name: "Medarbetare",
                     data: [{ y: 30000, x: 2 }, { y: 35000, x: 2 }, { y: 45000, x: 2 }, { y: 50000, x: 3 }, { y: 55000, x: 5 }, { y: 50000, x: 5 }],
                     type: 'scatter',
                 },
 
                 {
                     name: "P10",
                     type: 'line',
                     color: "#dda6a6",
                     data: [{ y: 25000, x: 1, p: "p" }, { y: 20000, x: 2, p: "p" }, { y: 35000, x: 3, p: "p" }, { y: 22500, x: 5, p: "p" },] //"P" is used to determe what type of item it is for tooltip
                 },
                 {
                     name: "P25",
                     type: 'line',
                     color: "#f6bdc7",
                     data: [{ y: 30000, x: 1, p: "p" }, { y: 40000, x: 2, p: "p" }, { y: 55000, x: 3, p: "p" }, { y: 92500, x: 5, p: "p" },]
                 },
 
                 {
                     name: "Median",
                     type: 'line',
                     color: "#8fa7e1",
                     data: [{ y: 35000, x: 1, p: "p" }, { y: 50000, x: 2, p: "p" }, { y: 65000, x: 3, p: "p" }, { y: 40500, x: 5, p: "p" },]
                 },
                 {
                     name: "P75",
                     type: 'line',
                     color: "#dbc191",
                     data: [{ y: 40000, x: 1, p: "p" }, { y: 58000, x: 2, p: "p" }, { y: 75000, x: 3, p: "p" }, { y: 97500, x: 5, p: "p" },]
                 },
                 {
                     name: "P90",
                     type: 'line',
                     color: "#9dc59d",
                     data: [{ y: 45000, x: 1, p: "p" }, { y: 60000, x: 2, p: "p" }, { y: 80000, x: 3, p: "p" }, { y: 102500, x: 5, p: "p" },]
                 },
             ],
 
        // ------------------- This is to create lines inside the boxes -----
                  [
                         { // This is the range TOP
                             y: 60000,
                             x: 1.66,
                             marker: {
                                 size: 1,
                                 shape: "square",
                                 cssClass: "markRangeLine",
                                 fillColor: "transparent",
                                 strokeWidth: 0,
                                 radius: 0,
                             },
                             borderColor: 'transparent',
                             fillColor: 'transparent',
                             label: {
                                 borderWidth: 0,
                                 text: '60 000', //set to undefined to hide the label
                                 textAnchor: 'middle',
                                 position: "left",
                                 style: {
                                     background: 'transparent',
                                 },
                                 offsetX: 31,
                                 offsetY: 10
                             }
                         },
                         {  // This is the midpoint
                             y: 45000,
                             x: 1.66,
                             marker: {
                                 size: 1,
                                 shape: "square",
                                 cssClass: "markRangeLine",
                                 fillColor: "#333333",
                                 strokeWidth: 0,
                                 radius: 0,
                             },
                             borderColor: 'transparent',
                             fillColor: 'transparent',
                             label: {
                                 borderWidth: 0,
                                 text: "45 000", //set to undefined to hide the label
                                 textAnchor: 'middle',
                                 position: "left",
                                 style: {
                                     background: 'transparent',
                                 },
                                 offsetX: 31,
                                 offsetY: 10
                             }
                         },
                         { // This is the range BOTTOM
                             y: 40000,
                             x: 1.66,
                             marker: {
                                 size: 1,
                                 shape: "square",
                                 cssClass: "markRangeLine",
                                 fillColor: "transparent",
                                 strokeWidth: 0,
                                 radius: 0,
                             },
                             borderColor: 'transparent',
                             fillColor: 'transparent',
                             label: {
                                 borderWidth: 0,
                                 text: '40 000', //set to undefined to hide the label
                                 textAnchor: 'middle',
                                 position: "left",
                                 style: {
                                     background: 'transparent',
                                 },
                                 offsetX: 31,
                                 offsetY: 22
                             }
                         },
 
                     ]
        ---------------------------------- 
        */

        // Update save btn
        let activateSalRangeSave = this.state.saveButtonStatus === "Spara" ? "Spara" : calledOnLoad === true ? "Sparat" : "Spara"
        if (this.state.autoCalculation === "yes") { this.setState({ saveButtonStatus: activateSalRangeSave }) }

        //Load comments about salary Range
        if (this.state.showCommentSalary === true) {
            this.setState({ showCommentSalary: false }, () => {
                this.commentSalary()
            })
        }
    }

    exitPopForFamilies = () => {
        this.setActiveInfo(
            "",
            {
                position: "",
                whatFam: "",
                whatMainFunction: "",
                whatSubfunction: "",
                whatSubfunction2: "",
                whatSubfunction3: "",
            }
        )
    }
    colorPicker = (event) => {
        this.setState({ colorPicker: event.target.value }, () => {
            // Change color for the group here
            let family = this.state.activeInfo.whatFam
            let newColor = this.state.colorPicker

            this.setState((prevState) => ({
                empData: prevState.empData.map((item) =>
                    item.family === family ? { ...item, color: newColor, edited: "Yes" } : item
                ),
                hierarchy: prevState.hierarchy.map((item) =>
                    item.name === family ? { ...item, color: newColor } : item
                ),
            }));
        });
        this.setState({ saveButtonStatus: "Spara" })
    }

    popUpForFamilies = (name, whatFam) => { // This us the popUp for families and sub families. So it runs when we click on them in the architecture <-- Not enitrely true, first popUpForFamiliesToggle is running
        let language = localStorage.getItem('language') ?? 'sv';
        let family = name // <-- Detta är null nu
        let selectionList = whatFam === "family" ? "familySelectList" : whatFam === "mainFunction" ? "mainFunctionsSelectList" : whatFam === "subFunction" ? "subFunctionSelectList" : whatFam === "subFunction2" ? "subFunctionSelectList2" : "subFunctionSelectList3"

        let checkWhat = whatFam === "family" ? "whatFam" : whatFam === "mainFunction" ? "whatMainFunction" : whatFam === "subFunction" ? "whatSubFunction" : whatFam === "subFunction2" ? "whatSubFunction2" : whatFam === "subFunction3" ? "whatSubFunction3" : "whatSubFunction4"

        // Update color
        if (this.state.activeWhat === "family") {
            const matchedFamily = this.state.hierarchy.find((item) => item.name === this.state.activeInfo.whatFam);
            if (matchedFamily) {
                if (matchedFamily.color !== this.state.colorPicker) {
                    this.setState({ colorPicker: matchedFamily.color });
                }
            }
        }

        return <div className="popUpArchitectureAbsolute" style={{ marginLeft: 122, marginTop: 9, borderTopLeftRadius: 0, borderTopRightRadius: 6 }}>
            <img alt="" src={exitImg} class="hide-box" style={{ width: 22 }} onClick={this.exitPopForFamilies} />
            <div className={this.state.activeWhat === "family" ? "colorBlockSelect" : "hide"}>
                <input
                    type="color"
                    value={this.state.colorPicker}
                    onChange={this.colorPicker}
                    style={{
                        width: 50,
                        height: 50,
                        padding: 0,
                        margin: 0,
                        border: "none",
                        marginLeft: -10,
                        marginTop: -10
                    }}
                />
            </div>
            <div className="createSalBandBtn" whatChart={whatFam} whatName={name} onClick={this.openWhatChart}>{language === "sv" ? "Skapa lönespann" : lang[language].createSalaryRange}</div>
            <div className="titleFamily" style={{ marginTop: 0, marginBottom: 20, paddingTop: 3 }}>
                {
                    this.state.activeWhat === "family" && language === "sv" ? "Jobbfamilj" :
                        this.state.activeWhat === "family" && language !== "sv" ? lang[language].jobFamily :
                            this.state.activeWhat === "mainFunction" && language === "sv" ? "Funktion" :
                                this.state.activeWhat === "mainFunction" && language !== "sv" ? lang[language].function :
                                    this.state.activeWhat === "subFunction" && language === "sv" ? "Subfunktion" :
                                        this.state.activeWhat === "subFunction" && language !== "sv" ? lang[language].subFunction :
                                            this.state.activeWhat === "subFunction2" && language === "sv" ? "Subfunktion 2" :
                                                this.state.activeWhat === "subFunction2" && language !== "sv" ? lang[language].subFunction + " 2" :
                                                    this.state.activeWhat === "subFunction3" && language === "sv" ? "Subfunktion 3" :
                                                        this.state.activeWhat === "subFunction3" && language !== "sv" ? lang[language].subFunction + " 3"
                                                            : "jobbfamilj"
                } </div>

            <div className={this.state.showInputNewFam !== "family" ? "" : "hide"}>
                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} onChange={this.selectFamilyForFamily}>
                    <option value={family === false || family === "" || family === null || family === undefined ? (language === "sv" ? "Välj familj" : lang[language].chooseCategory) : family}>{family === false || family === "" || family === null || family === undefined ? (language === "sv" ? "Välj familj" : lang[language].chooseCategory) : family}</option>
                    {/* <JobFamilies /> */}
                    {this.state[selectionList].map((item, index) => (
                        <option key={index} value={item}>
                            {item === "" ? language === "sv" ? "- Ta bort -" : lang[language].remove : item}
                        </option>
                    ))}

                </select>
                <div className="plusBtnDivContainer">
                    <div className="plusBtnDivBack" whatFam="family" onClick={this.showInputNewFam}>{language === "sv" ? "Skapa ny" : lang[language].create} </div><img src={addIcon} className="plusBtnSalaryRanges" />
                </div>
            </div>
            <div className={this.state.showInputNewFam === "family" ? "" : "hide"}>
                <input type="text" className="inputFamily" placeholder={language === "sv" ? "Skapa ny" : lang[language].create} onChange={
                    this.state.activeWhat === "family" ? this.changeFamilyToList :
                        this.state.activeWhat === "mainFunction" ? this.changeMainFunction :
                            this.state.activeWhat === "subFunction" ? this.changeSubFunction :
                                this.state.activeWhat === "subFunction2" ? this.changeSubFunction2 :
                                    this.changeSubFunction3
                } value={
                    this.state.activeWhat === "family" ? this.state.addFamilytoList :
                        this.state.activeWhat === "mainFunction" ? this.state.addMainFunction :
                            this.state.activeWhat === "subFunction" ? this.state.addSubFunction :
                                this.state.activeWhat === "subFunction2" ? this.state.addSubFunction2 :
                                    this.state.addSubFunction3
                } />
                <div data-family={family} data-whatSub={this.state.activeWhat} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }}>{language === "sv" ? "Skapa" : lang[language].create}</div>
                <div data-family={family} data-whatSub={this.state.activeWhat} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }}>{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
            </div>
        </div>
    }
    popUpForFamiliesToggle = async (event) => {
        // event.stopPropagation(); // Prevent the event from bubbling up
        let family = event.target.getAttribute('data-popfamily'); // the name
        let whatCat = event.target.getAttribute('data-whatcat'); // if it is family, mainFunction, subFunction, subFunction2 or subFunction3

        // Get parents info
        let whatFam = event.target.getAttribute('data-whatfam');
        let whatMainFunction = event.target.getAttribute('data-whatMainFunction');
        let whatSubfunction = event.target.getAttribute('data-whatSubfunction');
        let whatSubfunction2 = event.target.getAttribute('data-whatSubfunction2');
        let whatSubfunction3 = whatCat === "subFunction3" ? family : ""

        // First update the activeInfo
        let activeInfo = {
            position: "",
            whatFam: whatCat === "family" ? family : whatFam,
            whatMainFunction: whatCat === "mainFunction" ? family : (whatCat === "subFunction" || whatCat === "subFunction2" || whatCat === "subFunction3" || whatCat === "subFunction4") ? whatMainFunction : "",
            whatSubfunction: whatCat === "subFunction" ? family : (whatCat === "subFunction2" || whatCat === "subFunction3" || whatCat === "subFunction4") ? whatSubfunction : "",
            whatSubfunction2: whatCat === "subFunction2" ? family : (whatCat === "subFunction3" || whatCat === "subFunction4") ? whatSubfunction2 : "",
            whatSubfunction3: whatCat === "subFunction3" ? family : whatCat === "subFunction4" ? whatSubfunction3 : "",
            whatSubfunction4: ""
        }

        await this.setActiveInfo(whatCat, activeInfo) // <-- OBS! If slow, remove the await
        this.setState({ showInputNewFam: "hide" }) // This just closed the more info for family popUps
    };

    commentSalary = () => { //Show comment textfield and load the comment if is any
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.showCommentSalary) {
            if (this.state.thisSalaryComment.length > 0 && !this.state.salaryCommentSaved) {
                const userConfirmed = window.confirm(language === "sv" ? "Kommentaren har inte sparats och kommer att försvinna. Vill du fortsätta?" : lang[language].commentNotSaved);
                if (userConfirmed) { this.setState({ showCommentSalary: false }) } else { return }
            } else {
                this.setState({ showCommentSalary: false })
            }
        } else {
            let whatFunction = this.state.activeSalaryWhat // This is what type is selected: family, mainFunction, subFunction, subFunction2, subFunction3 or individual (equal work)
            let theName = whatFunction === "individual" ? this.state.activeSalaryInfo.position : whatFunction === "family" ? this.state.activeSalaryInfo.whatFam : whatFunction === "mainFunction" ? this.state.activeSalaryInfo.whatMainFunction : whatFunction === "subFunction" ? this.state.activeSalaryInfo.whatSubfunction : whatFunction === "subFunction2" ? this.state.activeSalaryInfo.whatSubfunction2 : whatFunction === "subFunction3" ? this.state.activeSalaryInfo.whatSubfunction3 : this.state.activeSalaryInfo.whatSubfunction4 // This is the name of the selected family, function or equal work name
            let allSalaryComments = this.state.allSalaryComments
            let familyName = this.state.activeSalaryInfo.whatFam
            // Check if there already is a comment
            let existingObject = allSalaryComments.find(obj => obj.whatFunction === whatFunction && obj.theName === theName && obj.familyName === familyName);
            if( this.state.activeSalaryWhat === "overall") { existingObject = allSalaryComments.find(obj => obj.theName === "overall" && obj.familyName === "overall") }
            if (existingObject) {    // If a comment is found, use the 'comment' property
                this.setState({ thisSalaryComment: existingObject.comment })
            } else {
                this.setState({ thisSalaryComment: "" })
            }
            this.setState({ showCommentSalary: true, salaryCommentSaved: true })
        }
    }

    editCommentSalary = (event) => { // Add or edit the comment when the group is selected.
        const text = event.target.value //what text should be saved
        this.setState({ thisSalaryComment: text })
    }
    deleteCommentInList = async (event) => { // This deletes the comment in the list
        let language = localStorage.getItem('language') ?? 'sv';
        const commentName = event.target.getAttribute('data-commentname');
        const commentId = event.target.getAttribute('data-commentid');
        let allSalaries = this.state.allSalaryComments
        let updatedAllSalaryComments = this.state.allSalaryComments.filter(obj => obj.theName !== commentName)

        const arcIds = this.state.arcIds.flatMap(group => group.items);
        let response = await fetchAuth(`/api/deleteSalaryRangeComment`, 'POST', JSON.stringify({ commentId: commentId }));
        if (response.status !== 200) { alert(language === "sv" ? "Ett problem uppstod. Vänligen prova igen." : lang[language].errorSave) }
        else {
            this.setState({ allSalaryComments: updatedAllSalaryComments })
        }
    }

    saveCommentSalary = async (e) => {
        let language = localStorage.getItem('language') ?? 'sv';
        // Info we need to know to identify the comment
        let whatFunction = this.state.activeSalaryWhat // This is what type is selected: family, mainFunction, subFunction, subFunction2, subFunction3 or individual (equal work)
        let theName = whatFunction === "individual" ? this.state.activeSalaryInfo.position : whatFunction === "family" ? this.state.activeSalaryInfo.whatFam : whatFunction === "mainFunction" ? this.state.activeSalaryInfo.whatMainFunction : whatFunction === "subFunction" ? this.state.activeSalaryInfo.whatSubfunction : whatFunction === "subFunction2" ? this.state.activeSalaryInfo.whatSubfunction2 : whatFunction === "subFunction3" ? this.state.activeSalaryInfo.whatSubfunction3 : this.state.activeSalaryInfo.whatSubfunction4 // This is the name of the selected family, function or equal work name
       
        let familyName = this.state.activeSalaryInfo.whatFam
        // Comment info
        let allSalaryComments = this.state.allSalaryComments.slice() // all salary comments
        let thisSalaryComment = this.state.thisSalaryComment // the edited comment
        // Check if new comment or already exists
        const existingObject = allSalaryComments.find(obj => obj.whatFunction === whatFunction && obj.theName === theName && obj.familyName === familyName);
        
        this.state.activeSalaryWhat === "overall" ? familyName = "overall" : familyName = familyName
        this.state.activeSalaryWhat === "overall" ? theName = "overall" : theName = theName
        if(familyName === "" || familyName === null || familyName === undefined) {
            alert(language === "sv" ? "Ni behöver skapa jobbfamiljer innan ni kan spara en kommentar för ett lönespann." : lang[language].createFamilyFirst)
            return;
        }

        let commentObject = {
            whatFunction: whatFunction,
            theName: theName,
            familyName: familyName,
            comment: thisSalaryComment,
            commentId: existingObject ? existingObject.commentId : undefined
        }

        // Save to database and send back id
        const arcIds = this.state.arcIds.flatMap(group => group.items);
        let response = await fetchAuth(`/api/saveSalaryRangeComment`, 'POST', JSON.stringify({ commentObject: commentObject, arcIds: arcIds }));
        if (response.status !== 200) { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        else {
            if (!existingObject) { // If the comment is new we first need to get the new id and push the object
                let newId = await response.json();
                commentObject.commentId = newId
                allSalaryComments.push(commentObject)
            } else if (existingObject) { // Otherwise just update it
                existingObject.comment = thisSalaryComment
            }
            // Add the comment to the comment list
            this.setState({ salaryCommentSaved: true, allSalaryComments: allSalaryComments })
        }
    }
    commentSalaryOnClick = () => {
        this.setState({ salaryCommentSaved: false })
    }

    showDraftEditor = () => {
        this.setState({ editJobDesc: true })
    }
    hideDraftEditor = () => {
        this.setState({ editJobDesc: false })
    }
    selectJobDesc = (event) => { this.setState({ jobDescSelected: event.target.value }) }
    draftEditorHandleSave = async (editorState) => {
        let language = localStorage.getItem('language') ?? 'sv';
        // Save jobDescription
        let title = this.state.jobDescSelected
        let equalGroup = this.state.jobDescWhatGroup // This may be the same as title, but that is on purpose. Will be saved like that.
        let contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState); // Serialize the content
        const jobDesc = JSON.stringify(rawContentState); // Convert it to JSON to be saved.
        const arcIds = this.state.arcIds.flatMap(group => group.items);
        // Save it 
        let response = await fetchAuth(`/api/saveJobDescription`, 'POST', JSON.stringify({ jobDesc: jobDesc, title: title, equalGroup: equalGroup, arcIds: arcIds }));
        if (response.status !== 200) { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        else {
            // Update object with new jobDescription, then close editor
            let jobDescObject = this.state.jobDescriptions.slice()
            let jobIndex = jobDescObject.findIndex(job => job.equalGroup === equalGroup && job.title === title);
            jobIndex !== -1
                ? jobDescObject[jobIndex].jobDesc = jobDesc // Update if exists
                : jobDescObject.push({ equalGroup, title, jobDesc }); // Add if not

            this.setState({ jobDescriptions: jobDescObject }, () => {
                this.setState({ editJobDesc: false });
            });
        }
    }

    settingPopUp = () => { this.state.settingPopUp ? this.setState({ settingPopUp: false, shareArcPop: false }) : this.setState({ settingPopUp: true, shareArcPop: false }) }
    searchToggle = () => { this.state.searchToggle ? this.setState({ searchToggle: false, searchString: "" }, this.highlightMatches) : this.setState({ searchToggle: true }) }

    searchAndMarkWork = (event) => {
        let searchString = event.target.value
        this.setState({ searchString: searchString }, this.highlightMatches)
    }
    highlightMatches = () => {
        const searchTerm = this.state.searchString
        const container = this.componentRef.current //this.printContentRef.current;

        if (container) {
            // Select all .showTitleNames elements
            const showTitleNamesItems = container.querySelectorAll(".showTitleNames");
            const nameStripeItems = container.querySelectorAll(".name-stripe");
            // Function to highlight elements
            const highlightElement = (element, second) => {
                const textContent = element.textContent || "";
                if (textContent.trim() && searchTerm !== "" && textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
                    element.style.backgroundColor = "yellow";
                } else {
                    element.style.backgroundColor = ""; // Reset the background
                }
            };
            // If a match for equal work, mark it
            nameStripeItems.forEach((nameStripeElement) => {
                highlightElement(nameStripeElement);
            });
            // If there is a match for the position, mark the equal work as well 
            showTitleNamesItems.forEach((showTitleElement) => {
                highlightElement(showTitleElement);
                // Find the previous sibling (the span with class "name-stripe")
                const previousSibling = showTitleElement.previousElementSibling;
                if (previousSibling && previousSibling.classList.contains("name-stripe")) {
                    // Highlight the previous sibling (span with class "name-stripe")
                    if (previousSibling.style.backgroundColor !== "yellow") {
                        previousSibling.style.backgroundColor = showTitleElement.style.backgroundColor || "";
                    }
                }
            });
        }
    };

    resetArc = async () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const userConfirmed = window.confirm(language === "sv" ? "Är du helt säker på att du vill nollställa arkitekturen? Alla era kategoriseringar och lönespann kommer att försvinna och går inte att återställa. Vill du fortsätta?" : lang[language].resetArcInfo);
        if (userConfirmed) {
            const response = await fetchAuth(`/api/resetArc`, 'POST');
            if (response.status !== 200) { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
            else { window.location.reload() } // Reload the page
            return;
        }
        else { return }
    }

    changeArcId = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        const selectedArcId = event.target.value !== "new" ? Number(event.target.value) : event.target.value
        const company = event.target.dataset.dotterbolag;
        const whatEvaluation = event.target.dataset.whatevaluation;
        const nrOfEqualGroups = Number(event.target.dataset.nrofequalgroups)

        // Before changing the arcId, make sure thatif there are companies in the new arcId, they cant have not equal of levels/equivalent groups <-- OBS! NOT WORKING FOR EXTERNAL AT THE MOMENT!
        let currentArcIds = [...this.state.arcIds]
        for (let i = 0; i < currentArcIds.length; i++) {
            if (currentArcIds[i].arcId === selectedArcId) {
                for (let a = 0; a < currentArcIds[i].items.length; a++) {
                    let nrOfGroups = 0
                    currentArcIds[i].items[a].whatEvaluation === null || currentArcIds[i].items[a].whatEvaluation === undefined || currentArcIds[i].items[a].whatEvaluation === "original" ? currentArcIds[i].items[a].nrOfEqualGroups === null ? nrOfGroups = 6 : nrOfGroups = currentArcIds[i].items[a].nrOfEqualGroups : currentArcIds[i].items[a].editedNrOfEqualGroups === null || currentArcIds[i].items[a].editedNrOfEqualGroups === undefined ? nrOfGroups = 6 : nrOfGroups = currentArcIds[i].items[a].editedNrOfEqualGroups
                    if (nrOfGroups !== nrOfEqualGroups) {
                        return alert(language === "sv" ? `Bolaget ${company} kan inte läggas till i arkitektur ${selectedArcId}. Detta då det finns ett eller flera bolag som inte har samma antal nivåer i arbetsvärderingen.` : (lang[language].sameLevPartOne + company + lang[language].sameLevPartTwo + selectedArcId + lang[language].sameLevPartThree))
                    }
                }
            }
        }
        // If try to create a new one, do not allow if there is only one inside the current architecture
        if (selectedArcId === "new") {
            for (let i = 0; i < currentArcIds.length; i++) {
                const group = currentArcIds[i];
                for (let j = 0; j < group.items.length; j++) {
                    const item = group.items[j];
                    if (item.dotterBolag === company) {  // Check if this item matches the target dotterBolag
                        // Check if there is more than one item in the items array
                        if (group.items.length > 1) { // If there is more than itself with the arcId, then we should allow to create a new arcId to separate the company
                            let maxArcId = 0;
                            for (let k = 0; k < currentArcIds.length; k++) {  // Find the highest arcId in the current groups
                                if (currentArcIds[k].arcId > maxArcId) { maxArcId = currentArcIds[k].arcId; }
                            }
                            const newArcId = maxArcId + 1;
                            const newItem = {
                                arcId: newArcId,
                                dotterBolag: company,
                                whatEvaluation: item.whatEvaluation === null || item.whatEvaluation === undefined ? "original" : item.whatEvaluation,
                                nrOfEqualGroups: item.nrOfEqualGroups === null || item.nrOfEqualGroups === undefined ? 6 : item.nrOfEqualGroups,
                                editedNrOfEqualGroups: item.editedNrOfEqualGroups === null || item.editedNrOfEqualGroups === undefined ? 6 : item.nrOfEqualGroups
                            };
                            const newGroup = {
                                arcId: newArcId,
                                items: [newItem]
                            };
                            currentArcIds.push(newGroup); // Add the new group to the currentArcIds
                            group.items.splice(j, 1); // Remove the item from the old group
                            if (group.items.length === 0) { currentArcIds.splice(i, 1) } // If the group becomes empty after removal, we also remove the group
                            this.setState({ arcIds: currentArcIds }); // Update state
                            return;
                        } else {// If it is alone with its arcId, we should not allow to change it
                            return alert(language === "sv" ? `Bolaget ${company} har redan en egen separat arkitektur.` : (lang[language].sameLevPartOne + company + lang[language].alreadyUniqueArc))
                        }
                    }
                }
            }
        }

        // Update the selected architecture id. This makes two or more companies have the same Id.
        let sourceGroup = null;
        let sourceIndex = -1;
        for (let i = 0; i < currentArcIds.length; i++) {
            const group = currentArcIds[i];
            for (let j = 0; j < group.items.length; j++) {
                const item = group.items[j];
                if (item.dotterBolag === company) {
                    sourceGroup = group;
                    sourceIndex = j;
                    break;
                }
            }
            if (sourceGroup) break; // Exit outer loop if group is found
        }
        if (!sourceGroup) { alert(language === "sv" ? "Ett fel uppstod. Kunde inte hitta bolaget." : lang[language].notFindCompany); return; }
        const removedItem = sourceGroup.items.splice(sourceIndex, 1)[0]; // Remove the selected dotterBolag from the source group's items. Remove and get the item
        let targetGroup = currentArcIds.find(group => group.arcId == selectedArcId);     // Find the target group (arcId) where we want to move the selected dotterBolag
        targetGroup.items.push(removedItem);    // Add the removed item to the target group's items
        removedItem.arcId = selectedArcId;  // Update the arcId of the moved item to the selected arcId
        currentArcIds = currentArcIds.filter(group => {     // If the source group is empty, remove it from the arcIds array
            return group.items.length > 0 || group.arcId !== sourceGroup.arcId;  // Only keep groups that have items
        });
        this.setState({ arcIds: currentArcIds }); // Update the state with the updated arcIds data
        return;
    }

    shareArcPop = () => { this.state.shareArcPop ? this.setState({ shareArcPop: false, settingPopUp: false }) : this.setState({ shareArcPop: true, settingPopUp: false }) }
    toggleArcPop = () => { this.state.toggleArcPop ? this.setState({ toggleArcPop: false }) : this.setState({ toggleArcPop: true }) }
    cancelSharedArc = () => {
        let arcIdsBackup = this.state.arcIdsBackup
        this.setState({ editSharedArc: false, arcIds: JSON.parse(JSON.stringify(arcIdsBackup)) }) // resets the changes
    }
    editSharedArc = () => {
        let arcIds = this.state.arcIds
        this.setState({ editSharedArc: true, arcIdsBackup: JSON.parse(JSON.stringify(arcIds)) })
    }
    saveSharedArc = async () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const userConfirmed = window.confirm(language === "sv" ? "Är du helt säker på att du vill utföra ändringarna? Arkitekturer som tagits bort kan inte återställas och ihopslagna eller separerade arkitekturer kommer påverkas av antal arbeten och löner." : lang[language].changeArcSure)
        if (userConfirmed) {
            let arcIds = this.state.arcIds
            // let arcIdsBackup = this.state.arcIdsBackup
            // Simplify the arcId
            let simplified = arcIds.flatMap(group => group.items);
            // Then save
            let response = await fetchAuth(`/api/saveSharedArc`, 'POST', JSON.stringify({ arcIds: simplified }));
            let data = await response.json();
            if (data == 'Saved') {
                //  this.setState({ editSharedArc: false })
                alert(language === "sv" ? "Sparningen lyckades! Laddar om sidan." : lang[language].successSaveReload)
                window.location.reload() // Reload the page
            } else { alert(language === "sv" ? 'Ett problem uppstod vid sparningen. Vänligen prova igen.' : lang[language].errorSave) }
        } else { return }
    }

    notNowBtn = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const userConfirmed = window.confirm(language === "sv" ? "Om du inte ändrar namnet eller värderingarna för arbetena kommer dessa arbeten att döljas i arkitekturen tills ni hanterat det. Ni kommer se valmöjligheten igen nästa gång ni går in på arkitekturen. Vill du fortsätta?" : lang[language].ifNotEditing)
        if (userConfirmed) {
            // Hide all employees that has groupNameArbVardering set to any work inside this.state.duplicateLevels
            const uniqueWorks = Array.from(new Set(this.state.duplicateLevels.map(item => item.groupNameArbVardering))); // This creates a list with only the works like this: ["montör", "Red. Ekonom"]
            let empData = this.state.empData
            const filteredList = empData.filter(
                item => !uniqueWorks.includes(item.groupNameArbVardering)
            );
            this.setState({
                empData: filteredList,
                duplicateLevels: []
            })
            this.getDataAndComments(); // rerun the function that runs on componentDidMount, but use the updated empData
        } else { return }
    }

    changeValuationBtn = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        alert(language === "sv" ? "För att ändra värdering, säkerställ att du är inne på rätt bolag, det bolag du representerar står alltid längst upp åt höger. Gå sedan till steg 2.2 i menyn till vänster och justera arbetsvärderingen så att slutsumman blir samma som nivån du vill ändra till." : lang[language].changeEvaluationInfo)
    }
    changeNamePop = (event) => {
        const companyName = event.target.dataset.compname;
        const workName = event.target.dataset.popname;
        this.state.changeNamePop ? this.setState({ changeNamePop: false, changeName: false, changeNameCompany: false }) : this.setState({ changeNamePop: true, changeName: workName, changeNameCompany: companyName })
    }
    inputNewName = (event) => { this.setState({ inputNewName: event.target.value }) }

    updateNewName = async () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const newName = this.state.inputNewName
        const oldName = this.state.changeName
        const company = this.state.changeNameCompany
        if (newName.length < 1 || newName === " ") {
            alert(language === "sv" ? "Du måste ange ett nytt namn." : lang[language].addNewName)
        }
        // Now update the name
        let response = await fetchAuth(`/api/changeWorkName`, 'POST', JSON.stringify({ newName: newName, oldName: oldName, company: company }));
        let data = await response.json();
        if (data == 'Saved') {
            alert(language === "sv" ? "Namnet har ändrats!" : lang[language].nameHasChanged)
            window.location.reload()
        } else { alert(language === "sv" ? 'Ett problem uppstod. Vänligen prova igen och säkerställ att namnet inte finns sedan tidigare.' : lang[language].errorNameExist) }
    }

    updateSalaryRangesPop = () => { this.state.updateSalaryRangesPop ? this.setState({ updateSalaryRangesPop: false }) : this.setState({ updateSalaryRangesPop: true }) }

    toggleRangeSettingInfoPop = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (!this.state.toggleRangeSettingInfoPop) {

            if (event.target.id === "marketPosition") {
                this.setState({
                    toggleRangeSettingInfoPop: true,
                    rangeSettingInfoTitle: language === "sv" ? "Marknadsposition" : lang[language].marketPosition,
                    rangeSettingInfoContent: language === "sv" ? "Välj om ni vill vara i nivå, leda eller eftersläpa marknaden." : lang[language].marketPositionInfo
                })
                return;
            }
            if (event.target.id === "sector") {
                this.setState({
                    toggleRangeSettingInfoPop: true,
                    rangeSettingInfoTitle: language === "sv" ? "Sektor" : lang[language].sector,
                    rangeSettingInfoContent: language === "sv" ? "Välj om ni vill använda den totala marknadslönedatan eller enbart privat eller offentlig sektor." : lang[language].sectorInfo
                })
                return
            }
            if (event.target.id === "progression") {
                this.setState({
                    toggleRangeSettingInfoPop: true,
                    rangeSettingInfoTitle: language === "sv" ? "Progression" : lang[language].progression,
                    rangeSettingInfoContent: language === "sv" ? "Progression innebär att varje nivå skall ha ett högre lönespann än föregående. Till exempel så kan nivå 3 inte ha en lägre minsta eller högsta lön än nivå 2. Detta är rekommenderat då högre nivå bör innebära högre lön." : lang[language].progressionInfo
                })
                return
            }
            if (event.target.id === "maxSpread") {
                this.setState({
                    toggleRangeSettingInfoPop: true,
                    rangeSettingInfoTitle: language === "sv" ? "Max spridning" : lang[language].maxSpread,
                    rangeSettingInfoContent: language === "sv" ? "Ett maxtak för hur brett spannet får vara mellan högsta och lägsta lön. 100% innebär att den högsta lönen kan vara max dubbelt så hög som den lägsta." : lang[language].maxSpreadInfo
                })
                return
            }
            if (event.target.id === "minSpread") {
                this.setState({
                    toggleRangeSettingInfoPop: true,
                    rangeSettingInfoTitle: language === "sv" ? "Lägsta spridning" : lang[language].minSpread,
                    rangeSettingInfoContent: language === "sv" ? "En lägsta nivå för hur brett spannet måste vara mellan högsta och lägsta lön. 20% innebär att den högsta lönen måste vara 20% högre än den lägsta." : lang[language].minSpreadInfo
                })
                return
            }
            if (event.target.id === "raiseMinimum") {
                this.setState({
                    toggleRangeSettingInfoPop: true,
                    rangeSettingInfoTitle: language === "sv" ? "Minsta ökning per nivå" : lang[language].minIncreasePerLevel,
                    rangeSettingInfoContent: language === "sv" ? "Endast valbart om progression är aktiverat. Den minsta ökningen i procent som progressionen i varje nivå måste öka med. 10% innebär att den lägsta och högsta lönen måste vara minst 10% högre i nästa nivå jämfört med nuvarande. Notera att detta är en lägsta nivå varav progressionen kan bli högre." : lang[language].minIncreasePerLevelInfo
                })
                return
            }



        } else {
            this.setState({ toggleRangeSettingInfoPop: false })
        }


    }
    closeRangeSettingInfoPop = () => { this.setState({ toggleRangeSettingInfoPop: "" }) }

    componentRef = React.createRef();
    lineHeightCalculateRef = React.createRef();

    handleBeforePrint = () => {
        window.history.replaceState(null, "", window.location.pathname); // Remove hash if added
    };

    handleAfterPrint = () => {
        window.history.replaceState(null, "", window.location.pathname); // Reset URL after print
    };

    render() {

        let language = localStorage.getItem('language') ?? 'sv';
        let widthOfContainer = 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) // The actual width of the div
        let printerWidth = 1123 // pixel width for the pdf
        let scale = printerWidth / widthOfContainer

        {/* Create columns for architecture depending on number of equivalent groups*/ }
        const columns = []
        for (let i = 0; i < this.state.nrOfEqualentGroups; i++) {
            const currentLevel = i + 1;
            const rangeObject = this.state.rangesLevels.find(obj => obj.x === currentLevel);
            const rangeText = rangeObject ? `${rangeObject.range[0].toLocaleString()} - ${rangeObject.range[2].toLocaleString()}` : "-";
            columns.push(
                <div key={i + 1} className="column-manual centerColumnTitle">
                    <div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div>
                    <span className="arcColumnTitleWhiteBack" whatChart="overall" onClick={this.openWhatChart}>{language === "sv" ? "Nivå" : lang[language].level} {currentLevel}</span>
                    <span className={this.state.showSalRange ? "salaryRangeLevelInArc" : "hide"}>{rangeText}</span>
                    <span style={{ position: "fixed", top: 73, zIndex: 1, marginLeft: 48, background: "white", display: this.state.showFixedTitle ? "block" : "none" }}>{language === "sv" ? "Nivå" : lang[language].level} {currentLevel}</span> {/* This is just for fixed titles when scrolling */}
                </div>
            )
        }

        let gridTable = this.state.jobFamilies.slice()

        /////////////////////////////////////

        function getRandomColor() {
            // Generate a random color in hexadecimal format
            return "#" + ((Math.random() * 0xFFFFFF) << 0).toString(16).padStart(6, '0');
        }
        // Update colors if changed
        gridTable.forEach((item, index) => {
            if (item.color === "") {
                const itemWithColor = gridTable.find((otherItem, otherIndex) =>
                    otherIndex !== index && otherItem.family === item.family && otherItem.color !== ""
                );
                if (itemWithColor) {
                    // Use the color from another item with the same family
                    item.color = itemWithColor.color;
                } else {
                    // Generate a random color
                    item.color = getRandomColor();
                }
            }
        });

        //Then, group
        const groupedByFamily = [];
        // gridTable.forEach((item) => {
        gridTable.forEach((item) => {
            const existingFamily = groupedByFamily.find((group) => group.family === item.family);
            if (existingFamily) {
                existingFamily.items.push(item);
            } else {
                groupedByFamily.push({ family: item.family, items: [item], color: item.color });
            }
        });

        const groupedBySubGroup = [];
        groupedByFamily.forEach((family) => {
            const familyGroups = {};
            family.items.forEach((item) => {
                if (!familyGroups[item.subGroup]) {
                    familyGroups[item.subGroup] = [];
                }
                familyGroups[item.subGroup].push(item);
            });
            const subGroupEntries = Object.entries(familyGroups);
            const subGroupArray = subGroupEntries.map(([subGroup, items]) => ({ subGroup, items }));
            // const subGroup1Array = 
            groupedBySubGroup.push({ family: family.family, subGroups: subGroupArray, color: family.color });
        });

        let gridRows = []
        for (let i = 0; i < groupedBySubGroup.length; i++) {
            gridRows = <div style={{
                display: "grid",
                gridTemplateAreas:
                    `'menu main right right right right'
          'menu main footer footer footer footer'`,
                gap: 10,
                backgroundColor: "#2196F3",
                padding: 10
            }}>
                <div style={{ gridColumn: '1 / span 1' }}>{groupedBySubGroup[i].family}</div>
                {groupedBySubGroup[i].subGroups.map((item, innerIndex) => (
                    <div style={{ gridColumn: '2 / span 1' }}>{item.subGroup}</div>
                ))}
            </div>
        }

        let createdRows = []
        let hierarchy = this.state.hierarchy

        const createColumns = (workData, color, family) => {
            let nrOfEqualentGroups = this.state.nrOfEqualentGroups
            let theDivs = []
            // this.state.sameNameDifferentLevel
            let sameNameDifferentLevel = this.state.sameNameDifferentLevel

            for (let i = 0; i < nrOfEqualentGroups; i++) {
                theDivs.push(<div className="column-manual">
                    {workData.map((item, b) => (item.name == i + 1 && item.groupNameArbVarderings.map((work) => {
                        //  let showCompName = ""
                        // const { lowestFirstFam, highestLastFam } = checkIfBandsExist("individual", work.name, family, showCompName);
                        const { lowestFirstFam, highestLastFam } = checkIfBandsExist("individual", work.name, family);
                        let salaryRange = `${lowestFirstFam === Infinity ? '' : lowestFirstFam} - ${highestLastFam === -Infinity ? '' : highestLastFam}`;
                        return (
                            <div className="column-equalWork" id={work.name} onClick={this.editGroup}>
                                <span class={!this.state.showSalRange || (lowestFirstFam === Infinity && highestLastFam === -Infinity) ? "hide" : "familyBandItem bandColorSingle"}><span>{salaryRange}</span>
                                    {/* <span class="notificationFilter" style={{ marginLeft: 5, marginTop: -10 }}>1</span> */}
                                </span> {/* This is the salary range*/}
                                {/* PopUp */}
                                <div className={this.state.activeInfo.position === work.name ? "popUpArchitectureAbsolute" : "hide"}>
                                    <div>
                                        <img alt="" src={exitImg} class="hide-box" style={{ width: 22 }} onClick={this.exitEditGroupPop} />
                                        {this.state.showJobDesc && <img alt="" src={jobInfo} class="hide-box" style={{ width: 20, marginTop: 0.5, marginRight: 9 }} onClick={this.showJobDesc} data-egroup={work.name} />}
                                        <div className="createSalBandBtn" whatChart="individual" onClick={this.openWhatChart}>{language === "sv" ? "Skapa lönespann" : lang[language].createSalaryRange}</div>
                                        <div className="titleFamily" style={{ marginTop: 0, marginBottom: 20, paddingTop: 3 }}>{language === "sv" ? "Jobbfamilj" : lang[language].jobFamily}</div>
                                        <div className={this.state.showInputNewFam !== "family" ? "" : "hide"}>
                                            <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} onChange={this.selectFamily}>
                                                <option value={family === false || family === "" || family === null || family === undefined ? (language === "sv" ? "Välj kategori" : lang[language].chooseCategory) : family}>{family === false || family === "" || family === null || family === undefined ? (language === "sv" ? "Välj kategori" : lang[language].chooseCategory) : family}</option>
                                                {/* <JobFamilies /> */}
                                                {this.state.familySelectList.map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {item === "" ? language === "sv" ? "- Ta bort -" : lang[language].remove : item}
                                                    </option>
                                                ))}

                                            </select>
                                            <div className="plusBtnDivContainer">
                                                <div className="plusBtnDivBack" whatFam="family" onClick={this.showInputNewFam}>{language === "sv" ? "Skapa ny" : lang[language].create} </div><img src={addIcon} className="plusBtnSalaryRanges" />
                                            </div>
                                        </div>
                                        {/* <div style={{ display: "inline", marginLeft: 5 }}>eller */}
                                        <div className={this.state.showInputNewFam === "family" ? "" : "hide"}>
                                            <input type="text" className="inputFamily" placeholder={language === "sv" ? "Skapa ny" : lang[language].create} onChange={this.changeFamilyToList} value={this.state.addFamilytoList} />
                                            <div data-family={family} data-whatSub="family" className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }}>{language === "sv" ? "Skapa" : lang[language].create}</div>
                                            <div data-family={family} data-whatSub="family" className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }}>{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                        </div>
                                        {/*</div>*/}
                                    </div>
                                    <div style={{ textDecoration: "underline", marginTop: 15, marginBottom: 5, cursor: "pointer", color: "#31a390", fontWeight: 600 }} onClick={this.showMore}>{!this.state.showMore ? (language === "sv" ? "Visa mer" : lang[language].showMore) : (language === "sv" ? "Visa mindre" : lang[language].showLess)}</div>
                                    <div className={!this.state.showMore ? "hide" : "moreDetailsFamily"}>
                                        <div>
                                            <div className="titleFamily">{language === "sv" ? "Funktion" : lang[language].function}</div>
                                            <div className={this.state.showInputNewFam !== "mainFunction" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="mainFunction" onChange={this.selectMainFunction}>
                                                    <option value={this.state.activeInfo.whatMainFunction === null || this.state.activeInfo.whatMainFunction === "" || this.state.activeInfo.whatMainFunction === undefined ? (language === "sv" ? "Välj jobbfunktion" : lang[language].chooseJobFunction) : this.state.activeInfo.whatMainFunction}>{this.state.activeInfo.whatMainFunction === null || this.state.activeInfo.whatMainFunction === "" || this.state.activeInfo.whatMainFunction === undefined ? (language === "sv" ? "Välj jobbfunktion" : lang[language].chooseJobFunction) : this.state.activeInfo.whatMainFunction}</option>
                                                    {this.state.mainFunctionsSelectList.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item === "" ? language === "sv" ? "- Ta bort -" : lang[language].remove : item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="mainFunction" onClick={this.showInputNewFam}>{language === "sv" ? "Skapa ny" : lang[language].create}</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>
                                            <div className={this.state.showInputNewFam === "mainFunction" ? "" : "hide"}>
                                                {/* <input type="text" className="inputFamily" placeholder="Skapa ny" /> */}
                                                <input type="text" className="inputFamily" placeholder={language === "sv" ? "Skapa ny" : lang[language].create} onChange={this.changeMainFunction} value={this.state.addMainFunction} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="mainFunction">{language === "sv" ? "Skapa" : lang[language].create}</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="mainFunction">{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="titleFamily">{language === "sv" ? "Subfunktion" : lang[language].subFunction}</div>
                                            <div className={this.state.showInputNewFam !== "subFunction" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="subFunction" onChange={this.selectMainFunction}>
                                                    <option value={this.state.activeInfo.whatSubfunction === null || this.state.activeInfo.whatSubfunction === "" || this.state.activeInfo.whatSubfunction === undefined ? (language === "sv" ? "Välj jobbfunktion" : lang[language].chooseJobFunction) : this.state.activeInfo.whatSubfunction}>{this.state.activeInfo.whatSubfunction === null || this.state.activeInfo.whatSubfunction === "" || this.state.activeInfo.whatSubfunction === undefined ? (language === "sv" ? "Välj jobbfunktion" : lang[language].chooseJobFunction) : this.state.activeInfo.whatSubfunction}</option>
                                                    {this.state.subFunctionSelectList.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item === "" ? language === "sv" ? "- Ta bort -" : lang[language].remove : item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="subFunction" onClick={this.showInputNewFam}>{language === "sv" ? "Skapa ny" : lang[language].create}</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>
                                            <div className={this.state.showInputNewFam === "subFunction" ? "" : "hide"}>
                                                <input type="text" className="inputFamily" placeholder={language === "sv" ? "Skapa ny" : lang[language].create} onChange={this.changeSubFunction} value={this.state.addSubFunction} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="subFunction">{language === "sv" ? "Skapa" : lang[language].create}</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="subFunction">{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="titleFamily">{language === "sv" ? "Subfunktion" : lang[language].subFunction} 2</div>
                                            <div className={this.state.showInputNewFam !== "subFunction2" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="subFunction2" onChange={this.selectMainFunction}>
                                                    <option value={this.state.activeInfo.whatSubfunction2 === null || this.state.activeInfo.whatSubfunction2 === "" || this.state.activeInfo.whatSubfunction2 === undefined ? (language === "sv" ? "Välj jobbfunktion" : lang[language].chooseJobFunction) : this.state.activeInfo.whatSubfunction2}>{this.state.activeInfo.whatSubfunction2 === null || this.state.activeInfo.whatSubfunction2 === "" || this.state.activeInfo.whatSubfunction2 === undefined ? "Välj subfunktion 2" : this.state.activeInfo.whatSubfunction2}</option>
                                                    {this.state.subFunctionSelectList2.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item === "" ? language === "sv" ? "- Ta bort -" : lang[language].remove : item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="subFunction2" onClick={this.showInputNewFam}>{language === "sv" ? "Skapa ny" : lang[language].create}</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>
                                            <div className={this.state.showInputNewFam === "subFunction2" ? "" : "hide"}>
                                                <input type="text" className="inputFamily" placeholder={language === "sv" ? "Skapa ny" : lang[language].create} onChange={this.changeSubFunction2} value={this.state.addSubFunction2} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="subFunction2">{language === "sv" ? "Skapa" : lang[language].create}</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="subFunction2">{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="titleFamily">{language === "sv" ? "Subfunktion" : lang[language].subFunction} 3</div>
                                            <div className={this.state.showInputNewFam !== "subFunction3" ? "" : "hide"}>
                                                <select className="selectFamily" style={{ float: "none", borderRadius: 3 }} data-family={family} data-whatSub="subFunction3" onChange={this.selectMainFunction}>
                                                    <option value={this.state.activeInfo.whatSubfunction3 === null || this.state.activeInfo.whatSubfunction3 === "" || this.state.activeInfo.whatSubfunction3 === undefined ? (language === "sv" ? "Välj jobbfunktion" : lang[language].chooseJobFunction) : this.state.activeInfo.whatSubfunction3}>{this.state.activeInfo.whatSubfunction3 === null || this.state.activeInfo.whatSubfunction3 === "" || this.state.activeInfo.whatSubfunction3 === undefined ? "Välj subfunktion 3" : this.state.activeInfo.whatSubfunction3}</option>
                                                    {this.state.subFunctionSelectList3.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item === "" ? language === "sv" ? "- Ta bort -" : lang[language].remove : item}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div className="plusBtnDivContainer">
                                                    <div className="plusBtnDivBack" whatFam="subFunction3" onClick={this.showInputNewFam}>{language === "sv" ? "Skapa ny" : lang[language].create}</div><img src={addIcon} className="plusBtnSalaryRanges" />
                                                </div>
                                            </div>
                                            <div className={this.state.showInputNewFam === "subFunction3" ? "" : "hide"}>
                                                <input type="text" className="inputFamily" placeholder={language === "sv" ? "Skapa ny" : lang[language].create} onChange={this.changeSubFunction3} value={this.state.addSubFunction3} />
                                                <div data-family={family} className="createBtnH" onClick={this.createMainFunction} style={{ marginLeft: 5 }} data-whatSub="subFunction3">{language === "sv" ? "Skapa" : lang[language].create}</div>
                                                <div data-family={family} className="createBtnH" onClick={this.showInputNewFamHide} style={{ marginLeft: 5, background: "#aaaaaa" }} data-whatSub="subFunction3">{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End of popUp */}
                                <span className={!this.state.showSalRange || (lowestFirstFam === Infinity && highestLastFam === -Infinity) ? "column-stripe" : "column-stripe column-stripe-salary"} style={{ background: color }}></span>
                                <span className="name-stripe">{work.name}</span>
                                <div className={this.state.toggleTitles ? "showTitleNames" : "showTitleNames hide"}>
                                    {[...new Set(work.positionTitles)].sort().map((title, index) => (
                                        //   <div className={work.positionTitles.length === 1 && title.trim() === work.name.trim() ? "hide" : ""} key={"work" + index}>{title}</div> // This hides it if only one work that is the same
                                        <div style={{ marginBottom: 5, color: "#6f6f6f" }} key={"work" + index}>{title}</div>
                                    ))}
                                </div>

                            </div>
                        )
                    })))}
                </div>)
            }
            return theDivs
        }

        let checkIfBandsExist = (familyOrFunc, name, familyName, company) => {
            let uniqueFamily = []
            if (familyOrFunc === "individual") {
                uniqueFamily = this.state[familyOrFunc].filter(obj => obj.range !== "hide" && obj.name === name);
            } else {
                uniqueFamily = this.state[familyOrFunc].filter(obj => obj.range !== "hide" && obj.name === name && obj.familyName === familyName);
            }

            let lowestFirstFam = Infinity;
            let highestLastFam = -Infinity;
            for (const obj of uniqueFamily) { // Get the lowest and highest salaries
                if (obj.range[0] < lowestFirstFam) { lowestFirstFam = obj.range[0]; }
                if (obj.range[obj.range.length - 1] > highestLastFam) { highestLastFam = obj.range[obj.range.length - 1]; }
            }
            return { lowestFirstFam: lowestFirstFam, highestLastFam: highestLastFam }
        }

        let createSalaryBandItems = (familyOrFunc, name, color, familyName) => { // Create salary bands for viewing in architecture
            for (let i = 0; i < hierarchy.length; i++) {
                let bandItems = [];

                // Add the family salary band
                let checkBands = checkIfBandsExist(familyOrFunc, name, familyName)
                let lowestFirstFam = checkBands.lowestFirstFam
                let highestLastFam = checkBands.highestLastFam

                if (lowestFirstFam === Infinity && highestLastFam === -Infinity) { return [] }  // If there are no salary bands, just return empty. This is also used for hiding the salary bands rows
                bandItems.push(
                    <span className="familyBandItem" style={{ order: 0 }}>
                        <span style={{ marginLeft: -10 }}>{(lowestFirstFam === Infinity ? "" : lowestFirstFam.toLocaleString()) + " - " + (highestLastFam === -Infinity ? "" : highestLastFam.toLocaleString())}</span>
                    </span>
                );
                if (this.state.nrOfSubFamilies > 0 && familyOrFunc === "family") { // If there is one sub family (mainFunction), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                if (this.state.nrOfSubFamilies > 1 && familyOrFunc === "family") { // If there is two sub families (subFamily), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                if (this.state.nrOfSubFamilies > 2 && familyOrFunc === "family") { // If there is three sub families (subFamily2), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "family") { // If there is four sub families (subFamily3), create an empty span to fill the spot
                    bandItems.push(
                        <span className="familyBandItem">
                            <span style={{ marginLeft: -10 }}>{""}</span>
                        </span>
                    );
                }
                // Add salary for each level in the family
                for (let j = 0; j < (this.state.nrOfEqualentGroups); j++) {
                    const currentLevel = j + 1;
                    let rangeObject = this.state[familyOrFunc].find(obj => obj.x === currentLevel && obj.name === name && obj.familyName === familyName);

                    const rangeText = rangeObject ? `${rangeObject.range[0].toLocaleString()} - ${rangeObject.range[2].toLocaleString()}` : "";

                    bandItems.push(
                        <span key={j} className="familyBandItem" style={{ order: j + this.state.nrOfSubFamilies }}> {/* NOTE: The order setts where the order for the flex divs */}
                            <span style={{ marginLeft: -10 }}>{rangeText}</span>
                        </span>
                    );
                    if (this.state.nrOfSubFamilies > 1 && familyOrFunc === "mainFunction" && j === 1) {  // If we have two subfunctions, and this is mainFunction, then add an empty span where the "subFunction" should be
                        bandItems.push(
                            <span className="familyBandItem" style={{ order: 1 }}>
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 2 && familyOrFunc === "mainFunction" && j === 1) {  // If we have three subfunctions, and this is mainFunction, then add an empty span where the "subFunction" should be and where the "subFunction2" should be
                        bandItems.push(
                            <span className="familyBandItem" style={{ order: 2 }}>
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "mainFunction" && j === 1) {  // If we have four subfunctions, and this is mainFunction, then add an empty span where the "subFunction" should be and where the "subFunction2" should be and where subFunction3 should be
                        bandItems.push(
                            <span className="familyBandItem" style={{ order: 3 }}>
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 2 && familyOrFunc === "subFunction" && j === 1) {  // If we have three subfunctions, and this is subFunction, then add an empty span where the "subFunction2" should be
                        bandItems.push(
                            <span className="familyBandItem" style={{ order: 4 }}>
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "subFunction" && j === 1) {  // If we have four subfunctions, and this is subFunction, then add an empty span where the "subFunction2" should be and where the subFunction3 should be
                        bandItems.push(
                            <span className="familyBandItem" style={{ order: 5 }}>
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                    if (this.state.nrOfSubFamilies > 3 && familyOrFunc === "subFunction2" && j === 1) {  // If we have four subfunctions, and this is subFunction, then add an empty span where the "subFunction2" should be and where the subFunction3 should be
                        bandItems.push(
                            <span className="familyBandItem" style={{ order: 6 }}>
                                <span style={{ marginLeft: -10 }}>{""}</span>
                            </span>
                        );
                    }
                }
                return bandItems
            }
        }

        //--- If only family  ---
        if (this.state.nrOfSubFamilies < 1) {
            for (let i = 0; i < hierarchy.length; i++) {

                // Create the columns and rows
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {(this.state.activeInfo.whatFam === this.state.hierarchy[i].name && this.state.activeWhat == "family") && this.popUpForFamilies(this.state.hierarchy[i].name, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name, "", this.state.hierarchy[i].name).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}> {/* This is the salary band row*/}
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color, this.state.hierarchy[i].name)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={this.state.hierarchy[i].name} data-whatcat={"family"} data-whatfam={this.state.hierarchy[i].name} onClick={this.popUpForFamiliesToggle}><span data-whatfam={this.state.hierarchy[i].name} data-popfamily={this.state.hierarchy[i].name} data-whatcat={"family"} className="column-stripe" style={{ background: hierarchy[i].color }}></span><span data-whatfam={this.state.hierarchy[i].name} data-whatcat={"family"} data-popfamily={this.state.hierarchy[i].name} className="name-stripe">{hierarchy[i].name}</span></span>

                        <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                            {createColumns(hierarchy[i].mainFunctions[0].subFunctions[0].subFunction2s[0].subFunction3s[0].equalentGroups, hierarchy[i].color, this.state.hierarchy[i].name)}
                        </div>
                    </div>
                </div>
                createdRows.push(createRow)
            }
        }

        //--- If family and function ---
        if (this.state.nrOfSubFamilies === 1) {
            for (let i = 0; i < this.state.hierarchy.length; i++) {
                let familyName = this.state.hierarchy[i].name;
                let color = this.state.hierarchy[i].color;
                // Create the columns
                let createRow = (
                    <div key={i} className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                        {(this.state.activeInfo.whatFam === familyName && this.state.activeWhat == "family") && this.popUpForFamilies(familyName, "family")}
                        <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name, "", familyName).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                            {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color, familyName)} {/* Creates the salary band items */}
                        </span>
                        <div className="column-row">
                            <span className="column-sub" data-popfamily={familyName} data-whatcat={"family"} data-whatfam={familyName} onClick={this.popUpForFamiliesToggle}>
                                <span className="column-stripe" style={{ background: color }} data-popfamily={familyName} data-whatcat={"family"} data-whatfam={familyName}></span>
                                <span className="name-stripe" data-popfamily={familyName} data-whatcat={"family"} data-whatfam={familyName}>{familyName}</span>
                            </span>
                            {this.state.hierarchy[i].mainFunctions.map((mainFunction, a) => (

                                <>
                                    {(this.state.activeInfo.whatMainFunction === mainFunction.name && this.state.activeWhat == "mainFunction" && this.state.activeInfo.whatFam === familyName) && this.popUpForFamilies(mainFunction.name, "mainFunction")}
                                    <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunction.name, "emptyFiller", familyName).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                        {createSalaryBandItems("mainFunction", mainFunction.name, "empyFiller", familyName)} {/* Creates the salary band items */}
                                    </span>

                                    <div key={a} className={
                                        a === 0 ? "subGroupFirst marginLeftColumn funcHover" :
                                            (a !== this.state.hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") :
                                                (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))
                                    }>
                                        <span className={mainFunction.name == null ? "hide" : "column-sub"} data-popfamily={mainFunction.name} data-whatcat={"mainFunction"} data-whatfam={familyName} onClick={this.popUpForFamiliesToggle}>
                                            <span className="column-stripe" style={{ background: color }} data-popfamily={mainFunction.name} data-whatcat={"mainFunction"} data-whatfam={familyName}></span>
                                            <span className="name-stripe" data-popfamily={mainFunction.name} data-whatcat={"mainFunction"} data-whatfam={familyName}>{mainFunction.name}</span>
                                        </span>
                                        <div style={{ display: "inline-block", verticalAlign: "top" }} className="marginLeftColumn">
                                            {createColumns(mainFunction.subFunctions[0].subFunction2s[0].subFunction3s[0].equalentGroups, color, familyName)}
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                );
                createdRows.push(createRow)
            }
        }

        //If family, function and subFunction1 
        if (this.state.nrOfSubFamilies === 2) {
            for (let i = 0; i < hierarchy.length; i++) {
                let family = hierarchy[i].name
                let color = hierarchy[i].color
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {(this.state.activeInfo.whatFam === family && this.state.activeWhat == "family") && this.popUpForFamilies(family, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name, "", family).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color, family)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={family} data-whatcat={"family"} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-whatfam={family} data-popfamily={family} data-whatcat={"family"} className="column-stripe" style={{ background: color }}></span>
                        <span data-whatfam={family} data-popfamily={family} data-whatcat={"family"} className="name-stripe">{hierarchy[i].name}</span>
                    </span>
                        {hierarchy[i].mainFunctions.map((mainFunctions, a) => (
                            <>
                                {(this.state.activeInfo.whatMainFunction === mainFunctions.name && this.state.activeWhat == "mainFunction" && this.state.activeInfo.whatFam === family) && this.popUpForFamilies(mainFunctions.name, "mainFunction")}
                                <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunctions.name, "", family).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                    {createSalaryBandItems("mainFunction", mainFunctions.name, "", family)} {/* Creates the salary band items */}
                                </span>

                                {/*  <div className={a !== hierarchy[i].mainFunctions.length - 1 ? "subGroup marginLeftColumn" : "subGroupLast marginLeftColumn"}>*/}
                                <div className={a === 0 ? "subGroupFirst marginLeftColumn funcHover" : (a !== hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))}>
                                    <span className={mainFunctions.name == null ? "hide" : "column-sub"} data-popfamily={mainFunctions.name} data-whatcat={"mainFunction"} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-popfamily={mainFunctions.name} data-whatcat={"mainFunction"} data-whatfam={family} className="column-stripe" style={{ background: color }}></span>
                                        <span data-popfamily={mainFunctions.name} data-whatcat={"mainFunction"} data-whatfam={family} className="name-stripe">{mainFunctions.name}</span>
                                    </span>
                                    {/* Sub-function */}
                                    {mainFunctions.subFunctions.map((subFunction, b) => ( // <-- There is a bug here creating "subGroupFirst" for wrong div, but it "works" visually so not changing it
                                        //  <div className={a !== subFunction.length - 1 ? "subGroup marginLeftColumn" : "subGroupLast marginLeftColumn"}>
                                        <>
                                            {(this.state.activeInfo.whatSubfunction === subFunction.name && this.state.activeWhat == "subFunction" && this.state.activeInfo.whatFam === family && this.state.activeInfo.whatMainFunction === mainFunctions.name) && this.popUpForFamilies(subFunction.name, "subFunction")}
                                            <span className={this.state.showSalRange && createSalaryBandItems("subFunction", subFunction.name, "", family).length > 0 ? b === 0 ? "familyBand mainFuncBandFirst bandColorThree" : "familyBand mainFuncBand bandColorThree" : "hide"} style={{ marginLeft: 132, width: ((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131 }}>
                                                {createSalaryBandItems("subFunction", subFunction.name, "", family)} {/* Creates the salary band items */}
                                            </span>

                                            <div className={b === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (b !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                <span className={subFunction.name == null ? "hide" : "column-sub"} data-popfamily={subFunction.name} data-whatcat={"subFunction"} data-whatfam={family} data-whatMainFunction={mainFunctions.name} onClick={this.popUpForFamiliesToggle}><span data-whatfam={family} data-whatMainFunction={mainFunctions.name} data-whatcat={"subFunction"} data-popfamily={subFunction.name} className="column-stripe" style={{ background: color }} ></span>
                                                    {/*} <span className={this.state.showSalRange ? "salaryRangeInArc" : "hide"}>20 000 - 40 000</span> {/* <-- OBS! TESTAR LÖNESPANN */}
                                                    <span data-popfamily={subFunction.name} data-whatcat={"subFunction"} data-whatMainFunction={mainFunctions.name} data-whatfam={family} className="name-stripe">{subFunction.name}</span>
                                                </span>
                                                <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                                                    {createColumns(subFunction.subFunction2s[0].subFunction3s[0].equalentGroups, color, family)}
                                                </div>
                                            </div>
                                        </>
                                    )

                                    )}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                createdRows.push(createRow)
            }
        }


        //If family, function, subFunction1 and subFunction 2
        if (this.state.nrOfSubFamilies === 3) {
            for (let i = 0; i < hierarchy.length; i++) {
                let family = hierarchy[i].name
                let color = hierarchy[i].color
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {(this.state.activeInfo.whatFam === family && this.state.activeWhat == "family") && this.popUpForFamilies(family, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name, "", family).length > 0 ? "familyBand bandColorOne" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color, family)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={family} data-whatcat={"family"} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-whatfam={family} data-whatcat={"family"} data-popfamily={family} className="column-stripe" style={{ background: color }}></span><span data-whatfam={family} data-whatcat={"family"} data-popfamily={family} className="name-stripe">{hierarchy[i].name}</span></span>
                        {/* Function */}
                        {hierarchy[i].mainFunctions.map((mainFunctions, a) => (
                            <>
                                {(this.state.activeInfo.whatMainFunction === mainFunctions.name && this.state.activeWhat == "mainFunction" && this.state.activeInfo.whatFam === family) && this.popUpForFamilies(mainFunctions.name, "mainFunction")}
                                <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunctions.name, "", family).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                    {createSalaryBandItems("mainFunction", mainFunctions.name, "", family)} {/* Creates the salary band items */}
                                </span>
                                {/* <div className={a !== hierarchy[i].mainFunctions.length - 1 ? "subGroup marginLeftColumn" : "subGroupLast marginLeftColumn"}> */}
                                <div className={a === 0 ? "subGroupFirst marginLeftColumn funcHover" : (a !== hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))}>
                                    <span className={mainFunctions.name == null ? "hide" : "column-sub"} data-popfamily={mainFunctions.name} data-whatcat={"mainFunction"} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-whatfam={family} data-whatcat={"mainFunction"} data-popfamily={mainFunctions.name} className="column-stripe" style={{ background: color }} ></span><span data-whatfam={family} data-whatcat={"mainFunction"} data-popfamily={mainFunctions.name} className="name-stripe">{mainFunctions.name}</span></span>
                                    {/* Sub-function */}
                                    {mainFunctions.subFunctions.map((subFunction, b) => (
                                        <>
                                            {(this.state.activeInfo.whatSubfunction === subFunction.name && this.state.activeWhat == "subFunction" && this.state.activeInfo.whatFam === family && this.state.activeInfo.whatMainFunction === mainFunctions.name) && this.popUpForFamilies(subFunction.name, "subFunction")}
                                            <span className={this.state.showSalRange && createSalaryBandItems("subFunction", subFunction.name, "", family).length > 0 ? b === 0 ? "familyBand mainFuncBandFirst bandColorThree" : "familyBand mainFuncBand bandColorThree" : "hide"} style={{ marginLeft: 132, width: ((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131 }}>
                                                {createSalaryBandItems("subFunction", subFunction.name, "", family)} {/* Creates the salary band items */}
                                            </span>

                                            <div className={b === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (b !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                <span className={subFunction.name == null ? "hide" : "column-sub"} data-popfamily={subFunction.name} data-whatcat={"subFunction"} data-whatMainFunction={mainFunctions.name} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatcat={"subFunction"} data-popfamily={subFunction.name} className="column-stripe" style={{ background: color }}></span><span data-whatfam={family} data-whatMainFunction={mainFunctions.name} data-whatcat={"subFunction"} data-popfamily={subFunction.name} className="name-stripe">{subFunction.name}</span></span>
                                                {/* Sub-function 2*/}
                                                {subFunction.subFunction2s.map((sub2, c) => (
                                                    <>
                                                        {(this.state.activeInfo.whatSubfunction2 === sub2.name && this.state.activeWhat == "subFunction2" && this.state.activeInfo.whatFam === family && this.state.activeInfo.whatMainFunction === mainFunctions.name && this.state.activeInfo.whatSubfunction === subFunction.name) && this.popUpForFamilies(sub2.name, "subFunction2")}
                                                        <span className={this.state.showSalRange && createSalaryBandItems("subFunction2", sub2.name, "", family).length > 0 ? c === 0 ? "familyBand mainFuncBandFirst bandColorFour" : "familyBand mainFuncBand bandColorFour" : "hide"} style={{ marginLeft: 132, width: (((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131) - 131 }}>
                                                            {createSalaryBandItems("subFunction2", sub2.name, "", family)} {/* Creates the salary band items */}
                                                        </span>
                                                        <div className={c === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (c !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>

                                                            <span className={sub2.name == null ? "hide" : "column-sub"} data-popfamily={sub2.name} data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatcat={"subFunction2"} data-whatSubfunction={subFunction.name} onClick={this.popUpForFamiliesToggle}><span data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatSubfunction={subFunction.name} data-whatcat={"subFunction2"} data-popfamily={sub2.name} className="column-stripe" /* style={{ background: groupedBySubGroup[i].color }} */></span><span data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatSubfunction={subFunction.name} data-whatcat={"subFunction2"} data-popfamily={sub2.name} className="name-stripe">{sub2.name}</span></span>
                                                            <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                                                                {createColumns(sub2.subFunction3s[0].equalentGroups, color, family)}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        )
                        )}

                    </div>
                </div>
                createdRows.push(createRow)
            }
        }

        //If family, function, subFunction1. subFunction2 and subFunction3
        if (this.state.nrOfSubFamilies === 4) {
            for (let i = 0; i < hierarchy.length; i++) {
                let family = hierarchy[i].name
                let color = hierarchy[i].color
                let createRow = <div className="column-row-style" style={{ background: i % 2 === 0 ? "#f3f9f8" : "white" }}>
                    {(this.state.activeInfo.whatFam === family && this.state.activeWhat == "family") && this.popUpForFamilies(family, "family")}
                    <span className={this.state.showSalRange && createSalaryBandItems("family", this.state.hierarchy[i].name, "", family).length > 0 ? "familyBand" : "hide"} style={{ width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                        {createSalaryBandItems("family", this.state.hierarchy[i].name, hierarchy[i].color, family)} {/* Creates the salary band items */}
                    </span>
                    <div className="column-row"><span className="column-sub" data-popfamily={family} data-whatcat={"family"} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-whatfam={family} data-whatcat={"family"} data-popfamily={family} className="column-stripe" style={{ background: color }}></span><span data-whatfam={family} data-whatcat={"family"} data-popfamily={family} className="name-stripe">{hierarchy[i].name}</span></span>
                        {/* Function */}
                        {hierarchy[i].mainFunctions.map((mainFunctions, a) => (
                            <>
                                {(this.state.activeInfo.whatMainFunction === mainFunctions.name && this.state.activeWhat == "mainFunction" && this.state.activeInfo.whatFam === family) && this.popUpForFamilies(mainFunctions.name, "mainFunction")}
                                <span className={this.state.showSalRange && createSalaryBandItems("mainFunction", mainFunctions.name, "", family).length > 0 ? a === 0 ? "familyBand mainFuncBandFirst bandColorTwo" : "familyBand mainFuncBand bandColorTwo" : "hide"} style={{ marginLeft: 132, width: (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131) }}>
                                    {createSalaryBandItems("mainFunction", mainFunctions.name, "", family)} {/* Creates the salary band items */}
                                </span>
                                <div className={a === 0 ? "subGroupFirst marginLeftColumn funcHover" : (a !== hierarchy[i].mainFunctions.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn funcHover showSalHeight" : "subGroup marginLeftColumn funcHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn funcHover showSalHeight" : "subGroupLast marginLeftColumn funcHover"))}>
                                    <span className={mainFunctions.name == null ? "hide" : "column-sub"} data-popfamily={mainFunctions.name} data-whatcat={"mainFunction"} data-whatfam={family} onClick={this.popUpForFamiliesToggle} ><span data-whatfam={family} data-whatcat={"mainFunction"} data-popfamily={mainFunctions.name} className="column-stripe" style={{ background: color }} ></span><span data-whatfam={family} data-whatcat={"mainFunction"} data-popfamily={mainFunctions.name} className="name-stripe">{mainFunctions.name}</span></span>
                                    {/* Sub-function */}
                                    {mainFunctions.subFunctions.map((subFunction, b) => (
                                        <>
                                            {(this.state.activeInfo.whatSubfunction === subFunction.name && this.state.activeWhat == "subFunction" && this.state.activeInfo.whatFam === family && this.state.activeInfo.whatMainFunction === mainFunctions.name) && this.popUpForFamilies(subFunction.name, "subFunction")}
                                            <span className={this.state.showSalRange && createSalaryBandItems("subFunction", subFunction.name, "", family).length > 0 ? b === 0 ? "familyBand mainFuncBandFirst bandColorThree" : "familyBand mainFuncBand bandColorThree" : "hide"} style={{ marginLeft: 132, width: ((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131 }}>
                                                {createSalaryBandItems("subFunction", subFunction.name, "", family)} {/* Creates the salary band items */}
                                            </span>
                                            <div className={b === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (b !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                <span className={subFunction.name == null ? "hide" : "column-sub"} data-popfamily={subFunction.name} data-whatcat={"subFunction"} data-whatMainFunction={mainFunctions.name} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-whatfam={family} data-whatMainFunction={mainFunctions.name} data-whatcat={"subFunction"} data-popfamily={subFunction.name} className="column-stripe" style={{ background: color }} ></span><span data-whatfam={family} data-whatMainFunction={mainFunctions.name} data-whatcat={"subFunction"} data-popfamily={subFunction.name} className="name-stripe">{subFunction.name}</span></span>
                                                {/* Sub-function 2*/}
                                                {subFunction.subFunction2s.map((sub2, c) => (
                                                    <>
                                                        {(this.state.activeInfo.whatSubfunction2 === sub2.name && this.state.activeWhat == "subFunction2" && this.state.activeInfo.whatFam === family && this.state.activeInfo.whatMainFunction === mainFunctions.name && this.state.activeInfo.whatSubfunction === subFunction.name) && this.popUpForFamilies(sub2.name, "subFunction2")}
                                                        <span className={this.state.showSalRange && createSalaryBandItems("subFunction2", sub2.name, "", family).length > 0 ? c === 0 ? "familyBand mainFuncBandFirst bandColorFour" : "familyBand mainFuncBand bandColorFour" : "hide"} style={{ marginLeft: 132, width: (((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131) - 131 }}>
                                                            {createSalaryBandItems("subFunction2", sub2.name, "", family)} {/* Creates the salary band items */}
                                                        </span>

                                                        <div className={c === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (c !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                            <span className={sub2.name == null ? "hide" : "column-sub"} data-popfamily={sub2.name} data-whatcat={"subFunction2"} data-whatfam={family} data-whatMainFunction={mainFunctions.name} data-whatSubfunction={subFunction.name} onClick={this.popUpForFamiliesToggle}><span data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatSubfunction={subFunction.name} data-whatcat={"subFunction2"} data-popfamily={sub2.name} className="column-stripe" style={{ background: color }} ></span><span data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatSubfunction={subFunction.name} data-whatcat={"subFunction2"} data-popfamily={sub2.name} className="name-stripe">{sub2.name}</span></span>
                                                            {/* Sub-function 3 */}
                                                            {sub2.subFunction3s.map((sub3, d) => (
                                                                <>
                                                                    {(this.state.activeInfo.whatSubfunction3 === sub3.name && this.state.activeWhat == "subFunction3" && this.state.activeInfo.whatFam === family && this.state.activeInfo.whatMainFunction === mainFunctions.name && this.state.activeInfo.whatSubfunction === subFunction.name && this.state.activeInfo.whatSubfunction2 === sub2.name) && this.popUpForFamilies(sub3.name, "subFunction3")}
                                                                    <span className={this.state.showSalRange && createSalaryBandItems("subFunction3", sub3.name, "", family).length > 0 ? d === 0 ? "familyBand mainFuncBandFirst bandColorFive" : "familyBand mainFuncBand bandColorFive" : "hide"} style={{ marginLeft: 132, width: ((((this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131)) - 131) - 131) - 131 }}>
                                                                        {createSalaryBandItems("subFunction3", sub3.name, "", family)} {/* Creates the salary band items */}
                                                                    </span>
                                                                    <div className={d === 0 ? "subGroupFirst marginLeftColumn subFuncHover" : (d !== subFunction.length - 1 ? (this.state.showSalRange ? "subGroup marginLeftColumn subFuncHover showSalHeight" : "subGroup marginLeftColumn subFuncHover") : (this.state.showSalRange ? "subGroupLast marginLeftColumn subFuncHover showSalHeight" : "subGroupLast marginLeftColumn subFuncHover"))}>
                                                                        <span className={sub3.name == null ? "hide" : "column-sub"} data-popfamily={sub3.name} data-whatcat={"subFunction3"} data-whatSubfunction={subFunction.name} data-whatMainFunction={mainFunctions.name} data-whatSubfunction2={sub2.name} data-whatfam={family} onClick={this.popUpForFamiliesToggle}><span data-whatSubfunction={subFunction.name} data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatSubfunction2={sub2.name} data-whatcat={"subFunction3"} data-popfamily={sub3.name} className="column-stripe" style={{ background: color }} ></span><span data-whatSubfunction={subFunction.name} data-whatMainFunction={mainFunctions.name} data-whatfam={family} data-whatSubfunction2={sub2.name} data-whatcat={"subFunction3"} data-popfamily={sub3.name} className="name-stripe">{sub3.name}</span></span>
                                                                        <div style={{ display: "inline-block", verticalAlign: "top", }} className="marginLeftColumn">
                                                                            {createColumns(sub3.equalentGroups, color, family)}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                createdRows.push(createRow)
            }
        }
        const selectedJob = this.state.jobDescriptions.find(job => job.title === this.state.jobDescSelected);

        return (
            <>
                <CustomPrompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
                />
                <TopNav />
                <Menu />
                <div className="container" style={{ marginBottom: 200 }}>
                    <Information step="lonestruktur" />

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButtonFunc : ""}>{this.state.saveButtonStatus}</button>

                    {/* PopUp for change name */}
                    <div className={this.state.changeNamePop ? "popUpChangeName" : "hide"}>
                        <p style={{ marginTop: 200 }}>{language === "sv" ? "Ändra namn för arbetet " : lang[language].changeNameForTheWork}<b>{this.state.changeName}</b>{language === "sv" ? " i bolaget " : lang[language].inTheCompany}<b>{this.state.changeNameCompany}:</b></p>
                        <input className="arcChangeNameInput" type="text" placeholder='Ange nytt namn' onChange={this.inputNewName} value={this.state.inputNewName} />
                        <p>
                            <div style={{ display: "inline-block", padding: 7.5, marginRight: 5 }} className="duplicateNotNowBtn" onClick={this.changeNamePop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                            <div onClick={this.updateNewName} style={{ display: "inline-block", padding: 7.5, marginLeft: 5, background: "linear-gradient(124deg, rgba(85, 203, 173, 1) 50%, rgba(85, 193, 203, 1) 100%)", color: "white" }} className="duplicateNotNowBtn">{language === "sv" ? "Spara" : lang[language].save}</div>
                        </p>
                    </div>

                    {/* PopUp if same work for different companies but different levels (not allowed) */}
                    <div className={this.state.duplicateLevels.length > 0 ? "popUpDifferentLevels" : "hide"}>
                        <p style={{ fontWeight: 800, fontSize: 20 }}>{language === "sv" ? "Hantera arbeten" : lang[language].handleWorks}</p>
                        <p style={{ paddingLeft: 20, paddingRight: 20, textAlign: "left" }}>{language === "sv" ? `Det finns arbeten i olika bolag som har samma namn men olika nivåer. Alla arbeten inom samma arkitektur med samma namn måste även ha samma nivå.
                            Till exempel så kan arbetet "HRBP" inte ligga under nivå 3 för ett bolag och i nivå 4 för ett annat.
                            Byt namn på arbetet eller ändra dess arbetsvärdering så att nivåerna stämmer. Observera att om ni ändrar namn eller värdering så ändras även detta i er lönekartläggning. Ni kan även skapa helt separata arkitekturer om det är många arbeten som värderas olika.`
                            : lang[language].jobsWithDifferenLevelsInfo}
                        </p>
                        <p style={{ paddingLeft: 20, paddingRight: 20, textAlign: "left", marginTop: 25 }}>{language === "sv" ? "Följande arbeten med olika nivåer hittades:" : lang[language].theseWorksAndLevelsFound}</p>
                        <ul style={{ overflowY: "scroll", height: 310, borderBottom: "1px solid black" }}>
                            <li key="header" style={{ margin: '0 10px', borderBottom: "1px solid black" }}>
                                <div className="duplicateLevelsItem" style={{ fontWeight: 600 }}>{language === "sv" ? "Bolag" : lang[language].company}</div>
                                <div className="duplicateLevelsItem" style={{ fontWeight: 600 }}>{language === "sv" ? "Arbete" : lang[language].work}</div>
                                <div className="duplicateLevelsItemLevel " style={{ fontWeight: 600 }}>{language === "sv" ? "Nivå" : lang[language].level}</div>
                                <div className="duplicateLevelsItemBtn" style={{ fontWeight: 600, width: "11%" }}></div>
                                <div className="duplicateLevelsItemBtn" style={{ fontWeight: 600 }}></div>
                            </li>
                            {this.state.duplicateLevels.map((item, index) => (
                                <li key={index} className="duplicateLevelsLi" style={{ margin: '0 10px' }}>
                                    <div className="duplicateLevelsItem">{item.dotterBolagReal}</div>
                                    <div className="duplicateLevelsItem">{item.groupNameArbVardering}</div>
                                    <div className="duplicateLevelsItemLevel ">{item.level}</div>
                                    <div className="duplicateLevelsItemBtn duplicateLevelsItemBtnStyle" style={{ width: "11%" }} onClick={this.changeNamePop} data-compname={item.dotterBolagReal} data-popname={item.groupNameArbVardering}>{language === "sv" ? "Byt namn" : lang[language].changeName}</div>
                                    <div className="duplicateLevelsItemBtn duplicateLevelsItemBtnStyle" onClick={this.changeValuationBtn}>{language === "sv" ? "Ändra värdering" : lang[language].editValuation}</div>
                                </li>
                            ))}
                        </ul>
                        <div className="duplicateNotNowBtn" onClick={this.notNowBtn}>{language === "sv" ? "Inte nu" : lang[language].notNow}</div>
                    </div>
                    <div className={this.state.duplicateLevels.length > 0 ? "popUpDifferentLevelsShader" : "hide"} />

                    {/* PopUp for info about shared architecture*/}

                    <div style={{ padding: 20 }} className={this.state.toggleArcPop ? "arcPopUpShare" : "hide"}>
                        <img src={exitImg} className="arcPopUpInfoBtn" onClick={this.toggleArcPop} />
                        <div>
                            <div style={{ paddingBottom: 10, fontSize: 15 }}><b>{language === "sv" ? "Information om gemensam arkitektur" : lang[language].sharedArcInfo}</b></div>
                            <p>{language === "sv" ? "Flera bolag inom samma koncern kan använda sig av en gemensam jobbarkitektur eller av egna separata. Att använda samma arkitektur för flera bolag kan vara användbart om bolagen normalt hanteras som ett stort, men om de är väldigt olika eller normalt hanteras som separata bolag är det oftast enklare och bättre att använda separata arkitekturer för respektive bolag." : lang[language].sharedArcInfoPartOne}</p>
                            <p>{language === "sv" ? `Om ni vill att flera bolag i samma koncern ska använda sig av samma jobbarkitektur kan ni här ange vilka bolag som skall ha samma arkitektur. Klicka på "redigera" och välj i listan vilken arkitektur respektive bolag skall tillhöra.` : lang[language].sharedArcInfoPartTwo}</p>
                            <div className="observeBoxGroup">
                                <p style={{ textDecoration: "underline", textAlign: "center", fontWeight: 700 }}>{language === "sv" ? "Observera:" : lang[language].noteThis}</p>
                                <ul style={{ listStyle: "disc", paddingLeft: 15 }}>
                                    <li style={{ marginBottom: 15 }}>{language === "sv" ? "Om ni lämnar en arkitektur tom utan bolag så kommer den att raderas. Detta inkluderar alla lönespann och jobbkategoriseringar. Detta går inte att ångra." : lang[language].empyArcInfo}</li>
                                    <li style={{ marginBottom: 15 }}>{language === "sv" ? "Om ni flyttar ett bolag från en arkitektur till en annan som ni redan har sedan tidigare så behöver ni se över och eventuellt uppdatera lönespann och jobbfamiljer, då nya arbeten och anställda tillkommit." : lang[language].moveToExistingArc}</li>
                                    <li style={{ marginBottom: 15 }}>{language === "sv" ? "Om bolagen inte använder samma faktorer eller antal nivåer för arbetsvärderingen kan ni inte använda dem i samma arkitektur. Systemet hindrar er då att välja samma arkitektur." : lang[language].moveDiffLevelsArc}</li>
                                    <li style={{ marginBottom: 15 }}>{language === "sv" ? "Om flera bolag har en gemensam arkitektur med samma namn på arbeten men där dessa har hamnat i olika nivåer, kommer ni behöva byta namn på ett av arbetena eller justera arbetsvärderingen så de har samma nivå. Ni bör även fundera på om inte lika arbete även borde ha samma krav, alternativt om bolagen verkligen skall ha en gemensam arkitektur." : lang[language].sharedArcLevelsInfo}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.toggleArcPop ? "backBlockGroup" : "hide"}></div>

                    {/* PopUp job description */}
                    <div className={this.state.popUpJobDesc ? "popUpJobDesc" : "hide"}>
                        <img style={{ position: "absolute", right: 20, top: 20, width: 22 }} alt="" src={exitImg} class="hide-box" onClick={this.closePopUpJobDesc} />
                        <div style={{
                            textAlign: "left", fontSize: 15, marginBottom: 20, borderBottom: "1px solid #efefef", paddingBottom: 20, marginLeft: -20, marginRight: -20, paddingLeft: 20, paddingRight: 20,


                        }}>
                            <div style={{ display: "inline-block", fontWeight: 600, borderRight: "1px solid #bdbdbd", paddingRight: 10, fontSize: 17 }}>{language === "sv" ? "Arbetsbeskrivning" : lang[language].jobDescription}</div>
                            {/*  <div style={{ display: "inline-block", marginLeft: 10 }}>{this.state.jobDescWhatGroup} </div> */}
                            <div style={{ display: "inline-block", marginLeft: 10 }}>
                                <select onChange={this.selectJobDesc} value={this.state.jobDescSelected} style={{ padding: 5, textAlign: "left", padding: 5, width: 200, width: "fit-content", border: "none", fontSize: 15, fontFamily: `'Inter', sans-serif !important`, cursor: "pointer" }}>
                                    <option value={this.state.jobDescWhatGroup} >{this.state.jobDescWhatGroup}</option>
                                    {this.state.positionsAndEqualWorks
                                        .filter(group => group.groupNameArbVardering === this.state.jobDescWhatGroup) // Filter the group by equalGroup.name
                                        .flatMap(group =>
                                            group.positionTitles.filter(work => work !== group.groupNameArbVardering) // Exclude works that match groupNameArbVardering
                                        )
                                        .map((work, index) => (
                                            <option key={index} value={work}>
                                                {work}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div style={{ textAlign: "left", fontSize: 13 }}>
                            {!this.state.editJobDesc && this.state.jobDescriptions.some(job => job.title === this.state.jobDescSelected) ? (
                                <>
                                    <ReadOnlyDraftEditor rawContent={selectedJob.jobDesc} />
                                    <div className="adjustDraftBtn" onClick={this.showDraftEditor}><img src={editImg} style={{ width: 12, marginRight: 6, verticalAlign: "top", filter: "invert(1)", opacity: 0.7, display: "inline-block", marginTop: 1.5 }} /><div style={{ display: "inline-block", verticalAlign: "top" }}>{language === "sv" ? "Redigera" : lang[language].edit}</div></div>
                                </>
                            ) : !this.state.editJobDesc ? (
                                <div>
                                    <div style={{ textAlign: "center", marginTop: 120 }}>{language === "sv" ? "Ingen arbetsbeskrivning" : lang[language].noJobDescription}</div>
                                    <div onClick={this.showDraftEditor} className="commentButton" style={{ textAlign: "center", margin: "auto", display: "block", marginTop: 20, width: 100 }}>{language === "sv" ? "Lägg till" : lang[language].personBenefitAdd}</div>
                                </div>)
                                : <DraftEditor rawContent={selectedJob && selectedJob.jobDesc ? selectedJob.jobDesc : ""} onSave={this.draftEditorHandleSave} onCancel={this.hideDraftEditor} />
                            }
                        </div>
                    </div>

                    <div className={!this.state.toggleRangeSettingInfoPop ? "hide" : "toggleRangeSettingInfoPop"}>
                        <img style={{ position: "absolute", right: 10, top: 10, width: 19 }} alt="" src={exitImg} class="hide-box" onClick={this.closeRangeSettingInfoPop} />
                        <div style={{ fontWeight: 600, marginBottom: 5 }}>{this.state.rangeSettingInfoTitle}</div>
                        <div style={{ fontSize: 13, width: "95%" }}>{this.state.rangeSettingInfoContent}</div>
                    </div>

                    <div style={{ zIndex: 3 }} className={this.state.popUpJobDesc ? "popUpFader" : "hide"}></div>

                    <div className={this.state.popUp ? "popUpFader" : "hide"}></div>



                    <div className={this.state.salaryRangesOpen ? "salaryRangesPopUpSettings" : "hide"}>
                        <div style={{ fontWeight: 700, marginBottom: 20 }}>{language === "sv" ? "Inställningar" : lang[language].settings}</div>
                        <img style={{ position: "absolute", right: 10, top: 10, width: 22 }} alt="" src={exitImg} class="hide-box" onClick={this.closeAutoCalc} />

                        <div className="slider-container">
                            <div style={{ borderBottom: "1px solid #e2e2e2", paddingBottom: 10 }}>
                                <span style={{ display: 'block', marginBottom: 20, color: '#333333', fontWeight: 600 }}>{language === "sv" ? "Vikt marknad" : lang[language].weightMarket}</span>
                                <div style={{ marginBottom: 20, fontWeight: 700, color: "#46b09c", fontSize: 20, borderBottom: "1px solid #e8e8e8", width: 69, marginLeft: "auto", marginRight: "auto", paddingBottom: 5, marginBottom: 15 }}>
                                    {this.state.marketWeight} <span>%</span></div>
                                <input type="range" min="0" max="100" value={this.state.snappedValue} step="1" class="slider" id="myRange" onChange={this.sliderValue} onMouseUp={this.handleSliderRelease} />
                            </div>
                            <div style={{ padding: 15, textAlign: "left", paddingTop: 15, paddingBottom: 0 }}>
                                <div style={{ paddingBottom: 15, marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, opacity: this.state.marketWeight === 0 ? 0.35 : 1 }}>

                                    <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Marknadsposition" : lang[language].marketPosition}</div>
                                    <img onClick={this.toggleRangeSettingInfoPop} id="marketPosition" src={questionIcon} style={{ width: 14, verticalAlign: "top", marginTop: 7.5, opacity: 0.5, cursor: "pointer" }} />
                                    <select onChange={this.selectMarketPosition} name="midPoint" value={this.state.marketPosition} style={{ padding: 5, float: "right", width: 92, textAlign: "center" }}>
                                        <option value="median" disabled={this.state.marketWeight === 0}>{language === "sv" ? "I nivå" : lang[language].marketInline}</option>
                                        <option value="lag" disabled={this.state.marketWeight === 0}>{language === "sv" ? "Eftersläpa" : lang[language].marketLag}</option>
                                        <option value="lead" disabled={this.state.marketWeight === 0}>{language === "sv" ? "Leda" : lang[language].marketLead}</option>
                                        {/* <option value="medianP25P75" disabled={this.state.marketWeight === 0}>{language === "sv" ? "I nivå p25-p75" : lang[language].marketLead}</option>*/}
                                    </select>
                                </div>

                                <div style={{ paddingBottom: 15, marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, opacity: this.state.marketWeight === 0 ? 0.35 : 1 }}>
                                    <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Sektor" : lang[language].sector}</div>
                                    <img onClick={this.toggleRangeSettingInfoPop} id="sector" src={questionIcon} style={{ width: 14, verticalAlign: "top", marginTop: 7.5, opacity: 0.5, cursor: "pointer" }} />
                                    <select onChange={this.selectMarketSector} name="midPoint" value={this.state.marketSector} style={{ padding: 5, float: "right", width: 92, textAlign: "center" }}>
                                        <option value="all" disabled={this.state.marketWeight === 0}>{language === "sv" ? "Alla" : lang[language].all}</option>
                                        <option value="privat" disabled={this.state.marketWeight === 0}>{language === "sv" ? "Privat" : lang[language].private}</option>
                                        <option value="offentlig" disabled={this.state.marketWeight === 0}>{language === "sv" ? "Offentlig" : lang[language].public}</option>
                                    </select>
                                </div>

                                <div style={{ marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, paddingTop: 15, borderTop: "1px solid #e2e2e2", }}>
                                    <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Skapa progression" : lang[language].createProgression}</div>
                                    <img onClick={this.toggleRangeSettingInfoPop} id="progression" src={questionIcon} style={{ width: 14, verticalAlign: "top", marginTop: 7.5, opacity: 0.5, cursor: "pointer" }} />
                                    <select onChange={this.selectCreateProgression} name="selectCreateProgression" value={this.state.createProgression} style={{ padding: 5, float: "right", width: 92, textAlign: "center" }}>
                                        <option value={"no"}>{language === "sv" ? "Nej" : lang[language].no}</option>
                                        <option value={"yes"}>{language === "sv" ? "Ja" : lang[language].yes}</option>
                                    </select>
                                </div>
                            </div>
                            <div className={this.state.advanceSettings ? "" : "hide"}>
                                <div className="advSettings" onClick={this.toggleAdvancedSettings}>{language === "sv" ? "Avancerade inställningar" : lang[language].advSetting}</div>
                                <div style={{ display: this.state.toggleAdvancedSettings ? "block" : "none", background: "#eeeeee", paddingBottom: 20, marginBottom: -20 }}>
                                    <div style={{ borderTop: "1px solid #e2e2e2", marginTop: 15, textAlign: "left", paddingLeft: 15, paddingTop: 10 }}>
                                        <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Max spridning" : lang[language].maxSpread}</div>
                                        <img onClick={this.toggleRangeSettingInfoPop} id="maxSpread" src={questionIcon} style={{ width: 14, verticalAlign: "top", marginTop: 7.5, opacity: 0.5, cursor: "pointer" }} />
                                        <span className="advSettingPercent">%</span>
                                        <input type="number" className="advSettingInput" onChange={this.maxSpread} value={this.state.maxSpread} />
                                    </div>
                                    <div style={{ borderTop: "1px solid #e2e2e2", marginTop: 10, textAlign: "left", paddingLeft: 15, paddingTop: 10 }}>
                                        <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Lägsta spridning" : lang[language].minSpread}</div>
                                        <img onClick={this.toggleRangeSettingInfoPop} id="minSpread" src={questionIcon} style={{ width: 14, verticalAlign: "top", marginTop: 7.5, opacity: 0.5, cursor: "pointer" }} />
                                        <span className="advSettingPercent">%</span>
                                        <input type="number" className="advSettingInput" onChange={this.minSpread} value={this.state.minSpread} />
                                    </div>
                                    <div style={{ borderTop: "1px solid #e2e2e2", marginTop: 10, textAlign: "left", paddingLeft: 15, paddingTop: 10, marginBottom: -10, opacity: this.state.createProgression !== "yes" ? 0.35 : 1 }}>
                                        <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Minsta ökning per nivå" : lang[language].minIncreasePerLevel}</div>
                                        <img onClick={this.toggleRangeSettingInfoPop} id="raiseMinimum" src={questionIcon} style={{ width: 14, verticalAlign: "top", marginTop: 7.5, opacity: 0.5, cursor: "pointer" }} />
                                        <span className="advSettingPercent">%</span>
                                        <input disabled={this.state.createProgression !== "yes"} type="number" className="advSettingInput" onChange={this.minimumSalaryMinHigher} value={this.state.minimumSalaryMinHigher} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="commentButton" onClick={this.autoCalcSalaryRanges}>{language === "sv" ? "Skapa" : lang[language].create}</div>
                    </div>

                    <div className={this.state.updateSalaryRangesPop ? "popUpUpdateSalaryRanges" : "hide"}>
                        <img style={{ position: "absolute", right: 10, top: 10, width: 22 }} alt="" src={exitImg} class="hide-box" onClick={this.updateSalaryRangesPop} />
                        <div style={{ fontWeight: 700, fontSize: 17 }}>Uppdatera lönespann</div>
                        <p>Uppdatera alla lönespann med en viss procent, till exempel enligt ”märket” eller ett index. Detta uppdaterar alla era lönespann.
                            Vill ni ha mer kontroll kan ni istället manuellt gå in och ändra respektive lönespann. Marknadslönedatan ser ni då i diagrammet och kan därmed ta hänsyn till detta.</p>
                        <p>När ni har klickat på ”Skapa” så kommer alla era lönespann att ha uppdaterats. Ingenting sparas förrens ni klickar på ”Spara” knappen längst ner åt höger.</p>
                        <div className="slider-container" style={{ width: "100%", padding: 20, boxSizing: "border-box", marginTop: 30 }}>

                            <div style={{ paddingBottom: 15, marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, opacity: this.state.marketWeight === 0 ? 0.35 : 1 }}>
                                <div style={{ display: "block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Ange ökning i procent" : lang[language].margetRaiseInfo}:</div>
                                        <input type="number" className="advSettingInput" onChange={this.minSpread} value={this.state.minSpread} style={{float: "none", height: 16, width: 50, marginRight: 10, fontSize: 15}}/>
                                        <span>%</span>
                            </div>
                            <div style={{ paddingBottom: 15, marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, opacity: this.state.marketWeight === 0 ? 0.35 : 1 }}>
                                <div style={{ display: "block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Justera endast lönespann som inte har uppdaterats sedan: " : lang[language].onlyUpdateThis}</div>
                                <input type="date" style={{ width: 110,height: 15, border: "1px solid #767676",fontSize: 13, textAlign: "left", padding: 6, borderRadius: 2 }}/>
                                <div style={{    display: "inline-block", fontSize: 13,width: 320, verticalAlign: "top", marginLeft: 10,fontStyle: "italic",marginTop: 2.5, color: "#767676"}}>Om lämnas tom så justeras alla era lönespann oavsett
                                    när de senast uppdaterades.
                                </div>
                            </div>
                        </div>
                        <div className="commentButton" style={{textAlign: "center",marginLeft: "auto", marginRight: "auto", display: "block", width: 190}}>Uppdatera lönespann</div>
                    </div>
                    <div className={this.state.updateSalaryRangesPop ? "popUpFader" : "hide"} style={{ zIndex: 100 }}></div>

                    {/* PopUp for manual */}
                    <div className={this.state.salaryRangesManualOpen ? "salaryRangesPopUpSettings" : "hide"}>
                        <div style={{ fontWeight: 700, marginBottom: 20 }}>{language === "sv" ? "Skapa lönespann" : lang[language].createSalaryRange}</div>
                        <img style={{ position: "absolute", right: 10, top: 10, width: 22 }} alt="" src={exitImg} class="hide-box" onClick={this.closeManualOpen} />
                        <div className="slider-container">
                            <div style={{ padding: 15, textAlign: "left", paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ paddingBottom: 0, marginLeft: -15, paddingLeft: 15, marginRight: -15, paddingRight: 15, opacity: this.state.marketWeight === 0 ? 0.35 : 1 }}>
                                    <div style={{ display: "inline-block", padding: 5, fontSize: 15 }}>{language === "sv" ? "Skapa för nivå" : lang[language].createForLevel}</div>
                                    <select name="selectCreateProgression" value={this.state.manualLevel} onChange={this.manualLevel} style={{ padding: 5, float: "right", width: 92, textAlign: "center" }}>
                                        {<option key={"notSelected"} value={"notSelected"} >
                                            -
                                        </option>}
                                        {this.state.nrOfSelectableEqualentGroups.map((value, index) => (
                                            <option key={value.level} value={value.level} disabled={value.display === "no"}>
                                                {value.level}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="commentButton" onClick={this.addManualLevel}>{language === "sv" ? "Skapa" : lang[language].create}</div>
                    </div>
                    <div className={this.state.salaryRangesManualOpen ? "popUpFader" : (this.state.salaryRangesManualOpen && this.state.fullScreen) ? "popUpFader fadeHighZ" : "hide"}></div>
                    <div className={this.state.popUpInfo ? "popUpFader" : (this.state.salaryRangesOpen && this.state.fullScreen) ? "popUpFader fadeHighZ" : this.state.salaryRangesOpen ? "popUpFader" : "hide"}></div>

                    <div className={this.state.fullScreen ? "arcFullScreenWhiteBack" : "hide"}></div>
                    <div className={this.state.fullScreen ? "arcFullScreen" : ""} style={{ textAlign: "left", width: 131 + (this.state.nrOfSubFamilies * 131) + (this.state.nrOfEqualentGroups * 131), marginBottom: 180, background: "white", padding: 20, borderRadius: 5, boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)", fontSize: 14, paddingBottom: 0, minWidth: "96.5%" }}>
                        {/* width: */}
                        <div>
                            <div className="filterValuationBtn" style={{ marginTop: 0, marginRight: 0 }}>
                                <img onClick={this.settingPopUp} src={settings} width={16} style={{ opacity: 0.4, filter: "invert(100%)", verticalAlign: "top" }} />

                                <div style={{ marginLeft: -184, width: 190, cursor: "default", overflow: "hidden" }} className={this.state.settingPopUp ? "filterValuationBtnBox" : "hide"}>

                                    <ReactToPrint
                                        trigger={() => <a className="moreSettingsItem" href="#" style={{ display: "block", marginTop: -9, borderTop: "none", paddingRight: 22, borderBottom: "1px solid rgb(215, 215, 215)" }}><img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: 27, marginLeft: 9 }} />{language === "sv" ? "Exportera" : lang[language].export}</a>}
                                        content={() => this.componentRef.current}
                                        onBeforeGetContent={this.handleBeforePrint}
                                        onAfterPrint={this.handleAfterPrint}
                                        pageStyle={`
                                            @page {
                                                size: A4 landscape; /* Force landscape mode */
                                                margin: 0; /* Set to 0 to avoid extra space */
                                            }
                                            @media print {
                                                body {
                                                    margin: 0;
                                                    padding: 0;
                                                    -webkit-print-color-adjust: exact;
                                                    color-adjust: exact;
                                                }
                                                .print-content {
                                                  /*  transform: scale(${scale}); *//* Scale to fit */
                                                  zoom: ${scale};
                                                    transform-origin: top left; /* Scale from the top-left corner */
                                                   /* width: ${widthOfContainer}px; *//* Set original width for scaling */
                                                    display: block; 
                                                  /*  height: auto; 
                                                    margin: 0; 
                                                    overflow: visible; 
                                                    position: relative; 
                                                    left: 0; 
                                                    top: 0;

                                                    display: block; 
                                                    page-break-inside: avoid; */
                                                }
                                                .bandColorSingle {
                                                  width: 112px !important;
                                                }
                                                .centerColumnTitle {
                                                    position: inherit !important; /* Adjust to prevent jumping */
                                                }
                                                    .column-equalWork { box-shadow: none !important; border: 1px solid lightgrey; box-sizing: border-box; border-radius: 5px !important}
                                            }
                                        `}
                                    />
                                    {/*<div onClick={this.updateSalaryRangesPop} className="moreSettingsItem" style={{ marginBottom: 9, borderTop: "none", borderBottom: "1px solid #d7d7d7" }}><img src={update} width={16} style={{ opacity: 0.47, verticalAlign: "top", marginRight: 25, marginLeft: 9 }} />{language === "sv" ? "Uppdatera lönespann" : lang[language].updSalaryRanges}</div>*/}

                                    <div onClick={this.resetArc} className="moreSettingsItem hoverSettingRed" style={{ /*marginTop: -9,*/ borderTop: "none", }}><img src={trashB} width={15} style={{ opacity: 0.47, verticalAlign: "top", marginRight: 27, marginLeft: 9 }} />{language === "sv" ? "Nollställ arkitektur" : lang[language].resetArc}</div>

                                </div>
                            </div>
                            <div className={this.state.arcIdOneComp === "yes" ? "hide" : "filterValuationBtn"} style={{ marginTop: 0, marginRight: 15, color: "#333333" }}><img src={hierarchyImg} width={16} style={{ marginRight: 7.5, width: 13, opacity: 0.55, verticalAlign: "top", marginTop: 1.3 }} onClick={this.shareArcPop} /><span onClick={this.shareArcPop}>Gemensam arkitektur </span>
                                <div style={{ marginLeft: this.state.editSharedArc ? -240 : -140, width: this.state.editSharedArc ? 370 : 270, cursor: "default", overflow: "hidden", padding: 15 }} className={this.state.shareArcPop ? "filterValuationBtnBox" : "hide"}>
                                    <div className="arcHeaderGroup">{language === "sv" ? "Bolag med gemensam arkitektur" : lang[language].companiesSameArc}
                                        <img onClick={this.toggleArcPop} src={questionIcon} style={{ filter: "invert(1)", float: "right", width: 17, marginLeft: 5, display: "inline-block", verticalAlign: "top", cursor: "pointer", marginTop: -1 }} />
                                    </div>

                                    <div>
                                        {this.state.arcIds.map((group) => (
                                            <div key={group.arcId}>
                                                <div className="arcTitleGroup">
                                                    <div className="arcSecondTitleGroup" style={{ width: "46%" }}>Arkitektur {group.arcId} <div className={group.arcId === this.state.activeCompanyAndId.arcId ? "arcActiveBtnGroup" : "hide"}>{language === "sv" ? "Aktiv" : lang[language].active}</div></div>
                                                    <div className={this.state.editSharedArc ? "arcSecondTitleGroup" : "hide"} style={{ width: "22%", fontWeight: "normal", fontStyle: "italic", fontSize: 12, textAlign: "center", color: "gray" }}>{language === "sv" ? "Värdering" : lang[language].evaluation}</div>
                                                    <div className={this.state.editSharedArc ? "arcSecondTitleGroup" : "hide"} style={{ width: "12%", fontWeight: "normal", fontStyle: "italic", fontSize: 12, textAlign: "center", color: "gray" }}>{language === "sv" ? "Nivåer" : lang[language].levels}</div>
                                                </div>
                                                <ul className="arcULGroup">
                                                    {group.items.map((item, index) => (
                                                        <li className="arcLiGroup" key={index}>
                                                            <div className="arcCompGroup">
                                                                <div className="arcSecondTitleGroup" style={{ width: !this.state.editSharedArc ? "100%" : "58.5%", wordWrap: "break-word" }}>{item.dotterBolag}</div>
                                                                <div style={{ width: "22%", textAlign: "center" }} className={this.state.editSharedArc ? "arcSecondTitleGroup" : "hide"}>{item.whatEvaluation === "original" || item.whatEvaluation === null || item.whatEvaluation === undefined ? "Standard" : item.whatEvaluation === "edited" ? (language === "sv" ? "Anpassad" : lang[language].customized) : item.whatEvaluation}</div>
                                                                <div style={{ width: "18%", textAlign: "center" }} className={this.state.editSharedArc ? "arcSecondTitleGroup" : "hide"}>{item.whatEvaluation === null || item.whatEvaluation === undefined || item.whatEvaluation === "original" ? item.nrOfEqualGroups === null ? 6 : item.nrOfEqualGroups : item.whatEvaluation === "edited" ? item.editedNrOfEqualGroups : 0}</div>
                                                            </div>
                                                            <select onChange={this.changeArcId}
                                                                data-dotterbolag={item.dotterBolag}
                                                                data-whatevaluation={item.whatEvaluation === null || item.whatEvaluation === undefined ? "original" : item.whatEvaluation}
                                                                data-nrofequalgroups={item.whatEvaluation === null || item.whatEvaluation === undefined || item.whatEvaluation === "original" ? item.nrOfEqualGroups === null ? 6 : item.nrOfEqualGroups : item.whatEvaluation === "edited" ? item.editedNrOfEqualGroups : 0}
                                                                className={this.state.editSharedArc ? "arcSelectionGroup" : "hide"}
                                                                value={item.arcId}>
                                                                <option key="new" value="new">{language === "sv" ? "Skapa ny" : lang[language].create}</option>
                                                                {this.state.arcIds.map((arcGroup) => (
                                                                    <option key={arcGroup.arcId} value={arcGroup.arcId}>
                                                                        {language === "sv" ? "Ark" : lang[language].arc} {arcGroup.arcId}
                                                                    </option>))}
                                                            </select>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>

                                    <div className={this.state.editSharedArc ? "hide" : "arcEditBtnGroup"} onClick={this.editSharedArc}>{language === "sv" ? "Redigera" : lang[language].edit}</div>
                                    <div className={!this.state.editSharedArc ? "hide" : ""} style={{ marginTop: -15 }}>
                                        <div className="arcEditBtnGroup" style={{ display: "inline-block" }} onClick={this.saveSharedArc}>{language === "sv" ? "Spara" : lang[language].save}</div>
                                        <div className="arcEditBtnGroup" style={{ display: "inline-block", marginLeft: 10, background: "#bbbbbb" }} onClick={this.cancelSharedArc}>{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                    </div>
                                    <div className="arcEditBtnGroup" style={{ marginTop: -26, float: "right", background: "#bbbbbb" }} onClick={this.toggleArcPop}>{language === "sv" ? "Hjälp" : lang[language].help}</div>

                                </div>
                            </div>
                            <div className={this.state.copyOpen ? (language === "sv" ? "copyButton" : "copyButton copyButtonEnglish") : "copyButton openCopyCont"} >
                                <span style={{ paddingTop: 20, paddingBottom: 20, marginLeft: -4, paddingLeft: 10, fontSize: 14.6 }} onClick={this.copyOpen}>{language === "sv" ? "Skapa automatiskt" : lang[language].autoCreate}</span>
                                <select onChange={this.whatAIToCreate} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseCompany">
                                    <option value="">{language === "sv" ? "Vad ska skapas?" : lang[language].whatToCreate}</option>
                                    <option value="families">{language === "sv" ? "Jobbfamiljer" : lang[language].jobFamilies}</option>
                                    <option value="salaryRange">{language === "sv" ? "Lönespann" : lang[language].payRanges}</option>
                                    <option value="fAndS">{language === "sv" ? "Jobbfamiljer & Lönespann" : lang[language].payRangesAndJobFamilies}</option>
                                </select>
                                <span onClick={this.runAIArchitecture} className={this.state.copyOpen ? "hiddenButton" : "showButton"}>{language === "sv" ? "Skapa" : lang[language].create}</span>
                                <span onClick={this.copyOpen} className={this.state.copyOpen ? "hide" : "questionM"} style={{ color: "white", lineHeight: 1, border: "none", fontSize: 16, marginLeft: 30 }}>x</span>
                            </div>

                            <div style={{
                                display: "block",
                                textAlign: "left",
                                verticalAlign: "top",
                            }}>
                                <div>
                                    <div style={{ display: "inline-block", marginRight: 10, verticalAlign: "middle", cursor: "pointer" }} onClick={this.toggleFullscreen}>
                                        <img style={{ width: 22, marginLeft: 5, marginTop: 4, opacity: 0.5, marginRight: 2.5 }} src={this.state.fullScreen ? smallerScreen : fullscreenWide} />
                                        <div style={{ display: "inline-block", marginLeft: 7.5, verticalAlign: "top", marginTop: 6 }}>{this.state.fullScreen ? (language === "sv" ? "Mindre skärm" : lang[language].smallerScreen) : (language === "sv" ? "Helskärm" : lang[language].fullscreen)}</div>
                                    </div>
                                    <div style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        marginRight: 10,
                                        borderLeft: "1px solid #e5e5e5",
                                        paddingLeft: 12.5,
                                        marginBottom: 15,
                                        marginTop: 15
                                    }}>
                                        <Switch width={40} height={20} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.toggleTitles} checked={this.state.toggleTitles} />
                                        <span style={{ marginLeft: 5 }}>{language === "sv" ? "Visa befattningar" : lang[language].showPositions}</span>
                                    </div>
                                    <div style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        borderLeft: "1px solid #e5e5e5",
                                        paddingLeft: 12.5,
                                        borderRight: "1px solid #e5e5e5",
                                        paddingRight: 12.5,
                                        marginBottom: 15,
                                        marginTop: 15
                                    }}>
                                        <Switch width={40} height={20} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.showSalRange} checked={this.state.showSalRange} />
                                        <span style={{ marginLeft: 5 }}>{language === "sv" ? "Visa lönespann" : lang[language].showPayRanges}</span>
                                    </div>
                                    <div style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        marginRight: 10,
                                        paddingLeft: 12.5,
                                        borderRight: "1px solid #e5e5e5",
                                        paddingRight: 12.5,
                                        marginBottom: 15,
                                        marginTop: 15
                                    }}>
                                        <Switch width={40} height={20} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.showComments} checked={this.state.showComments} />
                                        <span style={{ marginLeft: 5 }}>{language === "sv" ? "Visa kommentarer" : lang[language].showComments}</span>
                                    </div>
                                    <div style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        marginRight: 10,
                                        paddingLeft: 0,
                                        fontSize: 13,
                                        marginLeft: 2.5,
                                    }} onClick={this.openChart}><img src={chartIcon} width={17} style={{ marginRight: 10, verticalAlign: "top", opacity: 0.5 }} />{this.state.chartOpen ? (language === "sv" ? "Stäng diagram" : lang[language].closeChart) : (language === "sv" ? "Öppna diagram" : lang[language].openChart)}
                                    </div>


                                    <div style={{
                                        marginTop: 10,
                                        marginBottom: 10,
                                        cursor: "pointer",
                                        width: "fit-content",
                                        display: "inline-block",
                                        marginRight: 0,
                                        paddingLeft: 12.5,
                                        fontSize: 13,
                                        marginLeft: 2.5,
                                        borderLeft: "1px solid #e5e5e5",
                                        width: 150
                                    }}>
                                        <img src={!this.state.searchToggle ? searchIcon : exitImg} width={17} style={{
                                            marginTop: this.state.searchToggle ? 1 : 0, width: this.state.searchToggle ? 15 : 17, marginRight: 5, verticalAlign: "top", opacity: this.state.searchToggle ? 0.45 : 0.58, verticalAlign: "top", display: "inline-block"
                                        }} onClick={this.searchToggle} />
                                        <input type="text"
                                            className={this.state.searchToggle ? "arcSearchOpen" : "hide"}
                                            placeholder={language === "sv" ? "Sök" : lang[language].search}
                                            onChange={this.searchAndMarkWork}
                                            value={this.state.searchString}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={!this.state.hideNoGrade && this.state.noGrade.length > 0 ? "warningNoGrades" : "hide"}>
                            <img src={exitImg} style={{ width: 22, marginTop: -10, marginRight: -10 }} className="hide-box" onClick={this.hideNoGrade} />
                            <div style={{ marginBottom: 15, fontSize: 14.6, marginTop: 15 }}><b>OBS:</b></div>
                            <div style={{ maxWidth: 550, margin: "auto", marginBottom: 15, borderBottom: "1px solid #000000", paddingBottom: 15 }}>{language === "sv" ? "Det finns arbeten utan arbetsvärdering som därför inte syns i arkitekturen. Vänligen värdera följande arbeten under steg 2.2:" : lang[language].ungraded} </div>
                            <ul>
                                {this.state.noGrade.slice(0, 4).map((item, index) => (
                                    <li key={item + index} style={{ marginBottom: 7.5 }}>
                                        {item.groupNameArbVardering}
                                    </li>
                                ))}
                                {this.state.noGrade.length > 4 && (
                                    <li style={{ marginBottom: 7.5 }}>
                                        <b>... {language === "sv" ? "och" : lang[language].and} {this.state.noGrade.length - 4} {language === "sv" ? "fler" : lang[language].more}</b>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className={!this.state.chartOpen && "hide"}>

                            <div className="chartSalaryBandBox">
                                <ChartSalaryStructure
                                    triggerChartUpdate={this.state.triggerChartUpdate}
                                    p10={this.state.p10}
                                    p25={this.state.p25}
                                    median={this.state.median}
                                    p75={this.state.p75}
                                    p90={this.state.p90}
                                    employees={this.state.employeesInChartLevels}
                                    ranges={this.state.activeSalaryWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels}
                                    nrOfEqualentGroups={this.state.nrOfEqualentGroups} // Used to make sure that x axis displays correct number of levels
                                />

                                <div style={{ display: "inline-block", verticalAlign: "top", fontSize: 13, marginLeft: 20, minWidth: 436 }}>
                                    <div>
                                        <div style={{ display: "block", fontWeight: 700, marginBottom: 12.5, marginTop: 10, fontSize: 15 }}>{this.state.activeSalaryWhat === "overall" ? (language === "sv" ? "Lönespann per nivå" : lang[language].payRangePerLevel) : this.state.activeSalaryWhat === "individual" ? (language === "sv" ? "Lönespann för specifikt arbete" : lang[language].payRangePerPosition) : this.state.activeSalaryWhat === "family" ? (language === "sv" ? "Lönespann per nivå och familj" : lang[language].payRangePerLevelAndFamily) : (language === "sv" ? "Lönespann per nivå och underkategori" : lang[language].payRangePerLevelAndSubFunction)}
                                            <div onClick={this.commentSalary} className="commentSalaryBtn"><img src={commentIcon} style={{ width: 15, verticalAlign: "top", /*marginRight: 5*/ }} />{/*Kommentar*/}</div>
                                        </div>
                                        <div style={{ position: "relative" }} className={this.state.showCommentSalary ? "" : "hide"}>
                                            <TextareaAutosize onClick={this.commentSalaryOnClick} onChange={this.editCommentSalary} className="autoHeightText" style={{ width: "100%", border: "1px solid grey", fontSize: 13, textAlign: "left", padding: 10, boxSizing: "border-box", cursor: "auto", marginBottom: 10, borderRadius: 3, paddingRight: 70 }} placeholder={language === "sv" ? "Kommentar till lönespannet" : lang[language].commentForPayRange} name="notable" /*id={comment.commentId_PK}*/ value={this.state.thisSalaryComment} />
                                            <div onClick={this.saveCommentSalary} style={{
                                                marginTop: -41.5,
                                                background: this.state.salaryCommentSaved ? "#cacaca" : "#56cbb1",
                                                color: "white",
                                                padding: 5,
                                                borderRadius: 3,
                                                width: 50,
                                                textAlign: "center",
                                                marginRight: 5,
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: 0
                                            }}>{this.state.salaryCommentSaved ? (language === "sv" ? "Sparat" : lang[language].saved) : (language === "sv" ? "Spara" : lang[language].save)}</div>
                                        </div>

                                        <select className={this.state.activeSalaryWhat !== "overall" ? "selectFamily" : "hide"} style={{ borderRadius: 3, marginBottom: 5, marginTop: 0 }} onChange={this.selectFamilyForRanges}
                                            value={this.state.activeSalaryWhat === "family" ? JSON.stringify({ family: this.state.activeSalaryInfo.whatFam, name: this.state.activeSalaryInfo.whatFam }) :
                                                this.state.activeSalaryWhat === "mainFunction" ? JSON.stringify({ family: this.state.activeSalaryInfo.whatFam, name: this.state.activeSalaryInfo.whatMainFunction }) :
                                                    this.state.activeSalaryWhat === "subFunction" ? JSON.stringify({ family: this.state.activeSalaryInfo.whatFam, name: this.state.activeSalaryInfo.whatSubfunction }) :
                                                        this.state.activeSalaryWhat === "subFunction2" ? JSON.stringify({ family: this.state.activeSalaryInfo.whatFam, name: this.state.activeSalaryInfo.whatSubfunction2 }) :
                                                            this.state.activeSalaryWhat === "subFunction3" ? JSON.stringify({ family: this.state.activeSalaryInfo.whatFam, name: this.state.activeSalaryInfo.whatSubfunction3 }) :
                                                                // JSON.stringify({ family: this.state.activeSalaryInfo.whatFam, name: this.state.activeSalaryInfo.position })
                                                                this.state.activeSalaryInfo.position
                                            }>
                                            {
                                                (this.state.activeSalaryWhat === "individual") ?
                                                    this.state.uniqueListForScrolling.map((value, index) => (
                                                        <option key={value} value={value}>
                                                            {value}
                                                        </option>
                                                    )) :
                                                    (this.state.activeSalaryWhat === "family") ?
                                                        this.state.uniqueListForScrolling.map((value, index) => (
                                                            <option key={value.name + value.family} value={JSON.stringify(value)}>
                                                                {value.name}
                                                            </option>
                                                        )) :
                                                        this.state.uniqueListForScrolling.map((value, index) => (
                                                            <option key={value.name + value.family} value={JSON.stringify(value)}>
                                                                {value.name + ` (${value.family})`}
                                                            </option>
                                                        ))
                                            }
                                        </select>



                                        <div className="calculateWithAIBtn" onClick={this.salaryRangesSettingsPop}>{language === "sv" ? "Skapa med AI" : lang[language].createWithAI}</div>
                                        <div className="calculateWithAIBtn" style={{ background: "#e0e0e0", color: "#2a2a2a", marginLeft: 10 }} onClick={this.salaryRangesManualPop}>{language === "sv" ? "Skapa manuellt" : lang[language].createManually}</div>
                                    </div>
                                    <SalaryList handleTrashClick={this.handleTrashClick} onInputChange={this.manualSalaryRangesUpdate} createProgression={this.state.createProgression} ranges={this.state.activeSalaryWhat !== "overall" ? this.state.activeFamilyOrFunc : this.state.rangesLevels} />
                                </div>
                            </div>
                        </div>

                        <span ref={this.componentRef} className="print-content">
                            <span ref={this.lineHeightCalculateRef}>
                                <p style={{
                                    borderTop: "1px solid #efefef",
                                    paddingBottom: 5,
                                    marginLeft: -20,
                                    paddingLeft: 20,
                                    marginRight: -20,
                                    paddingRight: 20,
                                    marginTop: 0,
                                }}></p>

                                {/* Header */}
                                <div ref={this.targetDivRef} className="column-manual centerColumnTitle" style={{ marginBottom: 20 }}><span className="arcColumnTitleWhiteBack" whatChart="family" isTitle="Yes" onClick={this.openWhatChart}>{language === "sv" ? "Familj" : lang[language].family}</span><span style={{ position: "fixed", top: 73, zIndex: 1, marginLeft: 47, background: "white", display: this.state.showFixedTitle ? "block" : "none" }}>{language === "sv" ? "Familj" : lang[language].family}</span></div>
                                {this.state.nrOfSubFamilies > 0 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="mainFunction" isTitle="Yes" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">{language === "sv" ? "Funktion" : lang[language].function}</span><span style={{ position: "fixed", top: 73, zIndex: 1, marginLeft: 38, background: "white", display: this.state.showFixedTitle ? "block" : "none" }}>{language === "sv" ? "Funktion" : lang[language].function}</span></div>}
                                {this.state.nrOfSubFamilies > 1 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction" isTitle="Yes" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">{language === "sv" ? "Subfunktion" : lang[language].subFunction}</span><span style={{ position: "fixed", top: 73, zIndex: 1, marginLeft: 30, background: "white", display: this.state.showFixedTitle ? "block" : "none" }}>{language === "sv" ? "Subfunktion" : lang[language].subFunction}</span></div>}
                                {this.state.nrOfSubFamilies > 2 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction2" isTitle="Yes" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">{language === "sv" ? "Subfunktion 2" : lang[language].subFunction + " 2"}</span><span style={{ position: "fixed", top: 73, zIndex: 1, marginLeft: 25, background: "white", display: this.state.showFixedTitle ? "block" : "none" }}>{language === "sv" ? "Subfunktion" : lang[language].subFunction} 2</span></div>}
                                {this.state.nrOfSubFamilies > 3 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction3" isTitle="Yes" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">{language === "sv" ? "Subfunktion 3" : lang[language].subFunction + " 3"}</span><span style={{ position: "fixed", top: 73, zIndex: 1, marginLeft: 25, background: "white", display: this.state.showFixedTitle ? "block" : "none" }}>{language === "sv" ? "Subfunktion" : lang[language].subFunction} 3</span></div>}
                                {this.state.nrOfSubFamilies > 4 && <div className="column-manual centerColumnTitle"><div className="horisontional-line" style={{ height: this.state.printHeight - 58 }}></div><span whatChart="subFunction4" isTitle="Yes" onClick={this.openWhatChart} className="arcColumnTitleWhiteBack">{language === "sv" ? "Subfunktion 4" : lang[language].subFunction + " 4"}</span><span style={{ position: "fixed", top: 73, zIndex: 1, marginLeft: 25, background: "white", display: this.state.showFixedTitle ? "block" : "none" }}>{language === "sv" ? "Subfunktion" : lang[language].subFunction} 4</span></div>}
                                {columns}
                                {createdRows}
                            </span>

                            {/* Comment list, for pdf export*/}
                            <div className={this.state.allSalaryComments.length === 0 ? "hide" : !this.state.showComments ? "hide" : ""} style={{ borderTop: "10px solid #ecf1f0", padding: 20, paddingTop: 40, marginLeft: -20, paddingLeft: 40, marginRight: -20, paddingRight: 40 }}>
                                <div style={{ fontWeight: 600, paddingBottom: 20, fontSize: 13 }}>{language === "sv" ? "Kommentarer till lönespann" : lang[language].payRangesComments}</div>
                                <ul style={{ fontSize: 13 }}>
                                    <li style={{ fontWeight: 500, marginBottom: 10, color: "grey", fontStyle: "italic" }}>
                                        <div style={{ display: "inline-block", width: 150, paddingRight: 20 }}>{language === "sv" ? "Namn" : lang[language].name}</div>
                                        <div style={{ display: "inline-block", width: 150, paddingRight: 20 }}>{language === "sv" ? "Familj" : lang[language].family}</div>
                                        <div style={{ display: "inline-block", width: 150, paddingRight: 20 }}>{language === "sv" ? "Kategori" : lang[language].category}</div>
                                        <div style={{ display: "inline-block" }}>{language === "sv" ? "Kommentar" : lang[language].comment}</div>
                                    </li>

                                    {this.state.allSalaryComments.map((item, index) => (
                                        <li key={index} className={item.comment === "" ? "hide" : "arcCommentList"}>
                                            <div style={{ verticalAlign: "top", display: "inline-block", width: 150, paddingRight: 20, wordWrap: "break-word", whiteSpace: "normal" }}>
                                                <span style={{ width: 140 }}>{item.theName === "overall" ? "-" : item.theName}</span></div>
                                            <div style={{ verticalAlign: "top", display: "inline-block", width: 150, paddingRight: 20, wordWrap: "break-word", whiteSpace: "normal" }}>
                                                <span style={{ width: 140 }}>{item.familyName === "overall" ? "-" : item.familyName}</span></div>
                                            <div style={{ verticalAlign: "top", display: "inline-block", width: 150, paddingRight: 20 }}>
                                                <span style={{ width: 140 }}>{
                                                    item.whatFunction === "overall" ? (language === "sv" ? "Nivå" : lang[language].level) 
                                                    : item.whatFunction === "family" ? (language === "sv" ? "Jobbfamilj" : lang[language].jobFamily)
                                                        : item.whatFunction === "mainFunction" ? (language === "sv" ? "Funktion" : lang[language].function)
                                                            : item.whatFunction === "subFunction" ? (language === "sv" ? "Subfunktion" : lang[language].subFunction)
                                                                : item.whatFunction === "subFunction2" ? (language === "sv" ? "Subfunktion 2" : lang[language].subFunction + " 2")
                                                                    : item.whatFunction === "subFunction3" ? (language === "sv" ? "Subfunktion 3" : lang[language].subFunction + " 3")
                                                                        : (language === "sv" ? "Arbete" : lang[language].position)
                                                }</span>
                                            </div>
                                            <div style={{ verticalAlign: "top", display: "inline-block", }}>{item.comment}</div>
                                            <img src={trashIcon} style={{ width: 17, float: "right" }} data-commentname={item.theName} data-commentid={item.commentId} onClick={this.deleteCommentInList} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </span>
                    </div>
                </div>
            </>
        )
    }
}