import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client'; // Changed to this to fix  error: createRoot is not a function
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/*ReactDOM.render( // Old way before React 18
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

/*
 <React.StrictMode>
    <App />
  </React.StrictMode>
  */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
