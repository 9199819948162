import React from 'react';
import './Arbetsvardera.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import { PopUp } from './Popup/PopUp.js';
import infoImg from '../../assets/images/info.png';
import exitImg from '../../assets/images/exit.png';
import { fetchAuth } from '../../fetch-auth';
import MultiSearchSelect from "react-search-multi-select";
import CustomPrompt from '../General/CustomPrompt.js';
import { EditValuation } from './EditValuation/editValuation.js';
import editImg from '../../assets/images/edit.png';
import sortFilter from '../../assets/images/SortFilter.png';
import imgArrWhite from '../../assets/images/rightArrWhite.png';
import imgArrBlack from '../../assets/images/rightArrBlack.png';
import lang from '../Language/language.js';
import settings from '../../assets/images/Settings.png';
import Switch from "react-switch";
import openTab from '../../assets/images/openTab.png';
import arrowPrint from '../../assets/images/arrowPrint.png';
import filterBtn from '../../assets/images/filterBtn.png';
import { NavLink } from 'react-router-dom';
import eyeOn from '../../assets/images/eyeOn.png';
import eyeOff from '../../assets/images/eyeOff.png';
import exportImg from '../../assets/images/export.png';
import { exportEvaluationWord } from './exportEvaluationWord.js'

let selectedValues = []; //Fix because of bug in MultiSearchSelect

export class ArbetsvarderaNormal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItemId: "",
            showPopUp: null,
            fullValue: [],
            vUtb: 20,
            vProb: 15,
            vSoc: 10,
            vPer: 15,
            vVerk: 15,
            vMans: 10,
            vFys: 5,
            vPsy: 10,
            vTotal: 0,
            saveButtonStatus: "Sparat",
            //For import 
            copyOpen: true,
            importWhat: "",
            importFrom: "",
            concernComps: [],
            values: [],
            showPopUpWarning: "hide",
            equalentGroups: 9, //Should be 6 by default,
            showChangesPrompt: "Yes",
            percentChange: "No",
            //For edit
            whatLevelDesc: null,
            manualDescriptions: null,
            //For sorting
            sorting: "alphabet", // alphabet or points
            sortingOpen: false,
            //For settings popUp
            settingValPop: false,
            hideArrows: false,
            popInfoArrows: false,
            extraButton: "", // Will be "" or "yes"
            //For filter
            filterOpen: false,
            nrFilterOut: 0,
            filterSearch: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.percentChange = this.percentChange.bind(this);
        this.popUpNull = this.popUpNull.bind(this);
    }
    componentDidMount() {
        this.setState({ vTotal: Number(this.state.vUtb) + Number(this.state.vProb) + Number(this.state.vSoc) + Number(this.state.vPer) + Number(this.state.vVerk) + Number(this.state.vMans) + Number(this.state.vFys) + Number(this.state.vPsy) });
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }
        let hideArrows = localStorage.getItem('hideArrows');
        hideArrows === "true" ? this.setState({ hideArrows: true }) : this.setState({ hideArrows: false })
        this.getData();
    }


    getData() {
        const getUniquePositions = async () => {
            selectedValues = []
            let response = await fetchAuth(`/api/loadArbetsvardering`, 'POST');
            let data = await response.json();
            const vardering = data[1][0] //This is the saved valuation for each question
            let fullValue = data[0].map(item => item[0]) //Fix to remove arrays. This is the data for each equal work
            fullValue = fullValue.filter(p => p !== undefined)  //Fix to remove positions that did not have an equal group name
            const vUtb = vardering.education
            const vProb = vardering.problemsolving
            const vSoc = vardering.socialSkills
            const vPer = vardering.personnel
            const vVerk = vardering.operation
            const vMans = vardering.people
            const vFys = vardering.physical
            const vPsy = vardering.psychological
            const vTotal = Number(vUtb) + Number(vProb) + Number(vSoc) + Number(vPer) + Number(vVerk) + Number(vMans) + Number(vFys) + Number(vPsy)

            //Make all groups into alphabetic order and put groups without finished result (equalentgroup) on top
            fullValue.sort((a, b) => a.groupName != null ? a.groupName.localeCompare(b.groupName != null ? b.groupName : "") : "".localeCompare(b.groupName != null ? b.groupName : ""))
            let sortNullOnTop = [
                ...fullValue.filter(x => x.equalentGroup === null),
                ...fullValue.filter(x => x.equalentGroup !== null)
            ]
            fullValue = sortNullOnTop

            //How many equalent groups
            let nrOfEqualentGroups = data[3]

            //filter titles for use with MultiSearchSelect
            let titles = [];
            if (fullValue !== undefined && fullValue.length > 0) {
                for (let i = 0; i < fullValue.length; i++) {
                    titles.push(fullValue[i].groupName)
                }
            }

            //Get all concern companies (own self is not included), for MultiSearchSelect
            let concernComps = []
            if (data[2] !== undefined && data[2] !== null && data[2].length > 0) {
                for (let i = 0; i < data[2].length; i++) {
                    concernComps.push(data[2][i].dotterBolag)
                }
            }

            //Set whatLevelDesc and manualDescriptions
            let whatLevelDesc = data[4]
            if (whatLevelDesc === "") { whatLevelDesc = null }
            let manualDescriptions = data[5][0]
            if (manualDescriptions === undefined || manualDescriptions === null) { manualDescriptions = [] }
            //get setting for more setting button
            let showExtValuation = data[6]
            this.setState({
                fullValue: fullValue,
                vUtb: vUtb,
                vProb: vProb,
                vSoc: vSoc,
                vPer: vPer,
                vVerk: vVerk,
                vMans: vMans,
                vFys: vFys,
                vPsy: vPsy,
                vTotal: vTotal,
                concernComps: concernComps,
                values: titles,
                equalentGroups: nrOfEqualentGroups,
                whatLevelDesc: whatLevelDesc,
                manualDescriptions: manualDescriptions,
                extraButton: showExtValuation,
            });
        };
        getUniquePositions();
    };

    toggleActive = (id) => {
        this.setState({
            activeItemId: id
        });
    }

    togglePopUp = (id) => {
        this.setState({
            showPopUp: id
        });
    }

    handleChange(event) {
        let { value, id } = event.target; //id is question id, example: "q1val"
        let positionId = this.state.activeItemId

        if (Number(value) > 4 || Number(value) < 1) {
            value = ""
        }

        //add number to object for saving into state
        const evaluation = this.state.fullValue.map((item, i) => {
            if (item.groupName === this.state.activeItemId) {
                if (id in item) {
                    item[id] = value
                    return { ...item, [id]: value };
                }
            }
            else return { ...item }
        })

        //Calculate equalent group
        const equalentGroup = evaluation.map((item, i) => {
            if (item.groupName === this.state.activeItemId) {
                //groupVariable
                let eGroup = 0;
                //Make viktning percent
                const vUtbPercent = this.state.vUtb / 100
                const vVerkPercent = this.state.vVerk / 100
                const vMansPercent = this.state.vMans / 100
                const vPerPercent = this.state.vPer / 100
                const vFysPercent = this.state.vFys / 100
                const vProbPercent = this.state.vProb / 100
                const vPsyPercent = this.state.vPsy / 100
                const vSocPercent = this.state.vSoc / 100
                //Caclulate the weighted value
                let totalPoints =
                    (
                        (Number(item.education) * vUtbPercent)
                        + (Number(item.operation) * vVerkPercent)
                        + (Number(item.people) * vMansPercent)
                        + (Number(item.personnel) * vPerPercent)
                        + (Number(item.physical) * vFysPercent)
                        + (Number(item.problemsolving) * vProbPercent)
                        + (Number(item.psychological) * vPsyPercent)
                        + (Number(item.socialSkills) * vSocPercent)
                    )

                //Add equalent group based on weighted value value

                //For 6 equalent groups
                if (this.state.equalentGroups == 6) {
                    if (totalPoints < 0.95) { //Added 0.95 because sometimes it can be 0.99
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.5) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.5 && totalPoints < 2) {
                        eGroup = 2;
                    } else if (totalPoints >= 2 && totalPoints < 2.5) {
                        eGroup = 3;
                    } else if (totalPoints >= 2.5 && totalPoints < 3) {
                        eGroup = 4;
                    } else if (totalPoints >= 3 && totalPoints < 3.5) {
                        eGroup = 5;
                    } else if (totalPoints >= 3.5) {
                        eGroup = 6;
                    }
                }
                //If 9 equalent roups
                if (this.state.equalentGroups == 9) {
                    if (totalPoints < 0.95) {
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.333) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.333 && totalPoints < 1.666) {
                        eGroup = 2;
                    } else if (totalPoints >= 1.666 && totalPoints < 1.999) {
                        eGroup = 3;
                    } else if (totalPoints >= 1.999 && totalPoints < 2.332) {
                        eGroup = 4;
                    } else if (totalPoints >= 2.332 && totalPoints < 2.665) {
                        eGroup = 5;
                    } else if (totalPoints >= 2.665 && totalPoints < 2.998) {
                        eGroup = 6;
                    } else if (totalPoints >= 2.998 && totalPoints < 3.331) {
                        eGroup = 7;
                    } else if (totalPoints >= 3.331 && totalPoints < 3.664) {
                        eGroup = 8;
                    } else if (totalPoints >= 3.664) {
                        eGroup = 9;
                    }
                }
                //If 12 equalent roups
                if (this.state.equalentGroups == 12) {
                    if (totalPoints < 0.95) {
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.25) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.25 && totalPoints < 1.5) {
                        eGroup = 2;
                    } else if (totalPoints >= 1.5 && totalPoints < 1.75) {
                        eGroup = 3;
                    } else if (totalPoints >= 1.75 && totalPoints < 2) {
                        eGroup = 4;
                    } else if (totalPoints >= 2 && totalPoints < 2.25) {
                        eGroup = 5;
                    } else if (totalPoints >= 2.25 && totalPoints < 2.5) {
                        eGroup = 6;
                    } else if (totalPoints >= 2.5 && totalPoints < 2.75) {
                        eGroup = 7;
                    } else if (totalPoints >= 2.75 && totalPoints < 3) {
                        eGroup = 8;
                    } else if (totalPoints >= 3 && totalPoints < 3.25) {
                        eGroup = 9;
                    } else if (totalPoints >= 3.25 && totalPoints < 3.5) {
                        eGroup = 10;
                    } else if (totalPoints >= 3.5 && totalPoints < 3.75) {
                        eGroup = 11;
                    } else if (totalPoints >= 3.75) {
                        eGroup = 12;
                    }
                }
                  //If 15 equalent groups
                  if (this.state.equalentGroups == 15) {
                    if (totalPoints < 0.95) {
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.2) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.2 && totalPoints < 1.4) {
                        eGroup = 2;
                    } else if (totalPoints >= 1.4 && totalPoints < 1.6) {
                        eGroup = 3;
                    } else if (totalPoints >= 1.6 && totalPoints < 1.8) {
                        eGroup = 4;
                    } else if (totalPoints >= 1.8 && totalPoints < 2.0) {
                        eGroup = 5;
                    } else if (totalPoints >= 2.0 && totalPoints < 2.2) {
                        eGroup = 6;
                    } else if (totalPoints >= 2.2 && totalPoints < 2.4) {
                        eGroup = 7;
                    } else if (totalPoints >= 2.4 && totalPoints < 2.6) {
                        eGroup = 8;
                    } else if (totalPoints >= 2.6 && totalPoints < 2.8) {
                        eGroup = 9;
                    } else if (totalPoints >= 2.8 && totalPoints < 3.0) {
                        eGroup = 10;
                    } else if (totalPoints >= 3.0 && totalPoints < 3.2) {
                        eGroup = 11;
                    } else if (totalPoints >= 3.2 && totalPoints < 3.4) {
                        eGroup = 12;
                    } else if (totalPoints >= 3.4 && totalPoints < 3.6) {
                        eGroup = 13;
                    } else if (totalPoints >= 3.6 && totalPoints < 3.8) {
                        eGroup = 14;
                    } else if (totalPoints >= 3.8) {
                        eGroup = 15;
                    }
                }

                //Add equalent group to object that we set as state
                return { ...item, equalentGroup: eGroup };
            }
            else return { ...item }
        })

        this.setState({
            fullValue: equalentGroup,
            saveButtonStatus: "Spara"
        })
    }

    handleFocus(e) {
        e.currentTarget.select();
    }

    percentChange(event) {
        let { value, id } = event.target;
        if (Number(value) > 99 || Number(value) < 1) {
            value = ""
        }
        this.setState({
            [id]: value,
        }, function () { //Callback pga asynchront
            this.setState({
                vTotal: Number(this.state.vUtb) + Number(this.state.vProb) + Number(this.state.vSoc) + Number(this.state.vPer) + Number(this.state.vVerk) + Number(this.state.vMans) + Number(this.state.vFys) + Number(this.state.vPsy),
                saveButtonStatus: "Spara"
            });

            //recalculate points and change groups based on changed viktning
            const equalentGroup = this.state.fullValue.map((item, i) => {
                //groupVariable
                let eGroup = 0;
                //Make viktning percent
                const vUtbPercent = this.state.vUtb / 100
                const vVerkPercent = this.state.vVerk / 100
                const vMansPercent = this.state.vMans / 100
                const vPerPercent = this.state.vPer / 100
                const vFysPercent = this.state.vFys / 100
                const vProbPercent = this.state.vProb / 100
                const vPsyPercent = this.state.vPsy / 100
                const vSocPercent = this.state.vSoc / 100
                //Caclulate the weighted value
                let totalPoints =
                    (
                        (Number(item.education) * vUtbPercent)
                        + (Number(item.operation) * vVerkPercent)
                        + (Number(item.people) * vMansPercent)
                        + (Number(item.personnel) * vPerPercent)
                        + (Number(item.physical) * vFysPercent)
                        + (Number(item.problemsolving) * vProbPercent)
                        + (Number(item.psychological) * vPsyPercent)
                        + (Number(item.socialSkills) * vSocPercent)
                    )

                //Add equalent group based on weighted value value

                //For 6 equalent groups
                if (this.state.equalentGroups == 6) {
                    if (totalPoints < 0.95) {
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.5) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.5 && totalPoints < 2) {
                        eGroup = 2;
                    } else if (totalPoints >= 2 && totalPoints < 2.5) {
                        eGroup = 3;
                    } else if (totalPoints >= 2.5 && totalPoints < 3) {
                        eGroup = 4;
                    } else if (totalPoints >= 3 && totalPoints < 3.5) {
                        eGroup = 5;
                    } else if (totalPoints >= 3.5) {
                        eGroup = 6;
                    }
                }
                //If 9 equalent roups
                if (this.state.equalentGroups == 9) {
                    if (totalPoints < 0.95) {
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.333) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.333 && totalPoints < 1.666) {
                        eGroup = 2;
                    } else if (totalPoints >= 1.666 && totalPoints < 1.999) {
                        eGroup = 3;
                    } else if (totalPoints >= 1.999 && totalPoints < 2.332) {
                        eGroup = 4;
                    } else if (totalPoints >= 2.332 && totalPoints < 2.665) {
                        eGroup = 5;
                    } else if (totalPoints >= 2.665 && totalPoints < 2.998) {
                        eGroup = 6;
                    } else if (totalPoints >= 2.998 && totalPoints < 3.331) {
                        eGroup = 7;
                    } else if (totalPoints >= 3.331 && totalPoints < 3.664) {
                        eGroup = 8;
                    } else if (totalPoints >= 3.664) {
                        eGroup = 9;
                    }
                }
                //If 12 equalent roups
                if (this.state.equalentGroups == 12) {
                    if (totalPoints < 0.95) {
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.25) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.25 && totalPoints < 1.5) {
                        eGroup = 2;
                    } else if (totalPoints >= 1.5 && totalPoints < 1.75) {
                        eGroup = 3;
                    } else if (totalPoints >= 1.75 && totalPoints < 2) {
                        eGroup = 4;
                    } else if (totalPoints >= 2 && totalPoints < 2.25) {
                        eGroup = 5;
                    } else if (totalPoints >= 2.25 && totalPoints < 2.5) {
                        eGroup = 6;
                    } else if (totalPoints >= 2.5 && totalPoints < 2.75) {
                        eGroup = 7;
                    } else if (totalPoints >= 2.75 && totalPoints < 3) {
                        eGroup = 8;
                    } else if (totalPoints >= 3 && totalPoints < 3.25) {
                        eGroup = 9;
                    } else if (totalPoints >= 3.25 && totalPoints < 3.5) {
                        eGroup = 10;
                    } else if (totalPoints >= 3.5 && totalPoints < 3.75) {
                        eGroup = 11;
                    } else if (totalPoints >= 3.75) {
                        eGroup = 12;
                    }
                }
                //If 15 equalent groups
                if (this.state.equalentGroups == 15) {
                    if (totalPoints < 0.95) {
                        eGroup = null;
                    } else if (totalPoints >= 0.95 && totalPoints < 1.2) {
                        eGroup = 1;
                    } else if (totalPoints >= 1.2 && totalPoints < 1.4) {
                        eGroup = 2;
                    } else if (totalPoints >= 1.4 && totalPoints < 1.6) {
                        eGroup = 3;
                    } else if (totalPoints >= 1.6 && totalPoints < 1.8) {
                        eGroup = 4;
                    } else if (totalPoints >= 1.8 && totalPoints < 2.0) {
                        eGroup = 5;
                    } else if (totalPoints >= 2.0 && totalPoints < 2.2) {
                        eGroup = 6;
                    } else if (totalPoints >= 2.2 && totalPoints < 2.4) {
                        eGroup = 7;
                    } else if (totalPoints >= 2.4 && totalPoints < 2.6) {
                        eGroup = 8;
                    } else if (totalPoints >= 2.6 && totalPoints < 2.8) {
                        eGroup = 9;
                    } else if (totalPoints >= 2.8 && totalPoints < 3.0) {
                        eGroup = 10;
                    } else if (totalPoints >= 3.0 && totalPoints < 3.2) {
                        eGroup = 11;
                    } else if (totalPoints >= 3.2 && totalPoints < 3.4) {
                        eGroup = 12;
                    } else if (totalPoints >= 3.4 && totalPoints < 3.6) {
                        eGroup = 13;
                    } else if (totalPoints >= 3.6 && totalPoints < 3.8) {
                        eGroup = 14;
                    } else if (totalPoints >= 3.8) {
                        eGroup = 15;
                    }
                }

                //Add equalent group to object that we set as state
                return { ...item, equalentGroup: eGroup };
            })
            this.setState({
                fullValue: equalentGroup,
                percentChange: "Yes"
            });
        })
    }

    popUpNull() { //This is sent to the PopUp child component
        this.setState({
            showPopUp: null
        });
    }

    saveButton = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.vTotal === 100) {
            const saveData = async () => {
                const viktning = {
                    education: this.state.vUtb,
                    problemsolving: this.state.vProb,
                    socialSkills: this.state.vSoc,
                    personnel: this.state.vPer,
                    operation: this.state.vVerk,
                    people: this.state.vMans,
                    physical: this.state.vFys,
                    psychological: this.state.vPsy,
                }

                let response = await fetchAuth(`/api/saveArbetsvardering`, 'POST', JSON.stringify({ data: [this.state.fullValue, viktning, this.state.percentChange] }));
                let data = await response.json();
                data === "Saved" ? this.setState({ saveButtonStatus: "Sparat" }) : alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) //For save button status
            };
            saveData();
        } else {
            alert(language === "sv" ? 'Kan inte spara. Den totala viktningen måste vara 100%. Vänligen justera viktningen och spara sedan igen.' : lang[language].weightNotH)
        }
    }

    toggleCopy = () => {
        if (this.state.copyOpen) {
            this.setState({ copyOpen: false })
        } else this.setState({ copyOpen: true })
    }
    whatToImport = (event) => {
        this.setState({ importWhat: event.target.value });
    }
    importFrom = (event) => {
        this.setState({ importFrom: event.target.value });
    }
    handleChangeForMultiSelect = (arr) => { //<-- For react-search-multi-select
        if (arr.length > 0) {
            selectedValues = arr
        }
    }


    import = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const importIt = async () => {
            const importFrom = this.state.importFrom
            const importWhat = this.state.importWhat
            let groups = this.state.values
            //selectedValues are a variable at top of this file
            let data = ""
            if (importFrom === "" || importWhat === "") { //all, empty, selected
                alert(language === "sv" ? "Du måste välja vad och ifrån vilket bolag du vill importera." : lang[language].selectWhatComp)
                return;
                //Import all
            } else if (importWhat === "all") {
                data = { from: importFrom, what: "all", positions: groups }
                //Import empty ones
            } else if (importWhat === "empty") {
                let emptyPositions = [];
                let g = this.state.fullValue
                for (let i = 0; i < g.length; i++) {
                    if (
                        (g[i].education === null || g[i].education === "") &&
                        (g[i].operation === null || g[i].operation === "") &&
                        (g[i].people === null || g[i].people === "") &&
                        (g[i].personnel === null || g[i].personnel === "") &&
                        (g[i].physical === null || g[i].physical === "") &&
                        (g[i].problemsolving === null || g[i].problemsolving === "") &&
                        (g[i].psychological === null || g[i].psychological === "") &&
                        (g[i].socialSkills === null || g[i].socialSkills === "")) {
                        emptyPositions.push(g[i].groupName)
                    }
                }
                if (emptyPositions.length === 0) {
                    alert(language === "sv" ? "Inga befattningar saknar gruppindelning." : lang[language].noPosMissGroup)
                    return;
                }
                data = { from: importFrom, what: "empty", positions: emptyPositions }
                //import seleced ones
            } else if (importWhat === "selected") {
                if (selectedValues.length > 0) {
                    data = { from: importFrom, what: "selected", positions: selectedValues }
                } else if (selectedValues.length === 0) {
                    alert(language === "sv" ? "Du har inte valt några befattningar." : lang[language].noPosSelected)
                    return;
                }
            } else if (importWhat === "viktning") {
                data = { from: importFrom, what: "viktning" }
            }
            //Copy from the server
            const response = await fetchAuth(`/api/importArbetsvardering`, 'POST', JSON.stringify({ data: data }));
            //If error
            if (response.status !== 200) {
                alert(language === "sv" ? "Något gick fel, försök igen." : lang[language].somethingWrong)
                this.setState({ showPopUpWarning: "hide" })
                return;
            }

            let resData = await response.json();
            const whatText = resData[0]
            const viktning = resData[1][0]
            const vardering = resData[2]

            //Create a shallow copy of our state
            const dataNow = this.state.fullValue.concat()

            //Update all equal groups
            if (vardering.length > 0) {
                for (let i = 0; i < vardering.length; i++) {
                    let group = vardering[i].groupName
                    let education = vardering[i].education
                    let problemsolving = vardering[i].problemsolving
                    let socialSkills = vardering[i].socialSkills
                    let personnel = vardering[i].personnel
                    let operation = vardering[i].operation
                    let people = vardering[i].people
                    let physical = vardering[i].physical
                    let psychological = vardering[i].psychological
                    let equalentGroup = vardering[i].equalentGroup
                    for (let i = 0; i < dataNow.length; i++) {
                        if (dataNow[i].groupName === group) {
                            dataNow[i].education = education
                            dataNow[i].problemsolving = problemsolving
                            dataNow[i].socialSkills = socialSkills
                            dataNow[i].personnel = personnel
                            dataNow[i].operation = operation
                            dataNow[i].people = people
                            dataNow[i].physical = physical
                            dataNow[i].psychological = psychological
                            dataNow[i].equalentGroup = equalentGroup
                        }
                    }
                }
                //Update viktning
                const viktningTotal = viktning.education + viktning.problemsolving + viktning.socialSkills + viktning.personnel + viktning.operation + viktning.people + viktning.physical + viktning.psychological

                this.setState({
                    fullValue: dataNow,
                    vUtb: viktning.education,
                    vProb: viktning.problemsolving,
                    vSoc: viktning.socialSkills,
                    vPer: viktning.personnel,
                    vVerk: viktning.operation,
                    vMans: viktning.people,
                    vFys: viktning.physical,
                    vPsy: viktning.psychological,
                    vTotal: viktningTotal,
                    showPopUpWarning: "finished",
                })
                return;
            } else {
                alert(language === "sv" ? "Inga motsvarande grupperingar kunde hittas. Säkerställ att du valt rätt bolag att importera ifrån." : lang[language].noPosFound)
                return;
            }
        }
        importIt();
    }

    toggleWarning = () => (this.state.showPopUpWarning === "start" || this.state.showPopUpWarning === "finished") ? this.setState({ showPopUpWarning: "hide" }) : this.setState({ showPopUpWarning: "start" })

    updateManualDescFromPopUp = (childData) => {
        // Update the parent component's state with the data from the child (PopUp)
        let manualDescriptions = this.state.manualDescriptions.slice()
        let foundItem = false
        for (let item of manualDescriptions) {
            if (item.question === childData.question) {
                item.instructions = childData.instructions
                item.focus = childData.focus
                item.level1 = childData.level1
                item.level2 = childData.level2
                item.level3 = childData.level3
                item.level4 = childData.level4
                foundItem = true
                break;
            }
        }
        if (!foundItem) {
            manualDescriptions.push(childData)
        }
        this.setState({ manualDescriptions: manualDescriptions })
    }

    sorting = (event) => {
        let sortBy = event.target.id
        if (sortBy === this.state.sorting) { return }
        else {
            this.setState({ sorting: sortBy, sortingOpen: false })
            // Sort here
            let allWorks = this.state.fullValue.slice()
            if (sortBy === "points") {
                allWorks.sort((a, b) => (a.equalentGroup === null ? -1 : b.equalentGroup === null ? 1 : a.equalentGroup - b.equalentGroup));
                this.setState({ fullValue: allWorks })
                return;
            }
            if (sortBy === "alphabet") {
                allWorks.sort((a, b) => (a.groupName ?? '').localeCompare(b.groupName ?? '')); // Sort only by name
                // Sort by null first and then name
                /* allWorks.sort((a, b) => {
                   const aGroup = a.equalentGroup === null ? -1 : 1;
                   const bGroup = b.equalentGroup === null ? -1 : 1;
                   return aGroup - bGroup || a.groupName.localeCompare(b.groupName);
                 });*/

                this.setState({ fullValue: allWorks })
                return
            }
        }
    }
    sortingOpen = () => { this.state.sortingOpen ? this.setState({ sortingOpen: false, settingValPop: false }) : this.setState({ exportPopUp: false, sortingOpen: true, settingValPop: false, filterOpen: false }) }
    settingValPop = () => { this.state.settingValPop ? this.setState({ exportPopUp: false, settingValPop: false, sortingOpen: false, popInfoArrows: false }) : this.setState({ settingValPop: true, sortingOpen: false, popInfoArrows: false, filterOpen: false }) }
    popInfoArrows = () => { this.state.popInfoArrows ? this.setState({ popInfoArrows: false }) : this.setState({ popInfoArrows: true }) }
    switchArrows = () => {
        this.state.hideArrows ?
            this.setState({ hideArrows: false }, () => { localStorage.setItem('hideArrows', 'false') }) :
            this.setState({ hideArrows: true }, () => { localStorage.setItem('hideArrows', 'true') })
    }
    filterOpen = () => { this.state.filterOpen ? this.setState({ filterOpen: false }) : this.setState({ exportPopUp: false, filterOpen: true, sortingOpen: false, settingValPop: false }) }
    handleFilter = (event) => {
        let work = event.currentTarget.dataset.filterwork
        let theList = this.state.fullValue
        const updatedArray = theList.map(obj => {
            // Check if the groupName property matches the given string
            if (obj.groupName === work && !obj.filterOut) {
                // Return a new object with the filterOut property added
                return { ...obj, filterOut: true };
            }
            if (obj.groupName === work && obj.filterOut) {
                obj.filterOut = false
            }
            // Return the original object if no match
            return obj;
        });
        const countFilterOutTrue = updatedArray.filter(obj => obj.filterOut === true).length;
        this.setState({ fullValue: updatedArray, nrFilterOut: countFilterOutTrue })
    }
    filterAll = () => {
        let theList = this.state.fullValue;
        const updatedArray = theList.map(obj => {
            return { ...obj, filterOut: true };
        });
        this.setState({ fullValue: updatedArray, nrFilterOut: updatedArray.length, filterSearch: "" });
    }
    removeFilter = () => {
        let theList = this.state.fullValue;
        const updatedArray = theList.map(obj => {
            return { ...obj, filterOut: false };
        });
        this.setState({ fullValue: updatedArray, nrFilterOut: 0, filterSearch: "" });
    }
    filterSearch = (event) => {
        let searchString = event.target.value
        this.setState({ filterSearch: searchString })
    }
    clearSearch = () => { this.setState({ filterSearch: "" }) }

    exportPopUp = () => { this.state.exportPopUp ? this.setState({ exportPopUp: false }) : this.setState({ exportPopUp: true }) }

    exportThis = async (event) => {
        let exportWhat = event.target.id
        if (exportWhat === "exportFactors") { // Word
            let response = await fetchAuth(`/api/loadDocFields`, 'POST');
            let data = await response.json();
            let originalFactors = data[17][0]
            let whatLevelDesc = data[18]
            let editedFactors = data[16][0]
            let whatEvaluation = ""
            let valuationName = ""
            const vikt = data[1][0]; //<-- change to data[1] only if error
            if (data[8][0].length != 0) {
                if (data[8][0].whatEvaluation != undefined) {
                    whatEvaluation = data[8][0].whatEvaluation
                }
                if (data[8][0].valuationName != undefined) {
                    valuationName = data[8][0].valuationName
                }
            }
            exportEvaluationWord(vikt, whatEvaluation, valuationName, editedFactors, originalFactors, whatLevelDesc) // whatEvaluation, valuationName, editedFactors, originalFactors, whatLevelDesc
        }
        else if (exportWhat === "exportEvaluation") { // Excel
            let language = localStorage.getItem('language') ?? 'sv';
            let name = language === "sv" ? "Arbetsvardering" : lang[language].jobEvaluation

            let valuationData = {
                valuations: this.state.fullValue,
                weights: {
                    vFys: this.state.vFys,
                    vMans: this.state.vMans,
                    vPer: this.state.vPer,
                    vProb: this.state.vProb,
                    vPsy: this.state.vPsy,
                    vSoc: this.state.vSoc,
                    vUtb: this.state.vUtb,
                    vVerk: this.state.vVerk,
                    vTotal: this.state.vTotal,
                },
                nrOfEqGroups: this.state.equalentGroups,
                // Also send the descriptions later
            }
            const response = await fetchAuth(`/api/exportEquivalentNormal`, 'POST', JSON.stringify({ data: valuationData, language: language }));
            const blob = await response.blob();
            // Create a download link for the Blob
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name + '.xlsx'); // File name
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link after downloading
            link.parentNode.removeChild(link);
        }
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let arbetsvaderingList = "";
        const groupValuation = this.state.fullValue
        if (groupValuation.length > 0) {
            arbetsvaderingList = groupValuation.map((work) =>
                <li style={{ display: work.filterOut ? "none" : "list-item" }} id={work.groupName} className={this.state.activeItemId === `${work.groupName}` ? "valuation-li li-active" : "valuation-li"} key={work.groupName} onClick={name => this.toggleActive(name.currentTarget.id)}>
                    <div className="position" >{work.groupName}</div>
                    <div className="answers-container">
                        <div className="titlesInside">
                            <input type="number" min={1} max={4} value={work.education} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="education" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <input type="number" min={1} max={4} value={work.problemsolving} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="problemsolving" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <input type="number" min={1} max={4} value={work.socialSkills} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="socialSkills" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <input type="number" min={1} max={4} value={work.personnel} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="personnel" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <input type="number" min={1} max={4} value={work.operation} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="operation" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <input type="number" min={1} max={4} value={work.people} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="people" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <input type="number" min={1} max={4} value={work.physical} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="physical" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <input type="number" min={1} max={4} value={work.psychological} onChange={this.handleChange} onFocus={this.handleFocus} className={this.state.hideArrows ? "answer answerHide" : "answer"} id="psychological" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} />
                            <span className={work.equalentGroup === null || "" ? "equalentHide" : "equalentSpan"}>{work.equalentGroup === null || "" ? "" : work.equalentGroup}</span>
                        </div>
                    </div>
                </li>
            )
        }
        //List companies for MultiSearchSelect
        let companySelection = ["Inga bolag hittades"]
        if (this.state.concernComps.length > 0) {
            //Here add list of companies to choose from <-- FOR SELECTED LIST
            companySelection = this.state.concernComps.map(company => {
                return <option value={company}>{company}</option>
            });
        }

        return (
            <>
                <CustomPrompt
          when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
          message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
        />
                <TopNav />
                <Menu />
                <div className="container containerFixForValuation">

                    <Information step="arbetsvardera" />

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>
                    {this.state.manualDescriptions !== null && this.state.showPopUp !== null ?
                        <PopUp showPopUp={this.state.showPopUp} onDataSent={this.updateManualDescFromPopUp} exitAction={this.popUpNull} whatLevelDesc={this.state.whatLevelDesc} manualDescriptions={this.state.manualDescriptions} />
                        : <></>}
                    <div className={this.state.showPopUp === null ? "hide" : "popUpFader"}></div>
                    { /* Warning popUp */}
                    <div className={(this.state.showPopUpWarning === "start" || this.state.showPopUpWarning === "finished") ? "popUp active-popUp" : "popUp"} style={{ padding: 40, width: 400, height: "auto" }}>
                        {this.state.showPopUpWarning === "start" ?
                            <>
                                {language === "sv" ? "Är du säker på att du vill importera?" : lang[language].jobEPop}
                                <div>
                                    <div className="noButton" onClick={this.toggleWarning}>{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                    <div className="yesButton" onClick={this.import}>  {language === "sv" ? "Fortsätt" : lang[language].continue}</div>
                                </div>
                            </>
                            :
                            <>
                                {language === "sv" ? "Data importerad!" : lang[language].jobEImported}
                                <div>
                                    <div className="yesButton" onClick={this.toggleWarning}>Ok</div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={this.state.showPopUpWarning !== "hide" ? "popUpFader" : "hide"}></div>

                    <div className="evaluate-container" style={{ marginBottom: 100, marginTop: 0 }}>
                        {/* Import */}
                        <div style={{ paddingTop: 20 }} className={this.state.copyOpen ? "copyClosed" : "copyClosed openCopyCont"}>

                            <div className={this.state.copyOpen ? "copyButton" : "copyButton openCopyCont"} >
                                <span style={{ paddingTop: 20, paddingBottom: 20, marginLeft: -4, paddingLeft: 10, fontSize: 14.6 }} onClick={this.toggleCopy}>{language === "sv" ? "Hämta värderingar" : lang[language].importEvaluation}</span>
                                <select onChange={this.importFrom} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseCompany">
                                    <option value=""> {language === "sv" ? "Importera ifrån.." : lang[language].importFrom}</option>
                                    {companySelection}
                                </select>
                                <select onChange={this.whatToImport} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseWhat" >
                                    <option value=""> {language === "sv" ? "Vad vill du importera?" : lang[language].importWhat}</option>
                                    <option value="all"> {language === "sv" ? "Allt" : lang[language].everything} </option>
                                    <option value="empty"> {language === "sv" ? "Endast tomma" : lang[language].onlyEmpty}</option>
                                    <option value="selected"> {language === "sv" ? "Enskilda grupper" : lang[language].specificGroups} </option>
                                    <option value="viktning"> {language === "sv" ? "Endast viktning" : lang[language].onlyWeight} </option>
                                </select>
                                <div className={this.state.importWhat === "selected" ? "showMultiSearch" : "hide"}>
                                    <MultiSearchSelect
                                        searchPlaceholder={language === "sv" ? "Välj grupper" : lang[language].chooseGroups}
                                        searchable={true}
                                        showTags={false}
                                        multiSelect={true}
                                        width="180px"
                                        onSelect={this.handleChangeForMultiSelect}
                                        options={this.state.values}
                                        className={this.state.copyOpen ? "hide" : "multiSearch"}
                                        primaryColor="white"
                                        secondaryColor="white"
                                        textSecondaryColor="#333333"
                                    />
                                </div>
                                <span onClick={this.toggleWarning} className={this.state.copyOpen ? "hiddenButton" : "showButton"}>{language === "sv" ? "Importera" : lang[language].groupImport}</span>
                                <span onClick={this.toggleCopy} className={this.state.copyOpen ? "hide" : "questionM"} style={{ color: "white", lineHeight: 1, border: "none", fontSize: 16, marginLeft: 30 }}>x</span>
                            </div>
                            <span className="questionM" onClick={() => this.togglePopUp("importVardering")}>?</span>
                        </div>

                        {/* Filter Button */}
                        <div style={{ marginRight: 70 }} className={this.state.copyOpen ? "filterValuationBtn" : "filterValuationBtn filterValuationBtnSpaceFix"}>
                            <div className={this.state.nrFilterOut !== 0 ? "notificationFilter" : "hide"}>{this.state.nrFilterOut}</div>
                            <img onClick={this.filterOpen} src={filterBtn} width={9} style={{ marginRight: 5, opacity: 0.45 }} /><span style={{ paddingTop: 9, paddingBottom: 9, color: "#333333" }} onClick={this.filterOpen}>{language === "sv" ? "Filtrera" : lang[language].filter}</span>
                            <div style={{ width: 270, marginLeft: -222, paddingBottom: 0, cursor: "auto" }} className={this.state.filterOpen ? "filterValuationBtnBox" : "hide"}>
                                {/* <div>OBS: Alla arbeten måste arbetsvärderas</div> */}
                                <div style={{ borderBottom: "1px solid #cccccc", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9 }}><span style={{ padding: 5, paddingLeft: 0, float: "left", paddingTop: 0 }}><span className="notificationDetails" style={{ fontSize: 11, background: this.state.nrFilterOut !== 0 ? "#c95454" : "#56cbae", width: this.state.nrFilterOut > 9 ? 25 : 20, borderRadius: this.state.nrFilterOut > 9 ? 14 : "50%" }}>{this.state.nrFilterOut}</span> {language === "sv" ? "arbeten döljs." : lang[language].hiddenWorks}</span>
                                    {/*} <span className="filterShowAllBtn">Rensa filter</span>*/}
                                    <span onClick={this.removeFilter} style={{ cursor: "pointer", borderLeft: "1px solid #e8e8e8", float: "right", marginLeft: 9, paddingLeft: 9, color: "#40a18b", textDecoration: "underline", marginTop: 1, marginRight: 5 }}>{language === "sv" ? "Visa alla" : lang[language].showAll}</span>
                                    <span onClick={this.filterAll} style={{ cursor: "pointer", float: "right", marginLeft: 5, paddingLeft: 5, color: "#40a18b", textDecoration: "underline", marginTop: 1 }}>{language === "sv" ? "Dölj alla" : lang[language].hideAll}</span>
                                    <div>
                                        <input className="searchBoxFilterFocus" type="text" placeholder={language === "sv" ? "Sök" : lang[language].search} value={this.state.filterSearch} onChange={this.filterSearch} style={{ fontSize: 13, border: "1px solid #c8c8c8", width: 251, height: 24, cursor: "auto", marginBottom: 9, paddingLeft: 9, textAlign: "left" }} />
                                        <img onClick={this.clearSearch} style={{ display: this.state.filterSearch.trim().length > 0 ? "inline" : "none", width: 14, position: "absolute", marginLeft: -24, marginTop: 9.5, opacity: 0.5, cursor: "pointer" }} src={exitImg} />
                                    </div>
                                </div>


                                <ul className="filterScrollUl">
                                    {this.state.fullValue.map((work) =>
                                        <li data-filterwork={work.groupName} onClick={this.handleFilter} style={{ display: work.groupName.toLowerCase().includes(this.state.filterSearch.toLowerCase().trim()) ? "list-item" : "none", cursor: "pointer", paddingTop: 10, paddingBottom: 9, borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9 }}><img src={work.filterOut ? eyeOff : eyeOn} style={{ opacity: work.filterOut ? 0.5 : 1, width: 20, verticalAlign: "top", marginTop: -1.5 }} /><span style={{ opacity: work.filterOut ? 0.5 : 1, display: "inline-block", marginLeft: 9, verticalAlign: "top", width: 220, overflowWrap: "break-word" }}>{work.groupName}</span></li>
                                        //  <li data-filterwork="Drifttekniker" onClick={this.handleFilter} style={{ cursor: "pointer", paddingTop: 10,paddingBottom: 9,borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9}}><img src={eyeOn} style={{width: 20, verticalAlign: "top", marginTop: -1.5}} /><span style={{display: "inline-block",marginLeft: 9,verticalAlign: "top", width: 240, overflowWrap: "break-word"}}>Drifttekniker</span></li>
                                        //  <li style={{ cursor: "pointer", paddingTop: 10,paddingBottom: 9,borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9}}><img src={eyeOn} style={{width: 20, verticalAlign: "top", marginTop: -1.5}} /><span style={{display: "inline-block",marginLeft: 9,verticalAlign: "top", width: 240, overflowWrap: "break-word"}}>Fabriksarbetare</span></li>
                                        //  <li style={{ cursor: "pointer", paddingTop: 10,paddingBottom: 9,borderTop: "1px solid #e8e8e8", marginLeft: -9, paddingLeft: 9, marginRight: -9, paddingRight: 9}}><img src={eyeOff} style={{opacity: 0.5, width: 20, verticalAlign: "top", marginTop: -1.5}} /><span style={{opacity: 0.5, display: "inline-block",marginLeft: 9,verticalAlign: "top", width: 240, overflowWrap: "break-word"}}>Inköpare</span></li>
                                    )}
                                </ul>

                            </div>
                        </div>

                        {/* Sort Button */}
                        <div style={{ marginRight: language === "sv" ? 160 : 150 }} className={this.state.copyOpen ? "filterValuationBtn" : "filterValuationBtn filterValuationBtnSpaceFix"}><img onClick={this.sortingOpen} src={sortFilter} width={9.5} style={{ marginRight: 5, opacity: 0.5 }} /><span style={{ paddingTop: 9, paddingBottom: 9, color: "#333333" }} onClick={this.sortingOpen}>{language === "sv" ? "Sortera" : lang[language].sort}</span>
                            <div className={this.state.sortingOpen ? "filterValuationBtnBox" : "hide"}>
                                <div id="alphabet" onClick={this.sorting} style={{ paddingBottom: 10 }} className={this.state.sorting === "alphabet" ? "" : "greyFilterItem"}><img src={this.state.sorting === "alphabet" ? imgArrBlack : imgArrWhite} width={16} style={{ marginRight: 5, verticalAlign: "top" }} />{language === "sv" ? "Bokstavsordning" : lang[language].sortAlpha}</div>
                                <div id="points" onClick={this.sorting} className={this.state.sorting === "points" ? "" : "greyFilterItem"}><img src={this.state.sorting === "points" ? imgArrBlack : imgArrWhite} width={16} style={{ marginRight: 5, verticalAlign: "top" }} />{language === "sv" ? "Krav / Totalpoäng" : lang[language].sortLevel}</div>
                            </div>
                        </div>
                        {/* Settings button */}
                        <div className={this.state.copyOpen ? "filterValuationBtn" : "filterValuationBtn filterValuationBtnSpaceFix"}><img onClick={this.settingValPop} src={settings} width={16} style={{ opacity: 0.4, filter: "invert(100%)", verticalAlign: "top" }} />
                            <div style={{ marginLeft: -199, width: 205, cursor: "default" }} className={this.state.settingValPop ? "filterValuationBtnBox" : "hide"}>
                                <div id="alphabet" style={{ padding: 5, paddingLeft: 0, paddingRight: 0 }} className={this.state.sorting === "alphabet" ? "" : "greyFilterItem"}>
                                    <Switch width={41} height={21} onColor='#56cbad' offColor='#b9b9b9' borderRadius={4} className="valSettingsSwitch" activeBoxShadow="none" onChange={this.switchArrows} checked={this.state.hideArrows} />
                                    <div style={{ display: "inline-block", float: "right" }}>
                                        {language === "sv" ? "Dölj pilar i poängfält" : lang[language].hideArrows}
                                        <div className={this.state.popInfoArrows ? "popUpBoxHideArrow" : "hide"}>
                                            <img onClick={this.popInfoArrows} className="popUpHideArrowExit" src={exitImg} width={17} />
                                            <img src={arrowPrint} width={90} style={{ display: "inline-block", verticalAlign: "top" }} />
                                            <div style={{ display: "inline-block", verticalAlign: "top", marginLeft: 20, width: 305 }}><span style={{ width: 285, display: "block" }}>{language === "sv" ? "Om ni upplever att ni av misstag råkar klicka på pilarna i poängfälten kan ni välja att dölja dessa. Ni kommer då endast att kunna ändra poäng med tangentbordet." : lang[language].hideArrowsInfo}</span></div>
                                        </div>
                                        <span onClick={this.popInfoArrows} className="checkBoxQuestion" style={{ textAlign: "center", marginLeft: 8, marginTop: 2, width: 14, height: 14, fontSize: 12 }}>?</span>
                                    </div>
                                </div>
                                {/* Export button */}
                                <div onClick={this.exportPopUp} className="moreSettingsItem"><img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: language === "sv" ? 27 : 21, marginLeft: 17 }} />
                                    {language === "sv" ? "Exportera" : lang[language].export}
                                </div>
                                <div className={this.state.exportPopUp ? "whatExportPop" : "hide"}>
                                    <div id="exportFactors" onClick={this.exportThis} className="whatExportExcel">
                                        <img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: 15 }} />
                                        {language === "sv" ? "Faktorer" : lang[language].factors}
                                    </div>
                                    <div id="exportEvaluation" onClick={this.exportThis} className="whatExportWord">
                                        <img src={exportImg} width={16} style={{ opacity: 0.9, verticalAlign: "top", marginRight: 15 }} />
                                        {language === "sv" ? "Värdering" : lang[language].evaluation}
                                    </div>
                                </div>
                                {/* setttings button */}
                                {this.state.extraButton === "yes" ?
                                    <NavLink
                                    to={`/settings?component=Arbetsvardera`}
                                    >
                                        <div className="moreSettingsItem"><img src={openTab} width={16} style={{ opacity: 0.5, verticalAlign: "top", marginRight: language === "sv" ? 27 : 21, marginLeft: 17 }} />
                                            {language === "sv" ? "Fler inställningar" : lang[language].moreSettings}
                                        </div>
                                    </NavLink>
                                    : <></>
                                }
                            </div>
                        </div>
                        {/* Viktning */}
                        <div className="titles-container" style={{
                            background: "white", borderTopRightRadius: "4px",
                            borderTopLeftRadius: "4px"
                        }}>
                            <span className="title-head-position"><span style={{ fontWeight: 600 }}> {language === "sv" ? "Viktning:" : lang[language].weight}</span> <span className={this.state.vTotal == 100 ? "green-weight" : "red-weight"}>{this.state.vTotal}%</span> </span>
                            <div className="titles">
                                <div className="titlesInside">
                                    <span className="title-head"><input id="vUtb" value={this.state.vUtb} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    <span className="title-head"><input id="vProb" value={this.state.vProb} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    <span className="title-head"><input id="vSoc" value={this.state.vSoc} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    <span className="title-head"><input id="vPer" value={this.state.vPer} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    <span className="title-head"><input id="vVerk" value={this.state.vVerk} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    <span className="title-head"><input id="vMans" value={this.state.vMans} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    <span className="title-head"><input id="vFys" value={this.state.vFys} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                    <span className="title-head"><input id="vPsy" value={this.state.vPsy} onChange={this.percentChange} onFocus={this.handleFocus} className="input-vardering" onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }} /><span style={{ width: "50%" }}>%</span></span>
                                </div>
                            </div>
                        </div>
                        {/* END OF Viktning  */}

                        {/* Positions Titles */}
                        <div className={this.state.showPopUp != null || "" ? "titles-container titles-container-if-popup" : "titles-container"}>
                            <span className="title-head-position" style={{ verticalAlign: "bottom", marginBottom: 8 }}>{language === "sv" ? "Grupp" : lang[language].group}</span>
                            <div className="titles">
                                <div className="titlesInside">
                                    <span className="title-head" onClick={() => this.togglePopUp("utbildning")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Utbildning" : lang[language].edu} <p style={{ marginBottom: '-15px' }}></p>{language === "sv" ? "& Erfarenhet" : lang[language].andExp}</span>
                                    <span className="title-head" onClick={() => this.togglePopUp("problem")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Problemlösning" : lang[language].prob}</span>
                                    <span className="title-head" onClick={() => this.togglePopUp("sociala")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Sociala färdigheter" : lang[language].socSkills}</span>
                                    <span className="title-head" onClick={() => this.togglePopUp("personal")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Personal" : lang[language].staff}</span>
                                    <span className="title-head" onClick={() => this.togglePopUp("verksamhet")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Verksamhet" : lang[language].operation}</span>
                                    <span className="title-head" onClick={() => this.togglePopUp("manniskor")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Människor" : lang[language].people}</span>
                                    <span className="title-head" onClick={() => this.togglePopUp("fysiska")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Fysiska" : lang[language].physical}</span>
                                    <span className="title-head" onClick={() => this.togglePopUp("psykiska")}>
                                        <img src={infoImg} className="info-icon" alt="" />{language === "sv" ? "Psykiska" : lang[language].psychological}</span>
                                </div>
                            </div>
                        </div>
                        {/* END OF Position Titles */}

                        {/* List items */}
                        <ul className="valuation-ul" style={{ marginBottom: 0, letterSpacing: -0.2 }}>
                            {arbetsvaderingList}
                        </ul>
                        {/* <ul className="valuation-ul" style={{ marginBottom: 100, letterSpacing: -0.2 }}>
                        </ul>*/}
                    </div>
                </div>

            </>
        );
    }
}