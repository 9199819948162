// Note if marketdata is 0 for any percentile, interpolation will be used. If there is no market percentiels at all, only the internal data will be used.

//Settings
/*const maxSpread = 100; // Max 100% spread (double the minimum salary)
const minSpread = 10;  // Ensure at least a 10% spread
const marketPercentage = 100; // How much to weigh market data (e.g., 30% = 30% market, 70% internal)
const minimumSalaryMinHigher = 1; // Ensures each level is at least X% higher than the previous level
const lowPer = 25; // The lower bound percentile to use (e.g., 10, 25)
const highPer = 75; // The upper bound percentile to use (e.g., 75, 90)
const forceProgression = true; // If true, ensures each level is strictly higher than the previous one*/


// Data
/*let employeeData = [
{level1: {
p10: 20000,
p25: 20000,
p50: 28000,
p75: 45000,
p90: 55000,
}},
{level2: {
p10: 30000,
p25: 35000,
p50: 45000,
p75: 55000,
p90: 65000,
}},
{level3: {
p10: 40000,
p25: 45000,
p50: 55000,
p75: 65000,
p90: 70000,
}},
{level4: {
p10: 30000,
p25: 40000,
p50: 43000,
p75: 45000,
p90: 50000,
}},
{level5: {
p10: 45000,
p25: 50000,
p50: 55000,
p75: 60000,
p90: 75000,
}},
{level6: {
p10: 45000,
p25: 55000,
p50: 60000,
p75: 75000,
p90: 90000,
}},
]
let marketData = [
{level1: {
p10: 25000,
p25: 45000,
p50: 45000,
p75: 50000,
p90: 55000,
}},
{level2: {
p10: 35000,
p25: 35000,
p50: 45000,
p75: 65000,
p90: 75000,
}},
{level3: {
p10: 25000,
p25: 40000,
p50: 45000,
p75: 55000,
p90: 90000,
}},
{level4: {
p10: 30000,
p25: 40000,
p50: 43000,
p75: 45000,
p90: 50000,
}},
{level5: {
p10: 55000,
p25: 0,
p50: 65000,
p75: 70000,
p90: 85000,
}},
{level6: {
p10: 55000,
p25: 65000,
p50: 70000,
p75: 85000,
p90: 95000,
}},
]*/

// This select what version, if we want to be able to offer or experiment with different calculations
const salaryRangeCalc = (
  employeeData,
  marketData,
  maxSpread,
  minSpread,
  marketPercentage,
  minimumSalaryMinHigher,
  lowPer,
  highPer,
  forceProgression,
  levels,
  version,
  empMinMaxOverride,
) => {
  if (version === 1) {
    return versionOne(
      employeeData,
      marketData,
      maxSpread,
      minSpread,
      marketPercentage,
      minimumSalaryMinHigher,
      lowPer,
      highPer,
      forceProgression,
      levels,
      empMinMaxOverride
    );
  }
  if (version === 2) {
    // return versionTwo
  }
}

// Verson 1 - calculates the salary ranges with the use of percentiles
const versionOne = (
  employeeData,
  marketData,
  maxSpread,
  minSpread,
  marketPercentage,
  minimumSalaryMinHigher,
  lowPer,
  highPer,
  forceProgression,
  levels,
  empMinMaxOverride
) => {
  return new Promise((resolve, reject) => {
    let prevMin = 0;
    let prevMax = 0;
    const result = [];

    // Convert marketPercentage to weight factors
    const marketWeight = marketPercentage / 100;
    const internalWeight = 1 - marketWeight;

    // If empMinMaxOverride is true, the overrredie percentiles for internal employees and just use lowest and highest salary instead (p0 and p100). 
    // Note that if the difference is to big between p90 and p100 or p0 and p10, then it wont be highest and lowest salary but the value just between. Its calculated in arkitektur.js
    let lowPerEmp = lowPer
    let highPerEmp = highPer
    if (empMinMaxOverride) {
      lowPerEmp = 0
      highPerEmp = 100
    }

    try {
      // Loop through all levels (0 to levels-1)
      for (let i = 0; i < levels; i++) {

        const levelKey = `level${i + 1}`;

        // Check if this level exists in the data
        const employeeLevel = employeeData.find(levelObj => levelObj[levelKey]);
        const marketLevel = marketData.find(levelObj => levelObj[levelKey]);

        // If level data is missing (either employee or market data is empty), skip it
        if (!employeeLevel || !marketLevel) {
       //   console.log(`Skipping Level ${i + 1}: Data missing`);
          continue;  // Skip this level and move to the next one
        }

        // Extract the selected percentiles for both employee and market data
        let empLow = employeeLevel[levelKey][`p${lowPerEmp}`];
        let empHigh = employeeLevel[levelKey][`p${highPerEmp}`];
        let marketLow = marketLevel[levelKey][`p${lowPer}`];
        let marketHigh = marketLevel[levelKey][`p${highPer}`];

        // If percentiles are missing (0), we will perform interpolation, it will not be missing for employee data, only for market data

        // Interpolate missing values for percentiles ------
        if (marketLow === 0 || marketHigh === 0) {
        //  console.log(`Interpolating missing values for ${levelKey}`);

          const p10 = marketLevel[levelKey]?.[`p10`] || 0;
          const p25 = marketLevel[levelKey]?.[`p25`] || 0;
          const p50 = marketLevel[levelKey]?.[`p50`] || 0;
          const p75 = marketLevel[levelKey]?.[`p75`] || 0;
          const p90 = marketLevel[levelKey]?.[`p90`] || 0;

          // Check if percentiles are completely missing or only one is present
          const missingPercentiles = [p10, p25, p50, p75, p90].filter(val => val === 0).length;

          // If all percentiles or most of them are missing, fallback to internal data (employee data)
          if (missingPercentiles >= 4) {
          //  console.log(`Most percentiles are missing. Fallback to using employee data for marketLow and marketHigh`);

            const empLow = employeeLevel[levelKey][`p${lowPerEmp}`];
            const empHigh = employeeLevel[levelKey][`p${highPerEmp}`];

            // Use the employee data as a fallback for market data
            marketLow = empLow;
            marketHigh = empHigh;

           // console.log(`Using employee data as fallback: marketLow=${marketLow}, marketHigh=${marketHigh}`);
          } else {
            // Interpolate marketLow (p10)
            if (marketLow === 0) {
              if (p10 !== 0) {
                marketLow = p10;
              } else if (p25 !== 0) {
                marketLow = p25 * 0.85; // Estimate p10 as 85% of p25
              } else if (p50 !== 0) {
                marketLow = p50 * 0.7; // Estimate p10 as 70% of p50
              } else {
             //   console.log("Unable to estimate marketLow, fallback to 0");
              }
          //    console.log(`Interpolated marketLow (p10) to ${marketLow}`);
            }
            // Interpolate marketHigh (p90)
            if (marketHigh === 0) {
              if (p90 !== 0) {
                marketHigh = p90;
              } else if (p75 !== 0) {
                marketHigh = p75 * 1.25; // Estimate p90 as 125% of p75
              } else if (p50 !== 0) {
                marketHigh = p50 * 1.3; // Estimate p90 as 130% of p50
              } else {
            //    console.log("Unable to estimate marketHigh, fallback to 0");
              }
            //  console.log(`Interpolated marketHigh (p90) to ${marketHigh}`);
            }
            // If p50 is missing, estimate from p25/p75 or p10/p90
            if (p50 === 0) {
              if (p25 !== 0 && p75 !== 0) {
                marketLow = (p25 + p75) / 2;  // Estimate p50 from p25 and p75
              } else if (p10 !== 0 && p90 !== 0) {
                marketLow = (p10 + p90) / 2;  // Estimate p50 from p10 and p90
              } else {
          //      console.log("Unable to estimate p50, fallback to 0");
              }
           //   console.log(`Interpolated marketLow (p50) to ${marketLow}`);
            }
          //  console.log(`Final interpolated values: marketLow=${marketLow}, marketHigh=${marketHigh}`);
          }
        } // END OF INTERPOLATION ----


        // Weighted average based on marketPercentage setting
        let levelMin = 0
        let levelMax = 0
        if (marketWeight === 0) { // If market influence is 0, we only use employee data
          if (empLow === empHigh) { // If there's only one person in the group, p10 and p90 will be the same, so instead we need to create a range around it, otherwise the range would start from the employee salary
            let baseSalary = empLow; // This is the only salary we have
            // Apply a spread around if based on minSpread
            levelMin = baseSalary * (1 - minSpread / 200);  // Example: 10% spread -> 5% below
            levelMax = baseSalary * (1 + minSpread / 200);  // 10% spread -> 5% above
          } else { // If more than one person then use percentiels as usual
            levelMin = empLow;
            levelMax = empHigh;
          }
        } else { // Default behavior if we use both employee and market data
          levelMin = empLow * internalWeight + marketLow * marketWeight;
          levelMax = empHigh * internalWeight + marketHigh * marketWeight;
        }

        // Ensure progression: Min for next level must be higher than the previous min (not max)
        if (forceProgression && i > 0) {
          // If the level was skipped, use the previous valid min and max
          if (result.length > 0) {
            levelMin = Math.max(levelMin, prevMin * (1 + minimumSalaryMinHigher / 100)); // Enforce at least X% increase
            levelMax = Math.max(levelMax, prevMax * (1 + minimumSalaryMinHigher / 100));
          }
        }

        // Ensure a minimum spread of minSpread%
        if (levelMax < levelMin * (1 + minSpread / 100)) {
          levelMax = levelMin * (1 + minSpread / 100);
        }

        // Ensure spread does not exceed maxSpread (100% difference between min and max)
        let acceptedMax = levelMin * (1 + maxSpread / 100) // This will show the highest max from min based on maxSpread

        function adjustSalaryRange(levelMin, levelMax) { // This is the function to shrink in oth ends until it has shrinked down to the maxSpread limit
          let newLevelMin = levelMin;
          let newLevelMax = levelMax;

          for (let i = 0; i < 1000; i++) {
            newLevelMin += 1000;
            newLevelMax -= 1000;

            if (newLevelMax < newLevelMin * (1 + maxSpread / 100)) {
              break;
            }
          }
          return { newLevelMin, newLevelMax };
        }

        if (levelMax > acceptedMax) { // If levelMax is above acceptedMax we need to shrink the salary range from top and bottom
          let weightedLow = (empLow * (1 - marketWeight)) + (marketLow * marketWeight) // get weighted low value, because we can not shrink from the created levelMin, because that has already taking progression into account
          const { newLevelMin, newLevelMax } = adjustSalaryRange(weightedLow, levelMax); // Calls shrink function above
          levelMin = !forceProgression ? newLevelMin : newLevelMin < levelMin ? levelMin : newLevelMin // If force progression is not on, use the new low. Otherwise, if new low is lower than the previous levelMin, then use the old one instead so we know that it is taking progression into account.
          levelMax = newLevelMax
          // Also make sure we take the minSpread into account. If less than minSpread, raise it to minimum, here we just raise the levelMin. It works good enough.
          let newSpreadPercentage = ((levelMax - levelMin) / levelMin) * 100;
          if (newSpreadPercentage < minSpread) {
            levelMax = levelMin * (1 + minSpread / 100)
          }
        }

        // Calculate the mid value (midpoint between min and max)
        let midValue = (levelMin + levelMax) / 2;

        // Calculate the spread (percentage difference between min and max)
        let spread = ((levelMax - levelMin) / levelMin) * 100;

        // Store the calculated span for the level
        result.push({ level: parseInt(levelKey.replace("level", ""), 10), min: Math.round(levelMin), max: Math.round(levelMax), mid: Math.round(midValue), spread: spread.toFixed(2) });

        // Update prevMin and prevMax for the next iteration
        prevMin = levelMin;
        prevMax = levelMax;
      }

      // Resolve the promise with the result
      resolve(result);
    } catch (error) {
      console.log("Error in salary range calculation:", error);
      reject(error);  // Reject the promise in case of error
    }
  });
}

export default salaryRangeCalc;