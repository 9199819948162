import React from 'react';
import './Menu.css';
import { NavLink } from 'react-router-dom';
import lang from './Language/language.js';

export class Menu extends React.Component {
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        let settingEU = localStorage.getItem('settingEU');
        return (
            <div className="nav-container">
                <NavLink exact to="/import" className="NoActiveBack" style={{ marginTop: 0 }}><span className="num">1</span>{language === "sv" ? "Importera" : lang[language].s1}</NavLink>
                <NavLink to="/gruppera" className="NoActiveBack"><span className="num">2</span>{language === "sv" ? "Gruppera & Värdera" : lang[language].s2}</NavLink>
                <div className="submenu">
                    <NavLink exact to="/gruppera" end className={({ isActive }) => isActive ? 'active' : 'NoActiveBack'}><span className="subName">{language === "sv" ? "2.1 Gruppera lika arbeten" : lang[language].s21}</span></NavLink>
                    <NavLink exact to="/gruppera/arbetsvardera" end className={({ isActive }) => isActive ? 'active' : 'NoActiveBack'}><span className="subName">{language === "sv" ? "2.2 Arbetsvärdera" : lang[language].s22}</span></NavLink>
                    {/* <NavLink exact to="/arkitektur"  className={({ isActive }) => isActive ? 'active' : 'NoActiveBack'}><span className="subName">{language === "sv" ? "2.3 Jobbarkitektur" : lang[language].s22}</span></NavLink>*/}
                </div>
                <NavLink to="/analyslika" className="NoActiveBack"><span className="num">3</span>{language === "sv" ? "Analysera" : lang[language].s3}</NavLink>
                <div className="submenu">
                    <NavLink exact to="/analyslika" end className={({ isActive }) => isActive ? 'active' : 'NoActiveBack'}><span className="subName">{language === "sv" ? "3.1 Analysera lika arbeten" : lang[language].s31}</span></NavLink>
                    <NavLink exact to="/analyslika/analyslikvardiga" end className={({ isActive }) => isActive ? 'active' : 'NoActiveBack'}><span className="subName">{language === "sv" ? "3.2 Analysera likvärdiga arbeten" : lang[language].s32}</span></NavLink>
                  {settingEU === "on" &&  <NavLink exact to="/analyslika/foraldraledighet"  className={({ isActive }) => isActive ? 'active' : 'NoActiveBack'}><span className="subName">{language === "sv" ? "3.3 Analysera ledighet" : lang[language].s32}</span></NavLink>}
                    <NavLink exact to="/analyslika/utvardera" end className={({ isActive }) => isActive ? 'active' : 'NoActiveBack'}><span className="subName">{language === "sv" ? settingEU === "on" ? "3.4 Utvärdera" : "3.3 Utvärdera" : lang[language].s33}</span></NavLink>
                </div>
                <NavLink exact to="/Dokumentera" className="NoActiveBack"><span className="num">4</span>{language === "sv" ? "Dokumentera" : lang[language].s4}</NavLink>
            </div>
        );
    }
}