import React from 'react';
import './Foraldraledighet.css';
import './Foraldraledighet.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import yellowAlert from '../../assets/images/yellowAlert.png';
import greenCheck from '../../assets/images/greenCheck.png';
import lang from '../Language/language.js';
import exitImg from '../../assets/images/exit.png';
import arrowDown from '../../assets/images/arrow-down-black.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import remove from '../../assets/images/x-mark.png';
import autoFill from '../../assets/images/autoFill.png';
import { fetchAuth } from '../../fetch-auth';
import { hidePoweredBy } from 'helmet';
import CustomPrompt from '../General/CustomPrompt.js';;

export class Foraldraledighet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allEmps: [],
            equivalentGroups: [],
            onParentalLeave: [],
            notOnParentalLeave: [],
            onParentalLeaveAvgRaise: 0,
            onParentalLeaveAvgRaisePercent: 0,
            notOnParentalLeaveAvgRaise: 0,
            notOnParentalLeaveAvgRaisePercent: 0,
            menOnParentalRaise: 0,
            menOnParentalRaisePercent: 0,
            womenOnParentalRaise: 0,
            womenOnParentalRaisePercent: 0,
            activeGroup: false,
            openAnalyzeOne: false,
            openAnalyzeTwo: false,
            individualBoxOpen: false,
            whatComment: 1,
            autoNotableOneLower: "Föräldralediga medarbetare har fått en lägre löneökning än ej föräldralediga.",
            autoNotableOneOK: "Ser bra ut. Föräldralediga medarbetare har ej fått lägre löneökning.",
            autoNotableTwoLowerMen: "Föräldralediga män har fått en lägre löneökning än föräldralediga kvinnor.",
            autoNotableTwoLowerWomen: "Föräldralediga kvinnor har fått en lägre löneökning än föräldralediga män.",
            autoNotableTwoOK: "Inget behöver analyseras. Kvinnorna och männen har fått lika stor löneökning.",
            notableComment: "",
            notableCommentStatus: false,
            reasonComment: "",
            hideParentalLeave: false,
            hideNotParentalLeave: false,
            showCurrency: false,
            showAutofill: false,
            saveButtonStatus: "Sparat",
            saveButtonStatusNew: "Sparat",
            moreFields: false,
            addReason: "",
            addTodo: "",
            addDeadline: "",
            addCost: "",
            addResponsible: "",
            comments: [],
            commentsPast: [],
            deletedComments: [],
            filter: "all",
            showChangesPrompt: "Yes", //<-- For changes PopUp
            // Below is used for automatic analysis
            analysisOneText: "",
            analysisOneNeeded: false,
            analysisTwoText: "",
            analysisTwoNeeded: false,
            whatEvaluation: "",
            showOldComments: true,
            togglePrevOrThisSalary: "prev",
            menAvgSalPastYear: 0,
            womenAvgSalPastYear: 0,
            menAvgSalThisYear: 0,
            womenAvgSalThisYear: 0,
            notOnParentalLeaveAvgSalPastYear: 0,
            notOnParentalLeaveAvgSalThisYear: 0,
            onParentalLeaveAvgSalPastYear: 0,
            onParentalLeaveAvgSalThisYear: 0
        }
    }
    openAnalyzeOne = () => { this.state.openAnalyzeOne ? this.setState({ openAnalyzeOne: false }) : this.setState({ openAnalyzeOne: true }) }
    openAnalyzeTwo = () => { this.state.openAnalyzeTwo ? this.setState({ openAnalyzeTwo: false }) : this.setState({ openAnalyzeTwo: true }) }
    toggleIndividualBox = () => { this.state.individualBoxOpen ? this.setState({ individualBoxOpen: false }) : this.setState({ individualBoxOpen: true }) }
    selectAnalysisOne = () => { this.checkIfCommentIsSaved(1) }
    selectAnalysisTwo = () => { this.checkIfCommentIsSaved(2) }
    toggleParentalLeave = () => { this.state.hideParentalLeave ? this.setState({ hideParentalLeave: false }) : this.setState({ hideParentalLeave: true }) }
    toggleNotParentalLeave = () => { this.state.hideNotParentalLeave ? this.setState({ hideNotParentalLeave: false }) : this.setState({ hideNotParentalLeave: true }) }
    showPercent = () => { this.setState({ showCurrency: false }) }
    showCurrency = () => { this.setState({ showCurrency: true }) }
    toggleFields = () => { this.state.moreFields ? this.setState({ moreFields: false }) : this.setState({ moreFields: true }) }
    handleReason = (event) => { this.setState({ addReason: this.curSelReason.value }) }
    handleTodo = (event) => { this.setState({ addTodo: this.curSelTodo.value }) }
    handleDeadline = (event) => { this.setState({ addDeadline: event.target.value }) }
    handleCost = (event) => { this.setState({ addCost: this.curSelCost.value }) }
    handleResponsible = (event) => { this.setState({ addResponsible: this.curSelResp.value }) }
    filterAll = () => { this.setState({ filter: 'all' }) }
    filterEquivalent = () => { this.setState({ filter: this.state.activeGroup }) }

    saveNewComment = async () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.notableComment.length > 0) {
            const commentData = {
                notable: this.state.notableComment,
                reason: this.state.addReason,
                todo: this.state.addTodo,
                deadline: this.state.addDeadline,
                cost: this.state.addCost,
                responsible: this.state.addResponsible,
                analysis: "parental",
                groupE: this.state.activeGroup, // The equivalent group
                whatComment: this.state.whatComment // "1" or "2" (Analysis 1 or 2 for the group)
            }
            let response = await fetchAuth(`/api/saveParentalComment`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();

            //add todays date to temporary comment,or a previous date if we are doing it for a previous year
            let selectedYear = localStorage.getItem('year')
            if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
            if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
            let d = new Date()
            let month = d.getMonth();
            let day = d.getDate();
            const todaysDate = new Date(selectedYear, month, day);
            const dateNow = todaysDate.toISOString();

            //When saved, add the new comment to the comments array
            const toState = {
                notable: this.state.notableComment,
                reason: this.state.addReason,
                todo: this.state.addTodo,
                deadline: this.state.addDeadline,
                cost: this.state.addCost,
                responsible: this.state.addResponsible,
                commentId_PK: data,
                dateAdded: dateNow,
                groupE: this.state.activeGroup,
                parentalComment: this.state.whatComment // "1" or "2" (Analysis 1 or 2 for the group)
            }
            const commentList = this.state.comments.concat(toState);
            const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1) //Arrange by descending date so the new comments goes to top:
            if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                this.setState({
                    notableComment: "",
                    addReason: "",
                    addTodo: "",
                    addDeadline: "",
                    addCost: "",
                    addResponsible: "",
                    addCommentSummary: "",
                    comments: sortedComments, //add the new comment to the comments array
                    saveButtonStatusNew: "Sparat"
                })
                this.curSelReason.value = ""
                this.curSelTodo.value = ""
                this.curSelCost.value = ""
                this.curSelResp.value = ""
            } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        } else {
            alert(language === "sv" ? 'Du kan inte spara en tom notering. Vänligen fyll i fältet "Noterbart".' : lang[language].noEmptyComment)
        }
    }
    showAutofill = () => {
        if (this.state.notableComment.length < 1 && this.state.notableCommentStatus) {
            this.setState({ showAutofill: true })
        }
    }
    hideAutofill = () => { this.setState({ showAutofill: false }) }
    autoFillClick = () => {
        this.autoChecker();
    }
    handleCommentNotable = (event) => {
        let value = event.target.value
        if (value.length < 1 && this.state.notableCommentStatus) {
            this.setState({ notableComment: value, showAutofill: true, saveButtonStatusNew: "Spara" })
        } else {
            this.setState({ notableComment: value, showAutofill: false, saveButtonStatusNew: "Spara" })
        }
    }
    checkIfCommentIsSaved = (selectAnalysis) => {
        if (this.state.saveButtonStatusNew === "Spara" && this.state.notableComment.length > 2) {
            const userConfirmed = window.confirm("Din notering har inte sparats och kommer att försvinna. Vill du fortsätta?");
            if (userConfirmed) {
                if (selectAnalysis === 1 || selectAnalysis === 2) {
                    this.setState(() => ({ whatComment: selectAnalysis, saveButtonStatusNew: "Sparat" }), () => { this.autoCheckerStatus(); })
                } else {
                    this.autoCheckerStatus()
                }
                return
            } else {
                return
            }
        } else {
            if (selectAnalysis === 1 || selectAnalysis === 2) {
                this.setState(() => ({ whatComment: selectAnalysis }), () => { this.autoCheckerStatus(); })
            } else {
                this.autoCheckerStatus()
            }
        }
    }
    autoCheckerStatus = () => {
        let activeGroup = this.state.activeGroup
        let onParentalLeaveAvgRaise = this.state.onParentalLeaveAvgRaise
        let onParentalLeaveAvgRaisePercent = this.state.onParentalLeaveAvgRaisePercent
        let notOnParentalLeaveAvgRaise = this.state.notOnParentalLeaveAvgRaise
        let notOnParentalLeaveAvgRaisePercent = this.state.notOnParentalLeaveAvgRaisePercent
        let menOnParentalRaise = this.state.menOnParentalRaise
        let menOnParentalRaisePercent = this.state.menOnParentalRaisePercent
        let womenOnParentalRaise = this.state.womenOnParentalRaise
        let womenOnParentalRaisePercent = this.state.womenOnParentalRaisePercent

        if (this.state.whatComment == 1) { // This is parent vs non parent
            //notableComment
            if (Number(onParentalLeaveAvgRaisePercent) < Number(notOnParentalLeaveAvgRaisePercent)) {
                this.setState({ notableCommentStatus: true })
            } else {
                this.setState({ notableCommentStatus: false, notableComment: "" })
            }
        } else { // This is men on parental vs women on parental
            if (Number(womenOnParentalRaisePercent) < Number(menOnParentalRaisePercent)) {
                this.setState({ notableCommentStatus: true })
            } else if (Number(menOnParentalRaisePercent) < Number(womenOnParentalRaisePercent)) { // If men lower raise
                this.setState({ notableCommentStatus: true })
            } else { this.setState({ notableCommentStatus: false, notableComment: "" }) }
        }
    }
    autoCheckerAnalysis = () => {
        let activeGroup = this.state.activeGroup
        let onParentalLeaveAvgRaise = this.state.onParentalLeaveAvgRaise
        let onParentalLeaveAvgRaisePercent = this.state.onParentalLeaveAvgRaisePercent
        let notOnParentalLeaveAvgRaise = this.state.notOnParentalLeaveAvgRaise
        let notOnParentalLeaveAvgRaisePercent = this.state.notOnParentalLeaveAvgRaisePercent
        let menOnParentalRaise = this.state.menOnParentalRaise
        let menOnParentalRaisePercent = this.state.menOnParentalRaisePercent
        let womenOnParentalRaise = this.state.womenOnParentalRaise
        let womenOnParentalRaisePercent = this.state.womenOnParentalRaisePercent

        // parent vs non parent
        if (Number(onParentalLeaveAvgRaisePercent) < Number(notOnParentalLeaveAvgRaisePercent)) {
            this.setState({ analysisOneNeeded: true, analysisOneText: "Föräldralediga medarbetare har fått en lägre löneökning än ej föräldralediga. Undersök och kommentera nedan om det finns sakliga orsaker till detta. Om ingen saklig orsak finns bör en åtgärd skapas." })
        } else {
            this.setState({ analysisOneNeeded: false, analysisOneText: "Behöver ej analyseras. Föräldralediga har inte fått lägre lön än ej föräldralediga." })
        }
        // men on parental vs women on parental
        if (Number(womenOnParentalRaisePercent) < Number(menOnParentalRaisePercent)) {
            this.setState({ analysisTwoNeeded: true, analysisTwoText: "Föräldralediga kvinnor har fått lägre löneökning än föräldralediga män. Undersök och kommentera nedan om det finns sakliga orsaker till detta. Om ingen saklig orsak finns bör en åtgärd skapas." })
        } else if (Number(menOnParentalRaisePercent) < Number(womenOnParentalRaisePercent)) { // If men lower raise
            this.setState({ analysisTwoNeeded: true, analysisTwoText: "Föräldralediga män har fått lägre löneökning än föräldralediga kvinnor. Undersök och kommentera nedan om det finns sakliga orsaker till detta. Om ingen saklig orsak finns bör en åtgärd skapas." })
        } else { this.setState({ analysisTwoNeeded: false, analysisTwoText: "Inget behöver analyseras. Kvinnorna och männen har fått lika stor löneökning." }) }
    }

    autoChecker = () => {
        let activeGroup = this.state.activeGroup
        let onParentalLeaveAvgRaise = this.state.onParentalLeaveAvgRaise
        let onParentalLeaveAvgRaisePercent = this.state.onParentalLeaveAvgRaisePercent
        let notOnParentalLeaveAvgRaise = this.state.notOnParentalLeaveAvgRaise
        let notOnParentalLeaveAvgRaisePercent = this.state.notOnParentalLeaveAvgRaisePercent
        let menOnParentalRaise = this.state.menOnParentalRaise
        let menOnParentalRaisePercent = this.state.menOnParentalRaisePercent
        let womenOnParentalRaise = this.state.womenOnParentalRaise
        let womenOnParentalRaisePercent = this.state.womenOnParentalRaisePercent

        // If no comment found, add automatic comment
        if (this.state.whatComment == 1) { // This is parent vs non parent
            //notableComment
            if (Number(onParentalLeaveAvgRaisePercent) < Number(notOnParentalLeaveAvgRaisePercent)) {
                let parentalLower = this.state.autoNotableOneLower
                this.setState({ notableComment: parentalLower })
            } else {
                this.setState({ notableComment: "" })
            }
        } else { // This is men on parental vs women on parental
            if (Number(womenOnParentalRaisePercent) < Number(menOnParentalRaisePercent)) {
                let womenLower = this.state.autoNotableTwoLowerWomen // If women lower raise
                this.setState({ notableComment: womenLower })
            } else if (Number(menOnParentalRaisePercent) < Number(womenOnParentalRaisePercent)) { // If men lower raise
                let womenLower = this.state.autoNotableTwoLowerMen
                this.setState({ notableComment: womenLower })
            } else { this.setState({ notableComment: "" }) }
        }
    }
    clickGroup = (event, firsGroup) => {
        let eqGroup = this.state.whatEvaluation === "original" ? "equalentGroup" : this.state.whatEvaluation === "edited" ? "editedEqualentGroup" : "extEqualentGroup"
        let clickedGroup = ""
        if (event === "noEvent") {
            clickedGroup = firsGroup
        } else {
            clickedGroup = event.target.id
        }
        let onParentalLeave = this.state.allEmps.filter((emp) => emp[eqGroup] == clickedGroup && emp.parentalLeave !== null);
        let notOnParentalLeave = this.state.allEmps.filter((emp) => emp[eqGroup] == clickedGroup && emp.parentalLeave == null);
        // Calculate average salaryraise for parental leave vs not parental leave
        let onParentalLeaveAvgSalPastYear = Math.round(onParentalLeave.reduce((acc, { pastSalary }) => acc + pastSalary, 0) / onParentalLeave.length);
        let onParentalLeaveAvgSalThisYear = Math.round(onParentalLeave.reduce((acc, { salary }) => acc + salary, 0) / onParentalLeave.length);
        let onParentalLeaveAvgRaise = onParentalLeaveAvgSalThisYear - onParentalLeaveAvgSalPastYear
        let onParentalLeaveAvgRaisePercent = ((onParentalLeaveAvgRaise / onParentalLeaveAvgSalPastYear) * 100).toFixed(1);

        let notOnParentalLeaveAvgSalPastYear = Math.round(notOnParentalLeave.reduce((acc, { pastSalary }) => acc + pastSalary, 0) / notOnParentalLeave.length);
        let notOnParentalLeaveAvgSalThisYear = Math.round(notOnParentalLeave.reduce((acc, { salary }) => acc + salary, 0) / notOnParentalLeave.length);
        let notOnParentalLeaveAvgRaise = notOnParentalLeaveAvgSalThisYear - notOnParentalLeaveAvgSalPastYear
        let notOnParentalLeaveAvgRaisePercent = ((notOnParentalLeaveAvgRaise / notOnParentalLeaveAvgSalPastYear) * 100).toFixed(1);

        // Also calculate average salaryraise for men on parental leave and women on parental leave
        let menOnParentalLeave = onParentalLeave.filter((emp) => emp.gender == "man")
        let womenOnParentalLeave = onParentalLeave.filter((emp) => emp.gender == "woman")

        let menAvgSalPastYear = Math.round(menOnParentalLeave.reduce((acc, { pastSalary }) => acc + pastSalary, 0) / menOnParentalLeave.length);
        let menAvgSalThisYear = Math.round(menOnParentalLeave.reduce((acc, { salary }) => acc + salary, 0) / menOnParentalLeave.length);
        let menAvgRaise = menAvgSalThisYear - menAvgSalPastYear
        let menAvgSalPercent = ((menAvgRaise / menAvgSalPastYear) * 100).toFixed(1);

        let womenAvgSalPastYear = Math.round(womenOnParentalLeave.reduce((acc, { pastSalary }) => acc + pastSalary, 0) / womenOnParentalLeave.length);
        let womenAvgSalThisYear = Math.round(womenOnParentalLeave.reduce((acc, { salary }) => acc + salary, 0) / womenOnParentalLeave.length);
        let womenAvgRaise = womenAvgSalThisYear - womenAvgSalPastYear
        let womenAvgSalPercent = ((womenAvgRaise / womenAvgSalPastYear) * 100).toFixed(1);

        // Set state
        this.setState(() => ({
            activeGroup: clickedGroup,
            onParentalLeave: onParentalLeave,
            notOnParentalLeave: notOnParentalLeave,
            onParentalLeaveAvgRaise: onParentalLeaveAvgRaise,
            onParentalLeaveAvgRaisePercent: onParentalLeaveAvgRaisePercent,
            notOnParentalLeaveAvgRaise: notOnParentalLeaveAvgRaise,
            notOnParentalLeaveAvgRaisePercent: notOnParentalLeaveAvgRaisePercent,
            menOnParentalRaise: menAvgRaise,
            menOnParentalRaisePercent: menAvgSalPercent,
            womenOnParentalRaise: womenAvgRaise,
            womenOnParentalRaisePercent: womenAvgSalPercent,
            menAvgSalPastYear: menAvgSalPastYear,
            womenAvgSalPastYear: womenAvgSalPastYear,
            menAvgSalThisYear: menAvgSalThisYear,
            womenAvgSalThisYear: womenAvgSalThisYear,
            notOnParentalLeaveAvgSalPastYear: notOnParentalLeaveAvgSalPastYear,
            notOnParentalLeaveAvgSalThisYear: notOnParentalLeaveAvgSalThisYear,
            onParentalLeaveAvgSalPastYear: onParentalLeaveAvgSalPastYear,
            onParentalLeaveAvgSalThisYear: onParentalLeaveAvgSalThisYear
        }), () => {
            this.checkIfCommentIsSaved();
            this.autoCheckerAnalysis();
        }) // And set comment status for autofiller
    }
    editComment = (event) => {
        const column = event.target.name //what column should be saved
        const commentId_PK = event.target.id //For what comment ID
        const text = event.target.value //what text should be saved
        const editedComments = this.state.comments

        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { [column]: text, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });

        this.setState({ comments: updateComments })
        //Update save button
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }
    deleteComment = (event) => {
        const commentId_PK = event.target.id
        const removedComment = this.state.comments.filter(el => el.commentId_PK == commentId_PK);  //Get the deleted comment
        const listWithoutComment = this.state.comments.filter(el => el.commentId_PK != commentId_PK); //create new list without the deleted comment
        let del = this.state.deletedComments
        if (removedComment[0].reuseComment !== "Yes") { del = this.state.deletedComments.concat(removedComment) } //add removedComment to deletedComments array:

        this.setState({
            comments: listWithoutComment, //set state for new array without the deleted comment.
            deletedComments: del //set state for the comment/s that should be deleted, so it wont be deleted until we hit the save button
        })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }
    toggleOldComments = () => {
        this.state.showOldComments ? this.setState({ showOldComments: false }) : this.setState({ showOldComments: true })
    }
    //Save changes in comments
    saveButton = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        //send only updatedComments to server for saving (and also the deleted comments)
        const updatedComments = this.state.comments.filter(el => el.updated)
        const deletedComments = this.state.deletedComments

        //Query DB HERE (Use deletedComments and updatedComments)
        const saveData = async () => {
            const commentData = {
                changes: updatedComments,
                deleted: deletedComments
            }

            const response = await fetchAuth(`/api/saveParentalCommentChanges`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();
            if (data == 'Saved') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                this.setState({ saveButtonStatus: "Sparat" })
            } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        };
        saveData();

        //Remove property updated if saved
        const removeUpdatedProperty = this.state.comments.map(el => {
            if (el.updated) {
                delete el.updated;
                return el;
            }
            return el;
        });
        this.setState({ comments: removeUpdatedProperty })
    }
    togglePrevOrThisSalary = (event) => {
        event.stopPropagation();
        this.state.togglePrevOrThisSalary === "prev" ? this.setState({ togglePrevOrThisSalary: "new" }) : this.setState({ togglePrevOrThisSalary: "prev" })
    }
    componentDidMount() {
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }
        this.getData()
    }
    getData = async () => {
        let language = localStorage.getItem('language') ?? 'sv'
        try {
            let year = localStorage.getItem("year")
            if (year == undefined) { year = new Date().getFullYear() }
            const response = await fetchAuth(`/api/loadParental`, 'POST', JSON.stringify({ data: year }));
            let dataLoaded = await response.json();

            let data = dataLoaded[0]
            let comments = dataLoaded[1]
            let commentsPast = dataLoaded[2]
            // Get what evaluation
            let whatEvaluation = ""
            if (dataLoaded[3][0].whatEvaluation == undefined || dataLoaded[3][0].whatEvaluation == null) {
                whatEvaluation = "original"
            } else {
                whatEvaluation = dataLoaded[3][0].whatEvaluation
            }
            let eqGroup = whatEvaluation === "original" ? "equalentGroup" : whatEvaluation === "edited" ? "editedEqualentGroup" : "extEqualentGroup"

            // Only show equivalentGroups where there have been any parental leave
            let equivalentGroups = []

            const equalentGroupSet = new Set();    // Use a Set to store unique equalentGroup numbers

            // Group by equalentGroup, only show the groups if anyone has been on parental leave.
            data.forEach((item) => {
                const group = item[eqGroup];
                const leave = item.parentalLeave;
                // Check if the equalentGroup has already been processed
                if (!equalentGroupSet.has(group)) {
                    // Check if there's at least one non-null parentalLeave in this group
                    const hasNonNullLeave = data.some(
                        (el) => el[eqGroup] === group && el.parentalLeave !== null
                    );
                    // If there's at least one non-null parentalLeave, add the group to the set
                    if (hasNonNullLeave) {
                        equalentGroupSet.add(group);
                    }
                }
            });
            // Convert the set to an array
            equivalentGroups = Array.from(equalentGroupSet);
            this.setState({
                allEmps: data,
                equivalentGroups: equivalentGroups,
                comments: comments,
                commentsPast: commentsPast,
                whatEvaluation: whatEvaluation,
            }, () => {
                if (equivalentGroups.length > 0) { // Automatically select the first group if any
                    this.clickGroup("noEvent", equivalentGroups[0].toString())
                }
            });

        } catch (error) {
            // console.error('Error fetching data:', error);
            alert(language === "sv" ? "Ett fel inträffade, vänligen ladda om sidan och försök igen." : lang[language].errorOccuredTryAgain)
        }
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';

        let comments = language === "sv" ? "Inga kommentarer för senaste året." : lang[language].noCommentsFromThisYear

        if (this.state.comments.length > 0) {
            comments = this.state.comments.map((comment) => {
                if (this.state.filter === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding">
                            <span class="equalGroupUtvardera">Grupp {comment.groupE}: Analys {comment.parentalComment}</span>
                            <TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>
                        <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /></td>
                    </tr>
                } else if (comment.groupE === this.state.filter) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding">
                            <span class="equalGroupUtvardera">Grupp {comment.groupE}: Analys {comment.parentalComment}</span>
                            <TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
                        <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>
                        <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /></td>
                    </tr>
                }
            });
        }

        let commentsPast = ""
        if (this.state.commentsPast.length > 0) { // FILTER PAST YEARS COMMENTS
            commentsPast = this.state.commentsPast.map((comment) => {
                if (this.state.filter === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding">
                            <span class="equalGroupUtvardera">Grupp {comment.groupE}: Analys {comment.parentalComment}</span>
                            <TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly /></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        {/* <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>*/}
                        <td className="thpadding remove"></td>
                    </tr>
                } else if (comment.groupE === this.state.filter) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding">
                            <span class="equalGroupUtvardera">Grupp {comment.groupE}: Analys {comment.parentalComment}</span>
                            <TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly /></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        {/* <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>*/}
                        <td className="thpadding remove"></td>
                    </tr>
                }
            });
        }

        return (
            <>
                <CustomPrompt
          when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
          message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
        />
                <TopNav />
                <Menu />
                <div className="container">
                    <Information step="foraldraledighet" />
                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>
                    <div className={this.state.equivalentGroups.length < 1 ? "noNeedPopUp" : "hide"}>
                        <div className="importOK">&#10004;</div>
                        <div className="noNeedPopUpText">Analys behöver ej genomföras. Inga medarbetare har varit föräldralediga.</div>
                    </div>
                    <div className={this.state.equivalentGroups.length < 1 ? "hide" : "leftAndRightContainer"}>

                        <div className="leftList">
                            <h3 style={{ marginTop: 6, paddingBottom: 3, fontWeight: 800, paddingTop: 10, fontSize: 18.6 }}>Likvärdiga arbeten</h3>
                            <div style={{ fontSize: 13, marginTop: -10, marginBottom: 10, color: "grey" }}>Visar grupper där föräldraledighet förekommit</div>
                            <ul style={{ overflowY: 'auto', height: 550, fontSize: 15.6, letterSpacing: -0.2 }}>
                                {this.state.equivalentGroups.map(group =>
                                    <li className={this.state.activeGroup === `${group}` ? "list2 list2-active" : "list2"} key={group} id={group} onClick={this.clickGroup}>Grupp {group}</li>
                                )}
                            </ul>
                        </div>

                        <div className="rightContainer" style={{ width: 630 }}>
                            <div style={{ height: 40 }}>
                                <div style={{ float: "right", float: "right", paddingTop: 5, fontSize: 14, marginRight: 20 }}>
                                    <div className={this.state.showCurrency ? "comment-select-left comment-select-this" : "comment-select-left"} onClick={this.showCurrency}>Kronor</div>
                                    <div className={this.state.showCurrency ? "comment-select-right" : "comment-select-right comment-select-this"} onClick={this.showPercent}>Procent</div>
                                </div>
                            </div>

                            <div className={this.state.whatComment == 1 ? "parentalContainer" : "parentalContainer parentalContainerGrey"}>
                                <div onClick={this.selectAnalysisOne}>
                                    <span className={this.state.whatComment == 1 ? "parentalStepNr" : "parentalStepNr parentalStepNrGrey"}>1</span>  <div className="parentalHeader" style={{ borderBottom: this.state.whatComment == 1 ? "1px solid white" : "1px solid #e2e2e2" }}>Föräldralediga - Ej föräldralediga</div>
                                    {/* <div>Analysera Föräldralediga och ej föräldralediga</div> */}

                                    <div className="parentalRow">
                                        <div className="parentalCellBig"></div>
                                        <div className={this.state.whatComment == 1 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Antal</div>
                                        <div className={this.state.whatComment == 1 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"} style={{ cursor: "pointer" }} onClick={this.togglePrevOrThisSalary}>{this.state.togglePrevOrThisSalary === "prev" ? "Tid. lön" : "Ny lön"}</div>
                                        <div className={this.state.whatComment == 1 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Löneökning</div>
                                    </div>
                                    <div className="parentalRow">
                                        <div className="parentalCellBig">Föräldralediga</div>
                                        <div className="parentalCell">{this.state.onParentalLeave.length}</div>
                                        <div className="parentalCell">{this.state.togglePrevOrThisSalary === "prev" ? this.state.onParentalLeaveAvgSalPastYear.toLocaleString() : this.state.onParentalLeaveAvgSalThisYear.toLocaleString()}</div>
                                        <div className="parentalCell">{this.state.showCurrency ? this.state.onParentalLeaveAvgRaise.toLocaleString() : this.state.onParentalLeaveAvgRaisePercent + "%"}</div>
                                    </div>
                                    <div className="parentalRow">
                                        <div className="parentalCellBig">Ej föräldralediga</div>
                                        <div className="parentalCell">{this.state.notOnParentalLeave.length}</div>
                                        <div className="parentalCell">{this.state.togglePrevOrThisSalary === "prev" ? this.state.notOnParentalLeaveAvgSalPastYear.toLocaleString() : this.state.notOnParentalLeaveAvgSalThisYear.toLocaleString()}</div>
                                        <div className="parentalCell">{this.state.showCurrency ? this.state.notOnParentalLeaveAvgRaise.toLocaleString() : this.state.notOnParentalLeaveAvgRaisePercent + "%"}</div>
                                    </div>
                                    <div className="parentalRow" style={{ fontWeight: 800, marginTop: 10, paddingTop: 5, borderTop: this.state.whatComment == 1 ? "1px solid white" : "1px solid #e2e2e2", color: this.state.whatComment == 1 ? "white" : "#333333" }}>

                                        <div className="parentalCellBig">Differens</div>  
                                        <div className="parentalCell"></div>
                                        <div className="parentalCell"></div>
                                        <div className="parentalCell">{this.state.showCurrency ? (this.state.onParentalLeaveAvgRaise - this.state.notOnParentalLeaveAvgRaise).toLocaleString() : this.state.onParentalLeaveAvgRaisePercent - this.state.notOnParentalLeaveAvgRaisePercent + "%"}</div> 
                                    </div>
                                </div>
                                <div className={this.state.analysisOneNeeded ? "parentalAnalyzeBox parentalAnalyze" : "parentalAnalyzeBox parentalOK"}>
                                    <div onClick={this.openAnalyzeOne}><div className="parentalAnalyzeTitle" style={{ color: this.state.analysisOneNeeded ? "#5c6050" : "#26705f" }}><img src={this.state.analysisOneNeeded ? yellowAlert : greenCheck} width={20} style={{ verticalAlign: "top", marginRight: 5, marginTop: -1 }} />{this.state.analysisOneNeeded ? "Analysera" : "Ser bra ut"} </div> <img src={down} width={10} style={{ float: "right", marginTop: 4, marginRight: 4 }} /></div>
                                    <div className={this.state.openAnalyzeOne ? "parentalAnalyzeInfoPop" : "hide"}>
                                        {this.state.analysisOneText}
                                    </div>
                                </div>
                            </div>
                            <div className={this.state.whatComment == 2 ? "parentalContainer" : "parentalContainer parentalContainerGrey"}>
                                <div onClick={this.selectAnalysisTwo}>
                                    <span className={this.state.whatComment == 2 ? "parentalStepNr" : "parentalStepNr parentalStepNrGrey"}>2</span>  <div className="parentalHeader" style={{ borderBottom: this.state.whatComment == 2 ? "1px solid white" : "1px solid #e2e2e2" }}>Män - Kvinnor</div>
                                    {/*   <div>Analysera Föräldralediga kvinnor & män</div>*/}
                                    <div className="parentalRow">
                                        <div className={this.state.whatComment == 2 ? "parentalCellBig parentalHeaderWhite" : "parentalCellBig parentalHeaderGray"}>Föräldralediga</div>
                                        <div className={this.state.whatComment == 2 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Antal</div>
                                        <div className={this.state.whatComment == 2 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"} style={{ cursor: "pointer" }} onClick={this.togglePrevOrThisSalary}>{this.state.togglePrevOrThisSalary === "prev" ? "Tid. lön" : "Ny lön"}</div>
                                        <div className={this.state.whatComment == 2 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Löneökning</div>
                                    </div>
                                    <div className="parentalRow">
                                        <div className="parentalCellBig">Män</div>

                                        <div className="parentalCell">{this.state.onParentalLeave.filter((emp) => emp.gender == "man").length}</div>
                                        <div className="parentalCell">{this.state.togglePrevOrThisSalary === "prev" ? this.state.menAvgSalPastYear.toLocaleString() : this.state.menAvgSalThisYear.toLocaleString()}</div>
                                        <div className="parentalCell">{this.state.showCurrency ? this.state.menOnParentalRaise.toLocaleString() : this.state.menOnParentalRaisePercent + "%"}</div>
                                    </div>
                                    <div className="parentalRow">
                                        <div className="parentalCellBig">Kvinnor</div>
                                        <div className="parentalCell">{this.state.onParentalLeave.filter((emp) => emp.gender == "woman").length}</div>
                                        <div className="parentalCell">{this.state.togglePrevOrThisSalary === "prev" ? this.state.womenAvgSalPastYear.toLocaleString() : this.state.womenAvgSalThisYear.toLocaleString()}</div>
                                        <div className="parentalCell">{this.state.showCurrency ? this.state.womenOnParentalRaise.toLocaleString() : this.state.womenOnParentalRaisePercent + "%"}</div>
                                    </div>
                                    <div className="parentalRow" style={{ fontWeight: 800, marginTop: 10, paddingTop: 5, borderTop: this.state.whatComment == 2 ? "1px solid white" : "1px solid #e2e2e2", color: this.state.whatComment == 2 ? "white" : "#333333" }}>
                                        <div className="parentalCellBig">Differens</div>
                                        <div className="parentalCell"></div>
                                        <div className="parentalCell"></div>
                                        <div className="parentalCell">{this.state.showCurrency ? (this.state.womenOnParentalRaise - this.state.menOnParentalRaise).toLocaleString() : this.state.womenOnParentalRaisePercent - this.state.menOnParentalRaisePercent + "%"}</div>
                                    </div>
                                </div>
                                {/*<div className="parentalShowBtn">Visa individer</div>*/}
                                <div className={this.state.analysisTwoNeeded ? "parentalAnalyzeBox parentalAnalyze" : "parentalAnalyzeBox parentalOK"}>
                                    <div onClick={this.openAnalyzeTwo} ><div style={{ fontWeight: "bold", display: "inline-block", color: this.state.analysisTwoNeeded ? "#5c6050" : "#26705f" }}><img src={this.state.analysisTwoNeeded ? yellowAlert : greenCheck} width={20} style={{ verticalAlign: "top", marginRight: 5, marginTop: -1 }} />{this.state.analysisTwoNeeded ? "Analysera" : "Ser bra ut"}</div><img src={down} width={10} style={{ float: "right", marginTop: 4, marginRight: 4 }} /></div>

                                    <div className={this.state.openAnalyzeTwo ? "parentalAnalyzeInfoPop" : "hide"}>
                                        {this.state.analysisTwoText}</div>
                                </div>
                            </div>

                            <div className={this.state.individualBoxOpen ? "parentalShowBtn parentalShowBtnOpen" : "parentalShowBtn"} onClick={this.toggleIndividualBox}>Visa individer</div>
                            <div className={this.state.individualBoxOpen ? "parentalContainerForList" : "hide"}>
                                <div className="parentalSecondCont">
                                    <div className="parentalWhatTitle" onClick={this.toggleParentalLeave}>Föräldralediga <img src={down} width={10} style={{ marginLeft: 5, transform: this.state.hideParentalLeave ? "rotate(180deg)" : "rotate(0deg)" }} /></div>
                                    <div style={{ display: this.state.hideParentalLeave ? "none" : "block" }} >
                                        <div className="parentalListDiv" style={{ fontStyle: "italic", color: "grey" }}>
                                            <div className="parentalCellList parentalCellListBig">Namn</div>
                                            <div className="parentalCellList parentalCellListBig">Arbete</div>
                                            <div className="parentalCellList">FL dagar</div>
                                            <div className="parentalCellList">Tid. lön</div>
                                            <div className="parentalCellList">Ny lön</div>
                                            <div className="parentalCellList">Höjning</div>
                                        </div>
                                        <div className="parentalGenderContainer">
                                            <div className="parentalGenderTitle">Män</div>
                                            {this.state.onParentalLeave.filter((emp) => emp.gender == "man").map(emp =>
                                                <div className="parentalListContainer">
                                                    <div className="parentalCellList parentalCellListBig">{emp.fullName}</div>
                                                    <div className="parentalCellList parentalCellListBig">{emp.groupNameArbVardering}</div>
                                                    <div className="parentalCellList">{/^\d+$/.test(emp.parentalLeave) ? emp.parentalLeave : "Okänt"}</div>
                                                    <div className="parentalCellList">{emp.pastSalary}</div>
                                                    <div className="parentalCellList">{emp.salary}</div>
                                                    <div className="parentalCellList">{this.state.showCurrency ? emp.salaryRaise.toLocaleString() : emp.salaryRaisePercent + "%"}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="parentalGenderContainer parentalGenderContainerWomen">
                                            <div className="parentalGenderTitle parentalGenderTitleWomen">Kvinnor</div>
                                            {this.state.onParentalLeave.filter((emp) => emp.gender == "woman").map(emp =>
                                                <div className="parentalListContainer">
                                                    <div className="parentalCellList parentalCellListBig">{emp.fullName}</div>
                                                    <div className="parentalCellList parentalCellListBig">{emp.groupNameArbVardering}</div>
                                                    <div className="parentalCellList">{/^\d+$/.test(emp.parentalLeave) ? emp.parentalLeave : "Okänt"}</div>
                                                    <div className="parentalCellList">{emp.pastSalary}</div>
                                                    <div className="parentalCellList">{emp.salary}</div>
                                                    <div className="parentalCellList">{this.state.showCurrency ? emp.salaryRaise.toLocaleString() : emp.salaryRaisePercent + "%"}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderTop: "1px solid lightgrey" }} className="parentalSecondCont">
                                    <div className="parentalWhatTitle" onClick={this.toggleNotParentalLeave}>Ej Föräldralediga <img src={down} width={10} style={{ marginLeft: 5, transform: this.state.hideNotParentalLeave ? "rotate(180deg)" : "rotate(0deg)" }} /> </div>
                                    <div style={{ display: this.state.hideNotParentalLeave ? "none" : "block" }} >
                                        <div className="parentalListDiv" style={{ fontStyle: "italic", color: "grey" }}>
                                            <div className="parentalCellList parentalCellListBig">Namn</div>
                                            <div className="parentalCellList parentalCellListBig">Arbete</div>
                                            <div className="parentalCellList"></div>
                                            <div className="parentalCellList">Tid. lön</div>
                                            <div className="parentalCellList">Ny lön</div>
                                            <div className="parentalCellList">Höjning</div>
                                        </div>
                                        {this.state.notOnParentalLeave.map(emp =>
                                            <div className="parentalListContainer">
                                                <div className="parentalCellList parentalCellListBig">{emp.fullName}</div>
                                                <div className="parentalCellList parentalCellListBig">{emp.groupNameArbVardering}</div>
                                                <div className="parentalCellList"></div>
                                                <div className="parentalCellList">{emp.pastSalary}</div>
                                                <div className="parentalCellList">{emp.salary}</div>
                                                <div className="parentalCellList">{this.state.showCurrency ? emp.salaryRaise.toLocaleString() : emp.salaryRaisePercent + "%"}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>


                            {/* Comments*/}
                            <div className="commentContainer likvCommentCont" style={{ width: "calc(100% - 40px)", marginBottom: 20 }} >
                                <h3 style={{ marginTop: 0, fontWeight: 800 }}>{language === "sv" ? "Anteckna" : lang[language].note}</h3>
                                <div style={{ marginBottom: 15 }}>
                                    <div className={this.state.whatComment == 1 ? "comment-select-left comment-select-this" : "comment-select-left"} onClick={this.selectAnalysisOne}>{language === "sv" ? "Analys 1" : lang[language].equivalent}</div>
                                    <div className={this.state.whatComment == 2 ? "comment-select-right comment-select-this" : "comment-select-right"} onClick={this.selectAnalysisTwo}>{language === "sv" ? "Analys 2" : lang[language].hierarchical}</div>
                                </div>
                                {/*    <textarea id="curSelComment" className="comment" placeholder={language === "sv" ? "Noterbart" : lang[language].notable} style={{ minHeight: 48 }} onBlur={this.handleComment} defaultValue={this.state.notableComment} ref={el => this.curSelComment = el} />
                                <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onBlur={this.handleReason} defaultValue={this.state.addReason} ref={el => this.curSelReason = el} /> */}
                                <div style={{ width: 283, margin: "auto" }}>
                                    <div onMouseDown={this.autoFillClick} className={this.state.showAutofill ? "parentalAutofill parentalAutofillGreen" : "parentalAutofill"}><span className={this.state.showAutofill ? "" : "hide"}><img style={{ width: 15, verticalAlign: "top", display: "inline-block" }} src={autoFill} /></span> Autofyll</div>
                                    <textarea id="curSelComment" className="comment" placeholder={language === "sv" ? "Noterbart" : lang[language].notable} style={{ minHeight: 48 }} onChange={this.handleCommentNotable} value={this.state.notableComment} onClick={this.showAutofill} onBlur={this.hideAutofill} />
                                </div>
                                <div className="fieldsB" onClick={this.toggleFields}>{language === "sv" ? "Fler fält " : lang[language].moreFields}<img src={arrowDown} style={{ width: 9, marginLeft: 5 }} className={this.state.moreFields ? "arrowUp" : ""} /> </div>
                                <div className={this.state.moreFields ? "" : "hideFields"} style={{ width: 630, margin: "auto" }}>
                                    {/* <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onChange={this.handleReason} value={this.state.addReason} />*/}
                                    <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onBlur={this.handleReason} defaultValue={this.state.addReason} ref={el => this.curSelReason = el} />
                                    <textarea id="curSelTodo" className="comment todo" placeholder={language === "sv" ? "Åtgärd" : lang[language].action} style={{ minHeight: 48 }} onBlur={this.handleTodo} defaultValue={this.state.addTodo} ref={el => this.curSelTodo = el} />
                                    <input style={{ background: "white", textAlign: "center", }} type="date" id="deadline" name="deadline" className="dateBox" onChange={this.handleDeadline} value={this.state.addDeadline} />
                                    <input id="curSelCost" type="text" className="comment cost" placeholder={language === "sv" ? "Kostnad" : lang[language].cost} onBlur={this.handleCost} defaultValue={this.state.addCost} ref={el => this.curSelCost = el} />
                                    <input id="curSelResp" type="text" className="comment responsible" placeholder={language === "sv" ? "Ansvarig" : lang[language].responsible} onBlur={this.handleResponsible} defaultValue={this.state.addResponsible} ref={el => this.curSelResp = el} />
                                    <span className="dateBoxTitle">Deadline</span>
                                </div>
                                <div
                                    className="commentButton"
                                    style={{ width: 120, display: "block", margin: "auto", marginTop: 20, fontSize: 15.6 }}
                                    onClick={this.saveNewComment}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            </div>



                        </div>
                    </div>

                    {/* Comments list*/}
                    <div className={this.state.equivalentGroups.length < 1 ? "hide" : "allCommentsContainer"}>
                        <div>
                            <h3 style={{ paddingTop: 30, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>{language === "sv" ? "Noteringar" : lang[language].notes}</h3>
                            <div className={this.state.filter === "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterAll}>{language === "sv" ? "Alla" : lang[language].all}</div>
                            <div className={this.state.filter !== "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterEquivalent}>{language === "sv" ? "Vald grupp" : lang[language].selectedGroup}</div>
                        </div>

                        <table className="commentsTable">
                            <tr className="trStyleLine">
                                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="thpadding">Deadline</th>
                                <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                                <th className="thpadding remove" style={{ width: 30 }}></th>
                            </tr>
                            {comments}
                        </table>

                        <div className={this.state.commentsPast.length < 1 ? "hide" : ""}>
                            <div style={{ marginTop: 80, fontSize: 16, fontWeight: 900, marginBottom: 20, cursor: "pointer" }}><span onClick={this.toggleOldComments}>{language === "sv" ? "Föregående års kommentarer" : lang[language].commentPast}</span>
                                <img alt="" src={this.state.showOldComments ? down : up} style={{ width: 12, marginLeft: 5, cursor: "pointer" }} onClick={this.toggleOldComments} />
                            </div>

                            <div className={this.state.showOldComments ? "" : "hide"}>
                                {/*}    <select name="selectionCommentYear" id="selectionCommentYear" onChange={this.changeYearComments} value={this.state.year}>
                                    <option value={this.state.selectedYear - 1}>{this.state.selectedYear - 1}</option>
                                    <option value={this.state.selectedYear - 2}>{this.state.selectedYear - 2}</option>
                                    <option value={this.state.selectedYear - 3}>{this.state.selectedYear - 3}</option>
                                    <option value={this.state.selectedYear - 4}>{this.state.selectedYear - 4}</option>
                                </select>
                                <span class="lastYearButton" style={{ padding: 7, paddingLeft: 10, paddingRight: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Återanvänd alla" : lang[language].reuseAll}</span>
                               */}
                                <div style={{ top: 500, width: 450 }} className={this.state.popUpComments ? "popUp active-popUp" : "hide"}>
                                    <img alt="" src={exitImg} class="hide-box" onClick={this.toggleCommentPop} />
                                    <span style={{ marginTop: 60, display: "block", fontWeight: 600, display: "block", paddingLeft: 40, paddingRight: 40, fontWeight: "normal", lineHeight: 1.4 }}>{language === "sv" ? `Vill du återanvända alla kommentarer från ${this.state.year} för alla grupper?` : lang[language].reuseAllPop}</span>
                                    <div style={{ marginTop: 40 }}>
                                        <span class="lastYearButton" style={{ background: "#c99555", marginRight: 10, padding: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                                        <span class="lastYearButton" style={{ marginLeft: 10, padding: 10 }} onClick={this.reuseAll}>{language === "sv" ? "Ja, fortsätt" : lang[language].continue}</span>
                                    </div>
                                </div>
                                <div className={this.state.popUpComments ? "popUpFader" : "hide"}></div>
                                <table className="commentsTable lastyearList">
                                    {commentsPast}
                                </table>
                            </div>
                        </div>
                    </div>



                </div>
            </>
        )
    }
}