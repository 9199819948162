import React from 'react';
import './TopNav.css';
import arrowDown from '../assets/images/arrow-down.png';
import arrowUp from '../assets/images/arrow-up.png';
import logOut from '../assets/images/logOut.png';
import settings from '../assets/images/Settings.png';
import { fetchAuth } from '../fetch-auth';
import logoGreen from '../assets/images/logo-lonelys.png';
import { NavLink } from 'react-router-dom';
import account from '../assets/images/account.png';
import Switch from "react-switch";
import { jwtDecode } from 'jwt-decode';
import exitImg from '../assets/images/exit.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import searchIcon from '../assets/images/searchIcon.png';
import lang from './Language/language.js';
import { SalaryRangeBar } from "./General/SalaryRangeBar";
import ReactToPrint from "react-to-print";

export class TopNav extends React.Component {
    constructor() {
        super();
        this.componentRefEmployee = React.createRef();
        this.state = {
            showDropDown: false,
            settings: false,
            account: false,
            customer: false,
            hideName: false,
            hideSalary: false,
            popUp: false,
            settingsChanged: false,
            r: "",
            popDeleteContent: false,
            aComp: "",
            year: undefined,
            showArc: "off",
            //For search box
            showWhat: "original",
            showSearchBox: false,
            individuals: [],//[{ fullName: "Martin Svensson", positionTitle: "Montör", personID: 199001012211, age: 27 }, { fullName: "Martinius Joelisson-Eriksson", positionTitle: "Senior Frontend lead developer", personID: 199001012211, age: 35 }],
            whatEvaluation: null,
            nrOfEqualentGroups: 6,
            filteredIndividuals: [],
            searchString: "",
            employeeSelected: false,
            infoPopPastYear: false,
            totalAvgSal: "Arbetsvärdering saknas",
            menAvgSal: "-",
            womenAvgSal: "-",
            rangeBarWhat: "salaryRanges", // salaryRanges or spread, used for the salaryRangesBar (right now we are only using salaryRanges so this wont change)
            popWhatShare: false,
            checkBoxAverage: true,
            checkBoxInfo: true,
            checkBoxRange: false,
            checkBoxSpread: false,
            showSpread: "no", // Set to yes if we should be able to show this, but hide it now
            whatSalaryRange: "None", // This will be what type of salary range that is used, for example it can be the equal work, the level, the family, family and subFamily etc. Default is "None"
            salaryRanges: [], // This will hold the loaded salary ranges. Its empty if there is none created or if this.state.showSalaryRanges is not set to "yes"
            families: [], // The information about families
            salaryRangeSelected: "", // This will be an object with the correct salary range for the employee
            equalAvgSalary: 0, // If we will calculate averages for just equal, this is here as well
            equalAvgSalaryMen: 0,
            equalAvgSalaryWomen: 0,
            showSettingsEmployee: false,
            showSalaryRanges: "no", // This is temporary used during development, change to "no" for production and "yes" or remove it when its ready to release. "Yes" is with salary ranges, "No" is without salary ranges and only averages
            workCategory: "togheter", // "divided" or "togheter". Togheter means that there is only the equivalent group that is the "arbetstagarkategori", divided means that they will be separated. For the averages only. Use togheter but just change if need to use the other
            totalSalary: "yes" // If we should use total salary ("yes") or salary excluding benefits and addons ("no")
        }
    }

    handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (this.state.checkBoxRange === false && name === "checkBoxRange" && this.state.salaryRangeSelected === "") { alert("Det finns inget lönespann skapat. Detta kan skapas i jobbarkitekturen."); return }
        this.setState({ [name]: checked });
    };

    showDropDown = () => {
        this.state.showDropDown ? this.setState({
            showDropDown: false,
            settings: false,
            account: false,
            customer: false,
            popUp: false
        }) : this.setState({ showDropDown: true })

    }

    logout = () => {
        const fetchIt = async () => {
            let response = await fetchAuth(`/api/r/logout`, 'DELETE');
            const keepName = localStorage.getItem('settingName');
            const keepPercent = localStorage.getItem('settingPercent');
            const keepwarningPrompt = localStorage.getItem('warningPrompt');
            const keepHideSalary = localStorage.getItem('docoHideSalary');
            const keepLanguage = localStorage.getItem('language');
            const keepClickedYear = localStorage.getItem('clickedYear')
            // Save if below is true
            let keepYear = null
            let keepCurrentYearSelected = null
            let keepDateForYearSelected = null
            // *** Fix for popUp and year selection (if should be saved or not) ***
            //If there has been more than 90 days since a previous year was selected, then select current year instead.
            let dateForClickedYear = localStorage.getItem("dateForYearSelected")
            if (dateForClickedYear !== null) {
                const savedDate = new Date(dateForClickedYear);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - savedDate.getTime();
                const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

                if (daysDifference < 90) {
                    keepYear = localStorage.getItem('year')
                    keepCurrentYearSelected = localStorage.getItem('currentYearSelected')
                    keepDateForYearSelected = localStorage.getItem('dateForYearSelected')
                }
            }
            localStorage.clear(); // Clear
            localStorage.setItem('settingName', keepName);
            localStorage.setItem('settingPercent', keepPercent);
            localStorage.setItem('warningPrompt', keepwarningPrompt);
            localStorage.setItem('docoHideSalary', keepHideSalary);
            if (keepLanguage !== null) { localStorage.setItem('language', keepLanguage); }
            if (keepClickedYear !== null) { localStorage.setItem('clickedYear', keepClickedYear); }
            if (keepYear !== null) { localStorage.setItem('year', keepYear); }
            if (keepCurrentYearSelected !== null) { localStorage.setItem('currentYearSelected', keepCurrentYearSelected); }
            if (keepDateForYearSelected !== null) { localStorage.setItem('dateForYearSelected', keepDateForYearSelected); }
            window.location.href = '/login';
            return;
        }
        fetchIt();
    }

    toggleAccount = () => this.state.account && this.state.popUp ?
        this.setState({
            account: false,
            settings: false,
            customer: false,
            popUp: false
        }) :
        this.setState({
            account: true,
            popUp: true,
            settings: false,
            customer: false
        })

    toggleSettings = () => this.state.settings && this.state.popUp ?
        this.setState({
            account: false,
            settings: false,
            customer: false,
            popUp: false
        }) :
        this.setState({
            account: false,
            popUp: true,
            settings: true,
            customer: false
        })

    switchChangeName = () => this.state.hideName ? this.setState({ hideName: false, settingsChanged: true }) : this.setState({ hideName: true, settingsChanged: true })
    switchChangeSalary = () => this.state.hideSalary ? this.setState({ hideSalary: false, settingsChanged: true }) : this.setState({ hideSalary: true, settingsChanged: true })

    saveSettings = () => {
        let settingName = this.state.hideName
        let settingPercent = this.state.hideSalary

        localStorage.setItem("settingName", settingName);
        localStorage.setItem("settingPercent", settingPercent);
        window.location.reload();
    }

    changeYear = (event) => {
        let year = event.target.value
        localStorage.setItem("year", year);
        //Fix so that we dont calculate wrong year on import if the year switches but last year (which was current) is still saved in local storage
        const thisYear = new Date().getFullYear()
        const today = new Date()
        if (thisYear == year) {
            localStorage.setItem("currentYearSelected", "Yes")
            localStorage.removeItem('dateForYearSelected'); // remove the date for when we set the selected year
        } else {
            localStorage.setItem("currentYearSelected", "No")
            localStorage.setItem("dateForYearSelected", today.toISOString()) // Set when we selected the year. After 3 months it will go back to current year.
        }
        window.location.reload();
    }

    PopdeleteContent = () => {
        if (this.state.popDeleteContent) {
            this.setState({ popDeleteContent: false })
        } else {
            this.setState({ popDeleteContent: true })
        }
    }

    deleteExcel = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        const whatDelete = event.target.id
        if (this.state.r === "Bolag") {
            //Fetch for bolag here 
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelBolag`, 'POST');
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else if (this.state.r === "Koncern") {
            //Fetch for Koncern here, also send "whatDelete to backend"
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelKoncern`, 'POST', JSON.stringify({ data: whatDelete }));
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else if (this.state.r === "Konsult") {
            //Fetch for Konsult here, also send "whatDelete to backend"
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelKonsult`, 'POST', JSON.stringify({ data: whatDelete })); //<-- EXISTERAR ÄNNU INTE!
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else if (this.state.r === "Admin") {
            //Fetch for Admin here, also send "whatDelete to backend"
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelAdmin`, 'POST', JSON.stringify({ data: whatDelete })); //<-- EXISTERAR ÄNNU INTE!
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else {
            alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
        }
    }
    showSearchBox = () => {
        if (this.state.showSearchBox) {
            this.setState({ showSearchBox: false, filteredIndividuals: [], searchString: "", employeeSelected: false, showSettingsEmployee: false })
        } else {
            // Load employee data if not already loaded
            let getSalaryRanges = this.state.showSalaryRanges // "no" or "yes", if "no" then we dont need to load it 

            if (this.state.individuals.length < 1) {
                const runIt = async () => {
                    let response = await fetchAuth(`/api/loadSearchData`, 'POST', JSON.stringify({ data: getSalaryRanges }));
                    let data = await response.json();
                    // console.log(data)
                    let individuals = data[1]
                    let whatEvaluation = data[0][0].whatEvaluation
                    //  let nrOfEqualentGroups = data[0][1] == null ? 6 : data[0][1]
                    let nrOfEqualentGroups = data[0][0].nrOfEqualGroups == undefined || data[0][0].nrOfEqualGroups == null ? 6 : data[0][0].nrOfEqualGroups
                    if (whatEvaluation === "extLevel") {
                        nrOfEqualentGroups = Math.max(...individuals.map(obj => obj.extEqualentGroup));
                    }
                    // Get the salary ranges
                    let salaryRanges = data[2]
                    if (salaryRanges.length !== 0) { salaryRanges = data[2][0] }
                    let families = data[3]
                    if (families.length !== 0) { families = data[3][0] }

                    this.setState({ whatEvaluation: whatEvaluation, nrOfEqualentGroups: nrOfEqualentGroups, individuals: individuals, salaryRanges: salaryRanges, families: families })
                }
                runIt();
            }
            this.setState({ showSearchBox: true })
        }
    }
    searchChange = (event) => {
        let searchString = event.target.value
        if (searchString.length > 2) {
            let filteredList = this.state.individuals.filter((employee) =>
                employee.fullName.toLowerCase().includes(searchString.toLowerCase())
            );
            this.setState({ searchString: searchString, filteredIndividuals: filteredList })
        } else { this.setState({ searchString: searchString, filteredIndividuals: [] }) }
    }
    showSettingsEmployee = () => { this.state.showSettingsEmployee ? this.setState({ showSettingsEmployee: false }) : this.setState({ showSettingsEmployee: true }) }
    switchChangeSeparation = () => {
        if (this.state.workCategory === "togheter") {
            this.setState(
                { workCategory: "divided" }, // Update the state
                () => { this.selectEmployee(this.state.employeeSelected, "manualRun") }); // Rerun function to calculate data for equal work as well
        } else { this.setState({ workCategory: "togheter" }) } // No need to rerun function and recalculate because we already have the calculation for the equivalent group
    }

    switchSalaryInclAddon = () => {
        if (this.state.totalSalary === "yes") {
            this.setState(
                { totalSalary: "no" }, // Update the state
                () => { this.selectEmployee(this.state.employeeSelected, "manualRun") }); // Rerun function to calculate data for excluding salary
        } else {
            this.setState(
                { totalSalary: "yes" }, // Update the state
                () => { this.selectEmployee(this.state.employeeSelected, "manualRun") }); // Rerun function to calculate data for including salary
        }
    }
    switchSalaryRange = () => {
        if (this.state.showSalaryRanges === "yes") {
            this.setState(
                { showSalaryRanges: "no", checkBoxRange: false }, // Update the state
                () => { this.selectEmployee(this.state.employeeSelected, "manualRun") }); // Rerun function to calculate data for excluding salary
        } else {
            this.setState(
                { showSalaryRanges: "yes" }, // Update the state
                () => { this.selectEmployee(this.state.employeeSelected, "manualRun") }); // Rerun function to calculate data for including salary
        }
    }
    selectEmployee = (event, manualRun) => {
        //  const personId = event.currentTarget.id // <-- Filter by personal id later
        if (this.state.employeeSelected && manualRun === undefined) {
            this.setState({ employeeSelected: false })
        } else {
            let salary = this.state.totalSalary === "yes" ? "totalSalary" : "salary" // If we should use salary including or excluding addOns
            let person = ""
            if (event?.target) {
                // Then show popUp
                // let person = this.state.filteredIndividuals.filter((item) => item.personID == personId) // <-- Filter by personal id later
                const name = event.currentTarget.dataset.name
                const age = event.currentTarget.dataset.age
                const exp = event.currentTarget.dataset.exp
                const title = event.currentTarget.dataset.title
                person = this.state.filteredIndividuals.filter((item) => item.fullName === name && item.age == age && item.experience == exp && item.positionTitle === title)
            } else {
                person = event
            }
            //console.log(this.state.filteredIndividuals)
            //  console.log(person[0])

            // Calculate average salaries for the equivalent group
            let evaluation = this.state.whatEvaluation === "edited" ? "editedEqualentGroup" : this.state.whatEvaluation === "extLevel" ? "extEqualentGroup" : "equalentGroup"
            let evaluationLevel = person[0][evaluation]
            let menAvgSal = "-"
            let womenAvgSal = "-"
            let totalAvgSal = "Arbetsvärdering saknas"
            if (evaluationLevel !== null && evaluationLevel !== undefined) {
                const groupItems = this.state.individuals.filter(item => item[evaluation] == evaluationLevel);
                let totalSalaryAll = 0;
                let totalCountAll = 0;
                let totalSalaryMen = 0;
                let totalCountMen = 0;
                let totalSalaryWomen = 0;
                let totalCountWomen = 0;
                groupItems.forEach(item => {
                    totalSalaryAll += item[salary];
                    totalCountAll += 1;
                    if (item.gender === "man") {
                        totalSalaryMen += item[salary];
                        totalCountMen += 1;
                    } else if (item.gender === "woman") {
                        totalSalaryWomen += item[salary];
                        totalCountWomen += 1;
                    }
                });
                totalAvgSal = Math.round((totalSalaryAll / totalCountAll || 0));
                menAvgSal = Math.round((totalSalaryMen / totalCountMen || 0));
                womenAvgSal = Math.round((totalSalaryWomen / totalCountWomen || 0));
                /*   console.log('Total salary all:', totalAvgSal, 'Total count all:', totalCountAll);
                   console.log('Total salary men:', menAvgSal, 'Total count men:', totalCountMen);
                   console.log('Total salary women:', womenAvgSal, 'Total count women:', totalCountWomen);*/
            }

            // Below is prepared for calculating just equal works averages as well, but it is not in use right now
            let equalAvgSalary = 0
            let equalAvgSalaryMen = 0
            let equalAvgSalaryWomen = 0
            if (this.state.workCategory === "divided") {
                const groupEqualWorks = this.state.individuals.filter(item => item.groupNameArbVardering === person[0].groupNameArbVardering);
                let totalSalary = 0;
                let manSalary = 0;
                let womanSalary = 0;
                let manCount = 0;
                let womanCount = 0;
                groupEqualWorks.forEach(person => {
                    totalSalary += person[salary];
                    if (person.gender === "man") {
                        manSalary += person[salary];
                        manCount++;
                    } else if (person.gender === "woman") {
                        womanSalary += person[salary];
                        womanCount++;
                    }
                });
                equalAvgSalary = Math.round(totalSalary / groupEqualWorks.length)
                equalAvgSalaryMen = manCount > 0 ? Math.round(manSalary / manCount) : 0;
                equalAvgSalaryWomen = womanCount > 0 ? Math.round(womanSalary / womanCount) : 0;
            }

            // End of calculation of equalWork averages

            // Also get the salary range and connect the family for the individual
            let whatSalaryRange = "None" // This is used for generating the correct text
            let salaryRange = "";

            if (this.state.showSalaryRanges === "yes") { // Not running if we do not activate it
                // First, get the families for the individual
                if (this.state.families.length !== 0) { // Families can be not set
                    for (let i = 0; i < this.state.families.length; i++) { // First, get what 
                        if (person[0].groupNameArbVardering === this.state.families[i].groupNameArbVardering_FK) {
                            person[0].family = this.state.families[i].family
                            person[0].mainFunction = this.state.families[i].mainFunction
                            person[0].subFunction = this.state.families[i].subFunction
                            person[0].subFunction2 = this.state.families[i].subFunction2
                            person[0].subFunction3 = this.state.families[i].subFunction3
                            break;
                        }
                    }
                }
                // Then get the salary range, it checks for the priority list in order, so first it looks for individual, then subFunction3, then subFunction 2 etc
                if (this.state.salaryRanges.length !== 0) {
                    const priorityList = [
                        { name: person[0].groupNameArbVardering, familyOrFunction: "individual" },
                        { name: person[0].subFunction3, familyOrFunction: "subFunction3", familyName: person[0].family },
                        { name: person[0].subFunction2, familyOrFunction: "subFunction2", familyName: person[0].family },
                        { name: person[0].subFunction, familyOrFunction: "subFunction", familyName: person[0].family },
                        { name: person[0].mainFunction, familyOrFunction: "mainFunction", familyName: person[0].family },
                        { name: person[0].family, familyOrFunction: "family", familyName: person[0].family },
                        { name: "overall", familyOrFunction: "overall" }
                    ];
                    for (let priority of priorityList) {
                        for (let range of this.state.salaryRanges) {
                            if (
                                range.name === priority.name &&
                                range.familyOrFunction === priority.familyOrFunction &&
                                (priority.familyName === undefined || range.familyName === priority.familyName) &&
                                (priority.familyOrFunction === "individual" || range.level === person[0][evaluation]) // Skip level check for "individual"
                            ) {
                                salaryRange = range;
                                break; // Exit the inner loop when a match is found
                            }
                        }
                        if (salaryRange !== "") break; // Exit the outer loop when a match is found
                    }
                }
                if (salaryRange !== "") { whatSalaryRange = salaryRange.familyOrFunction }
            }

            this.setState({ employeeSelected: person, totalAvgSal: totalAvgSal, menAvgSal: menAvgSal, womenAvgSal: womenAvgSal, salaryRangeSelected: salaryRange, whatSalaryRange: whatSalaryRange, equalAvgSalary: equalAvgSalary, equalAvgSalaryMen: equalAvgSalaryMen, equalAvgSalaryWomen: equalAvgSalaryWomen })
        }
    }

    createMail = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        //const email = "recipient@example.com";
        const subject = language === "sv" ? "Löneinformation" : lang[language].salaryInformation
        //   const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        //    const body = "Hej!\n\nHär är din löneinformation:\nLön för januari: 25 000 kr\n\nMed vänlig hälsning,\nFöretaget";

        let averageChecked = this.state.checkBoxAverage
        let rangeChecked = this.state.checkBoxRange
        let infoChecked = this.state.checkBoxInfo
        let averageEqual = this.state.workCategory

        let avgEqual = averageEqual === "divided" ?
            `
Snittlöner lika arbete:
Män: ${this.state.equalAvgSalaryMen.toLocaleString()}
Kvinnor: ${this.state.equalAvgSalaryWomen.toLocaleString()}
Totalt: ${this.state.equalAvgSalary.toLocaleString()}
`
            : ""
        let avg = averageChecked ?
            `Snittlöner ${averageEqual === "divided" ? "likvärdigt arbete:" : ""}
Män: ${this.state.menAvgSal.toLocaleString()}
Kvinnor: ${this.state.womenAvgSal.toLocaleString()}
Totalt: ${this.state.totalAvgSal.toLocaleString()}`
            : ""

        let range = rangeChecked ?
            `Aktuellt lönespann:
Min: ${this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.min}
Mid: ${this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.mid}
Max: ${this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.max}`
            : ""
        if (rangeChecked && this.state.salaryRangeSelected === "") { range = "" }

        let info = ""

        if (averageEqual === "divided") { // If equal works is divided from equivalent works
            info = rangeChecked && averageChecked && infoChecked ?
                `Ovanstående löner är ${this.state.totalSalary === "yes" ? "inklusive" : "exklusive"} eventuella tillägg. Snittlönerna för lika arbete visar den genomsnittliga lönen för arbeten som utför ungefär samma arbetsuppgifter. Snittlönerna för likvärdiga arbeten inkluderar arbeten som utför likvärdiga arbeten, där likvärdiga inte har samma arbetsuppgifter men har samma kravnivå (ju högre nivå desto högre krav och komplexitet i arbetet).
            
${this.state.salaryRangeSelected === "" ? "" : `Utöver snittlöner ser du även lönespannet. Detta är skapat av oss som arbetsgivare och är alltså inom det spann där vi förväntar oss att lönen ska ligga för ${this.state.whatSalaryRange === "individual" ? "ditt arbete" : this.state.whatSalaryRange === "overall" ? "kravnivån" : this.state.whatSalaryRange === "family" ? `jobbfamiljen ${this.state.salaryRangeSelected.familyName} inom denna kravnivå` : `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" och underkategorin "${this.state.salaryRangeSelected.name}" inom denna kravnivå`}.`}`
                : !rangeChecked && averageChecked && infoChecked ?
                    `Ovanstående löner är ${this.state.totalSalary === "yes" ? "inklusive" : "exklusive"} eventuella tillägg. Snittlönerna för lika arbete visar den genomsnittliga lönen för arbeten som utför utför ungefär samma arbetsuppgifter. Snittlönerna för likvärdiga arbeten inkluderar arbeten som utför likvärdiga arbeten, där likvärdiga inte har samma arbetsuppgifter men har samma kravnivå (ju högre nivå desto högre krav och komplexitet i arbetet).
` : rangeChecked && !averageChecked && infoChecked ?
                        `${this.state.salaryRangeSelected === "" ? "" : `Ovanför ser du ett lönespann. Detta är skapat av oss som arbetsgivare och är alltså inom det spann där vi förväntar oss att lönen ska ligga för ${this.state.whatSalaryRange === "individual" ? "ditt arbete" : this.state.whatSalaryRange === "overall" ? "kravnivån" : this.state.whatSalaryRange === "family" ? `jobbfamiljen ${this.state.salaryRangeSelected.familyName} inom denna kravnivå` : `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" och underkategorin "${this.state.salaryRangeSelected.name}" inom denna kravnivå`}.`}`
                        : ""
        } else {
            info = rangeChecked && averageChecked && infoChecked ?
                `Ovanstående löner är ${this.state.totalSalary === "yes" ? "inklusive" : "exklusive"} eventuella tillägg. Snittlönerna visar den genomsnittliga lönen för din arbetstagarkategori. En arbetstagarkategori inkluderar arbeten som utför lika och likvärdiga arbeten, där likvärdiga inte nödvändigtvis har samma arbetsuppgifter men har samma kravnivå (ju högre nivå desto högre krav och komplexitet i arbetet).
            
${this.state.salaryRangeSelected === "" ? "" : `Utöver snittlöner ser du även lönespannet. Detta är skapat av oss som arbetsgivare och är alltså inom det spann där vi förväntar oss att lönen ska ligga för ${this.state.whatSalaryRange === "individual" ? "ditt arbete" : this.state.whatSalaryRange === "overall" ? "kravnivån" : this.state.whatSalaryRange === "family" ? `jobbfamiljen ${this.state.salaryRangeSelected.familyName} inom denna kravnivå` : `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" och underkategorin "${this.state.salaryRangeSelected.name}" inom denna kravnivå`}.`}`
                : !rangeChecked && averageChecked && infoChecked ?
                    `Ovanstående löner är ${this.state.totalSalary === "yes" ? "inklusive" : "exklusive"} eventuella tillägg. Snittlönerna visar den genomsnittliga lönen för din arbetstagarkategori. En arbetstagarkategori inkluderar arbeten som utför lika och likvärdiga arbeten, där likvärdiga inte nödvändigtvis har samma arbetsuppgifter men har samma kravnivå (ju högre nivå desto högre krav och komplexitet i arbetet).
            ` : rangeChecked && !averageChecked && infoChecked ?
                        `${this.state.salaryRangeSelected === "" ? "" : `Ovanför ser du ett lönespann. Detta är skapat av oss som arbetsgivare och är alltså inom det spann där vi förväntar oss att lönen ska ligga för ${this.state.whatSalaryRange === "individual" ? "ditt arbete" : this.state.whatSalaryRange === "overall" ? "kravnivån" : this.state.whatSalaryRange === "family" ? `jobbfamiljen ${this.state.salaryRangeSelected.familyName} inom denna kravnivå` : `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" och underkategorin "${this.state.salaryRangeSelected.name}" inom denna kravnivå`}.`}`
                        : ""

        }

        let body = `Hej!
    
Här är din löneinformation.

Information om din anställning:
Lön: ${!this.state.employeeSelected ? "" : this.state.totalSalary === "yes" ? this.state.employeeSelected[0].totalSalary : this.state.employeeSelected[0].salary}
Befattning: ${!this.state.employeeSelected ? "" : this.state.employeeSelected[0].positionTitle}
Lika arbete: ${!this.state.employeeSelected ? "" : this.state.employeeSelected[0].groupNameArbVardering}
Nivå: Grupp ${this.state.whatEvaluation === "edited" ? this.state.employeeSelected[0].editedEqualentGroup : this.state.whatEvaluation === "extLevel" ? this.state.employeeSelected[0].extEqualentGroup : this.state.employeeSelected[0].equalentGroup} (av ${this.state.nrOfEqualentGroups})
${avgEqual}  
${avg}

${range}

Notera:
${info}
`;

        // ------ Translation below ------
        if (language !== "sv") {
             avgEqual = averageEqual === "divided" ?
                `
${lang[language].avgSalaryEqualWork}:
${lang[language].men}: ${this.state.equalAvgSalaryMen.toLocaleString()}
${lang[language].women}: ${this.state.equalAvgSalaryWomen.toLocaleString()}
${lang[language].total}: ${this.state.equalAvgSalary.toLocaleString()}
`
                : ""
             avg = averageChecked ?
                `${lang[language].avgSal} ${averageEqual === "divided" ? lang[language].equivalentWork : ""}
${lang[language].men}: ${this.state.menAvgSal.toLocaleString()}
${lang[language].women}: ${this.state.womenAvgSal.toLocaleString()}
${lang[language].total}: ${this.state.totalAvgSal.toLocaleString()}`
                : ""

             range = rangeChecked ?
                `${lang[language].salaryRange}:
Min: ${this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.min}
Mid: ${this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.mid}
Max: ${this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.max}`
                : ""
            if (rangeChecked && this.state.salaryRangeSelected === "") { range = "" }

            if (averageEqual === "divided") { // If equal works is divided from equivalent works
                info = rangeChecked && averageChecked && infoChecked ?
                    `${lang[language].aboveRightInfoThree} ${this.state.totalSalary === "yes" ? lang[language].incBen : lang[language].exBen}. ${lang[language].aboveRightInfoSeven}.

${this.state.salaryRangeSelected === "" ? "" : `${lang[language].aboveRightInfoSix} ${this.state.whatSalaryRange === "individual" ? lang[language].theJob : this.state.whatSalaryRange === "overall" ? lang[language].theLevel : this.state.whatSalaryRange === "family" ? `${lang[language].theJobFamily} ${this.state.salaryRangeSelected.familyName} ${lang[language].forThisLevel}` : `${lang[language].theJobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].andSubfunction} "${this.state.salaryRangeSelected.name}" ${lang[language].forThisLevel}`}.`}`
                    : !rangeChecked && averageChecked && infoChecked ?
                        `${lang[language].aboveRightInfoThree} ${this.state.totalSalary === "yes" ? lang[language].incBen : lang[language].exBen}. ${lang[language].aboveRightInfoSeven}.
` : rangeChecked && !averageChecked && infoChecked ?
                            `${this.state.salaryRangeSelected === "" ? "" : `${lang[language].aboveRightInfoFive} ${this.state.whatSalaryRange === "individual" ? lang[language].theJob : this.state.whatSalaryRange === "overall" ? lang[language].theLevel : this.state.whatSalaryRange === "family" ? `${lang[language].theJobFamily} ${this.state.salaryRangeSelected.familyName} ${lang[language].forThisLevel}` : `${lang[language].theJobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].andSubfunction} "${this.state.salaryRangeSelected.name}" ${lang[language].forThisLevel}`}.`}`
                            : ""
            } else {
                info = rangeChecked && averageChecked && infoChecked ?
                    `${lang[language].aboveRightInfoThree} ${this.state.totalSalary === "yes" ? lang[language].incBen : lang[language].exBen}. ${lang[language].aboveRightInfoFour}.

${this.state.salaryRangeSelected === "" ? "" : `${lang[language].aboveRightInfoSix} ${this.state.whatSalaryRange === "individual" ? lang[language].theJob : this.state.whatSalaryRange === "overall" ? lang[language].theLevel : this.state.whatSalaryRange === "family" ? `${lang[language].theJobFamily} ${this.state.salaryRangeSelected.familyName} ${lang[language].forThisLevel}` : `${lang[language].theJobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].andSubfunction} "${this.state.salaryRangeSelected.name}" ${lang[language].forThisLevel}`}.`}`
                    : !rangeChecked && averageChecked && infoChecked ?
                        `${lang[language].aboveRightInfoThree} ${this.state.totalSalary === "yes" ? lang[language].incBen : lang[language].exBen}. ${lang[language].aboveRightInfoFour}.
` : rangeChecked && !averageChecked && infoChecked ?
                            `${this.state.salaryRangeSelected === "" ? "" : `${lang[language].aboveRightInfoFive} ${this.state.whatSalaryRange === "individual" ? lang[language].theJob : this.state.whatSalaryRange === "overall" ? lang[language].theLevel : this.state.whatSalaryRange === "family" ? `${lang[language].theJobFamily} ${this.state.salaryRangeSelected.familyName} ${lang[language].forThisLevel}` : `${lang[language].theJobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].andSubfunction} "${this.state.salaryRangeSelected.name}" ${lang[language].forThisLevel}`}.`}`
                            : ""

            }
             body = `${lang[language].hello}!

${lang[language].hereIsYouSalaryInfo}

${lang[language].infoAboutYourEmployment}:
${lang[language].salary}: ${!this.state.employeeSelected ? "" : this.state.totalSalary === "yes" ? this.state.employeeSelected[0].totalSalary : this.state.employeeSelected[0].salary}
${lang[language].position}: ${!this.state.employeeSelected ? "" : this.state.employeeSelected[0].positionTitle}
${lang[language].equalWork}: ${!this.state.employeeSelected ? "" : this.state.employeeSelected[0].groupNameArbVardering}
${lang[language].level}: ${lang[language].group}${this.state.whatEvaluation === "edited" ? this.state.employeeSelected[0].editedEqualentGroup : this.state.whatEvaluation === "extLevel" ? this.state.employeeSelected[0].extEqualentGroup : this.state.employeeSelected[0].equalentGroup} (${lang[language].outOff} ${this.state.nrOfEqualentGroups})
${avgEqual}  
${avg}

${range}

${lang[language].noteThis}:
${info}
`;
        }

        const encodedBody = encodeURIComponent(body).replace(/%0A/g, '%0D%0A'); // Changes the code to match the mailTo coding

        const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodedBody}`;

        window.location.href = mailtoLink;// Opens the default email client
    }

    indPopUp = () => {
        let language = localStorage.getItem('language') ?? 'sv';

        /* For text generation based on selection and view */
        // What is selected (checkboxes)
        let averageChecked = this.state.checkBoxAverage // If averages should be shown this is true
        let rangeChecked = this.state.checkBoxRange // If salaryRanges should be shown this is true
        let infoChecked = this.state.checkBoxInfo // If text info should be shown this is true
        // What is shown (live in system)
        let averageEqual = this.state.workCategory // If the average salaries are for the equivalent group only, this is "togheter", it we also show the equal box, then it is "divided"
        let showSalaryRanges = this.state.showSalaryRanges // This is "yes" or "no"
        let totalSalary = this.state.totalSalary // This is "yes" or "no". Yes is with benefits and no is without
        // General text
        let incOrExBen = (totalSalary === "yes" ? (language === "sv" ? "inklusive tillägg" : lang[language].incBen) : (language === "sv" ? "exkl tillägg" : lang[language].exBen))
        let incOrExBenVague = (totalSalary === "yes" ? (language === "sv" ? "inklusive eventuella tillägg" : lang[language].incBen) : (language === "sv" ? "exklusive eventuella tillägg" : lang[language].exBen))
        let dividedOrTogheter = averageEqual === "togheter" ?
            `Ovanstående löner är ${incOrExBenVague}. Snittlönerna visar den genomsnittliga lönen för din arbetstagarkategori. En arbetstagarkategori inkluderar arbeten som utför lika och likvärdiga arbeten, där likvärdiga inte nödvändigtvis har samma arbetsuppgifter men har samma kravnivå (ju högre nivå desto högre krav och komplexitet i arbetet).`
            : `Ovanstående löner är ${incOrExBenVague}. Snittlönerna för lika arbete visar den genomsnittliga lönen för arbeten som utför utför lika arbetsuppgifter. Snittlönerna för likvärdiga arbeten inkluderar arbeten som utför likvärdigt arbeten, där likvärdiga inte har samma arbetsuppgifter men har samma kravnivå (ju högre nivå desto högre krav och komplexitet i arbetet).`
        let dividedOrTogheterOtherLang = language === "sv" ? "" : averageEqual === "togheter" ?
            `${lang[language].aboveRightInfoThree} ${incOrExBenVague} ${lang[language].aboveRightInfoFour}.`
            : `${lang[language].aboveRightInfoThree} ${incOrExBenVague} ${lang[language].aboveRightInfoSeven}.`

        // Text to generate for system
        let systemJustAverage = language === "sv" ? `Ovanstående är info som medarbetaren har rätt att begära ut. Lönerna visas ${incOrExBen}.` : lang[language].aboveRightInfo + incOrExBen + "."
        let systemSalaryRange = language === "sv" ? `Medarbetaren har rätt att begära ut snittlönerna. Lönespann kan vara intressant för medarbetaren, men är inte lagkrav att ge ut. Lönespannet är skapat i jobbarkitekturen och kan redigeras där. Lönerna visas ${incOrExBen}.` : lang[language].aboveRightInfoTwo + incOrExBen + "."
        let systemAboveSalaryRange = language === "sv" ? (`Nedan visar var medarbetaren ligger i förhållande till lönespannet som gäller för ${this.state.whatSalaryRange === "individual" ? "det specifika arbetet." : this.state.whatSalaryRange === "overall" ? "kravnivån." : this.state.whatSalaryRange === "family" ? `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" inom denna kravnivå.` : `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" och underkategorin "${this.state.salaryRangeSelected.name}" inom denna kravnivå.`}`) :
            (`${lang[language].belowSalaryRangeInfo} ${this.state.whatSalaryRange === "individual" ? lang[language].specificJob : this.state.whatSalaryRange === "overall" ? lang[language].level : this.state.whatSalaryRange === "family" ? `${lang[language].jobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].forThisLevel}.` : `${lang[language].jobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].andSubfunction} "${this.state.salaryRangeSelected.name}" ${lang[language].forThisLevel}.`}`)
        // Text to generate for employee info
        let empInfoAverage = language === "sv" ? dividedOrTogheter : dividedOrTogheterOtherLang
        let empInfoSalaryRanges = language === "sv" ? (`${!averageChecked ? "Ovanför ser du ett lönespann." : "Utöver snittlöner ser du även lönespannet."} Detta är skapat av oss som arbetsgivare och är alltså inom det spann där vi förväntar oss att lönen ska ligga för ${this.state.whatSalaryRange === "individual" ? "det specifika arbetet." : this.state.whatSalaryRange === "overall" ? "kravnivån." : this.state.whatSalaryRange === "family" ? `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" inom denna kravnivå.` : `jobbfamiljen "${this.state.salaryRangeSelected.familyName}" och underkategorin "${this.state.salaryRangeSelected.name}" inom denna kravnivå.`}`)
            : (`${!averageChecked ? lang[language].aboveRightInfoFive : lang[language].aboveRightInfoSix} ${this.state.whatSalaryRange === "individual" ? lang[language].specificJob : this.state.whatSalaryRange === "overall" ? lang[language].level : this.state.whatSalaryRange === "family" ? `${lang[language].jobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].forThisLevel}.` : `${lang[language].jobFamily} "${this.state.salaryRangeSelected.familyName}" ${lang[language].andSubfunction} "${this.state.salaryRangeSelected.name}" ${lang[language].forThisLevel}`}`)

        // Get it togheter (total salary is already handled above, also if we show the average salaries divided or togheter)
        let textForSystem = showSalaryRanges === "no" ? systemJustAverage : systemSalaryRange
        let textForSystemAboveSalaryRange = systemAboveSalaryRange // also below we have an alternative to show "no salary ranges created"
        let textForEmpOnShare = (infoChecked && averageChecked && rangeChecked) ? empInfoAverage + " " + empInfoSalaryRanges :
            (infoChecked && averageChecked && !rangeChecked) ? empInfoAverage :
                (infoChecked && !averageChecked && rangeChecked) ? empInfoSalaryRanges : ""
        /* End of text generation */

        return <>
            <div className="backgroundBlackHide"></div>
            <div className="individuealPopBox" ref={this.componentRefEmployee} style={{ width: this.state.workCategory === "divided" ? 883 : 593 }} >
                <img alt="" src={exitImg} className="hide-box" style={{ width: 22, marginRight: -10, marginTop: -10 }} onClick={this.showSearchBox} />
                <div style={{ float: "right", marginRight: 15 }} >
                    <img alt="settings" src={settings} className="settingsIconEmployeeSearch" onClick={this.showSettingsEmployee} />
                    <div className={this.state.showSettingsEmployee ? "settingsEmployeeContainer" : "hide"}>
                        <div style={{ paddingBottom: 10, borderBottom: "1px solid #d7d7d7", marginLeft: -10, marginRight: -10, paddingLeft: 10, paddingRight: 10 }}>
                            <div style={{ display: "inline-block", width: 130, fontSize: 13 }}>{language === "sv" ? "Separera lika arbete" : lang[language].separateEqual}</div>
                            <div style={{ display: "inline-block" }}>
                                <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition" activeBoxShadow="none" onChange={this.switchChangeSeparation} checked={this.state.workCategory === "divided" ? true : false} width={41} height={21} />
                            </div>
                        </div>
                        <div style={{ paddingBottom: 10, borderBottom: "1px solid #d7d7d7", marginLeft: -10, marginRight: -10, paddingLeft: 10, paddingRight: 10, marginTop: 10 }}>
                            <div style={{ display: "inline-block", width: 130, fontSize: 13 }}>{language === "sv" ? "Lön inkl tillägg" : lang[language].salIncBen}</div>
                            <div style={{ display: "inline-block" }}>
                                <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition" activeBoxShadow="none" onChange={this.switchSalaryInclAddon} checked={this.state.totalSalary === "yes" ? true : false} width={41} height={21} />
                            </div>
                        </div>
                        <div style={{ paddingBottom: 10, marginLeft: -10, marginRight: -10, paddingLeft: 10, paddingRight: 10, marginTop: 10 }}>
                            <div style={{ display: "inline-block", width: 130, fontSize: 13 }}>{language === "sv" ? "Visa lönespann" : lang[language].showSalaryRange}</div>
                            <div style={{ display: "inline-block" }}>
                                <Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition" activeBoxShadow="none" onChange={this.switchSalaryRange} checked={this.state.showSalaryRanges === "yes" ? true : false} width={41} height={21} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 18, fontWeight: 900 }}>{this.state.employeeSelected[0].fullName}</div>
                <div style={{ marginTop: 40 }}>
                    <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                        <div style={{ display: "inline-block", width: "50%" }}>
                            <div className="inBoxInline">
                                <div className="inTitleBox">{language === "sv" ? "Lön" : lang[language].salary}:</div>
                                <div style={{ display: "inline-block", width: 147, marginBottom: 10 }}>{this.state.employeeSelected[0].totalSalary.toLocaleString()} kr</div>
                            </div>
                            <div className="inBoxInline">
                                <div className="inTitleBox">{language === "sv" ? "Befattning" : lang[language].position}:</div>
                                <div style={{ display: "inline-block", width: 147, marginBottom: 10 }}>{this.state.employeeSelected[0].positionTitle}</div>
                            </div>
                            <div className="inBoxInline">
                                <div className="inTitleBox">{language === "sv" ? "Lika arbete" : lang[language].equalWork}:</div>
                                <div style={{ display: "inline-block", width: 147, marginBottom: 10 }}>{this.state.employeeSelected[0].groupNameArbVardering}</div>
                            </div>
                            <div className="inBoxInline">
                                <div className="inTitleBox" style={{ marginBottom: 0 }}>{language === "sv" ? "Likvärdigt arbete" : lang[language].equivalentWork}:</div>
                                <div style={{ display: "inline-block", width: 147 }}>{language === "sv" ? "Nivå" : lang[language].level} {this.state.whatEvaluation === "edited" ? this.state.employeeSelected[0].editedEqualentGroup : this.state.whatEvaluation === "extLevel" ? this.state.employeeSelected[0].extEqualentGroup : this.state.employeeSelected[0].equalentGroup} <span>({language === "sv" ? "av" : lang[language].outOff} {this.state.nrOfEqualentGroups})</span></div>
                            </div>
                        </div>
                        <div className="searchAverageInfo" style={{ width: "50%", textAlign: "center", verticalAlign: "top", display: this.state.workCategory === "divided" ? "inline-block" : "none" }}>
                            <div className="salaryBoxContainerTop">
                                <div style={{ fontWeight: 600, paddingBottom: 10, textAlign: "left" }}>{language === "sv" ? "Snittlön för lika arbete" : lang[language].avgSalaryEqualWork}</div>
                                <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">{language === "sv" ? "Män" : lang[language].men}: </span>{this.state.equalAvgSalaryMen.toLocaleString()} kr</div>
                                <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">{language === "sv" ? "Kvinnor" : lang[language].women}: </span>{this.state.equalAvgSalaryWomen.toLocaleString()} kr</div>
                                <div className="inTitleBoxChild" style={{ marginBottom: 0 }}><span className="inTitleBoxChildTitle">{language === "sv" ? "Totalt" : lang[language].total}: </span>{this.state.equalAvgSalary.toLocaleString()} kr</div>
                            </div>
                        </div>
                        <div className="searchAverageInfo" style={{ display: "inline-block", width: "50%", textAlign: "center", verticalAlign: "top" }}>
                            <div className="salaryBoxContainerTop">
                                <div style={{ fontWeight: 600, paddingBottom: 10, textAlign: "left" }}>{language === "sv" ? "Snittlön för likvärdigt arbete" : lang[language].avgSalaryEquivalentWork}</div>
                                <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">{language === "sv" ? "Män" : lang[language].men}: </span>{this.state.menAvgSal.toLocaleString()} kr</div>
                                <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">{language === "sv" ? "Kvinnor" : lang[language].women}: </span>{this.state.womenAvgSal.toLocaleString()} kr</div>
                                <div className="inTitleBoxChild" style={{ marginBottom: 0 }}><span className="inTitleBoxChildTitle">{language === "sv" ? "Totalt" : lang[language].total}: </span>{this.state.totalAvgSal.toLocaleString()} kr</div>
                            </div>
                        </div>
                    </div>
                    {/* PopUp for what to share */}
                    <div className={this.state.popWhatShare ? "individuealPopBox individuealPopBoxHideOnPDF" : "hide"} style={{ width: 400 }}>
                        <img alt="" src={exitImg} className="hide-box" style={{ width: 22, marginRight: -10, marginTop: -10 }} onClick={this.closePopWhatShare} />
                        <div style={{ textAlign: "center", fontWeight: 600, marginBottom: 20 }}>{language === "sv" ? "Välj vad du vill dela" : lang[language].chooseWhatShare}</div>
                        <div className="salaryBoxContainerTop">
                            <div>
                                <input
                                    style={{ marginTop: 0 }}
                                    className={this.state.checkBoxAverage ? "checkBoxType" : "checkBoxType checkBoxEmpty"}
                                    type="checkbox"
                                    id="checkBoxAverage"
                                    name="checkBoxAverage"
                                    checked={this.state.checkBoxAverage}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label className="checkBoxTypeLabel" htmlFor="checkBoxAverage">
                                    {language === "sv" ? "Snittlöner (lagkrav)" : lang[language].avgSalaryLaw}
                                </label>
                            </div>
                            <div>
                                <input
                                    className={this.state.checkBoxInfo ? "checkBoxType" : "checkBoxType checkBoxEmpty"}
                                    type="checkbox"
                                    id="checkBoxInfo"
                                    name="checkBoxInfo"
                                    checked={this.state.checkBoxInfo}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label className="checkBoxTypeLabel" htmlFor="checkBoxInfo">
                                    {language === "sv" ? "Förklaring av data" : lang[language].dataExplanation}
                                </label>
                            </div>
                            <div style={{ display: this.state.showSalaryRanges === "no" ? "none" : "block" }}>
                                <input
                                    className={this.state.checkBoxRange ? "checkBoxType" : "checkBoxType checkBoxEmpty"}
                                    type="checkbox"
                                    id="checkBoxRange"
                                    name="checkBoxRange"
                                    checked={this.state.checkBoxRange}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label className="checkBoxTypeLabel" htmlFor="checkBoxRange">
                                    {language === "sv" ? "Lönespann" : lang[language].salaryRange}
                                </label>
                            </div>
                            <div style={{ display: this.state.showSpread === "yes" ? "block" : "none" }}>
                                <input
                                    className={this.state.checkBoxSpread ? "checkBoxType" : "checkBoxType checkBoxEmpty"}
                                    type="checkbox"
                                    id="checkBoxSpread"
                                    name="checkBoxSpread"
                                    checked={this.state.checkBoxSpread}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label className="checkBoxTypeLabel" htmlFor="checkBoxSpread">
                                    {language === "sv" ? "Lönespridning" : lang[language].salarySpread}
                                </label>
                            </div>
                        </div>
                        <div style={{ margin: "auto", textAlign: "center", marginTop: 30, borderTop: "1px solid #dcdcdc", marginLeft: -20, marginRight: -20, paddingTop: 15, paddingBottom: 15 }}>
                            <ReactToPrint
                                trigger={() => <a className="searchInfoShareBtn" href="#" style={{ display: "inline-block", marginLeft: 5, marginRight: 5 }}>{language === "sv" ? "Skapa PDF" : lang[language].createPDF}</a>}
                                content={() => this.componentRefEmployee.current}
                                pageStyle={`
                                            @page {
                                                size: A4 landscape; /* Force landscape mode */
                                                margin: 0; /* Set to 0 to avoid extra space */
                                            }
                                            @media print {
                                                body {
                                                    margin: 0;
                                                    padding: 0;
                                                    -webkit-print-color-adjust: exact;
                                                    color-adjust: exact;
                                                }
                                                .individuealPopBoxHideOnPDF {
                                                    display: none !important;
                                                }
                                                    .infoForEmployee {
                                                    display: inline-block !important;
                                                    }
                                                    .infoForHR {
                                                    display: none !important;
                                                    }
                                                    .searchInfoShareBtn {
                                                    display: none !important;
                                                    }
                                                    .salary-marker {
                                                    box-shadow: none !important;
                                                    }
                                                    .hide-box {
                                                    display: none
                                                    }
                                                    .settingsIconEmployeeSearch {
                                                    display: none
                                                    }
                                                    .settingsEmployeeContainer {
                                                    display: none
                                                    }
                                                    .hideOnPDF {
                                                    display: none !important;
                                                    }
                                                    .hideWhenNotPDF {
                                                    display: block !important;
                                                    }
                                                    .searchAverageInfo {
                                                    display: ${this.state.checkBoxAverage ? "block" : "none !important"}
                                                    }
                                                    .searchSalaryRangesInfo {
                                                       display: ${this.state.checkBoxRange ? "block" : "none !important"};
                                                        padding-bottom: ${!this.state.checkBoxInfo && this.state.checkBoxRange ? "100px" : "0px"}
                                                    }
                                                       .inBoxBottomDiv {
                                                       display: ${this.state.checkBoxInfo ? "block" : "none !important"}
                                                       }
                                                       .searchTextInfoRange {
                                                       display: ${this.state.checkBoxInfo && this.state.checkBoxRange ? "block" : "none !important"}
                                                       }
                                                        .searchTextInfoAvg {
                                                      display: ${this.state.checkBoxInfo && this.state.checkBoxAverage ? "block" : "none !important"}
                                                       }
                                            }
                                        `}
                            />
                            <div className="searchInfoShareBtn" style={{ display: "inline-block", marginLeft: 5, marginRight: 5 }} onClick={this.createMail}>{language === "sv" ? "Skicka Mail" : lang[language].sendEmail}</div>
                        </div>
                    </div>
                    <div className={this.state.showSalaryRanges === "yes" ? "hide" : "inBoxBottomDiv hideOnPDF"}>
                        <span class="informationMarkImg">i</span>
                        <span>{textForSystem}</span>
                        <div className="searchInfoShareBtn" onClick={this.openPopWhatShare}>{language === "sv" ? "Dela info" : lang[language].shareInfo}</div>
                    </div>
                    <div className={this.state.showSalaryRanges === "yes" ? "hide" : "inBoxBottomDiv hideWhenNotPDF"}>
                        <div style={{ display: "inline-block", verticalAlign: "top" }}>
                            <span class="informationMarkImg">i</span>
                        </div>
                        <span style={{ width: "84%", textAlign: "justify" }} className="infoForEmployee">
                            <span className="searchTextInfoAvg">{textForEmpOnShare}</span>
                        </span>
                        <div className="searchInfoShareBtn" onClick={this.openPopWhatShare}>{language === "sv" ? "Dela info" : lang[language].shareInfo}</div>
                    </div>

                    <div className={this.state.showSalaryRanges !== "yes" && "hide"} style={{ marginTop: 50 }}>

                        <div className="searchSalaryRangesInfo">
                            <div style={{ display: this.state.showSpread === "yes" ? "block" : "none" }} className="searchInfoSelectionContainer">
                                <div className={this.state.rangeBarWhat === "salaryRanges" ? "searchInfoSelectionItem searchInfoSelectionItemActive" : "searchInfoSelectionItem"} id="salaryRanges" onClick={this.changeRangeBar}>{language === "sv" ? "Lönespann" : lang[language].salaryRange}</div>
                                <div className={this.state.rangeBarWhat === "spread" ? "searchInfoSelectionItem searchInfoSelectionItemActive" : "searchInfoSelectionItem"} id="spread" onClick={this.changeRangeBar}>{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</div>
                            </div>

                            <div className="seachInfoSelectionInfo">
                                <div style={{ fontWeight: 600, marginBottom: 15, fontSize: 16 }}>{language === "sv" ? "Lönespann" : lang[language].salaryRange}</div>
                                {this.state.salaryRangeSelected !== "" ? textForSystemAboveSalaryRange : <></>}
                            </div>
                            {this.state.salaryRangeSelected !== "" ?
                                <SalaryRangeBar
                                    percentage={this.state.salaryRangeSelected === "" ? 0 : ((this.state.salaryRangeSelected.mid - this.state.salaryRangeSelected.min) / (this.state.salaryRangeSelected.max - this.state.salaryRangeSelected.min)) * 100}
                                    employeeSalary={!this.state.employeeSelected ? 0 : this.state.employeeSelected[0].salary}
                                    midPoint={this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.mid}
                                    min={this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.min}
                                    max={this.state.salaryRangeSelected === "" ? 0 : this.state.salaryRangeSelected.max}
                                    rangeBarWhat={this.state.rangeBarWhat} />
                                : <span style={{ display: "block", margin: "auto", width: "fit-content", fontStyle: "italic", color: "grey", marginTop: -20, marginBottom: -20, fontSize: 14 }}>- {language === "sv" ? "Inget lönespann skapat" : lang[language].noSalaryRangeCreated} -</span>}

                        </div>
                        <div className="inBoxBottomDiv" style={{ marginTop: 90 }}>
                            <div>
                                <div style={{ display: "inline-block", verticalAlign: "top" }}>
                                    <span class="informationMarkImg">i</span>
                                </div>
                                <span style={{ display: "inline-block", width: "84%", textAlign: "justify" }} className="infoForHR">{textForSystem}</span>
                                <span style={{ width: "84%", textAlign: "justify" }} className="infoForEmployee">
                                    <span className="searchTextInfoAvg">
                                        {textForEmpOnShare}
                                    </span>
                                </span>
                            </div>
                            <div className="searchInfoShareBtn" onClick={this.openPopWhatShare}>{language === "sv" ? "Dela info" : lang[language].shareInfo}</div>
                        </div>
                    </div>
                </div>
            </div ></>
    }
    enterYearInfo = () => { this.setState({ infoPopPastYear: true }) }
    leaveYearInfo = () => { this.setState({ infoPopPastYear: false }) }
    componentDidMount() {
        const getSettings = async () => {
            let nameStatus = localStorage.getItem("settingName");
            let salaryStatus = localStorage.getItem("settingPercent");
            nameStatus === "true" ? nameStatus = true : nameStatus = false
            salaryStatus === "true" ? salaryStatus = true : salaryStatus = false
            let localT = localStorage.getItem('localT');
            let { role, aComp } = jwtDecode(localT);
            let year = localStorage.getItem("year")
            if (year == undefined) { year = new Date().getFullYear() }
            let showArc = localStorage.getItem("showArc");
            let settingEU = localStorage.getItem('settingEU');
            let showWhat = settingEU === "on" ? "EU" : "original"

            this.setState({ hideName: nameStatus, hideSalary: salaryStatus, r: role, aComp: aComp, year: year, showArc: showArc, showWhat: showWhat })
        }
        getSettings();
    }

    changeRangeBar = (event) => { this.setState({ rangeBarWhat: event.target.id }) }
    openPopWhatShare = () => { this.setState({ popWhatShare: true }); }
    closePopWhatShare = () => { this.setState({ popWhatShare: false }) }

    //componentRefEmployee = React.createRef();
    render() {
        let acc = ""
        let r = this.state.r
        if (r === "Bolag") { acc = "/bolagsVy" }
        else if (r === "Koncern") { acc = "/koncernvy" }
        else if (r === "Konsult") { acc = "/konsultvy" }
        else if (r === "Admin") { acc = "/admin" }
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <ReactTooltip />
                {/* PopUp delete contents*/}
                <div className={this.state.popDeleteContent ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 16, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.PopdeleteContent} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}>{language === "sv" ? "Vill du radera individdata?" : lang[language].deleteDataPop}
                        <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>{language === "sv" ? "Du kan återställa raderad data genom att importera excelfilen på nytt." : lang[language].reImport}</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.PopdeleteContent}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                        {this.state.r === "Bolag" ?
                            <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} id="singleCompBolag" onClick={this.deleteExcel}>{language === "sv" ? "Fortsätt" : lang[language].continue}</span> :
                            this.state.r === "Koncern" || this.state.r === "Konsult" || this.state.r === "Admin" ?
                                <>
                                    <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} id="singleComp" onClick={this.deleteExcel}>{language === "sv" ? "Radera för bolag" : lang[language].deleteForBolag}</span>
                                    <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} id="fullKoncern" onClick={this.deleteExcel}>{language === "sv" ? "Radera för hela koncernen" : lang[language].deleteForKoncern}</span>
                                </>
                                : language === "sv" ? "Något gick fel, prova ladda om sidan" : lang[language].errorTryAgainToLoad}
                    </div>
                </div>
                {/* PopUp Faders */}
                <div style={{ zIndex: 15 }} className={this.state.popDeleteContent ? "popUpFader" : "hide"}></div>

                {/*Menu */}
                <div className="topnav">
                    <a className="logo"><img src={logoGreen} style={{ marginTop: -9, width: 150, marginLeft: -9 }} /></a>
                    <a onClick={this.showDropDown} className="menu-item business">{this.state.aComp} <img alt="" src={this.state.showDropDown === false ? arrowDown : arrowUp} className="arrow-down" /></a>
                    <NavLink exact to="/" className="menu-item introduktion-item">{language === "sv" ? "Introduktion" : lang[language].introduction}</NavLink>
                    {(this.state.showArc === "on" || (this.state.showWhat === "EU" && this.state.showArc === "adminOn")) && <NavLink exact to="/arkitektur" className="menu-item introduktion-item">{language === "sv" ? "Arkitektur" : lang[language].architecture}</NavLink>}
                    {this.state.showWhat === "EU" && <NavLink exact to="/rapportering" className="menu-item introduktion-item">{language === "sv" ? "Rapportering" : lang[language].reporting}</NavLink>}
                    <NavLink exact to="/overview" className="menu-item introduktion-item">{language === "sv" ? "Översikt" : lang[language].overview}</NavLink>
                    <div className={this.state.showWhat === "EU" ? "" : "hide"}>
                        <img onClick={this.showSearchBox} src={searchIcon} className={!this.state.showSearchBox ? "searchIndividualIcon" : "hide"} />
                        <div style={{ float: "right" }}>
                            <div className={this.state.showSearchBox ? "searchBoxIndividual" : "hide"}> <input className="searchBoxIndInput" type="text" placeholder={language === "sv" ? "Sök medarbetare" : lang[language].searchEmp} onChange={this.searchChange} value={this.state.searchString} /><img onClick={this.showSearchBox} src={exitImg} className="searchIndividualIconInside" style={{ opacity: 0.8 }} /></div>
                            <ul className={this.state.showSearchBox && this.state.filteredIndividuals.length > 0 ? "searchIndividualDropdown" : "hide"}>
                                {this.state.filteredIndividuals.map(employee =>
                                    <li className="searchIndividualLi" onClick={this.selectEmployee} data-name={employee.fullName} data-age={employee.age} data-exp={employee.experience} data-title={employee.positionTitle} ><div className="inlineListFatLeft">{employee.fullName} <span style={{ color: "grey", fontSize: 13 }}>| {employee.age} {language === "sv" ? "år" : lang[language].years}, {employee.positionTitle}</span></div></li>
                                )}
                            </ul>
                            {this.state.employeeSelected && this.indPopUp()}
                        </div>
                    </div>

                    {/*  {this.state.year == new Date().getFullYear() || this.state.year === undefined ? "" : <span style={{ background: "#eaac3a", marginTop: 12, display: "inline-block", padding: 7, borderRadius: 3, float: "right", marginRight: 35, color: "white", fontWeight: 600, }} data-tip="Gå till inställningar för att ändra till nuvarande år."><b>OBS: </b>Kartläggning utförs för år {this.state.year}</span>} */}
                    {this.state.year == new Date().getFullYear() || this.state.year === undefined ? "" : <span onMouseEnter={this.enterYearInfo} onMouseLeave={this.leaveYearInfo} style={{ background: "#eaac3a", marginTop: 12, display: "inline-block", padding: 7, borderRadius: 3, float: "right", marginRight: 35, color: "white", fontWeight: 600, }}><b>OBS: </b>{language === "sv" ? "Kartläggning utförs för år" : lang[language].madeForYear} {this.state.year}  <span className={this.state.infoPopPastYear ? "infoPopPastYear" : "hide"}>{language === "sv" ? "Gå till inställningar för att ändra till nuvarande år" : lang[language].goToSettingsToChangeYear}</span></span>}
                </div>

                <div className={this.state.showDropDown ? "dropDown" : "hideDropDown"}>
                    <div className={this.state.showDropDown ? "dropDown-inner" : ""}>
                        <div>
                            <div style={{ fontWeight: 800, fontSize: 17.6, paddingLeft: 20, paddingRight: 20, paddingTop: 38, paddingBottom: 38 }}>{this.state.aComp}</div>
                        </div>
                        {/*<div style={{ borderBottom: "none" }} className="dropDownAccount" onClick={this.toggleAccount}><img alt="" src={account} style={{ width: 16, float: "right" }} /><span>Konto</span></div>*/}
                        <NavLink exact to={acc} style={{ color: "white" }}><div style={{ borderBottom: "none" }} className="dropDownAccount" ><img alt="" src={account} style={{ width: 16, float: "right" }} /><span>{language === "sv" ? "Konto" : lang[language].account}</span></div></NavLink>
                        <div className="dropDownAccount" onClick={this.toggleSettings}><img alt="" src={settings} style={{ width: 16, float: "right" }} /><span>{language === "sv" ? "Inställningar" : lang[language].settings}</span></div>
                        {/*<NavLink exact to="/costumerAccounts" style={{ color: "white" }}><div style={{borderTop: "none"}} className="dropDownAccount" ><img alt="" src={customerAcc} style={{ width: 17, float: "right" }} /><span>Kundkonton</span></div></NavLink>*/}
                        <div className="dropDownLogOut" onClick={this.logout}><img alt="" src={logOut} style={{ width: 16, float: "right" }} /><span>{language === "sv" ? "Logga Ut" : lang[language].logout}</span></div>
                    </div>
                </div>

                {/* PopUp Faders */}
                <div className={this.state.popUp ? "popUpFader" : "hide"}></div>
                {/* Settings */}
                <div style={{ marginTop: 107 }} className={this.state.popUp && this.state.settings ? "active-popUp-TopNav" : "hide"}>
                    <div className={this.state.popUp && this.state.settings ? "active-popUp-TopNav-inner" : "hide"}>
                        <div className="settingsInline" style={{ borderRight: "1px solid #e9e9e9" }}>
                            <div className="kontoText"><span style={{ fontWeight: 800 }}>{language === "sv" ? "Dölj information" : lang[language].hideInfo}</span></div>
                            <div className="kontoText" style={{ paddingTop: 20 }}><span style={{ display: "inline-block", width: language !== "sv" ? 105 : 80, fontSize: 15.6 }}>{language === "sv" ? "Dölj namn: " : lang[language].hideName}</span><Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition" activeBoxShadow="none" onChange={this.switchChangeName} checked={this.state.hideName} /></div>
                            <div className="kontoText"><span style={{ display: "inline-block", width: language !== "sv" ? 105 : 80, fontSize: 15.6 }}>{language === "sv" ? "Dölj löner: " : lang[language].hideSalary}</span><Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition" activeBoxShadow="none" onChange={this.switchChangeSalary} checked={this.state.hideSalary} /></div>
                            <div style={{ fontSize: 11, position: "fixed", marginLeft: 35, marginTop: -13, borderTop: "1px solid #e9e9e9", width: 73 }}>{language === "sv" ? "Visas i %" : lang[language].showInPercent}</div>
                            {this.state.settingsChanged ? <div style={{ margin: "auto", fontSize: 15.6, position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)" }} className="kontoButton" onClick={this.saveSettings}>{language === "sv" ? "Spara" : lang[language].save}</div> : <div style={{ margin: "auto", marginTop: 30 }} className="kontoButton inactive-konto-button">{language === "sv" ? "Sparat" : lang[language].saved}</div>}
                        </div>
                        <div className="settingsInline" style={{ borderRight: "1px solid #e9e9e9" }}>
                            <div className="kontoText"><span style={{ fontWeight: 800 }}>{language === "sv" ? "Välj år" : lang[language].selectYear}</span></div>
                            <div className="kontoText" style={{ fontSize: 14.6 }}>{language === "sv" ? "Justera vilket år lönekartläggningen gäller. Noteringar hämtas och sparas för detta år, likaså dokumentationen." : lang[language].yearInfo}</div>
                            {/* <div className="kontoText" style={{ fontSize: 11, borderTop: "1px solid #e9e9e9", marginLeft: 25, marginRight: 25 }}>Notera att individdata även raderas automatiskt efter 30 dagar av säkerhetsskäl. </div>*/}
                            <select name="theYear" id="theYear" onChange={this.changeYear} value={this.state.year}>
                                <option value={new Date().getFullYear()}>{language === "sv" ? "Nuvarande år" : lang[language].thisYear}</option>
                                <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                                <option value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</option>
                                <option value={new Date().getFullYear() - 4}>{new Date().getFullYear() - 4}</option>
                            </select>
                        </div>
                        <div className="settingsInline">
                            <div className="kontoText"><span style={{ fontWeight: 800 }}>{language === "sv" ? "Radera data" : lang[language].deleteData}</span></div>
                            <div className="kontoText" style={{ fontSize: 14.6 }}>{language === "sv" ? "Radera individdata. Notera att endast individdata raderas (data från uppladdad excelfil). Kommentarer etc. finns kvar." : lang[language].deleteInfo}</div>
                            {/* <div className="kontoText" style={{ fontSize: 11, borderTop: "1px solid #e9e9e9", marginLeft: 25, marginRight: 25 }}>Notera att individdata även raderas automatiskt efter 30 dagar av säkerhetsskäl. </div>*/}
                            <div className="kontoButton" style={{ margin: "auto", background: "#cb5656", fontSize: 15.6, position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)" }} onClick={this.PopdeleteContent}>{language === "sv" ? "Radera data" : lang[language].deleteData}</div>
                        </div>
                    </div>
                    {/* <div className="moreSettingsBtn">Fler inställningar</div> */}
                    <div className="moreSettingsBackground">
                        <NavLink exact to="/settings">
                            <div className="moreSettingsBtn" ><span><img src={settings} style={{ width: 16, opacity: 0.4, marginRight: 7.5, filter: "invert(100%)", verticalAlign: "top" }} />{language === "sv" ? "Fler inställningar" : lang[language].moreSettings}</span></div>
                        </NavLink>
                    </div>
                </div>
            </>
        );
    }
}