import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomPrompt = ({ when, message }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!when) return;

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message; // For modern browsers
      return message; // For some older browsers
    };

    const handleNavigation = (event) => {
      if (when) {
        const confirmed = window.confirm(message);
        if (confirmed) {
          navigate(event.target.href, { replace: true }); // Allow navigation
        } else {
          event.preventDefault(); // Prevent navigation
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    document.querySelectorAll('a').forEach((anchor) => {
      anchor.addEventListener('click', handleNavigation);
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.querySelectorAll('a').forEach((anchor) => {
        anchor.removeEventListener('click', handleNavigation);
      });
    };
  }, [when, message, navigate]);

  return null; // This component does not render anything
};

export default CustomPrompt;
