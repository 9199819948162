import React from 'react';
import './App.css';
import { Import } from './components/Importera/Import';
import { Overview } from './components/Overview/Overview';
import { GrupperaLika } from './components/GrupperaLika/GrupperaLika';
import { Arbetsvardera } from './components/Arbetsvardera/Arbetsvardera';
import { Lika } from './components/AnalysLika/Lika';
import { Likvardiga } from './components/AnalysLikvardiga/Likvardiga';
import { Dokumentera } from './components/Dokumentera/Dokumentera';
import { Login } from './components/Login/Login';
import { Utvardera } from './components/Utvardera/Utvardera';
import { Introduktion } from './components/Introduktion/Introduktion';
import { Admin } from './components/KoncernVy/Admin';
import { KoncernVy } from './components/KoncernVy/Koncernvy';
import { KonsultVy } from './components/KoncernVy/KonsultVy';
import { TwoFactor } from './components/Login/twoFactor';
import { BolagsVy } from './components/KoncernVy/BolagsVy';
import Settings from './components/moreSettings/Settings';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { fetchAuth } from './fetch-auth';
import { Arkitektur } from './components/Arkitektur/Arkitektur';
import { Foraldraledighet } from './components/Foraldraledighet/Foraldraledighet';
import { Reporting } from './components/Rapportering/Reporting';
/*if (process.env.NODE_ENV === "development") {console.log("its dev!")}
if (process.env.NODE_ENV === "production") {console.log("its prod!")}*/
//import { useEffect } from "react";
//import { useNavigate } from "react-router-dom";

const auth = {
  isAuthenticated: true
}

function PrivateRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) { //If no local token
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp } = jwtDecode(localT);
    if (Date.now() <= exp * 1000) { //valid
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  // If authenticated, render children; otherwise, redirect to login page
  return auth.isAuthenticated ? children : <Navigate to='/login' />;
}

function AdminRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) { //If no local token
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwtDecode(localT);
    //  console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Admin") { //both are validvalid
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  // If authenticated as Admin, render children; otherwise, redirect to login page
  return auth.isAuthenticated ? children : <Navigate to='/login' />;
}

function KoncernRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) { //If no local token
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwtDecode(localT);
    // console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Koncern") { //both are validvalid
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  // Return the correct route based on authentication status
  return auth.isAuthenticated ? children : <Navigate to="/login" />;
}

function KonsultRoute({ children }) {
  if (localStorage.getItem('localT') == null || undefined) {
    //  console.log('no localToken!')
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwtDecode(localT);
    //   console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Konsult") {
      //   console.log('Valid date and correct role (Konsult)')
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  return auth.isAuthenticated ? children : <Navigate to="/login" />;
}

function BolagsRoute({ children }) {
  if (localStorage.getItem('localT') == null || undefined) {
    //    console.log('no localToken!')
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwtDecode(localT);
    //  console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Bolag") {
      //    console.log('Valid date and correct role (Konsult)')
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  return auth.isAuthenticated ? children : <Navigate to="/login" />;
}

function LogRoute({ children, ...rest }) {
  return !auth.isAuthenticated ? children : <Navigate to="/" />;
}

class App extends React.Component {
  // ComponentDidMount: Clear cache to make sure user gets the newest version. This update check runs once a day (not hours) and only when we refresh the page.
  componentDidMount() {
    const getData = async () => {
      try {
        // Get todays date and last checked date
        const today = new Date().toDateString() //Todays date
        let checkedUpdate = localStorage.getItem('checkedUpdate')
        // If last check is today, do nothing
        if (today == checkedUpdate) {
          return;
        }
        // Set localStorage to today if there is none
        if (checkedUpdate == null || checkedUpdate == "null" || checkedUpdate == undefined) {
          checkedUpdate = today
          localStorage.setItem("checkedUpdate", today);
        }
        // Check for update
        localStorage.setItem("checkedUpdate", today);
        const version = "7.9.5"
        const response = await fetchAuth(`/api/checkUpdate`, 'POST', JSON.stringify({ version: version }));
        let data = await response.json();
        //If there is an update, clear cache
        if (data === "Update") {
          if ('caches' in window) {
            caches.keys().then((names) => {
              // Delete all the cache files
              names.forEach(name => {
                caches.delete(name);
              })
            });
            console.log("Cache emptied")
            window.location.reload(true); //changes only visible after reload
          }
          return;
        }
        // If no update available, then just update last checked time to today
        else {
          localStorage.setItem("checkedUpdate", today);
          console.log("Checked update, no update needed.")
          return;
        }
      } catch (err) {
        console.log("An error occured when trying to update.")
      }
    }
    getData();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Routes>
            <Route path="/twoFactor" element={<TwoFactor />} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/import" element={<PrivateRoute><Import /></PrivateRoute>} />
            <Route path="/overview" element={<PrivateRoute><Overview /></PrivateRoute>} />
            <Route path="/" element={<PrivateRoute><Introduktion /></PrivateRoute>} />
            <Route path="/gruppera" element={<PrivateRoute><GrupperaLika /></PrivateRoute>} />
            <Route path="/gruppera/arbetsvardera" element={<PrivateRoute><Arbetsvardera /></PrivateRoute>} />
            <Route path="/analyslika" element={<PrivateRoute><Lika /></PrivateRoute>} />
            <Route path="/analyslika/analyslikvardiga" element={<PrivateRoute><Likvardiga /></PrivateRoute>} />
            <Route path="/analyslika/foraldraledighet" element={<PrivateRoute><Foraldraledighet /></PrivateRoute>} />
            <Route path="/rapportering" element={<PrivateRoute><Reporting /></PrivateRoute>} />
            <Route path="/analyslika/utvardera" element={<PrivateRoute><Utvardera /></PrivateRoute>} />
            <Route path="/arkitektur" element={<PrivateRoute><Arkitektur /></PrivateRoute>} />
            <Route path="/dokumentera" element={<PrivateRoute><Dokumentera /></PrivateRoute>} />
            <Route path="/koncernVy" element={<KoncernRoute><KoncernVy /></KoncernRoute>} />
            <Route path="/konsultVy" element={<KonsultRoute><KonsultVy /></KonsultRoute>} />
            <Route path="/bolagsVy" element={<BolagsRoute><BolagsVy /></BolagsRoute>} />
            <Route path="/login" element={<LogRoute><Login /></LogRoute>} />
            <Route path="/admin" element={<AdminRoute><Admin /></AdminRoute>} />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;

